import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './womenemp.css'
import './dsr.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function AnandUtsav(props) {
    const { heading, subheading, paragraph } = useContext(DataContext);

	return(
		<div>
			<Header 
				headerClass="allAnandUtsavHeader"
				pageTitle={heading('au_header')}
                pageSubTitle={subheading('au_header')}
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>

			<div style={{backgroundColor: 'white'}} className="my-5">
				<div className="main-video-container-wemp">
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('au_vdo')}</h2>
					<hr style={{backgroundColor: 'black', width: '75px'}}/>
					<h3 className="Subtitle-2regular-26ptleftdark-kulimpark pt-md-3 mx-md-5 text-justify text-md-center">{subheading('au_vdo')}</h3>
					<div style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
						<div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('au_vdo_1')} title="au_vdo_1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
							<aside className="wemp-text wemp-text-left">
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('au_vdo_1')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('au_vdo_1')}}></p>
                            </aside>
                        </div>
                    </div>
                    <div  style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
                        <div  style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
                            <aside className="wemp-text wemp-text-right"> 
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('au_vdo_2')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('au_vdo_2')}}></p>
                            </aside>
                        </div>
                        <div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('au_vdo_2')} title="au_vdo_2" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
						<div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('au_vdo_3')} title="au_vdo_3" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
							<aside className="wemp-text wemp-text-left">
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('au_vdo_3')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('au_vdo_3')}}></p>
                            </aside>
                        </div>
                    </div>
                    <div  style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
                        <div  style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
                            <aside className="wemp-text wemp-text-right"> 
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('au_vdo_4')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('au_vdo_4')}}></p>
                            </aside>
                        </div>
                        <div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('au_vdo_4')} title="au_vdo_4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
        	</div>

			<Footer
                svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
                svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
                svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
            />
		</div>
	)
}

export default AnandUtsav;