import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {FormLabel, FormGroup, FormControlLabel} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { ButtonA } from '../Components/Button.js'
import { TextField, Checkbox, Divider, Grid, Snackbar } from '@material-ui/core';
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import emailjs from 'emailjs-com';
import './pmtform.css'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		boxShadow: 'none',
		backgroundColor: 'transparent'
	},
	title: {
		flex: 1,
		fontWeight: 'normal',
		color: 'grey',
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(3)
	},
	text: {
		flex: 1,
		fontWeight: 'normal',
		color: '#333',
		marginBottom: theme.spacing(2)
	},
	close: {
	    color: 'white',
	    backgroundColor: '#9a0808',
	    position: 'fixed',
	    top: '20px',
	    right: '30px',
		zIndex: '100',
	    '&:hover': {
	      color: 'white',
	      backgroundColor: '#9a0808',
	  	}
    },
	formControl: {
	},
	formControlCheckox: {
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default function FullScreenDialog(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(props.openform);
	const [message, setmessage] = useState(false);
	const [formstatus, setformstatus] = useState('error');
	const [logtext , setlogtext] = useState('');

	const [fname, setfname] = useState('');
	const [lname, setlname] = useState('');
	const [city, setcity] = useState('');
	const [state, setstate] = useState('');
	const [mob, setmob] = useState('');
	const [email, setemail] = useState('');
	const [howq, sethowq] = useState('');
	const [haveq, sethaveq] = useState('');

	const [emailerr, setemailerr] = useState('');
	const [moberr, setmoberr] = useState('');
	const [openloading, setopenloading] = React.useState(false);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [maxWidth, setMaxWidth] = React.useState('md');

	const config = {
		cors: 'https://infinite-bastion-79180.herokuapp.com/', // <optional> doesn't display the cors error
		formUrl: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScEzwPZnVYRmG6BzrQk2zbF0bPXXOsVNEEkCl5Rvu0bEwj_XQ/formResponse',
		fname_id: '1479589101',
		lname_id: '912188441',
		city_id: '1183509015',
		state_id: '800421508',
		mob_id: '590787895',
		email_id: '1033099276',
		howq_id: '261555975',
		haveq_id: '627300984'
	};

	const handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if(name==='fname')
		  setfname(value);
		if(name==='lname')
		  setlname(value);
		if(name==='city')
		  setcity(value);
		if(name==='state')
		  setstate(value);
		if(name==='mob') {
			if (/^[0-9]{1,10}$/.test(+value)) {
				setmob(value);
				if(mob.length!==9) {
					setmoberr('Mobile number is Not Correct');
				}
				else {
					setmoberr('');
				}
			}
		}
		if(name==='howq')
		  sethowq(value);
		if(name==='haveq')
		  sethaveq(value);
		if(name==='email') {
		  	setemail(value);
			// eslint-disable-next-line no-useless-escape
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(value) === false) {
				setemailerr('Email is Not Correct');
				return;
			}
			else {
				setemailerr('');
			}
		}
	};

	const sendEmail = (mail, name) => {
		emailjs.send("service_aaoatkl","template_plxkylm",{
			user_name: name,
			to_email: mail,
		}, "user_sY1m8Ki5Oafoc32NNK9TG");

		emailjs.send("service_6tpaae9","template_ehavusr",{
			form_link: "https://docs.google.com/spreadsheets/d/1qNZ9MxrdfEYvyNQWkWYseIIUVlrNipixs8162r4GoMQ/edit#gid=856272023",
			form_name: "Divya Shishu Ratna",
			to_email: "register@divyashishuratna.com",
		}, "user_WO7pjjJ2kpBHGbUJekjMm");
	}
	
	const handleFormSubmit = async () => {
		setopenloading(true);
		const formData = new FormData()
		if (fname && city && state && mob && email && emailerr==='' && moberr==='') {
			formData.append(`entry.${config.fname_id}`, fname)
			formData.append(`entry.${config.lname_id}`, lname);
			formData.append(`entry.${config.email_id}`, email);
			formData.append(`entry.${config.mob_id}`, mob);
			formData.append(`entry.${config.city_id}`, city);
			formData.append(`entry.${config.state_id}`, state);
			formData.append(`entry.${config.howq_id}`, howq);
			formData.append(`entry.${config.haveq_id}`, haveq);

			setmessage(true);
			setformstatus('info');
			setlogtext('Submitting');
			await axios({
				url: `${config.cors}${config.formUrl}`,
				method: 'post',
				data: formData,
				responseType: 'json'
		    })
		    .then(() => {
				sendEmail(email, fname + lname);
				setmessage(true);  
				setformstatus('success');
				setlogtext('Details Submitted Successfully');
				setfname('');
				setlname('');
				setcity('');
				setstate('');
				setmob('');
				setemail('');
				sethowq('');
				sethaveq('');
		    })
		    .catch(err => {
				console.log('err', err)
				setmessage(true)
				setformstatus('error')
				setlogtext('Something went wrong')
			})
		}
		else {
			setmessage(true);
			setformstatus('error');
			setlogtext('Please fill all details properly');
		}
		setopenloading(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<ButtonA buttonto={props.buttontext} onClick={handleClickOpen} />
			<Dialog className={classes.dailog} scroll="body" fullScreen={fullScreen} maxWidth={maxWidth} open={open} onClose={handleClose} TransitionComponent={Transition}>
				<div className="form-header">
					<Link to="/DivyaShishuRatna"><IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton></Link>
				</div>	
				<div className="my-5 form-wrapper2">
					<div className="dsr-form-header">
						<div>
							<h2 className="Subheading-2regular-36ptleftdark-kulimpark">Divya Shishu Ratna</h2>
							<hr style={{backgroundColor: 'black', width: '100px', height: '2px', marginLeft: '0'}}/>
							<h6 className="t2 my-4 text-left" >Introductory Seminar Registration</h6>
						</div>
						<div className="my-2 my-xs-0">
							<img src={require("../images/dsr/dsr_logo.png")} alt="" width="150px" height="150px"/>
						</div>
					</div>
					<div className="my-5">
						<Typography variant="h6" className={classes.title}>
							Personal Details
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="fname"
									name="fname"
									variant="outlined"
									required
									fullWidth
									id="fname"
									label="First Name"
									value={fname}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									fullWidth
									id="lname"
									label="Last Name"
									name="lname"
									autoComplete="lname"
									value={lname}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="city"
									name="city"
									variant="outlined"
									required
									fullWidth
									id="city"
									label="City"
									value={city}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="state"
									label="State"
									name="state"
									autoComplete="state"
									value={state}
									onChange={handleInputChange}
								/>
							</Grid>
						</Grid>

						<Typography variant="h6" className={classes.title}>
							Contact Details
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="mob"
									name="mob"
									variant="outlined"
									required
									fullWidth
									id="mob"
									label="Mobile"
									value={mob}
									onChange={handleInputChange}
								/>
								<p style={{color: 'orangered', fontSize: '0.8rem', marginLeft: '8px'}}>{moberr}</p>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email"
									name="email"
									autoComplete="email"
									value={email}
									onChange={handleInputChange}
								/>
								<p style={{color: 'orangered', fontSize: '0.8rem', marginLeft: '8px'}}>{emailerr}</p>
							</Grid>
						</Grid>

						<Typography variant="h6" className={classes.title}>
							Other Details
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									autoComplete="howq"
									name="howq"
									variant="outlined"
									fullWidth
									id="howq"
									label="How did you get to know about DSR?"
									value={howq}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									id="haveq"
									label="Have you attended DSR seminar earlier?"
									name="haveq"
									autoComplete="haveq"
									value={haveq}
									onChange={handleInputChange}
								/>
							</Grid>
						</Grid>
					</div>
					<Grid item xs={12} className="mt-4 text-right">
						<ButtonA buttonto="Submit Details" onClick={handleFormSubmit}/>
					</Grid>
					<Snackbar open={message} autoHideDuration={6000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
						<Alert onClose={() => setmessage(false)} severity={formstatus}>
							{logtext}
						</Alert>
					</Snackbar>
					<Backdrop className={classes.backdrop} open={openloading} onClick={()=>{}}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</div>
			</Dialog>
		</div>
	);
}