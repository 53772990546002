import React from 'react';
import '../fonts.css'

const e24 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	King Mandhata once said to Vasishtha Muni, "O great
	sage, kindly be merciful to me and tell me of a holy
	fast that will benefit me eternally."
	<hr style={{border: 'none'}} className="my-4"/>
	Vasishtha Muni replied. O king, kindly listen as I
	describe the best of all fast days, Amalakii Ekadasi. He
	who faithfully observes a fast on this Ekadasi obtains
	enormous wealth, gets free of the effects of all kinds
	of sins, and attains liberation. Fasting on this Ekadasi
	is more purifying than donating one thousand cows in
	charity to a pure Brahman.
	<hr style={{border: 'none'}} className="my-4"/>
	There was once a kingdom named Vaidika, where all the Brahmanas,
	kshatriyas, vaishyas, and shudras were equally endowed with Vedic
	knowledge, great bodily strength, and fine intelligence. Oh lion
	among kings, the whole kingdom was full of Vedic sounds, not a
	single person was atheistic, and no one sinned. The ruler of this
	kingdom was Chitraratha and was very religious and truthful. All the
	people of the kingdom were devotees of Vishnu and all the people,
	young, old, women, and men - twice a month everyone fasted on
	Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Once, in the month of Phalguna (February - March), the holy fast of
	Amalakii Ekadasi arrived, conjoined with Dvadasi. That day, King
	Chitraratha and all the citizens observed this sacred Ekadasi very
	strictly, carefully following all the rules and regulations.
	<hr style={{border: 'none'}} className="my-4"/>
	The King along with his citizens went to the temple and offered and
	installed and worshipped the Amalaki tree with a pot filled with
	water and worshipped with incense, ghee lamp, plate of sumptuous
	food and panchratna,etc.
	<hr style={{border: 'none'}} className="my-4"/>
	Then they prayed, Oh offspring of Lord Brahma, you can destroy all
	kinds of sinful reactions. Please accept our respectful obeisances
	and these humble gifts. O Amalakii, you are actually the form of
	Brahman, and you were once worshiped by Lord Ramachandra
	Himself. Whoever circumambulates you is therefore immediately
	freed of all his sins.
	<hr style={{border: 'none'}} className="my-4"/>
	After offering these excellent prayers, King Chitraratha and his
	subjects remained awake throughout the night, praying and
	worshiping according to the regulations governing a sacred Ekadasi
	fast. It was during this auspicious time of fasting and prayer that a
	very irreligious man approached the assembly, a man who
	maintained himself and his family by killing animals. Burdened with
	both fatigue and sin, the hunter saw the king and the citizens of
	Vaidiska observing Amalakii Ekadasi by performing an all-night vigil,
	fasting, and worshiping Lord Vishnu in the beautiful forest setting,
	which was brilliantly illuminated by many lamps.
	<hr style={{border: 'none'}} className="my-4"/>
	Despite himself, that staunchly irreligious killer of innocent birds
	and animals spent the entire night in great amazement as he
	watched the Ekadasi celebration and listened to the glorification of
	the Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	Soon after sunrise, the king and his royal retinue - including the
	court sages and all the citizens – completed their observance of
	Ekadasi and returned to the city of Vaidiska. The hunter then
	returned to his hut and happily ate his meal. In due time the hunter
	died, but the merit he had gained by fasting on Amalakii Ekadasi and
	hearing the glorification of the Supreme Lord, as well as by being
	forced to stay awake all night, made him eligible to be reborn as a
	great king endowed with many chariots, elephants, horses, and
	soldiers. His name was Vasuratha, the son of King Viduratha, and he
	ruled over the kingdom of Jayanti.
	<hr style={{border: 'none'}} className="my-4"/>
	King Vasuratha was strong and fearless, as effulgent as the Sun, and
	as handsome as the Moon. In strength he was like Sri Vishnu, and in
	forgiveness like the Earth itself. Very charitable and every truthful,
	King Vasuratha always rendered loving devotional service to the
	Supreme Lord, Sri Vishnu. He therefore became very well versed inVedic knowledge. He performed many kinds of sacrifices, and he
	always made certain that the needy in his kingdom received enough
	charity.
	<hr style={{border: 'none'}} className="my-4"/>
	One day, while hunting in the jungle, King Vasuratha strayed from
	the footpath and lost his way. Wandering for some time and
	eventually growing weary, he paused beneath a tree and, using his
	arms as a pillow, fell asleep. As he slept, some barbarian tribesmen
	came upon him and, remembering their longstanding enmity toward
	the king, began discussing among themselves various ways to kill
	him.
	<hr style={{border: 'none'}} className="my-4"/>
	It is because he killed our fathers, mothers, brothers-in-law,
	grandsons, nephews, and uncles that we are forced to aimlessly
	wander like so many madmen in the forest.
	<hr style={{border: 'none'}} className="my-4"/>
	So saying, they prepared to kill King Vasuratha with various
	weapons, including spears, swords, arrows, and mystic ropes.
	<hr style={{border: 'none'}} className="my-4"/>
	But none of these deadly weapons could even touch the sleeping
	king, and soon the uncivilized, dog-eating tribesmen grew
	frightened. Their fear sapped their strength, and before long they
	lost what little intelligence they had and became almost unconscious
	with bewilderment and weakness. Suddenly a beautiful woman
	appeared from the king's body, startling the aborigines. Decorated
	with many ornaments, emitting a wonderful fragrance, wearing an
	excellent garland around her neck, her eyebrows drawn in a mood of
	fierce anger, and her fiery red eyes ablaze, she looked like death
	personified. With her blazing chakra discus she quickly killed all the
	tribal hunters, who had tried to slay the sleeping king.
	<hr style={{border: 'none'}} className="my-4"/>
	Just then the king awoke, and seeing all the dead tribesmen lying
	around him, he was astonished. He wondered, 'These are all great
	enemies of mine! Who has slain them so violently? Who is my great
	benefactor?
	<hr style={{border: 'none'}} className="my-4"/>
	At that very moment he heard a voice from the sky: "You ask who
	helped you. Well, who is that person who alone can help anyone is
	distress? He is none other than Sri Keshava, the Supreme Lord, He
	who saves all who take shelter of Him without any selfish motive."
	<hr style={{border: 'none'}} className="my-4"/>
	Upon hearing these words, King Vasuratha became over-whelmed
	with love for the Lord Sri Keshava (Krishna). He returned to his
	capital city and ruled without any obstacles at all.
	<hr style={{border: 'none'}} className="my-4"/>
	Therefore, Oh King, The venerable Vasishtha Muni concluded,
	...Anyone who observes this holy Amalakii Ekadasi will undoubtedly
	attain the supreme abode of Lord Vishnu, so great is the religious
	merit earned from the observance of this most sacred fast day.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Phalguna-sukla Ekadasi, or
	Amalaki Ekadasi, from the Brahmanda Purana.
</p>

export default e24;
