import React from 'react';
import '../fonts.css'

const e6 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	The Story of Pandava Nirjala Ekadasi: Pandava Nirjala Ekadasi -
	Jyeshtha-shukla Ekadasi - from Brahma-vaivarta Purana.
	<hr style={{border: 'none'}} className="my-3"/>
	Once Bhimasena, the younger brother of Maharaja
	Yudhisthira, asked the great sage Shrila Vyasadeva,
	the grandfather of the Pandavas, if it is possible to
	return to the spiritual world without having
	observed all the rules and regulations of the Ekadasi
	fasts.
	<hr style={{border: 'none'}} className="my-3"/>
	Bhimasena then spoke as follows, Oh greatly
	intelligent and learned grandfather, my brother
	Yudhisthira, my dear mother Kunti, and my beloved
	wife Draupadi, as well as Arjuna, Nakula and
	Sahadeva, fast completely on each Ekadasi and strictly follow all the
	rules, guidelines and regulative injunctions of that sacred day. Being
	very religious, they always tell me that I should also fast on that day
	too. But, Oh learned grandfather, I tell them that I cannot live
	without eating. I can give widely in charity and worship Lord
	Keshava properly ; I cannot but be asked to fast on Ekadasi. Please
	tell me how I can obtain the same merits result without fasting.
	<hr style={{border: 'none'}} className="my-3"/>
	Hearing these words, the grandsire of Bhima, Srila Vyasadeva said,
	"If you want to go to the heavenly planets and avoid the hellish
	planets, you should indeed observe a fast on both the light and dark
	Ekadasis.
	"Bhima replied, "Oh great saintly intelligent grandfather, please listen
	to my plea. Oh greatest of munis, since I cannot live if I eat only
	once in a day, how can I possibly live if I fast completely? Within my
	stomach burns a special fire named Vrika, the fire of digestion."
	<hr style={{border: 'none'}} className="my-3"/>
	Only when I eat to my full satisfaction does the fire in my stomach
	become satisfied. Oh great sage, I might possibly be able to fast only
	once, so I beg that you tell me of an Ekadasi that is worthy of my
	fasting and that includes all other Ekadasis. I shall faithfully observe
	that fast and hopefully still become eligible for liberation's release."
	<hr style={{border: 'none'}} className="my-3"/>
	Shrila Vyasadeva replied, Oh king, you have heard from me about
	the various kinds of occupational duties, such as elaborate Vedic
	ceremonies and pujas. Observing Ekadasi strictly is a great aid in
	that process, and here Shrila Vyasadeva is simply stressing to Bhima
	the importance of the Ekadasi vratam. "One who fasts on Ekadasis is
	saved from going to the hellish planets."
	<hr style={{border: 'none'}} className="my-3"/>
	Hearing Shrila Vyasadeva's words, the son of Vayu, Bhimasena, the
	strongest of all warriors, became frightened and began to shake like
	a leaf. The frightened Bhimasena then said, "Oh grandfather, what
	should I do? I am completely unable and ill equipped to fast twice in
	a month throughout the year! Please tell me of the one fasting day
	that will bestow the greatest benefit upon me!"
	<hr style={{border: 'none'}} className="my-3"/>
	Vyasadeva replied, "Without drinking even water, you should fast on
	the Ekadasi that occurs during the light fortnight of the month of
	Jyeshtha (May-June) when the sun travels in the sign of Taurus
	(Vrishabh) and Gemini (Mithun), According to learned personalities,
	on this day one may bathe and perform Achamana for
	pratiprokshana purification. But while performing Achamana one
	may drink only that amount of water equal to a drop of gold, or that
	amount it takes to immerse a single mustard seed. Only this amount
	of water should be placed in the right palm for sipping, which one
	should form to resemble a cow's ear. One must certainly not eat
	anything, for if he does so he breaks his fast.
	<hr style={{border: 'none'}} className="my-3"/>
	This rigid fast is in effect from sunrise on the Ekadasi day to sunrise
	on the Dwadashii day. If a person endeavors to observe this great
	fast very strictly, he easily achieves the result of observing all
	twenty-four other Ekadasi fasts throughout the entire year.
	On Dwadashii the devotee should bathe early in the morning. Then,
	according to the prescribed rules, guidelines and regulative
	injunctions, and of course depending on his ability, he should give
	some charity and water to worthy Brahmanas. Finally, he should
	cheerfully honor prasadam with a Brahman.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh Bhimasena, one who can fast on this special Ekadasi in this
	manner reaps the benefit of having fasted on every Ekadasi during
	the year. There is no doubt of this, nor should there be. Oh Bhima,
	now hear the specific merit one gets by fasting on this Ekadasi. The
	Supreme Lord Keshava, who personally told me, Everyone should
	take shelter of Me and follow My instructions. Then He told me that
	one who fasts on this Ekadasi, without taking even drinking water or
	eating, becomes free of all sinful reactions, and that one who
	observes the difficult Nirjala fast on Jyeshtha-shukla Ekadasi truly
	reaps the benefit of all other Ekadasi fasts.
	<hr style={{border: 'none'}} className="my-3"/>
	Whoever fasts on this Ekadasi receives the merits of bathing in all
	the places of pilgrimage, giving all kinds of charities to worthy
	persons, and fasting on all the dark and light Ekadasis throughout
	the year, in one go. Of this there is no doubt.
	<hr style={{border: 'none'}} className="my-3"/>
	And at the fearful moment of death, the terrible Yamadutas, will
	refuse to approach him. Rather, such a faithful soul will at once be
	taken to the supreme abode of Lord Vishnu by the Vishnu-dutas, in
	Vishnu’s vehicle to Heaven- who each hold a disk, club, conch and
	lotus in their four hands, resembling Lord Vishnu. It is to gain all
	these benefits that one should certainly fast on this very auspicious
	and important Ekadasi, even from water. That day one should chant
	" OM NAMO BHAGVATE VASUDEVA" and do the charity of a cow.
	<hr style={{border: 'none'}} className="my-3"/>
	As per the instruction of Vyasji Bhimsen kept this fast and thus this
	Ekadasi is known as Bhimaseni or Pandava Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	Shrila Vyasadeva continued, You should pray to the Supreme Lord,
	Lord Sri Krishna in this way making your sankalpa declaration, Oh
	Lord of all the devas (demigods), Oh Supreme Lord, today I shall
	observe Ekadasi without taking any water. I shall break fast on the
	next day, Dwadashii. Thereafter, to remove all his sins, the devotee
	should honor this Ekadasi fast with full faith in the Lord and with full
	control over his senses. The sins that have been accumulated all
	become nullified and are burned to ashes. Such is the great power of
	this Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	On this day, then he should give a qualified Brahman some cloth or a
	pot filled with water. Indeed, the merit achieved by giving water
	alone equals that gained by giving gold ten million times a day.
	Observance of this Ekadasi fast alone promotes one to the supreme
	abode of Sri Vishnu.
	<hr style={{border: 'none'}} className="my-3"/>
	Remember, whosoever eats any grains on Ekadasi becomes
	contaminated by sin and verily eats only sin. In effect, he has
	already become a dog-eater, and after death he suffers a hellish
	existence. Observing this Ekadasi, which is merged with Dwadashii,
	frees one from the horrible sin of killing a Brahman, drinking liquor
	and wine, becoming envious of one's spiritual master and ignoring
	his instructions, and continually telling lies.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh son of Kunti, with devotion any man or woman who observes this
	fast properly and worships the Supreme Lord (He who sleeps on the
	water), and who on the next day satisfies a qualified Brahman with
	nice sweets and a donation of cows and money - such a person
	certainly pleases the Supreme Lord Vasudeva. Indeed, one who
	observes this amazing Ekadasi rides on a glorious celestial airplane
	(vimana) to the Lord's abode.
	<hr style={{border: 'none'}} className="my-3"/>
	One who on this day gives a Brahman a waterpot, an umbrella, or
	shoes surely goes to the heavenly planets. Indeed, he who simply
	hears these glories also attains to the transcendental abode of the
	Supreme Lord, Shri Vishnu. But this same merit is achieved by him
	who simply hears this sacred narration - so powerful and so dear to
	the Lord is this Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	Thus ends the narration of the glories of Jyeshtha-shukla Ekadasi, or
	Bhimaseni-Nirjala Ekadasi, from the Brahma-vaivarta Purana.
</p>

export default e6;