import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import AnandUtsavPage from '../../Pages/AnandUtsav.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AnandUtsav() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Festivals" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <AnandUtsavPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="au_header" imageKeys={["/images/anandutsav/bg1.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="IMPORTANCE OF FESTIVALS" textKey="au_vdo" heading subheading />
                <EditCard title="FESTIVAL 1" textKey="au_vdo_1" heading subheading paragraph titleP="Youtube link 1"/>
                <EditCard title="FESTIVAL 2" textKey="au_vdo_2" heading subheading paragraph titleP="Youtube link 2"/>
                <EditCard title="FESTIVAL 3" textKey="au_vdo_3" heading subheading paragraph titleP="Youtube link 3"/>
                <EditCard title="FESTIVAL 4" textKey="au_vdo_4" heading subheading paragraph titleP="Youtube link 4"/>
            </TabPanel>
        </div>
    );
}
