import React from 'react';
import './slider.css'
import '../fonts.css'

function SliderRight(props) {
	return(
		<div className='slider-left-wrapper'>
			<div id="demo2" className="carousel slide" data-ride="carousel">

				<ul className="carousel-indicators car-ind-sm">
					<li data-target="#demo2" data-slide-to="0" className="active"></li>
					<li data-target="#demo2" data-slide-to="1"></li>
					<li data-target="#demo2" data-slide-to="2"></li>
				</ul>

				<div className="text-center carousel-inner">
					<div className="carousel-item active">
						<img className="custom-slider-img" src={props.img1} width="80%" alt=""/>
					</div>
					<div className="carousel-item">
						<img className="custom-slider-img" src={props.img2} width="80%" alt=""/>
					</div>
					<div className="carousel-item">
						<img className="custom-slider-img" src={props.img3} width="80%" alt=""/>
					</div>
				</div>

				<a className="carousel-control-prev" href="#demo2" data-slide="prev">
					<span className="carousel-control-prev-icon"></span>
				</a>
				<a className="carousel-control-next" href="#demo2" data-slide="next">
					<span className="carousel-control-next-icon"></span>
				</a>

			</div>
		</div>
	)
}

export function SliderRightLong(props) {
	return(
		<div className='slider-long-left-wrapper pb-md-4 pb-lg-0'>
			<div id="demo2" className="carousel slide" data-ride="carousel">

				<ul className="carousel-indicators car-ind-lg">
					<li data-target="#demo2" data-slide-to="0" className="active"></li>
					<li data-target="#demo2" data-slide-to="1"></li>
					<li data-target="#demo2" data-slide-to="2"></li>
				</ul>

				<div className="text-center carousel-inner">
					<div className="carousel-item active">
						<img className="custom-slider-long-img" src={props.img1} width="80%" alt=""/>
					</div>
					<div className="carousel-item">
						<img className="custom-slider-long-img" src={props.img2} width="80%" alt=""/>
					</div>
					<div className="carousel-item">
						<img className="custom-slider-long-img" src={props.img3} width="80%" alt=""/>
					</div>
				</div>

				<a className="carousel-control-prev" href="#demo2" data-slide="prev">
					<span className="carousel-control-prev-icon"></span>
				</a>
				<a className="carousel-control-next" href="#demo2" data-slide="next">
					<span className="carousel-control-next-icon"></span>
				</a>

			</div>
		</div>
	)
}

export default SliderRight;