import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100vw',
        overflowX: 'hidden',
        margin: 'auto auto',
        zoom: '0.6',
        MozTransform: 'scale(0.6)',
        MozTransformOrigin: '50% 0',
        pointerEvents: 'none'
    },
    editWrapper: {
        overflowX: 'hidden',
        margin: 'auto auto',
    }
}));

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
        role="tabpanel"
        style={{width: '100%'}}
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        <div className={props.preview ? classes.wrapper : classes.editWrapper}>
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
