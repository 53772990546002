import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { ButtonA } from '../Components/Button.js'
import { TextField, Divider, Grid, Snackbar } from '@material-ui/core';
import emailjs from 'emailjs-com';
import './pmtform.css'
import  '../Pages/collaborate.css';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		boxShadow: 'none',
		backgroundColor: 'transparent'
	},
	title: {
		flex: 1,
		fontWeight: 'normal',
		color: 'grey',
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(3)
	},
	text: {
		flex: 1,
		fontWeight: 'normal',
		color: '#333',
		marginBottom: theme.spacing(2)
	},
	close: {
	    color: 'white',
	    backgroundColor: '#9a0808',
	    position: 'fixed',
	    top: '20px',
	    right: '20px',
	    '&:hover': {
	      color: 'white',
	      backgroundColor: '#9a0808',
	  	}
    },
	formControl: {
    },
	formControlCheckox: {
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
	const [message, setmessage] = useState(false);
	const [formstatus, setformstatus] = useState('error');
	const [logtext , setlogtext] = useState('');

	const [name, setname] = useState('');
    const [age, setage] = useState('');
    const [gender, setgender] = useState('');
    const [married, setmarried] = useState('');
    const [city, setcity] = useState('');
    const [state, setstate] = useState('');
    const [country, setcountry] = useState('');
    const [mobile, setmobile] = useState('');
    const [wap, setwap] = useState('');
    const [qual, setqual] = useState('');
    const [occup, setoccup] = useState('');
    const [desig, setdesig] = useState('');
    const [skills, setskills] = useState('');
    const [seva, setseva] = useState('');
    const [hours, sethours] = useState('');
    const [email, setemail] = useState('');
    const [fb, setfb] = useState('');
    const [insta, setinsta] = useState('');
    const [twit, settwit] = useState('');
    const [other, setother] = useState('');

	const [emailerr, setemailerr] = useState('');
	const [openloading, setopenloading] = React.useState(false);

	const handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if(name==='name')
			setname(value);
		else if(name==='age')
			setage(value);
		else if(name==='gender')
			setgender(value);
		else if(name==='married')
			setmarried(value);
		else if(name==='city')
			setcity(value);
		else if(name==='state')
			setstate(value);
        else if(name==='country')
            setcountry(value);
		else if(name==='mobile')
			setmobile(value);
		else if(name==='wap')
			setwap(value);
		else if(name==='qual')
			setqual(value);
		else if(name==='occup')
			setoccup(value);
		else if(name==='desig')
			setdesig(value);
        else if(name==='skills')
            setskills(value);
		else if(name==='seva')
			setseva(value);
		else if(name==='hours')
			sethours(value);
		else if(name==='fb')
			setfb(value);
		else if(name==='insta')
			setinsta(value);
		else if(name==='twit')
			settwit(value);
        else if(name==='other')
			setother(value);
		
		else if(name==='email') {
		  	setemail(value);
			// eslint-disable-next-line no-useless-escape
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(value) === false) {
				setemailerr('Email is Not Correct');
				return;
			}
			else {
				setemailerr('');
			}
		}
    };
    
    const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendEmail = (mail, name) => {
        emailjs.send("service_6tpaae9","template_8oq7nfl",{
            form_name: name,
            to_email: mail,
        }, "user_WO7pjjJ2kpBHGbUJekjMm");

		emailjs.send("service_6tpaae9","template_ehavusr",{
			form_link: "https://satprerna.org/850274/Admin/Collaborate",
			form_name: name,
			to_email: "poonam.lpdf2@gmail.com",
		}, "user_WO7pjjJ2kpBHGbUJekjMm");
	}
	
	const handleFormSubmit = async () => {
		setopenloading(true);
		if (name && age && gender && married && city && state && qual && country && mobile
                && qual && occup && desig && skills && email) {

            const formData = new FormData();
            formData.append("name", name);
            formData.append("age", age);
            formData.append("gender", gender);
            formData.append("married", married);
            formData.append("city", city);
            formData.append("state", state);
            formData.append("country", country);
            formData.append("mobile", mobile);
            formData.append("qual", qual);
            formData.append("occup", occup);
            formData.append("desig", desig);
            formData.append("skills", skills);
            formData.append("email", email);
            formData.append("wap", wap);
            formData.append("seva", seva);
            formData.append("hours", hours);
            formData.append("fb", fb);
            formData.append("insta", insta);
            formData.append("twit", twit);
            formData.append("other", other);

			setmessage(true);
			setformstatus('info');
			setlogtext('Submitting');
			await axios({
		      url: `https://satprerna.org/satprerna_backend/home/insert_into_vform.php`,
		      method: 'post',
		      data: formData,
		      responseType: 'json'
		    })
		    .then(() => {
				sendEmail(email, 'LPDF Volunteer');
				setmessage(true);
				setformstatus('success');
				setlogtext('Details Submitted Successfully');
				setname('');
				setage('');
				setgender('');
                setmarried('');
                setcity('');
                setstate('');
                setcountry('');
                setmobile('');
                setwap('');
                setqual('');
                setoccup('');
                setdesig('');
                setskills('');
                setseva('');
                sethours('');
                setemail('');
                setfb('');
                setinsta('');
                settwit('');
                setother('');
		    })
		    .catch(err => {
				console.log('err', err)
				setmessage(true)
				setformstatus('error')
				setlogtext('Something went wrong')
			})
		}
		else {
			setmessage(true);
			setformstatus('error');
			setlogtext('Please fill all details');
		}
		setopenloading(false);
	};

	return (
		<div>
            <input className="styled-button-white-bg" style={{marginTop: "0em", marginBottom: "0em", borderRadius: "10em/1em"}} type='button' value='Volunteer' onClick={handleClickOpen}/>
			<Dialog className={classes.dailog} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<div className="form-header-volunteer">
					<AppBar className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
							</Typography>
							<IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<svg  className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                    
						<path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
						<path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
						<path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
					</svg>
				</div>
                <div className="form-wrapper">
                    <div className="dsr-form-header">
                        <div>
                            <h1 className="t1" >Be a Volunteer</h1>
                            <h6 className="t2 my-3" >Volunteer Registration</h6>
                        </div>
                        <div className="my-2 my-xs-0">
                            <img src={require("../images/decoration/vol.svg")} alt="" width="auto" height="150px"/>
                        </div>
                    </div>
                    <Grid container className="mb-5" alignItems="center">
                        <Typography variant="h6" className={classes.title}>
                            Personal Details
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Full Name"
                                    value={name}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="age"
                                    label="Age"
                                    name="age"
                                    autoComplete="age"
                                    value={age}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="gender"
                                    label="Gender"
                                    name="gender"
                                    autoComplete="gender"
                                    value={gender}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="married"
                                    label="Marital Status"
                                    name="married"
                                    autoComplete="married"
                                    value={married}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    autoComplete="city"
                                    value={city}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    autoComplete="state"
                                    value={state}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    name="country"
                                    autoComplete="country"
                                    value={country}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className="my-5">
                        <Typography variant="h6" className={classes.title}>
                            Contact Details
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="mobile"
                                    label="Mobile Number"
                                    name="mobile"
                                    autoComplete="mobile"
                                    value={mobile}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="wap"
                                    label="Whatsapp Number"
                                    name="wap"
                                    autoComplete="wap"
                                    value={wap}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="email"
                                    name="email"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    value={email}
                                    onChange={handleInputChange}
                                />
                                <p style={{color: 'orangered', fontSize: '0.8rem', marginLeft: '8px'}}>{emailerr}</p>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider />
                    <div className="my-5">
                        <Typography variant="h6" className={classes.title}>
                            Work & Seva Experience
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="qual"
                                    label="Qualification"
                                    name="qual"
                                    autoComplete="qual"
                                    value={qual}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="occup"
                                    label="Occupation"
                                    name="occup"
                                    autoComplete="occup"
                                    value={occup}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="desig"
                                    label="Designation"
                                    name="desig"
                                    autoComplete="desig"
                                    value={desig}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="skills"
                                    label="Skills"
                                    name="skills"
                                    autoComplete="skills"
                                    value={skills}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="seva"
                                    label="Present Seva"
                                    name="seva"
                                    autoComplete="seva"
                                    value={seva}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="hours"
                                    label="Hours in Seva"
                                    name="hours"
                                    autoComplete="hours"
                                    value={hours}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <Divider />
                    <div className="my-5">
                        <Typography variant="h6" className={classes.title}>
                            Social Media Accounts
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="fb"
                                    label="Facebook"
                                    name="fb"
                                    autoComplete="fb"
                                    value={fb}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="insta"
                                    label="Instagram"
                                    name="insta"
                                    autoComplete="insta"
                                    value={insta}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="twit"
                                    label="Twitter"
                                    name="twit"
                                    autoComplete="twit"
                                    value={twit}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="other"
                                    label="Other Social Media Accounts"
                                    name="other"
                                    autoComplete="other"
                                    value={other}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="mt-4 text-right">
                                <ButtonA buttonto="Submit Details" onClick={handleFormSubmit}/>
                            </Grid>
                            <Snackbar open={message} autoHideDuration={6000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={() => setmessage(false)} severity={formstatus}>
                                    {logtext}
                                </Alert>
                            </Snackbar>
                            <Backdrop className={classes.backdrop} open={openloading} onClick={()=>{}}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Grid>
                    </div>
                </div>
            </Dialog>
		</div>
	);
}
