import React from 'react';
import '../fonts.css'

const e23 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhisthira Maharaj said, Oh Lord Sri Krishna, O
	glorious son of Vasudeva, please be merciful to
	me and describe the Ekadasi that occurs during
	the dark fortnight of the month of Phalguna
	(February-March).
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, Oh Yudhisthira, Oh king
	of kings, gladly I shall tell you about this great
	fast, known as Vijaya Ekadasi. Whoever observes
	it certainly achieves success in this life and the next. All the sins of
	one who fasts on this Ekadasi and hears its sublime glories are
	eradicated.
	<hr style={{border: 'none'}} className="my-4"/>
	Narada Muni once asked lord Brahma, who sits on a lotus flower
	about the Vijaya Ekadasi. Sri Narada said, Oh best of all the
	demigods, kindly tell me the merit one can achieve by faithfully
	observing Vijaya Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Narada’s great father then replied, My dear son, this oldest of fasting
	days is pure, and it nullifies all sins. I have never revealed this to
	anyone until today, but you can understand beyond any doubt that
	this Ekadasi bestows the result indicated by its name... (Vijaya
	meaning Victory).
	<hr style={{border: 'none'}} className="my-4"/>
	When Lord Rama was exiled to the forest for fourteen years, He, the
	goddess Sita, and His divine brother Lakshmana stayed at
	Panchavati as mendicants. Mother Sita was then kidnapped by the
	demon Ravana, and Lord Rama seemingly became bewildered like an
	ordinary man by distress. While searching for His beloved consort,
	the Lord came upon the dying Jatayu. The great devotee-vulture
	Jatayu returned to Vaikuntha after telling Rama how His dear Sita
	had been abducted by Ravana. Little ahead he met Sugriva.
	<hr style={{border: 'none'}} className="my-4"/>
	Later, Lord Rama and Sugriva, the king of the monkeys, became
	friends and Bali who was killed. Shri Hanumanji went to Sri Lanka,
	where he was able to see Janaki (Srimati Sita devi) in an Ashoka
	grove garden. He delivered Lord Rama’s message and showed the
	ring proving his authenticity for rendering such great service to the
	Supreme Lord Sri Rama. With the help of Sugriva, Lord Rama
	proceeded toward Sri Lanka. Upon arriving at the shore of the ocean
	with the army of monkeys, He could understand that the water was
	uncommonly deep and hostile. Thus He said to Lakshmana, Oh son of
	Sumitra, how can We earn enough merit to be able to cross this vast
	ocean, the unfathomable abode of Varuna deva? I can see no easy
	way to cross it, teeming as it is with sharks and other ferocious
	aquatics.
	<hr style={{border: 'none'}} className="my-4"/>
	Lakshmana replied, Oh best of all beings, Oh origin of all the devas,
	Oh primal personality, the great sage Bakadalbhya lives on an island
	just four miles from here. Let us go to him, take his darshan
	(audience) and ask him how We can safely reach Our goal.
	<hr style={{border: 'none'}} className="my-4"/>
	So Rama and Lakshmana proceeded to the humble Ashram of the
	incomparable Bakadalbhya Muni. Approaching him, the two Lords
	paid their respectful obeisances to him as if he were a second
	Vishnu. Bakadalbhya could immediately understand, however, that
	Sri Rama was actually the Supreme Lord, who for His own reasons
	had appeared on the Earth and was enacting just like a human
	being.
	<hr style={{border: 'none'}} className="my-4"/>
	Sri Rama, said Bakadalbhya, Oh best of the beings, why have You
	come to my lowly abode?
	<hr style={{border: 'none'}} className="my-4"/>
	The Lord replied, Oh great, twice born Brahmin, I have come here to
	the ocean shore with My army of monkey and bear warriors in order
	to cross the sea and conquer Lanka and its demon horde headed by
	Ravana. Oh greatest of sages, please be merciful unto Me and please
	tell Me how I can cross this vast ocean. That is why I have come to
	your Ashram today.
	<hr style={{border: 'none'}} className="my-4"/>
	The sage said, Oh Lord Sri Rama, I shall tell you of the most exalted
	of all fasts, observing which You will surely conquer Ravana and be
	eternally glorified. Kindly now listen with full attention.
	<hr style={{border: 'none'}} className="my-4"/>
	On the day before Ekadasi, fashion a water pot of gold or silver, or
	even copper. Even clay will do if these metals are unavailable. Fill
	the pot with pure water and then decorate it nicely with mango
	leaves. Cover it and place it near a holy altar upon a mound of seven
	grains (the seven grains are barley, wheat, rice, corn, chickpeas,
	kukani, and dahi or peas). Now take Your morning bath, decorate
	the water pot with flower garlands and sandalwood paste, and in the
	concave lid atop the pot place there the barley, pomegranate, and
	coconut. Now with great love and devotion worship the water pot
	Deity form and offer Him incense, sandalwood paste, flowers, a ghee
	lamp, and a plate of sumptuous foods. Remain there awake that
	night beside this sacred pot. On top of the lid filled with barley, etc.,
	place a golden murthy of Lord Sri Narayana. When Ekadasi dawns,
	take Your morning bath and then decorate the water pot with fine
	sandalwood paste and garlands. Then worship the pot again with
	first class incense, lamps, sandalwood paste and flowers dipped in
	sandalwood paste, and then devoutly place many kinds of cooked
	food, pomegranate, and coconut before the water pot. Then remain
	awake over night.
	<hr style={{border: 'none'}} className="my-4"/>
	When the Dwadasi dawns, take the waterpot to the bank of a holy
	river, or even to the shore of a small pond. After worshipping it
	again properly, Oh King of kings, offer it with all the aforementioned
	ingredients to a pure hearted Brahmin, expert in the Vedic sciences.
	If You and Your military commanders observe the Vijaya Ekadasi in
	this way, You will surely be victorious in every way.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Ramachandra Bhagawan, the Supreme Lord, did just as
	Bakadalbhya Muni instructed, and thus He conquered all demoniac
	forces. Similarly, anyone who observes the Vijaya Ekadasi in this
	way will always be victorious in this mortal world, and after leaving
	this world he/she will reside forever in the anxiety free realm of the
	Kingdom of God known as the Vaikunthas.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh Narada, my son, from this history you can understand why one
	should observe this Ekadasi fast properly, strictly following the rules
	and regulations. This fast is powerful enough to eradicate all one’s
	sinful reactions, even the most abominable ones.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna concluded, Oh Yudhisthira, anyone who reads or
	hears this history will attain the same great merit as that which is
	earned by performing a horse sacrifice on days of yore.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Phalguna-krishna Ekadasi
	or Vijaya Ekadasi, from the Skanda Purana.
</p>

export default e23;
