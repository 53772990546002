import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import HomePage from '../../Pages/Home.js'
import GalleryTable from '../Editables/GalleryTable.js';
import TestimonialsTable from '../Editables/TestimonialsTable.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Home() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview All" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Projects" {...a11yProps(3)} />
                <Tab label="Magazine" {...a11yProps(4)} />
                <Tab label="Suvakya" {...a11yProps(5)} />
                <Tab label="Testimonials" {...a11yProps(6)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <HomePage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="top_header" imageKeys={["/images/header/header-bg.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="carousel_1" imageKeys={["/images/carousel/img1.jpg", "/images/carousel/img2.jpg", "/images/carousel/img3.jpg"]} heading subheading/>
                <EditCard title="CAROUSEL 2" textKey="carousel_2" imageKeys={["/images/carousel/img3.jpg", "/images/carousel/img1.jpg", "/images/carousel/img2.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="PROJECT 1" textKey="projects_2" imageKeys={["/images/projects/1.jpg"]} heading subheading/>
                <EditCard title="PROJECT 2" textKey="projects_1" imageKeys={["/images/projects/2.jpg"]} heading subheading/>
                <EditCard title="PROJECT 3" textKey="projects_4" imageKeys={["/images/projects/3.jpg"]} heading subheading/>
                <EditCard title="PROJECT 4" textKey="projects_8" imageKeys={["/images/projects/4.jpg"]} heading subheading/>
                <EditCard title="PROJECT 5" textKey="projects_5" imageKeys={["/images/projects/5.jpg"]} heading subheading/>
                <EditCard title="PROJECT 6" textKey="projects_7" imageKeys={["/images/projects/6.jpg"]} heading subheading/>
                <EditCard title="PROJECT 7" textKey="projects_3" imageKeys={["/images/projects/7.jpg"]} heading subheading/>
                <EditCard title="PROJECT 8" textKey="projects_6" imageKeys={["/images/projects/8.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="MAGAZINE" textKey="magazine" imageKeys={["/images/pmt/covers_sm/2017_2.jpg", "/images/pmt/covers_sm/2017_3.jpg", "/images/pmt/covers_sm/2019_6.jpg", "/images/pmt/covers_sm/2019_2.jpg", "/images/pmt/covers_sm/2020_3.jpg"]} heading subheading paragraph/>
            </TabPanel>
            <TabPanel value={value} index={5} preview={false}>
                <EditCard title="SUVAKYA" textKey="suvakya" heading subheading/>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="suvakya"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6} preview={false}>
                <div style={{padding: '30px'}}><TestimonialsTable /></div>
            </TabPanel>
        </div>
    );
}
