/* eslint-disable jsx-a11y/anchor-has-content */
import React, {  } from 'react'
import  './contactus.css'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function Refund(props) {

	return (
		<div>
			<Header 
				headerClass="allGalleryHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "2"
				buttonto=""
				linkto = "/"
			/>
			<div className="contactus-wrapper mx-auto">
				<div className="collaborate-header">Shipping & Delivery Policy</div>
                <div className=" my-5">
                    <p style={{ columnCount: 1 }} className="text-center Bodytext-1regular-16ptleftdark-lato">
                    Last updated on Dec 10th 2023.<br/>
                    Shipping is not applicable for business.<br/>
                    </p>
                </div>
            </div>
            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
		</div>
	)
}

export default Refund;