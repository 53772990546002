import React from 'react';
import AdminPanel from './AdminPanel'
import ScrollToTop from '../ScrollToTop'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

function AdminApp() {
  return (
  	<Router>
  		<ScrollToTop/>
  		<div>
	  		<Switch>
                <Route path="/850274/Admin/3G-Project/Gaay">
                    <AdminPanel name="Gaay" />
                </Route>
                <Route path="/850274/Admin/3G-Project/ShriGitaPrerna">
                    <AdminPanel name="Gita" />
                </Route>
                <Route path="/850274/Admin/3G-Project/Ganga">
                    <AdminPanel name="Ganga" />
                </Route>
                <Route path="/850274/Admin/PrabhuMeinTera">
                    <AdminPanel name="Pmt" />
                </Route>
                <Route path="/850274/Admin/3G-Project">
                    <AdminPanel name="ThreeGproject" />
                </Route>
                <Route path="/850274/Admin/WelfareActivities">
                    <AdminPanel name="Welfare" />
                </Route>
                <Route path="/850274/Admin/WomenEmpowerment">
                    <AdminPanel name="WomenEmp" />
                </Route>
                <Route path="/850274/Admin/AnandUtsav">
                    <AdminPanel name="AnandUtsav" />
                </Route>
                <Route path="/850274/Admin/DivyaShishuRatna">
                    <AdminPanel name="DivyaShishuRatna"/>
                </Route>
                <Route path="/850274/Admin/AscentOfLife">
                    <AdminPanel name="AscentOfLife" />
                </Route>
                <Route path="/850274/Admin/AyushmanBhav">
                    <AdminPanel name="AyushmanBhav" />
                </Route>
                <Route path="/850274/Admin/BodyServicingDay">
                    <AdminPanel name="BodyServicingDay" />
                </Route>
                <Route path="/850274/Admin/Collaborate">
                    <AdminPanel name="Collaborate" />
                </Route>
                <Route path="/850274/Admin/Gallery">
                    <AdminPanel name="Gallery" />
                </Route>
                <Route path="/850274/Admin/ContactUs">
                    <AdminPanel name="Contact us" />
                </Route>
                <Route path="/850274/Admin/AboutUs">
                    <AdminPanel name="About us" />
                </Route>
                <Route path="/850274/Admin">
                    <AdminPanel name="Home" />
                </Route>
	        </Switch>
	    </div>
  	</Router>
  );
}

export default AdminApp;
