import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import GalleryTable from '../Editables/GalleryTable.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Gallery() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Anand Utsav" {...a11yProps(0)} />
                <Tab label="Ascent of Life" {...a11yProps(1)} />
                <Tab label="Ayushman Bhav" {...a11yProps(2)} />
                <Tab label="DSR" {...a11yProps(3)} />
                <Tab label="3G Project" {...a11yProps(4)} />
                <Tab label="Welfare Activities" {...a11yProps(5)} />
                <Tab label="Power of Women" {...a11yProps(6)} />
            </Tabs>
            <TabPanel value={value} index={0} preview={false}>
                <div style={{padding: '30px'}}>                    
                    <GalleryTable project="anand_utsav"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <div style={{padding: '30px'}}>                    
                    <GalleryTable project="ascent_of_life"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="ayushman_bhav"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="divya_shishu_ratna"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="gau_ganga_gita"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5} preview={false}>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="welfare_activities"/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6} preview={false}>
                <div style={{padding: '30px'}}>
                    <GalleryTable project="women_empowerment"/>
                </div>
            </TabPanel>
        </div>
    );
}
