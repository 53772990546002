import React from 'react'
import './magazine.css'
import {ButtonB} from './Button.js'
import '../fonts.css'
import {
	Link
} from "react-router-dom";

function Magazine(props) {
	return(
		<div className="magazine-wrapper">
			<h2 className="Subheading-1bold-36ptcenterdark-lato">{props.title('magazine')}</h2>
            <hr style={{width: '10%', height: '2px', backgroundColor: 'black', marginLeft: '45%'}}/>
            <h3 className="Bodytext-1regular-16ptcenterdark-lato">{props.text('magazine')}</h3>
            <div className="magazine-summary-wrapper">
				<div className="mgsm-cards mx-auto my-5">
					<div className="mgsm-card mgsm-img-xl">
						<img className="mgsm-card-img" src="https://satprerna.org/satprerna_backend/images/pmt/covers_sm/2017_2.jpg" alt=""/>
					</div>
					<div className="mgsm-card mgsm-img-md">
						<img className="mgsm-card-img" src="https://satprerna.org/satprerna_backend/images/pmt/covers_sm/2017_3.jpg" alt=""/>
					</div>
					<div className="mgsm-card mgsm-img-sm">
						<img className="mgsm-card-img" src="https://satprerna.org/satprerna_backend/images/pmt/covers_sm/2019_6.jpg" alt=""/>
					</div>
					<div className="mgsm-card">
						<img className="mgsm-card-img" src="https://satprerna.org/satprerna_backend/images/pmt/covers_sm/2019_2.jpg" alt=""/>
					</div>
					<div className="mgsm-card">
						<img className="mgsm-card-img" src="https://satprerna.org/satprerna_backend/images/pmt/covers_sm/2020_3.jpg" alt=""/>
					</div>
				</div>
            	<div className="magazine-summary-text-wrapper mx-auto">
	            	<p className="text-justify Bodytext-1regular-16ptcenterdark-lato pt-4 magazine-summary-text" dangerouslySetInnerHTML={{__html: props.para('magazine')}}></p>
					<Link to="/PrabhuMeinTera" className="mgsm-button">
						Read More
					</Link>  
	            </div>
            </div>
		</div>
	)
}

export default Magazine;