import React from 'react';
import '../fonts.css'

const e15 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhisthira Maharaj said, O Janardana, O protector
	of all beings, what is the name of the Ekadasi that
	comes during the dark fortnight (Krishna paksha)
	of the month of Karttika (October - November)?
	Please impart this sacred knowledge to me.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Sri Krishna then spoke as
	follows, "O king, please listen as I narrate to you.
	The Ekadasi that occurs during the dark part of the
	month of Karttika is called Ramaa Ekadasi. It is
	most auspicious, for it at once eradicates the greatest sins and
	awards one the passage to the spiritual abode. I shall now narrate to
	you its history and glories.
	<hr style={{border: 'none'}} className="my-4"/>
	There once lived a famous king of the name Muchakunda, who was
	friendly to Lord Indra, the king of the heavenly planets, as well as
	with Yamaraj, Varuna, and Vibhishana, the pious brother of the
	demon Ravana. Muchakunda always spoke the truth and constantly
	rendered devotional service to Vishnu. Because he ruled according to
	religious principles, there were no disturbances in his kingdom.
	<hr style={{border: 'none'}} className="my-4"/>
	Muchakunda's daughter was named Chandrabhaagaa, after a sacred
	river, and the king gave her in marriage to Shobhana, the son of
	Chandrasena. One day, Shobhana visited his father-in-law's palace
	on the auspicious Ekadasi day. This visit made Shobhana's wife
	Chandrabhaagaa quite anxious, for she knew that her husband was
	physically very weak and unable to bear the austerity of a daylong
	fast. She said to him, My father is very strict about following
	Ekadasi. On Dasami, the day before Ekadasi, he strikes a large
	kettledrum and announces nobody should eat on Ekadasi, the sacred
	day of Sri Hari!
	<hr style={{border: 'none'}} className="my-4"/>
	When Shobhana heard the sound of the kettledrum, he said to his
	wife, O beautiful one, what am I to do now? Please tell me how I can
	save my life and obey your father's strictness and at the same time
	satisfy our guests!
	<hr style={{border: 'none'}} className="my-4"/>
	Chandrabhaagaa then spoke, My dear husband, in my father's house
	nobody - not even the elephants or horses, what to speak of
	consenting human beings - eats on Ekadasi. Indeed, none of the
	animals are given their ration of grains, leaves, or straw - or even
	water! - On Ekadasi, the sacred day of Sri Hari. So how can you
	escape fasting? My beloved husband, if you must eat something,
	then you should leave here at once. Now, with firm conviction decide
	on what you have to do.'
	<hr style={{border: 'none'}} className="my-4"/>
	Prince Shobhana then said, I have decided to fast on the sacred
	Ekadasi day. Whatever my fate is, it will surely come to pass.
	Deciding thus, Shobhana attempted to fast on this Ekadasi, but he
	became unbearably disturbed with excessive hunger and thirst.
	Eventually the sun set in the west and the arrival of the auspicious
	night made all the Vaishnavas very happy. O Yudhisthira, all the
	devotees enjoyed worshipping Me (Sri Hari) and remaining awake all
	through night, but Prince Shobhana that night became absolutely
	unbearable. Indeed, when the Sun rose on the Dwadasi, that Prince
	Shobhana was dead.
	<hr style={{border: 'none'}} className="my-4"/>
	King Muchakunda observed his son-in-law's funeral, ordering a large
	stack of wood be assembled for the fire, but he instructed his
	daughter Chandrabhaagaa not to join her husband on the funeral
	pyre. Thus Chandrabhaagaa, after performing all the purificatory
	processes and procedures for honoring her deceased husband,
	continued to live in her father's house.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna continued, O best of the kings, Yudhisthira, even
	though Shobhana died because of observing Ramaa Ekadasi, the
	merit that he accrued enabled him, after his death, to become the
	ruler of a kingdom high on the peak of Mandarachala Mountain. This
	kingdom was like a city of the demigods; very lustrous, with
	unlimited jewels set in the walls of its buildings that gave off light.
	The pillars were made of rubies, and gold inlaid with diamonds
	shone everywhere. As King Shobhana sat upon a throne beneath a
	pure white canopy, servants fanned him with yak-tail whisks. A
	stunning crown rested upon his head, beautiful earrings adorned his
	ears, a necklace graced his throat, and bejeweled armlets and
	bracelets encircled his arms. He was served by Gandharvas (the best
	of heavenly singers) and Apsaras (celestial dancers). Verily, he
	resembled a second Indra.
	<hr style={{border: 'none'}} className="my-4"/>
	One day, a Brahmin named Somasharma, who lived in Muchakunda's
	kingdom, happened to come to Shobhana's kingdom while travelling
	to various places of pilgrimage. The Brahmin saw Shobhana in all his
	resplendent glory and thought he might be the son-in-law of his own
	king Muchakunda. When Shobhana saw the Brahmin approaching, he
	immediately rose up from his seat and welcomed him. After
	Shobhana had paid his respectful obeisance he asked the Brahmin
	about his well being and about the health and welfare of his
	(Shobhana's) father-in-law, his wife and all the residents of the city.
	<hr style={{border: 'none'}} className="my-4"/>
	Somasharma then said, O king, all the residents and subjects are
	well in your father-in-law's kingdom, and Chandrabhaagaa and your
	other family members are also quite well. Peace and prosperity reign
	throughout the kingdom. But there is one thing; I'm quite astonished
	to find you here! Please tell me about yourself. Nobody has ever
	seen such a beautiful city as this! Kindly tell me how you obtained it.
	<hr style={{border: 'none'}} className="my-4"/>
	King Shobhana then began to tell his story, `Because I observed the
	Ramaa Ekadasi, I was given this splendid city to rule over. But for all
	of its grandeur, it is only temporary. I beg you to do something to
	correct this deficiency. You see, this is only an ephemeral city, a
	place of this material world. How may I make its beauties and
	glories permanent? Kindly reveal this to me by your instructions.
	<hr style={{border: 'none'}} className="my-4"/>
	The Brahmin then asked, ‘Why is this kingdom unstable and how will
	it become stable? Please fully explain this to me, and I shall try to
	help you.’
	<hr style={{border: 'none'}} className="my-4"/>
	Shobhana then answered, ‘Because I fasted on the Ramaa Ekadasi
	without any faith, this kingdom is impermanent. Now hear how it
	can become permanent. Please return to Chandrabhaagaa, the
	beautiful daughter of king Muchukunda, and tell her what you have
	seen and understood about this place and about me. Surely, if you, a
	pure hearted Brahmin, tell her this, my city will soon become
	permanent.’
	<hr style={{border: 'none'}} className="my-4"/>
	Thus the Brahmin returned to his city and related the entire episode
	to Chandrabhaagaa, who was both surprised and overjoyed to hear
	this news of her husband. She said, O Brahman, is this a dream you
	have seen, or is it actually a factual thing?
	<hr style={{border: 'none'}} className="my-4"/>
	Somasharma the Brahmin replied, O Princess, I have seen your late
	husband face to face in that wonderful kingdom, which resembles a
	realm of the denizens of heavens playgrounds. But you former
	husband has asked me to relate to you that he says that his kingdom
	is unstable and could vanish into thin air at any moment. Therefore
	he hope you can find a way to make it permanent.
	<hr style={{border: 'none'}} className="my-4"/>
	Chandrabhaagaa then said, O sage among the Brahmins, please take
	me to that place where my husband resides at once, for I greatly
	desire to see him again! Surely I shall make his kingdom permanent
	with the merit that I have acquired by fasting on every Ekadasi
	throughout my life. Please reunite us at once, again. It is said that
	one who reunites separated persons also obtains very great merit.
	<hr style={{border: 'none'}} className="my-4"/>
	The humble Brahmin Somasharma then led Chandrabhaagaa to
	Shobhana's effulgent kingdom. Before reaching it, however, they
	stopped at the foot of Mt. Mandaracala, at the sacred ashrama of
	Vamadeva. Upon hearing their story, Vamadeva chanted hymns from
	the Vedas and sprinkled holy water from his samanya arghya upon
	Chandrabhaagaa. By the influence of that great Rishi's rites, the
	merit she had accrued by fasting for so many Ekadasis made her
	body transcendental. Ecstatic, her eyes beaming in wonder,
	Chandrabhaagaa continued on her journey.
	<hr style={{border: 'none'}} className="my-4"/>
	When Shobhana saw his wife approaching him high on Mount
	Mandarachala, he was overwhelmed with joy and called out to her in
	great happiness and jubilation. After she arrived, he seated her on
	his left side and she said to him, O dearest Patiguru, please listen as
	I tell you something that will benefit you greatly. Since I was eight
	years old I have fasted regularly and with full faith on every Ekadasi.
	If I transfer to you all the merit I have accumulated, your kingdom
	will surely become permanent, and its prosperity will grow and grow
	until the coming of the great inundation!
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna then continued to address Yudhisthira as follows, O
	Yudhisthira, in this way Chandrabhaagaa who was beautifully
	decorated with the finest ornaments and had an exquisitely
	transcendental body, at last enjoyed peace and happiness with her
	husband. By the potency of Ramaa Ekadasi, Shobhana found hiskingdom on the peaks of Mt. Mandarachala able to fulfill all his
	desires and bestow upon him everlasting happiness.
	<hr style={{border: 'none'}} className="my-4"/>
	O greatest of kings, I have thus narrated to you the glories of Ramaa
	Ekadasi that falls in the dark fortnight of the month of Karttika.
	<hr style={{border: 'none'}} className="my-4"/>
	Anyone who observes sacred Ekadasi during both the light and the
	dark fortnight of each month is undoubtedly freed from the reactions
	to the sin of killing a Brahmin. One should not differentiate between
	the Ekadasis of the light and dark parts of the month. As we have
	seen, both can award pleasure in this world and liberate even the
	most sinful and fallen souls. So the Ekadasis of the dark fortnight
	(Krishna paksha) and the light fortnight (shukla or Gaura paksha)
	award the same high degree of merit and eventually liberate one
	from the repeated cycle of birth and death. Anyone, who simply
	hears this narration of the glories of the sacred day of Ramaa
	Ekadasi, is freed from all kinds of sin and attains the supreme abode
	of Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration for the glories of the sacred Karttika-krishna
	Ekadasi, or Ramaa Ekadasi, from the Brahma-Vaivarta Purana of
	Srila Krishna Dwaipayana Veda Vyaasa.
</p>

export default e15;
