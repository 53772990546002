import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './womenemp.css'
import './dsr.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import SliderLeft from '../Components2/SliderLeft.js'
import SliderRight from '../Components2/SliderRight.js'
import '../fonts.css'

function Welfare(props) {
	const { state, heading, subheading, paragraph } = useContext(DataContext);

	return(
		<div>
			<Header 
				headerClass="allWelfareHeader"
				pageTitle={heading('wa_header')} 
                pageSubTitle={subheading('wa_header')} 
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>

			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeft 
					img1="https://satprerna.org/satprerna_backend/images/welfare/ban1.jpg"
					img2="https://satprerna.org/satprerna_backend/images/welfare/ban2.jpg"
					img3="https://satprerna.org/satprerna_backend/images/welfare/ban3.jpg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
			    		<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_c1_p1')}</h2>
					    <p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('wa_c1_p1')}}></p>
					</aside>
		    	</div>
		    </div>

		    <div className="content-ekadashi-left-slider-wrapper content-ekadashi-right-slider-wrapper-display py-5">
		    	<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pr-xl-5">
					<aside>	
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_c2_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('wa_c2_p1')}}></p>
					</aside>
				</div>
				<SliderRight
					img1="https://satprerna.org/satprerna_backend/images/welfare/ban2.jpg"
					img2="https://satprerna.org/satprerna_backend/imageswelfare/ban3.jpg/"
					img3="https://satprerna.org/satprerna_backend/images/welfare/ban1.jpg"
				/>
			</div>


			<div className="main-video-dsr">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: 'white'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: 'white'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: 'white'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className="main-video-container-dsr">
					<h2 className="Subheading-1bold-36ptleftwhite-kulimpark">{heading('wa_pts')}</h2>
					<hr/>
					<h3 className="Subtitle-2regular-26ptleftwhite-kulimpark text-justify text-md-center my-5">{subheading('wa_pts')}</h3>
					<div className="main-video-container-dsr-inner">
						<div className="dsr-iframe">
                            <iframe width="100%" height="100%" src={paragraph('wa_pts')} title="wa_pts" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
						<ul className="dsr-list my-5 mb-lg-0 mt-lg-3">
							{state.home_text.map(row => {
								return (row.name.startsWith('wa_pts_')) ? <li className="Subtitle-1bold-26ptleftwhite-lato text-left">{row.paragraph}</li> : undefined;
							})}
						</ul>
                    </div>
                </div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>


			<div style={{backgroundColor: 'white'}}>
				<div className="main-video-container-wemp">
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_act')}</h2>
					<hr style={{backgroundColor: 'black', width: '15%'}}/>
					<h3 className="Subtitle-2regular-26ptleftdark-kulimpark pt-md-4 mx-md-5 text-justify text-md-center">{subheading('wa_act')}</h3>
					<div style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
						<div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('wa_act_1')} title='wa_act_1' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
							<aside className="wemp-text wemp-text-left">
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_act_1')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('wa_act_1')}}></p>
                            </aside>
                        </div>
                    </div>
                    <div  style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
                        <div  style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
                            <aside className="wemp-text wemp-text-right"> 
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_act_2')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('wa_act_2')}}></p>
                            </aside>
                        </div>
                        <div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('wa_act_2')} title='wa_act_2' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div style={{marginBottom: '5%'}} className="main-video-container-wemp-inner">
						<div className="wemp-iframe">
                            <iframe width="1302" height="500" src={paragraph('wa_act_3')} title='wa_act_3' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{backgroundColor: 'white'}} className="wemp-text-wrapper mt-5 mt-md-0">
							<aside className="wemp-text wemp-text-left">
                                <h2 className="Subheading-1bold-36ptleftdark-lato">{heading('wa_act_3')}</h2>
                                <hr style={{width: '20%', height: '2px', backgroundColor: 'black', marginLeft: '0px'}}/>
                                <p className="text-justify Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('wa_act_3')}}></p>
                            </aside>
                        </div>
                    </div>
                </div>
        	</div>

        	<Footer
                svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
                svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
                svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
            />
		</div>
	)
}

export default Welfare;