import React from 'react';
import '../fonts.css'

const e20 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	The pious and saintly Yudhisthira Maharaj said, "Oh Lord,
	You have so nicely explained to us the wonderful glories
	of the Saphalaa Ekaadasi, which occurs during the dark
	fortnight (Krishna paksha) of the month of Pausha
	(December – January). Now please be merciful to me and
	explain to me the details of the Ekaadasi that occurs in the
	light fortnight (Shukla or Gaura paksha) of this month.
	What is its name, and what Deity is to be worshipped on that sacred
	day? Oh Purushottama, Oh Hrishikesha, please also tell me how you
	can be pleased on this day? Lord Sri Krishna then replied, "Oh saintly
	king, for the benefit of all humanity I shall now tell you how to
	observe fasting on the Pausha-shukla Ekadashi.
	<hr style={{border: 'none'}} className="my-4"/>
	As previously explained, everyone should observe the rules and
	regulations of the Ekaadasi vrata, to the very best of their ability.
	This injunction also applies to the Ekaadasi named Putradaa, which
	destroys all sins and elevates one to the spiritual abode. The
	Supreme Lord Shri Narayana, the original personality, is the
	worshipable Deity of the Ekaadasi, and for His faithful devotees He
	happily fulfils all desires and awards full perfection. Thus among all
	the animate and inanimate beings in the three worlds (lower, middle
	and higher planetary systems), there is no better personality than
	Lord Narayana.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh King, now I shall narrate to you the history of Putradaa Ekaadasi,
	which removes all kinds of sins and makes one famous and learned.
	There was once a kingdom named Bhadraavati, which was ruled by
	King Suketumaan. His queen was the famous Shaibyaa. Because he
	had no son, he spent a long time in anxiety, thinking, ‘If I have no
	son, who will carry on my dynasty?’ In this way the king meditated
	in a religious attitude for a very long time, thinking, ‘Where should I
	go? What should I do? How can I get a pious son (putra)? In this
	way King Suketumaan could find no happiness anywhere in his
	kingdom, even in his own palace, and soon he was spending more
	and more time inside his wife’s palace, gloomily thinking only of how
	he could get a son.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus both King Suketumaan and Queen Shaibyaa were in great
	distress. Even when they offered tarpana (oblations of water to their
	forefathers), their mutual misery made them think that it was as
	undrinkable as boiling water. They thus thought that they would
	have no descendents to offer tarpana to them when they died and
	thus become lost souls (ghosts). The king and queen were especially
	upset to learn that their forefathers were worried that soon there
	would be no one to offer them tarpana also.
	<hr style={{border: 'none'}} className="my-4"/>
	After learning of their forefather’s unhappiness, the king and queen
	became more and more miserable, and neither ministers, nor
	friends, nor even loved ones could cheer them up. To the king, his
	elephants and horses and infantry were no solace, and at last he
	became practically inert and helpless.
	<hr style={{border: 'none'}} className="my-4"/>
	The king thought to himself, ‘It is said that without a son, marriage
	is wasted. Indeed, for a family man with no son, both his heart and
	his splendid house remain vacant and miserable. Bereft of a son, a
	man cannot liquidate the debts that he owes his forefathers, the
	demigods (devas) and to other human beings. Therefore every
	married man should endeavor to beget a son; thus he will become
	famous within this world and at last attain the auspicious celestial
	realms. A son is proof of the pious activities a man performed in his
	past one hundred lifetimes, and such a person achieves a long
	duration of life in this world, along with good health and great
	wealth. Possessing sons and grandsons in this lifetime proves that
	one has worshipped Lord Vishnu, the Supreme Lord, in the past. The
	great blessing of sons, wealth, and sharp intelligence can be
	achieved only by worshipping the Supreme Lord, Sri Krishna. That is
	my opinion.
	<hr style={{border: 'none'}} className="my-4"/>
	Thinking thus, the king had no peace. He remained in anxiety day
	and night, from morning to evening, and from the time he lay down
	to sleep at night until the sun rose in the morning, his dreams were
	equally full of great anxiety. Suffering such constant anxiety and
	apprehension, King Suketumaan decided to end his misery by
	committing suicide. But he realized that suicide throws a person into
	hellish conditions of rebirth, and so he abandoned that idea. Seeing
	that he was gradually destroying himself by his all-consuming
	anxiety over the lack of a son, the king at last mounted his horse and
	left for the dense forest alone. No one, not even the priests and
	Brahmins of the palace, knew where he had gone.
	<hr style={{border: 'none'}} className="my-4"/>
	In that forest, which was filled with deer and birds and other
	animals, King Suketumaan wandered aimlessly, noting all the
	different kinds of trees and shrubs. All were beautifully decorated
	with fruits and flowers. He saw deer, tigers, wild boar, lions,
	monkeys, snakes, and huge bull elephants in a rut, cow elephants
	with their calves, and four-tusked elephants with their mates close
	by. There were cows, jackals, rabbits, leopards, and
	hippopotamuses. Beholding all these animals accompanied by their
	mates and offspring, the king remembered his own menagerie,
	especially his palace elephants, and became so sad that he
	absentmindedly wandered into their very midst.
	<hr style={{border: 'none'}} className="my-4"/>
	Suddenly the king heard a jackal howl in the distance. Startled, he
	began wandering about, looking around in all directions. Soon it was
	midday, and the king started to tire. Hunger and thirst tormented
	him also.
	<hr style={{border: 'none'}} className="my-4"/>
	He thought, What sinful deed could possibly have done so that I am
	now forced to suffer like this, with my throat parched and burning,
	and my stomach empty and rumbling? I have pleased the devas
	(demigods) with numerous fire sacrifices and abundant devotional
	worship. I have given many gifts and delicious sweets in charity to
	all the worthy Brahmins too. And I have taken care of my subjects as
	though they were my very own children. Why then am I sufferingso? What unknown sins have come to bear fruit and torment me in
	this dreadful way?
	<hr style={{border: 'none'}} className="my-4"/>
	Absorbed in these thoughts, King Suketumaan struggled forward,
	and eventually, due to his thirst and search for water, he came upon
	a beautiful lotus-bearing pond that resembled the famous Lake
	Maanasarova. It was filled with aquatics, including crocodiles and
	many varieties of fish, and graced with varieties of lilies and lotuses.
	The beautiful lotuses had opened to the Sun, and swans, cranes and
	ducks swam happily in its waters. Nearby were many attractive
	ashrams, where there resided many saints and sages who could
	fulfill the desires of anyone. Indeed, they wished everyone well.
	When the king saw all this, his right arm and right eye began to
	quiver a sakuna sign (for a male) that something auspicious was
	about to happen.
	<hr style={{border: 'none'}} className="my-4"/>
	As the king dismounted his horse and stood before the sages, who
	sat on the shore of the pond, he saw that they were chanting the
	holy names of God on japa beads. The king paid his obeisance and,
	joining his palms addressed them with glorified praises. Observing
	the respect the king offered them, the sages said, ‘We are very
	pleased with you, Oh king. Kindly tell us why you have come here.
	What is on your mind? Please inform us what is your heart’s desire.’
	The king replied, ‘Oh great sages, who are you? What are your
	names, surely your presence reveals that you are auspicious saints?
	Why have you come to this beautiful place? Please tell me
	everything.
	<hr style={{border: 'none'}} className="my-4"/>
	The sages replied, ‘Oh king, we are known as the ten Vishvadevas
	(the sons of Vishva; Vasu, Satya, Kratu, Daksha, Kaala, Kaama,
	Dhriti, Pururavaa, Maadrava, and Kuru). We have come here to this
	very lovely pond to bathe. The month of Magha (Madhava maas) will
	soon be here in five days (from the Magh nakshatra), and today is
	the famous Putradaa Ekadasi. One who desires a son should strictly
	observe this particular Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	The king said, I have tried so hard to have a son. If you great sages
	are pleased with me, kindly grant the boon of having a good son
	(putra).
	<hr style={{border: 'none'}} className="my-4"/>
	The very meaning of Putradaa, the sages replied, ...is "giver of a
	putra, pious son." So please observe a complete fast on this Ekadasi
	day. If you do so, then by our blessing – and by the mercy of Lord Sri
	Keshava invested in us – surely you will obtain a son.
	<hr style={{border: 'none'}} className="my-4"/>
	On the advice of the Vishvadevas, the king observed the auspicious
	fast day of Putradaa Ekadasi according to the established rules and
	regulations, and on the Dvaasasii, after breaking his fast, he paid
	obeisances again and again to all of them.
	<hr style={{border: 'none'}} className="my-4"/>
	Soon after Suketumaan returned to his palace and united with his
	queen. Queen Shaibya immediately became pregnant, and exactly as
	the Vishvadevas had predicted, a bright faced, beautiful son was
	born to them. In due course of time he became famous as a heroic
	prince, and the king gladly pleased his noble son by making him his
	successor. The son of Suketumaan took care of his subjects very
	conscientiously, just as if they were his own children.
	<hr style={{border: 'none'}} className="my-4"/>
	In conclusion, Oh Yudhisthira, one who wises to fulfill his desires,
	should strictly observe Putradaa Ekadasi. While on this planet, one
	who strictly observes this Ekadasi will surely obtain a son, and after
	death he will achieve liberation. Anyone who even reads or hears the
	glories of Putradaa Ekadasi obtains the merit earned by performing a
	horse sacrifice. It is to benefit all humanity that I have explained all
	this to you.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Pausha-shukla Ekadasi, or
	Putradaa Ekadasi, from the Bhavishya Purana of Veda Vyaasadeva .
</p>

export default e20;
