import React from 'react'
import './suvakya.css'

// =========================
// Slide
// =========================

class Slide extends React.Component {
  constructor(props) {
    super(props)

    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleSlideClick = this.handleSlideClick.bind(this)
    this.imageLoaded = this.imageLoaded.bind(this)
    this.slide = React.createRef()
  }
  
  handleMouseMove(event) {
    const el = this.slide.current
    const r = el.getBoundingClientRect()

    el.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)))
    el.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)))
  }
  
  handleMouseLeave(event) {    
    this.slide.current.style.setProperty('--x', 0)
    this.slide.current.style.setProperty('--y', 0)
  }
  
  handleSlideClick(event) {
    this.props.handleSlideClick(this.props.slide.index)
  }
  
  imageLoaded(event) {
    event.target.style.opacity = 1
  }
  
  render() {
    const { src, index } = this.props.slide
    const current = this.props.current
    let classNames = 'suvakya-slide'
    
    if (current === index) classNames += ' suvakya-slide--current'
    else if (current - 1 === index) classNames += ' suvakya-slide--previous'
    else if (current + 1 === index) classNames += ' suvakya-slide--next'
        
    return (
      <li 
        ref={this.slide}
        className={classNames} 
        onClick={this.handleSlideClick}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="suvakya-slide__image-wrapper">
          <img 
            className="suvakya-slide__image"
            alt="Suvakya"
            src={src}
            onLoad={this.imageLoaded}
          />
        </div>
      </li>
    )
  }
}


// =========================
// Slider control
// =========================

const SliderControl = ({ type, title, handleClick }) => {
  return (
    <button className={`suvakya-btn suvakya-btn--${type}`} title={title} onClick={handleClick}>
      <svg className="suvakya-slider-icon" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </button>
  )
}


// =========================
// Slider
// =========================

class Suvakya extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = { current: 0 }
    this.handlePreviousClick = this.handlePreviousClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleSlideClick = this.handleSlideClick.bind(this)
  }

  handlePreviousClick() {
    const previous = this.state.current - 1
        
    this.setState({ 
      current: (previous < 0) 
        ? this.props.slideData().length - 1
        : previous
    })
  }
  
  handleNextClick() {
    const next = this.state.current + 1;
    
    this.setState({ 
      current: (next === this.props.slideData().length)
        ? 0
        : next
    })
  }
  
  handleSlideClick(index) {
    if (this.state.current !== index) {
      this.setState({
        current: index
      })
    }
  }

  render() {
    const { current, direction } = this.state
    const slides = this.props.slideData();
    const heading = ""
    const headingId = `suvakya-slider-heading__${heading.replace(/\s+/g, '-').toLowerCase()}`
    const wrapperTransform = {
      'transform': `translateX(-${current * (100 / slides.length)}%)`
    }
    const downloadImage = (image) => {
      var newTab = window.open();
      newTab.document.body.innerHTML = '<img src="' + image + '" alt="">';
    }
    
    return (
      <div className="suvakya-wrapper-outer">
        <h2 className="Subheading-1bold-36ptcenterdark-lato">{this.props.title('suvakya')}</h2>
        <hr style={{width: '10%', height: '2px', backgroundColor: 'black', marginLeft: '45%'}}/>
        <h3 className="Bodytext-1regular-16ptcenterdark-lato my-5">{this.props.text('suvakya')}</h3>
        <div className="suvakya-wrapper">
          <div className='suvakya-slider' aria-labelledby={headingId}>
            <div style={{overflow: 'hidden'}}>
            <ul className="suvakya-slider__wrapper" style={wrapperTransform}>
              <h3 id={headingId} className="suvakya-visuallyhidden">{heading}</h3>
              
              {slides.map(slide => {
                return (
                  <Slide
                    key={slide.index}
                    slide={slide}
                    current={current}
                    handleSlideClick={this.handleSlideClick}
                  />
                )
              })}
            </ul>
            </div>
            
            <div className="suvakya-slider__controls">
              <SliderControl 
                type="previous"
                title="Go to previous slide"
                handleClick={this.handlePreviousClick}
              />
              <button className="suvakya-slide__download" title="Download" onClick={() => downloadImage(slides[current].src)}>
                <img src={require("../images/decoration/download.svg")} alt="Download" width="40px" height="auto"/>
              </button>
              <SliderControl 
                type="next"
                title="Go to next slide"
                handleClick={this.handleNextClick}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Suvakya;
