import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import CollaboratePage from '../../Pages/Collaborate.js'
import VolunteerTable from '../Editables/VolunteerTable.js'
import EventTable from '../Editables/EventTable.js'
import SgpFormTable from '../Editables/SgpFormTable.js'
import NPK24FormTable from '../Editables/NPK24FormTable.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Collaborate() {
    const classes = useStyles();
    const [value, setValue] = React.useState(6);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Article" {...a11yProps(1)} />
                <Tab label="Cards" {...a11yProps(2)} />
                <Tab label="Volunteer Form" {...a11yProps(3)} />
                <Tab label="Host a event Form" {...a11yProps(4)} />
                <Tab label="Shri Gita Prerna Form" {...a11yProps(5)} />
                <Tab label="Narmada Parikrama 2024" {...a11yProps(6)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <CollaboratePage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="ARTICLE" textKey="col_art" heading subheading titleS="Content"/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="VOLUNTEER" textKey="col_form_1" heading subheading titleS="Content"/>
                <EditCard title="DONATION" textKey="col_form_2" heading subheading titleS="Content"/>
                <EditCard title="EVENT" textKey="col_form_2" heading subheading titleS="Content"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <div style={{padding: '30px'}}><VolunteerTable /></div>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <div style={{padding: '30px'}}><EventTable /></div>
            </TabPanel>
            <TabPanel value={value} index={5} preview={false}>
                <div style={{padding: '30px'}}><SgpFormTable /></div>
            </TabPanel>
            <TabPanel value={value} index={6} preview={false}>
                <div style={{padding: '30px'}}><NPK24FormTable /></div>
            </TabPanel>
        </div>
    );
}
