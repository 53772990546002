import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import './sgpform.css'
import { ButtonA } from '../Components/Button.js'
import { TextField, Select, MenuItem, Divider, Grid, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    title: {
        flex: 1,
        fontWeight: 'normal',
        color: 'grey',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3)
    },
    text: {
        flex: 1,
        fontWeight: 'normal',
        color: '#333',
        marginBottom: theme.spacing(2)
    },
    close: {
        color: 'white',
        backgroundColor: '#9a0808',
        position: 'fixed',
        top: '20px',
        right: '20px',
        '&:hover': {
            color: 'white',
            backgroundColor: '#9a0808',
        }
    },
    uploadedImg: {
        height: '100px',
        paddingLeft: '20px'
    },
    qrImg: {
        height: '500px',
    },
    formControl: {
    },
    formControlCheckox: {
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useDailogStyles = makeStyles((theme) => ({
    bodyTitle: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    text: {
        color: '#222'
    },
    dailogbutton: {
        color: '#fff'
    },
    uploadedImg: {
        height: '100px',
        paddingLeft: '20px'
    }
}));

function GroupsTable(props) {
    return (
        <div className='my-5'>
            <TableContainer>
                <Table
                    aria-labelledby={props.groupname}
                    size='medium'
                    aria-label={props.groupname}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell key={1} align='center' padding='default'><b>{props.groupname}</b></TableCell>
                            <TableCell key={2} align='center' padding='default'>Student's Name(Surname)</TableCell>
                            <TableCell key={3} align='center' padding='default'>Name</TableCell>
                            <TableCell key={4} align='center' padding='default'>Parents's Name</TableCell>
                            <TableCell key={5} align='center' padding='default'>Parents's Mobile No.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover key={1}>
                            <TableCell align="center">Winner 1</TableCell>
                            <TableCell align="center">{props.group.student1.surname}</TableCell>
                            <TableCell align="center">{props.group.student1.fullname}</TableCell>
                            <TableCell align="center">{props.group.student1.attendingparents}</TableCell>
                            <TableCell align="center">{props.group.student1.parentsmobile}</TableCell>
                        </TableRow>
                        <TableRow hover key={2}>
                            <TableCell align="center">Winner 2</TableCell>
                            <TableCell align="center">{props.group.student2.surname}</TableCell>
                            <TableCell align="center">{props.group.student2.fullname}</TableCell>
                            <TableCell align="center">{props.group.student2.attendingparents}</TableCell>
                            <TableCell align="center">{props.group.student2.parentsmobile}</TableCell>
                        </TableRow>
                        <TableRow hover key={3}>
                            <TableCell align="center">Winner 3</TableCell>
                            <TableCell align="center">{props.group.student3.surname}</TableCell>
                            <TableCell align="center">{props.group.student3.fullname}</TableCell>
                            <TableCell align="center">{props.group.student3.attendingparents}</TableCell>
                            <TableCell align="center">{props.group.student3.parentsmobile}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function AddDialog(props) {
    const classes = useDailogStyles();
    const [open, setOpen] = React.useState(false);
    const [s1fullname, sets1fullname] = useState('');
    const [s1surname, sets1surname] = useState('');
    const [s1attendingparents, sets1attendingparents] = useState('');
    const [s1parentsmobile, sets1parentsmobile] = useState('');
    const [s2fullname, sets2fullname] = useState('');
    const [s2surname, sets2surname] = useState('');
    const [s2attendingparents, sets2attendingparents] = useState('');
    const [s2parentsmobile, sets2parentsmobile] = useState('');
    const [s3fullname, sets3fullname] = useState('');
    const [s3surname, sets3surname] = useState('');
    const [s3attendingparents, sets3attendingparents] = useState('');
    const [s3parentsmobile, sets3parentsmobile] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleNext = (e) => {
        e.preventDefault();
        props.handleSumbit({
            student1: {
                fullname: s1fullname,
                surname: s1surname,
                attendingparents: s1attendingparents,
                parentsmobile: s1parentsmobile
            },
            student2: {
                fullname: s2fullname,
                surname: s2surname,
                attendingparents: s2attendingparents,
                parentsmobile: s2parentsmobile
            },
            student3: {
                fullname: s3fullname,
                surname: s3surname,
                attendingparents: s3attendingparents,
                parentsmobile: s3parentsmobile
            }
        });
        setOpen(false);
    };

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === 's1fullname')
            sets1fullname(value);
        if (name === 's1surname')
            sets1surname(value);
        if (name === 's1attendingparents')
            sets1attendingparents(value);
        if (name === 's1parentsmobile')
            sets1parentsmobile(value);
        if (name === 's2fullname')
            sets2fullname(value);
        if (name === 's2surname')
            sets2surname(value);
        if (name === 's2attendingparents')
            sets2attendingparents(value);
        if (name === 's2parentsmobile')
            sets2parentsmobile(value);
        if (name === 's3fullname')
            sets3fullname(value);
        if (name === 's3surname')
            sets3surname(value);
        if (name === 's3attendingparents')
            sets3attendingparents(value);
        if (name === 's3parentsmobile')
            sets3parentsmobile(value);
    };


    return (
        <div>
            <div className="ml-3 pmt-submit-btn"><ButtonA buttonto={"+ Add " + props.groupname} onClick={handleClickOpen} /></div>
            {/* <Button onClick={handleClickOpen} style={{ border: "none", outline: "none" }}>
                + Add Group
            </Button> */}
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth='xl'
            >
                <DialogTitle id="responsive-dialog-title">{"Add Group"}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" className={classes.bodyTitle}>
                        Winner 1
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="s1surname"
                                name="s1surname"
                                variant="outlined"
                                required
                                fullWidth
                                id="s1surname"
                                label="Student's Name(Surname)"
                                value={s1surname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s1fullname"
                                label="Full Name"
                                name="s1fullname"
                                autoComplete="s1fullname"
                                value={s1fullname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s1attendingparents"
                                label="Parents' Name"
                                name="s1attendingparents"
                                autoComplete="s1attendingparents"
                                value={s1attendingparents}
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">Parents Attending</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={s1attendingparents}
                                    onChange={handleInputChange}
                                    label="Parents Attending"
                                    name='s1attendingparents'
                                >
                                    <MenuItem value={'mother'}>Only Mother</MenuItem>
                                    <MenuItem value={'father'}>Only Father</MenuItem>
                                    <MenuItem value={'both'}>Both</MenuItem>
                                    <MenuItem value={'school authority'}>School authority</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s1parentsmobile"
                                label="Parents' Mobile No. (WhatsApp no.)"
                                name="s1parentsmobile"
                                autoComplete="s1parentsmobile"
                                value={s1parentsmobile}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="body1" className={classes.bodyTitle}>
                        Winner 2
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="s2surname"
                                name="s2surname"
                                variant="outlined"
                                required
                                fullWidth
                                id="s2surname"
                                label="Student' s Name(Surname)"
                                value={s2surname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s2fullname"
                                label="Full Name"
                                name="s2fullname"
                                autoComplete="s2fullname"
                                value={s2fullname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s2attendingparents"
                                label="Parents' Name"
                                name="s2attendingparents"
                                autoComplete="s2attendingparents"
                                value={s2attendingparents}
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">Parents Attending</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={s2attendingparents}
                                    onChange={handleInputChange}
                                    label="Parents Attending"
                                    name='s2attendingparents'
                                >
                                    <MenuItem value={'mother'}>Only Mother</MenuItem>
                                    <MenuItem value={'father'}>Only Father</MenuItem>
                                    <MenuItem value={'both'}>Both</MenuItem>
                                    <MenuItem value={'school authority'}>School authority</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s2parentsmobile"
                                label="Parents' Mobile No. (WhatsApp no.)"
                                name="s2parentsmobile"
                                autoComplete="s2parentsmobile"
                                value={s2parentsmobile}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="body1" className={classes.bodyTitle}>
                        Winner 3
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                autoComplete="s3surname"
                                name="s3surname"
                                variant="outlined"
                                required
                                fullWidth
                                id="s3surname"
                                label="Student's Name(Surname)"
                                value={s3surname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s3fullname"
                                label="Full Name"
                                name="s3fullname"
                                autoComplete="s3fullname"
                                value={s3fullname}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s3attendingparents"
                                label="Parents' Name"
                                name="s3attendingparents"
                                autoComplete="s3attendingparents"
                                value={s3attendingparents}
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">Parents Attending</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={s3attendingparents}
                                    onChange={handleInputChange}
                                    label="Parents Attending"
                                    name='s3attendingparents'
                                >
                                    <MenuItem value={'mother'}>Only Mother</MenuItem>
                                    <MenuItem value={'father'}>Only Father</MenuItem>
                                    <MenuItem value={'both'}>Both</MenuItem>
                                    <MenuItem value={'school authority'}>School authority</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="s3parentsmobile"
                                label="Parents' Mobile No. (WhatsApp no.)"
                                name="s3parentsmobile"
                                autoComplete="s3parentsmobile"
                                value={s3parentsmobile}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)} color="primary" style={{ border: "none", outline: "none" }}>
                        Back
                    </Button>
                    <Button onClick={handleNext} color="primary" autoFocus style={{ border: "none", outline: "none" }}>
                        Next
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function FullScreenDialog(props) {
    const classes = useStyles();
    // const Razorpay = require('razorpay');
    const [open, setOpen] = useState(props.openform);
    const [message, setmessage] = useState(false);
    const [formstatus, setformstatus] = useState('error');
    const [logtext, setlogtext] = useState('');

    const [schoolname, setschoolname] = useState('');
    const [city, setcity] = useState('');
    const [taluka, settaluka] = useState('');
    const [district, setdistrict] = useState('');
    const [email, setemail] = useState('');
    const [emailerr, setemailerr] = useState('');

    const [principalname, setprincipalname] = useState('');
    const [principalmobile, setprincipalmobile] = useState('');
    const [cordinatorname, setcordinatorname] = useState('');
    const [cordinatormobile, setcordinatormobile] = useState('');
    // const [gitainspiringteacher, setgitainspiringteacher] = useState('gitainspiringteacher');

    const [countgroupA, setcountgroupA] = useState(0);
    const [countgroupB, setcountgroupB] = useState(0);
    const [countgroupC, setcountgroupC] = useState(0);
    const [countgroupD, setcountgroupD] = useState(0);
    const [countgroupE, setcountgroupE] = useState(0);
    const [counttotal, setcounttotal] = useState(0);

    const [group1, setgroup1] = useState({});
    const [group2, setgroup2] = useState({});
    const [group3, setgroup3] = useState({});
    const [group4, setgroup4] = useState({});
    const [group5, setgroup5] = useState({});

    const [pancard, setpancard] = useState('removed');
    const [paymentsc, setpaymentsc] = useState('');

    const [price, setprice] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const countInPayment = (count) => {
        if (count > 0) return 1;
        else return 0;
    }

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'schoolname')
            setschoolname(value);
        if (name === 'city')
            setcity(value);
        if (name === 'district')
            setdistrict(value);
        if (name === 'taluka')
            settaluka(value);
        if (name === 'email') {
            setemail(value);
            // eslint-disable-next-line no-useless-escape
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(value) === false) {
                setemailerr('Email is Not Correct');
                return;
            }
            else {
                setemailerr('');
            }
        }

        if (name === 'principalname')
            setprincipalname(value);
        if (name === 'principalmobile')
            setprincipalmobile(value);
        if (name === 'cordinatorname')
            setcordinatorname(value);
        if (name === 'cordinatormobile')
            setcordinatormobile(value);
        // if (name === 'gitainspiringteacher')
        //     setgitainspiringteacher(value);

        if (name === 'pancard')
            setpancard(value);

        if (name === 'countgroupA') {
            let c = parseInt(value);
            if (c >= 0 || value === '') {
                setcountgroupA(c);
                if (value === '') c = 0;
                setcounttotal(c + parseInt(countgroupB) + parseInt(countgroupC) + parseInt(countgroupD) + parseInt(countgroupE));
                setprice((countInPayment(c) + countInPayment(countgroupB) + countInPayment(countgroupC) + countInPayment(countgroupD) + countInPayment(countgroupE)) * 100);
            }
        }
        if (name === 'countgroupB') {
            let c = parseInt(value);
            if (c >= 0 || value === '') {
                setcountgroupB(c);
                if (value === '') c = 0;
                setcounttotal(c + parseInt(countgroupA) + parseInt(countgroupC) + parseInt(countgroupD) + parseInt(countgroupE));
                setprice((countInPayment(c) + countInPayment(countgroupA) + countInPayment(countgroupC) + countInPayment(countgroupD) + countInPayment(countgroupE)) * 100);
            }
        }
        if (name === 'countgroupC') {
            let c = parseInt(value);
            if (c >= 0 || value === '') {
                setcountgroupC(c);
                if (value === '') c = 0;
                setcounttotal(c + parseInt(countgroupA) + parseInt(countgroupB) + parseInt(countgroupD) + parseInt(countgroupE));
                setprice((countInPayment(c) + countInPayment(countgroupB) + countInPayment(countgroupA) + countInPayment(countgroupD) + countInPayment(countgroupE)) * 100);
            }
        }
        if (name === 'countgroupD') {
            let c = parseInt(value);
            if (c >= 0 || value === '') {
                setcountgroupD(c);
                if (value === '') c = 0;
                setcounttotal(c + parseInt(countgroupB) + parseInt(countgroupC) + parseInt(countgroupA) + parseInt(countgroupE));
                setprice((countInPayment(c) + countInPayment(countgroupB) + countInPayment(countgroupC) + countInPayment(countgroupA) + countInPayment(countgroupE)) * 100);
            }
        }
        if (name === 'countgroupE') {
            let c = parseInt(value);
            if (c >= 0 || value === '') {
                setcountgroupE(c);
                if (value === '') c = 0;
                setcounttotal(c + parseInt(countgroupB) + parseInt(countgroupC) + parseInt(countgroupD) + parseInt(countgroupA));
                setprice((countInPayment(c) + countInPayment(countgroupB) + countInPayment(countgroupC) + countInPayment(countgroupD) + countInPayment(countgroupA)) * 100);
            }
        }
        if (name === 'counttotal')
            setcounttotal(value);
    };

    const validateForm = () => {
        if (schoolname && city && district && taluka && email && principalname && principalmobile && cordinatorname && cordinatormobile &&
            (countgroupA || countgroupB || countgroupC || countgroupD || countgroupE) && counttotal && pancard && paymentsc &&
            ((group1?.student1?.surname && group1?.student1?.fullname && group1?.student1?.attendingparents && group1?.student1?.parentsmobile &&
                group1?.student2?.surname && group1?.student2?.fullname && group1?.student2?.attendingparents && group1?.student2?.parentsmobile &&
                group1?.student3?.surname && group1?.student3?.fullname && group1?.student3?.attendingparents && group1?.student3?.parentsmobile
            ) || (group2?.student1?.surname && group2?.student1?.fullname && group2?.student1?.attendingparents && group2?.student1?.parentsmobile &&
                group2?.student2?.surname && group2?.student2?.fullname && group2?.student2?.attendingparents && group2?.student2?.parentsmobile &&
                group2?.student3?.surname && group2?.student3?.fullname && group2?.student3?.attendingparents && group2?.student3?.parentsmobile
                ) || (group3?.student1?.surname && group3?.student1?.fullname && group3?.student1?.attendingparents && group3?.student1?.parentsmobile &&
                    group3?.student2?.surname && group3?.student2?.fullname && group3?.student2?.attendingparents && group3?.student2?.parentsmobile &&
                    group3?.student3?.surname && group3?.student3?.fullname && group3?.student3?.attendingparents && group3?.student3?.parentsmobile
                ) || (group4?.student1?.surname && group4?.student1?.fullname && group4?.student1?.attendingparents && group4?.student1?.parentsmobile &&
                    group4?.student2?.surname && group4?.student2?.fullname && group4?.student2?.attendingparents && group4?.student2?.parentsmobile &&
                    group4?.student3?.surname && group4?.student3?.fullname && group4?.student3?.attendingparents && group4?.student3?.parentsmobile
                ) || (group5?.student1?.surname && group5?.student1?.fullname && group5?.student1?.attendingparents && group5?.student1?.parentsmobile &&
                    group5?.student2?.surname && group5?.student2?.fullname && group5?.student2?.attendingparents && group5?.student2?.parentsmobile &&
                    group5?.student3?.surname && group5?.student3?.fullname && group5?.student3?.attendingparents && group5?.student3?.parentsmobile
                ))) {
            return true;
        }
        else {
            return false;
        }
    }

    const sendEmail = (mail) => {
        emailjs.send("service_8m18sys", "template_uh4himl", {
            to_email: mail,
        }, "user_sY1m8Ki5Oafoc32NNK9TG");

        // emailjs.send("service_6tpaae9", "template_ehavusr", {
        //     form_link: "https://satprerna.org/850274/Admin/PrabhuMeinTera",
        //     form_name: "Prabhu Mein Tera",
        //     to_email: "poonam.lpdf2@gmail.com",
        // }, "user_WO7pjjJ2kpBHGbUJekjMm");
    }

    const handleFormSubmit = async (e) => {
        setmessage(!message)
        const formData = new FormData()
        if (validateForm()) {
            setmessage(true)
            setformstatus('info');
            setlogtext('Submitting. Please wait for atleast a minute.');

            formData.append("schoolname", schoolname);
            formData.append("city", city);
            formData.append("district", district);
            formData.append("taluka", taluka);
            formData.append("email", email);
            formData.append("principalname", principalname);
            formData.append("principalmobile", principalmobile);
            formData.append("cordinatorname", cordinatorname);
            formData.append("cordinatormobile", cordinatormobile);
            // formData.append("gitainspiringteacher", gitainspiringteacher);
            formData.append("countgroupA", countgroupA);
            formData.append("countgroupB", countgroupB);
            formData.append("countgroupC", countgroupC);
            formData.append("countgroupD", countgroupD);
            formData.append("countgroupE", countgroupE);
            formData.append("counttotal", counttotal);
            formData.append("pancard", pancard);
            formData.append("paymentsc", paymentsc);

            formData.append("groupa_winner1_surname", group1?.student1?.surname);
            formData.append("groupa_winner1_fullname", group1?.student1?.fullname);
            formData.append("groupa_winner1_attendingparents", group1?.student1?.attendingparents);
            formData.append("groupa_winner1_parentsmobile", group1?.student1?.parentsmobile);
            formData.append("groupa_winner2_surname", group1?.student2?.surname);
            formData.append("groupa_winner2_fullname", group1?.student2?.fullname);
            formData.append("groupa_winner2_attendingparents", group1?.student2?.attendingparents);
            formData.append("groupa_winner2_parentsmobile", group1?.student2?.parentsmobile);
            formData.append("groupa_winner3_surname", group1?.student3?.surname);
            formData.append("groupa_winner3_fullname", group1?.student3?.fullname);
            formData.append("groupa_winner3_attendingparents", group1?.student3?.attendingparents);
            formData.append("groupa_winner3_parentsmobile", group1?.student3?.parentsmobile);

            formData.append("groupb_winner1_surname", group2?.student1?.surname);
            formData.append("groupb_winner1_fullname", group2?.student1?.fullname);
            formData.append("groupb_winner1_attendingparents", group2?.student1?.attendingparents);
            formData.append("groupb_winner1_parentsmobile", group2?.student1?.parentsmobile);
            formData.append("groupb_winner2_surname", group2?.student2?.surname);
            formData.append("groupb_winner2_fullname", group2?.student2?.fullname);
            formData.append("groupb_winner2_attendingparents", group2?.student2?.attendingparents);
            formData.append("groupb_winner2_parentsmobile", group2?.student2?.parentsmobile);
            formData.append("groupb_winner3_surname", group2?.student3?.surname);
            formData.append("groupb_winner3_fullname", group2?.student3?.fullname);
            formData.append("groupb_winner3_attendingparents", group2?.student3?.attendingparents);
            formData.append("groupb_winner3_parentsmobile", group2?.student3?.parentsmobile);

            formData.append("groupc_winner1_surname", group3?.student1?.surname);
            formData.append("groupc_winner1_fullname", group3?.student1?.fullname);
            formData.append("groupc_winner1_attendingparents", group3?.student1?.attendingparents);
            formData.append("groupc_winner1_parentsmobile", group3?.student1?.parentsmobile);
            formData.append("groupc_winner2_surname", group3?.student2?.surname);
            formData.append("groupc_winner2_fullname", group3?.student2?.fullname);
            formData.append("groupc_winner2_attendingparents", group3?.student2?.attendingparents);
            formData.append("groupc_winner2_parentsmobile", group3?.student2?.parentsmobile);
            formData.append("groupc_winner3_surname", group3?.student3?.surname);
            formData.append("groupc_winner3_fullname", group3?.student3?.fullname);
            formData.append("groupc_winner3_attendingparents", group3?.student3?.attendingparents);
            formData.append("groupc_winner3_parentsmobile", group3?.student3?.parentsmobile);

            formData.append("groupd_winner1_surname", group4?.student1?.surname);
            formData.append("groupd_winner1_fullname", group4?.student1?.fullname);
            formData.append("groupd_winner1_attendingparents", group4?.student1?.attendingparents);
            formData.append("groupd_winner1_parentsmobile", group4?.student1?.parentsmobile);
            formData.append("groupd_winner2_surname", group4?.student2?.surname);
            formData.append("groupd_winner2_fullname", group4?.student2?.fullname);
            formData.append("groupd_winner2_attendingparents", group4?.student2?.attendingparents);
            formData.append("groupd_winner2_parentsmobile", group4?.student2?.parentsmobile);
            formData.append("groupd_winner3_surname", group4?.student3?.surname);
            formData.append("groupd_winner3_fullname", group4?.student3?.fullname);
            formData.append("groupd_winner3_attendingparents", group4?.student3?.attendingparents);
            formData.append("groupd_winner3_parentsmobile", group4?.student3?.parentsmobile);

            formData.append("groupe_winner1_surname", group5?.student1?.surname);
            formData.append("groupe_winner1_fullname", group5?.student1?.fullname);
            formData.append("groupe_winner1_attendingparents", group5?.student1?.attendingparents);
            formData.append("groupe_winner1_parentsmobile", group5?.student1?.parentsmobile);
            formData.append("groupe_winner2_surname", group5?.student2?.surname);
            formData.append("groupe_winner2_fullname", group5?.student2?.fullname);
            formData.append("groupe_winner2_attendingparents", group5?.student2?.attendingparents);
            formData.append("groupe_winner2_parentsmobile", group5?.student2?.parentsmobile);
            formData.append("groupe_winner3_surname", group5?.student3?.surname);
            formData.append("groupe_winner3_fullname", group5?.student3?.fullname);
            formData.append("groupe_winner3_attendingparents", group5?.student3?.attendingparents);
            formData.append("groupe_winner3_parentsmobile", group5?.student3?.parentsmobile);

            await axios({
                url: 'https://satprerna.org/satprerna_backend/home/insert_into_sgp_form.php',
                method: 'post',
                data: formData,
                responseType: 'json'
            })
                .then(() => {
                    sendEmail(email);
                    setmessage(true);
                    setformstatus('success');
                    setlogtext('Details Submitted Successfully');

                    setemail('');
                    setschoolname('');
                    setcity('');
                    setdistrict('');
                    settaluka('');
                    setemail('');
                    setprincipalname('');
                    setprincipalmobile('');
                    setcordinatorname('');
                    setcordinatormobile('');
                    // setgitainspiringteacher('');
                    setcountgroupA('0');
                    setcountgroupB('0');
                    setcountgroupC('0');
                    setcountgroupD('0');
                    setcountgroupE('0');
                    setcounttotal('0');
                    setpancard('removed');
                    setpaymentsc('');
                    setgroup1({});
                    setgroup2({});
                    setgroup3({});
                    setgroup4({});
                    setgroup5({});
                })
                .catch(err => {
                    console.log('err', err)
                    setmessage(true)
                    setformstatus('error')
                    setlogtext('Something went wrong')
                })
        }
        else {
            setmessage(true)
            setformstatus('error');
            setlogtext('Please fill all details');
        }
    };

    // const uploadFile = (name) => {
    //     const formData = new FormData();
    //     setmessage(true)
    //     setformstatus('info');
    //     setlogtext('Please wait...');
    //     formData.append("dir", "../images/sgp_form/pan/" + name);
    //     formData.append("image", pancard);
    //     axios.post('https://satprerna.org/satprerna_backend/home/store_image_to_folder.php', formData)
    //         .then(() => {
    //             setmessage(true);
    //             setformstatus('success');
    //             setlogtext('Uploaded');
    //         });
    // }

    // const handleUpload = (event) => {
    //     event.preventDefault();
    //     var fileReader = new FileReader();
    //     let base64data;
    //     fileReader.onloadend = function () {
    //         base64data = fileReader.result;
    //         setpancard(base64data);
    //     }
    //     if (event.target.files[0].size > 540789) {
    //         setmessage(true)
    //         setformstatus('error');
    //         setlogtext('Fill size should be less then 500kb');
    //     }
    //     else {
    //         fileReader.readAsDataURL(event.target.files[0]);
    //         uploadFile(schoolname + "_pan_card_" + event.target.files[0].name);
    //     }
    // }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    const handlePaymentScUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        let scBase64 = '';
        getBase64(file, (result) => {
            scBase64 = result;
            setpaymentsc(scBase64);
            // uploadPaymentScFile(schoolname + "_payment_screenshot_" + file.name);
        });
    }

    const uploadPaymentScFile = (name) => {
        const formData = new FormData();
        setmessage(true);
        setformstatus('info');
        setlogtext('Please wait...');
        formData.append("dir", "../images/sgp_form/payment_screenshot/" + name);
        formData.append("image", paymentsc);
        axios.post('https://satprerna.org/satprerna_backend/home/store_image_to_folder.php', formData)
            .then(() => {
                setmessage(true);
                setformstatus('success');
                setlogtext('Uploaded');
            });
    }

    // else {
    //     convertBase64(file)
    //         .then((res) => {
    //             console.log(res);
    //             setpaymentsc(res);
    //         }).then(() => {
    //             uploadPaymentScFile(schoolname + "_payment_screenshot_" + file.name);
    //         });
    // }
    // console.log(base64data);

    // let base64data;
    // var fileReader = new FileReader();
    // fileReader.onloadend = function () {
    //     base64data = fileReader.result;
    // }
    // fileReader.readAsDataURL(event.target.files[0]);
    // setpaymentsc(base64data);
    // }

    // useEffect(() => {
    //     uploadPaymentScFile(schoolname + "_payment_screenshot_");
    // }, [paymentsc]);

    // return (
    //     <div>
    //         <div className="form-header">
    //             <AppBar className={classes.appBar}>
    //                 <Toolbar>
    //                     <Typography variant="h6" className={classes.title}>
    //                     </Typography>
    //                     <Link to="/"><IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
    //                         <CloseIcon />
    //                     </IconButton></Link>
    //                 </Toolbar>
    //             </AppBar>
    //             <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">
    //                 <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
    //                 <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
    //                 <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
    //             </svg>
    //         </div>
    //         <div className="form-wrapper">
    //             <h1 className="t1" >Shri Gita Prerna 2023</h1>
    //             <h6 className="t2 my-3" >Winner details online registration</h6>
    //             <p>
    //                 Terms & Conditions:<br />

    //                 1. Please read rules carefully before filling form. <br />

    //                 2. All the fields are mandatory. Form should be filled with utmost care.<br />

    //                 3. Please provide PAN Card number which is linked to the same account through which payment is made.<br />

    //                 4. Please mail 3 photographs and 2 videos of the competition conducted in the school to <b>info@satprerna.org</b>. This is mandatory to qualify for "State Level Competition".<br />

    //                 5. Only 1st winner of each group will be eligible to participate in State Level Competition. <br />

    //                 6. The participation fee for 1st winner of all groups will be 100 ₹ i.e. 1st winners of group A,B,C,D and E respectively. Registration will be free or 2nd and 3rd winners.<br />

    //                 7. 1st, 2nd, 3rd winners of each School Level Competition will be felicitated during State Level Programme.<br />

    //                 8. Names of students and schools entered during registration will only be considered for certificate generation during State Level Program.<br />

    //                 9. Participating Students, Principals, Teachers and Parents will attend programme voluntarily and indemnify Satprerna Trust. <br />

    //                 10. In case of any disputes during State Level Competition, the decision of "Satprerana Trust" will be final and binding.<br />

    //                 11. Certificates will be issued to the "Gita Inspiring Teachers" only.<br />
    //             </p>
    //             <Grid container className="my-5" alignItems="center">
    //                 <Grid item xs={12} md={12}>
    //                     <div className="mb-5 mb-lg-0">
    //                         <img className="aa" src={require("../images/3G/6.jpg")} alt="" width="100%" height="auto" />
    //                     </div>
    //                 </Grid>
    //             </Grid>
    //             <Divider />
    //             <div className="my-5">
    //                 <Typography variant="h6" className={classes.title}>
    //                     School Details
    //                 </Typography>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} sm={12}>
    //                         <TextField
    //                             autoComplete="schoolname"
    //                             name="schoolname"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="schoolname"
    //                             label="School Name"
    //                             value={schoolname}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="city"
    //                             label="City"
    //                             name="city"
    //                             autoComplete="city"
    //                             value={city}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="taluka"
    //                             label="Taluka"
    //                             name="taluka"
    //                             autoComplete="taluka"
    //                             value={taluka}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="district"
    //                             label="District"
    //                             name="district"
    //                             autoComplete="district"
    //                             value={district}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             autoComplete="email"
    //                             name="email"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="email"
    //                             label="School Email id"
    //                             value={email}
    //                             onChange={handleInputChange}
    //                         /><p style={{ color: 'orangered', fontSize: '0.8rem', marginLeft: '8px' }}>{emailerr}</p>
    //                     </Grid>
    //                 </Grid>

    //                 <Typography variant="h6" className={classes.title}>
    //                     Principal and Co ordinator's Details
    //                 </Typography>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             autoComplete="principalname"
    //                             name="principalname"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="principalname"
    //                             label="Principal's Name:"
    //                             value={principalname}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="principalmobile"
    //                             label="Principal's Mobile No:"
    //                             name="principalmobile"
    //                             autoComplete="principalmobile"
    //                             value={principalmobile}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="cordinatorname"
    //                             label="Gita Inspiring Teacher' s Name"
    //                             name="cordinatorname"
    //                             autoComplete="cordinatorname"
    //                             value={cordinatorname}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="cordinatormobile"
    //                             label="Gita Inspiring Teacher's Mobile No."
    //                             name="cordinatormobile"
    //                             autoComplete="cordinatormobile"
    //                             value={cordinatormobile}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     {/* <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="gitainspiringteacher"
    //                             label="Gita Inspiring Teacher"
    //                             name="gitainspiringteacher"
    //                             autoComplete="gitainspiringteacher"
    //                             value={gitainspiringteacher}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid> */}
    //                 </Grid>

    //                 <Typography variant="h6" className={classes.title}>
    //                     No. of students participated in each group:
    //                 </Typography>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="countgroupA"
    //                             name="countgroupA"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             id="countgroupA"
    //                             label="Group A"
    //                             value={countgroupA}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="countgroupB"
    //                             name="countgroupB"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             id="countgroupB"
    //                             label="Group B"
    //                             value={countgroupB}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="countgroupC"
    //                             name="countgroupC"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             id="countgroupC"
    //                             label="Group C"
    //                             value={countgroupC}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="countgroupD"
    //                             name="countgroupD"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             id="countgroupD"
    //                             label="Group D"
    //                             value={countgroupD}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="countgroupE"
    //                             name="countgroupE"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             id="countgroupE"
    //                             label="Group E"
    //                             value={countgroupE}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={4}>
    //                         <TextField
    //                             autoComplete="counttotal"
    //                             name="counttotal"
    //                             variant="outlined"
    //                             type="number"
    //                             required
    //                             fullWidth
    //                             disabled
    //                             id="counttotal"
    //                             label="Total"
    //                             value={counttotal}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </div>
    //             <Divider />
    //             <div className="my-5">
    //                 <Typography variant="h5" className={classes.title}>
    //                     Name of winners
    //                 </Typography>
    //                 {Object.keys(group1).length !== 0 ?
    //                     <GroupsTable group={group1} groupname="Group A" />
    //                     : countgroupA !== NaN && countgroupA > 0 && <Grid container spacing={3}>
    //                         <Grid item xs={12} className="mt-4">
    //                             <AddDialog handleSumbit={setgroup1} groupname="Group A" />
    //                         </Grid>
    //                     </Grid>
    //                 }
    //                 {Object.keys(group2).length !== 0 ?
    //                     <GroupsTable group={group2} groupname="Group B" />
    //                     : countgroupB !== NaN && countgroupB > 0 && <Grid container spacing={3}>
    //                         <Grid item xs={12} className="mt-4">
    //                             <AddDialog handleSumbit={setgroup2} groupname="Group B" />
    //                         </Grid>
    //                     </Grid>
    //                 }
    //                 {Object.keys(group3).length !== 0 ?
    //                     <GroupsTable group={group3} groupname="Group C" />
    //                     : countgroupC !== NaN && countgroupC > 0 && <Grid container spacing={3}>
    //                         <Grid item xs={12} className="mt-4">
    //                             <AddDialog handleSumbit={setgroup3} groupname="Group C" />
    //                         </Grid>
    //                     </Grid>
    //                 }
    //                 {Object.keys(group4).length !== 0 ?
    //                     <GroupsTable group={group4} groupname="Group D" />
    //                     : countgroupD !== NaN && countgroupD > 0 && <Grid container spacing={3}>
    //                         <Grid item xs={12} className="mt-4">
    //                             <AddDialog handleSumbit={setgroup4} groupname="Group D" />
    //                         </Grid>
    //                     </Grid>
    //                 }
    //                 {Object.keys(group5).length !== 0 ?
    //                     <GroupsTable group={group5} groupname="Group E" />
    //                     : countgroupE !== NaN && countgroupE > 0 && <Grid container spacing={3}>
    //                         <Grid item xs={12} className="mt-4">
    //                             <AddDialog handleSumbit={setgroup5} groupname="Group E" />
    //                         </Grid>
    //                     </Grid>
    //                 }
    //             </div>
    //             <Divider />
    //             <Grid container spacing={1}>
    //                 <Grid item xs={12}>
    //                     <Typography variant="h5" className={classes.title}>
    //                         Payment Options
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item xs={12} className="mb-3">
    //                     <Typography variant="h6" className={classes.text}>
    //                         You have to transfer <b>{'₹' + price}</b> online to following QR code.
    //                     </Typography>
    //                     <img src={require("../images/qr.jpg")} alt="" className={classes.qrImg} />
    //                 </Grid>
    //                 <Grid item xs={12} sm={12}>
    //                     <input type="file" id="contained-button-file-2" accept="image/*" onChange={(e) => handlePaymentScUpload(e)} style={{ display: 'none' }} />
    //                     <label htmlFor="contained-button-file-2">
    //                         <Button variant="contained" color="secondary" component="span">
    //                             Upload Payment Screenshot
    //                         </Button>
    //                     </label>
    //                     <img src={paymentsc} alt="" className={classes.uploadedImg} />
    //                 </Grid>
    //                 {/* <Grid item xs={12} sm={6} className="my-5">
    //                     <Typography variant="body2" className={classes.text}>
    //                         Please provide PAN Card number which is linked to the same account through which payment is made.
    //                     </Typography>
    //                     <TextField
    //                         autoComplete="pancard"
    //                         name="pancard"
    //                         variant="outlined"
    //                         required
    //                         fullWidth
    //                         id="pancard"
    //                         label="Pan Number"
    //                         value={pancard}
    //                         onChange={handleInputChange}
    //                     />
    //                 </Grid> */}

    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} className="mt-4 text-right pmt-form-buttons">
    //                         <div className="ml-3 pmt-submit-btn"><ButtonA buttonto="Submit Details" onClick={handleFormSubmit} disabled={message} /></div>
    //                     </Grid>
    //                     <Snackbar open={message} autoHideDuration={formstatus !== 'success' ? null : 20000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
    //                         <Alert onClose={() => setmessage(false)} severity={formstatus}>
    //                             {logtext}
    //                         </Alert>
    //                     </Snackbar>
    //                 </Grid>
    //             </Grid>
    //         </div>
    //     </div>
    // );

    return (
        <h1>Form closed</h1>
    );
}
