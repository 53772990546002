import React from 'react';
import '../fonts.css'

const e22 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	My Lord, You have so kindly explained to me the
	auspicious day known as Sat-tila Ekadasi, which occurs
	during the dark fortnight (krishna paksha) of the month
	of Magha (January - February). Now please explain to
	me the Ekadasi that occurs in the light fortnight (shukla
	or Gaura paksha) of this month. By what name is it
	known, and what is the process for observing it? Who is
	the presiding Deity that is to be worshipped on this
	sublime day, which is so very dear to You?
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, Oh Yudhisthira, I shall gladly tell you about
	the Ekadasi that occurs during the light half of this month of Magha.
	This Ekadasi obliterates all kinds of sinful reactions and demoniac
	influences that may be affecting the spirit soul. It is known as Jaya
	Ekadasi, and the fortunate soul who observes a fast on this sacred
	day is relieved of the great burden of ghostly existence. Thus there
	is no better Ekadasi than this, for it truly bestows freedom from
	birth and death. It is to be honored very carefully and diligently. So
	you to listen to Me very attentively, Oh Pandava, as I explain a
	wonderful historic episode regarding this Ekadasi, an episode that I
	have already related in the Padma Purana.
	<hr style={{border: 'none'}} className="my-4"/>
	Long, long ago in the heavenly planets, Lord Indra ruled his celestial
	kingdom very nicely, and all the devas (demigods) living there were
	very happy and content. In Nandana Forest, which was beautifully
	graced with Parijata Flowers, Indra drank ambrosia whenever he
	liked and enjoyed the service of fifty million celestial maidens, the
	Apsaras, who danced in ecstasy for his pleasure.
	<hr style={{border: 'none'}} className="my-4"/>
	Many singers, led by Pushpadanta, sang in sweet voices beyond
	compare. Chitrasena, Indra's chief musician was there in the
	company of his wife Malini and his handsome son Malyavan. An
	Apsara named Pushpavati became very much attracted to Malyavan;
	indeed cupid’s sharp arrow pierced the core of her heart. Her
	beautiful body and complexion, along with the enchanting
	movements of her eyebrows, captivated Malayavan.
	<hr style={{border: 'none'}} className="my-4"/>
	Seeing Pushpavati in all her heavenly beauty, Malyavan was
	bewitched at once. They had come with the other performers to
	please Lord Indra by singing and dancing enchantingly, but because
	they had become so enamored of each other, pierced through the
	heart by the arrows of Cupid, lust personified, they were utterly
	unable to sing or dance properly before the lord and master of the
	heavenly realms.
	<hr style={{border: 'none'}} className="my-4"/>
	Their pronunciation was wrong and their rhythm careless. Lord
	Indra understood the source of the errors at once. Offended at the
	discord in the musical performance, he became very angry and
	screamed, "You useless fools! You pretend to sing for me while in a
	stupor of infatuation with each other! You are mocking me! I curse
	you both to suffer henceforth as pisAchas (hobgoblins). As husband
	and wife, go to the earthly regions and reap the reactions of your
	offenses.
	<hr style={{border: 'none'}} className="my-4"/>
	Struck dumb by these harsh words, Malyavan and Pushpavati at
	once became morose and fell from the beautiful Nandana Forest in
	the kingdom of heaven to a Himalayan peak here on planet Earth.
	<hr style={{border: 'none'}} className="my-4"/>
	Immeasurably distressed, and their celestial intelligence vastly
	diminished by the effects of lord Indra’s fierce curse, they lost their
	sense of taste and smell, and even their sense of touch. It was so
	cold and miserable high on the Himalayan wastes of snow and ice
	that they could not even enjoy the oblivion of sleep.
	<hr style={{border: 'none'}} className="my-4"/>
	Roaming aimlessly hither and thither in those harsh altitudes,
	Malyavan and Pushpavati suffered more and more, from one moment
	to the next. Even though they were situated in a cave, because of
	the snowfall and cold their teeth chattered ceaselessly, and their
	hair stood on end because of their fright and bewilderment.
	<hr style={{border: 'none'}} className="my-4"/>
	In this utterly desperate situation, Malyavan said to Pushpavati,
	What abominable sins did we commit to have to suffer in these
	pisacha bodies, in this impossible environment? This is absolutely
	hellish! Though hell is very ferocious, the suffering we are
	undergoing here is even more abominable. Therefore it is
	abundantly clear that one should never commit sins.
	<hr style={{border: 'none'}} className="my-4"/>
	And so the forlorn lovers trudged onward in the snow and ice. By
	their great good fortune, however, it so happened that very day was
	the all-auspicious Jaya (Bhaimi) Ekadasi, the Ekadasi of the light
	fortnight of the month of Magha. Because of their misery they
	neglected to drink any water, kill any game, or even eat whatever
	fruits and leaves were available at that altitude, they unknowingly
	observed Ekadasi by fasting completely from all food and drink. Sunk
	in misery Malyavan and Pushpavati collapsed beneath a Pipal tree
	and did not even try to get up. The Sun had set by that time.
	<hr style={{border: 'none'}} className="my-4"/>
	The night was even colder and more miserable than the day. They
	shivered in the frigid snowfall as their teeth chattered in unison, and
	when they became numb, they embraced just to keep warm, locked
	in each other’s arms. Thus they suffered through the whole night
	under the powerful curse of the deva Indra.
	<hr style={{border: 'none'}} className="my-4"/>
	Still, Oh Yudhisthira, by the mercy of the fast they had by chance
	(unknowingly) observed on Jaya Ekadasi, and because they had
	remained awake all night, they were blessed.
	<hr style={{border: 'none'}} className="my-4"/>
	Please hear what happened on the next day. As Dwadasii dawned,
	Malyavan and Pushpavati had given up their demoniac forms and
	were once again beautiful heavenly beings wearing lustrous
	ornaments and exquisite garments. A chorus of heavenly denizens
	sang their praises and showered flowers. Soon Malyavan and
	Pushpavati arrived at Amaravati, Lord Indra’s capital city, and then
	they immediately went before their lord (Indradev) and offered him
	their cheerful obeisances.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Indra was astonished to see that they had been transformed,
	restored to their original status and forms so soon after he had
	cursed them to suffer as demons far, far below his celestial kingdom.
	Indradev ask of them, ‘What extraordinary meritorious deeds have
	you performed so that you could give up your pisacha bodies so
	quickly after I cursed you? Who released you from my irresistible
	curse?
	<hr style={{border: 'none'}} className="my-4"/>
	Malyavan replied, Oh lord, it was by the extreme mercy of the
	Supreme Lord, Lord Sri Krishna (Vasudeva) and also by the powerful
	influence of the Jaya Ekadasi, that we were released from our
	suffering condition as pisachas. This is the truth, Oh master:
	Because we executed devotional service to Lord Vishnu (even
	performed unknowingly – by ajnata sukriti) by observing the day
	most dear to Him, we have happily been restored to our former
	status.
	<hr style={{border: 'none'}} className="my-4"/>
	Indradev then said, Because you served the Supreme Lord Sri
	Keshava by observing Ekadasi, you have become worshippable even
	by me, and I can see that you are now completely purified of sin.
	Whosoever engages in devotional service to Lord Sri Hari or Lord
	Shiva becomes praiseworthy and worshippable even by me. Of this
	there is no doubt.’ Lord Indradev then gave Malyavan and
	Pushpavati free rein to enjoy each other and wander about his
	heavenly planet as they wished. Therefore, Oh Maharaj Yudhisthira,
	one should strictly observe a fast on the sacred day of Lord Hari,
	especially on this Jaya Ekadasi, which frees one from the sin . A
	great soul who observes this fast with full faith and devotion has in
	effect given all kinds of charity, performed all kinds of sacrifice, and
	bathed in all the Holy places of pilgrimage. Fasting on Jaya Ekadasi
	qualifies one to reside in Vaikuntha and enjoy unending happiness
	for billions of yugas – indeed, forever as the soul is eternal.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Magha-shukla Ekadasi, or
	Jaya Ekadasi, from the Bhavishya-uttara Purana.
</p>

export default e22;
