import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EcoIcon from '@material-ui/icons/Eco';
import FaceIcon from '@material-ui/icons/Face';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Looks3Icon from '@material-ui/icons/Looks3';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import BookIcon from '@material-ui/icons/Book';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import PetsIcon from '@material-ui/icons/Pets';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Dummy from './Tabs/Dummy';
import Home from './Tabs/Home';
import AboutUs from './Tabs/AboutUs';
import ContactUs from './Tabs/ContactUs';
import Collaborate from './Tabs/Collaborate';
import Welfare from './Tabs/Welfare';
import WomenEmp from './Tabs/WomenEmp'
import Dsr from './Tabs/Dsr'
import Ekadashi from './Tabs/Ekadashi'
import AscentOfLife from './Tabs/AscentOfLife'
import AyushmanBhav from './Tabs/AyushmanBhav'
import AnandUtsav from './Tabs/AnandUtsav'
import ThreeGproject from './Tabs/3Gproject'
import Gaay from './Tabs/Gaay'
import Gita from './Tabs/Gita'
import Ganga from './Tabs/Ganga'
import Pmt from './Tabs/Pmt'
import Gallery from './Tabs/Gallery'
import './AdminPanel.css'
import {
	Link
} from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#ff9100',
    },
    text: {
        secondary: '#fff'
    }
  },
});

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
    color: 'white'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  logo: {
    width: '80%',
	  marginLeft: '10%',
    marginTop: '15px',
    marginBottom: '100px',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
  },
  logout: {
    color: '#fff',
    position: 'absolute',
    right: '25px',
    top: '22px',
    cursor: 'pointer',
  }
}));

export default function AdminPanel(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const AdminComponent = () => {
    if(props.name === 'Home') 
      return <Home />
    else if(props.name === 'About us')
      return <AboutUs />
    else if(props.name === 'Contact us')
      return <ContactUs />
    else if(props.name === 'Collaborate')
      return <Collaborate />
    else if(props.name === 'ThreeGproject')
      return <ThreeGproject />
    else if(props.name === 'Welfare')
      return <Welfare />
    else if(props.name === 'WomenEmp')
      return <WomenEmp />
    else if(props.name === 'AnandUtsav')
      return <AnandUtsav />
    else if(props.name === 'DivyaShishuRatna')
      return <Dsr />
    else if(props.name === 'AscentOfLife')
      return <AscentOfLife />
    else if(props.name === 'AyushmanBhav')
      return <AyushmanBhav />
    else if(props.name === 'BodyServicingDay')
      return <Ekadashi />
    else if(props.name === 'Gaay')
      return <Gaay />
    else if(props.name === 'Ganga')
      return <Ganga />
    else if(props.name === 'Gita')
      return <Gita />
    else if(props.name === 'Pmt')
      return <Pmt />
    else if(props.name === 'Gallery')
      return <Gallery />
    else
      return <Dummy name={props.name} />
  }

  return (
    <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <CssBaseline />
			<AppBar 
				position="fixed" 
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
        <Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h5" noWrap color="textSecondary">
						Admin Panel
					</Typography>
          <button style={{border: 'none'}} onClick={() => {
            sessionStorage.setItem("876789", '');
            sessionStorage.setItem("453465", '');
            window.location.reload();
          }}><h6 className={classes.logout}>LOGOUT</h6></button>
        </Toolbar>
      </AppBar>
      <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
      >
          <div className={classes.drawerContainer} id="style-scroll">
					<div className={classes.toolbar}>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>
					</div>
					<Divider />
                    <List>
                        {[['Home', '/850274/Admin', <HomeIcon />], ['Vision and Mission', '/850274/Admin/AboutUs', <SupervisedUserCircleIcon />],
                            ['Contact Us', '/850274/Admin/ContactUs', <PermContactCalendarIcon />], ['Collaborate', '/850274/Admin/Collaborate', <GroupWorkIcon />]].map((text, index) => (
                            <MenuItem component={Link} to={text[1]} button key={text} style={{height: '50px'}}> 
                                <ListItemIcon>{text[2]}</ListItemIcon>
                                <ListItemText primary={text[0]} />
                            </MenuItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {[['Welfare Activities', '/850274/Admin/WelfareActivities', <EmojiPeopleIcon />], ['\'Devi\' Being Women', '/850274/Admin/WomenEmpowerment', <FaceIcon />],
                            ['Divya Shishu Ratna', '/850274/Admin/DivyaShishuRatna', <PregnantWomanIcon />], ['Body Servicing Day', '/850274/Admin/BodyServicingDay', <AccessibilityNewIcon />],
                            ['Ascent of Life', '/850274/Admin/AscentOfLife', <EcoIcon />], ['Ayushman Bhav', '/850274/Admin/AyushmanBhav', <EmojiEmotionsIcon />],
                            ['Anand Utsav', '/850274/Admin/AnandUtsav', <BeachAccessIcon />], ['3G Project', '/850274/Admin/3G-Project', <Looks3Icon />]].map((text, index) => (
                            <MenuItem component={Link} to={text[1]} button key={text} style={{height: '50px'}}> 
                                <ListItemIcon>{text[2]}</ListItemIcon>
                                <ListItemText primary={text[0]} />
                            </MenuItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {[['Gau Madhuram',  '/850274/Admin/3G-Project/Gaay', <PetsIcon />], ['Shri Gita Prerna',  '/850274/Admin/3G-Project/ShriGitaPrerna', <BookIcon />],
                            ['Ganga Protection',  '/850274/Admin/3G-Project/Ganga', <NaturePeopleIcon />]].map((text, index) => (
                        <MenuItem component={Link} to={text[1]} button key={text} style={{height: '50px'}}> 
                            <ListItemIcon>{text[2]}</ListItemIcon>
                            <ListItemText primary={text[0]} />
                        </MenuItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {[['Prabhu Mein Tera',  '/850274/Admin/PrabhuMeinTera', <MenuBookIcon />], ['Gallery',  '/850274/Admin/Gallery', <PhotoLibraryIcon />]].map((text, index) => (
                        <MenuItem component={Link} to={text[1]} button key={text} style={{height: '50px'}}> 
                            <ListItemIcon>{text[2]}</ListItemIcon>
                            <ListItemText primary={text[0]} />
                        </MenuItem>
                        ))}
                    </List>
                    <Divider />
                    <img className={classes.logo} src={require("../logo.svg")} title="Home" alt="logo"/>
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />
                <AdminComponent />
            </main>
        </div>
    </ThemeProvider>
  );
}
