/* eslint-disable jsx-a11y/anchor-has-content */
import React, {  } from 'react'
import  './contactus.css'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function TnC(props) {

	return (
		<div>
			<Header 
				headerClass="allGalleryHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "2"
				buttonto=""
				linkto = "/"
			/>
			<div className="contactus-wrapper mx-auto">
				<div className="collaborate-header">Terms And Conditions</div>
                <div className=" my-5">
                    <p style={{ columnCount: 1 }} className="text-justify Bodytext-1regular-16ptleftdark-lato">
                        Terms &amp; Conditions for Satprerna Trust (www.satprerna.org)<br/><br/>
                        Effective Date: 10 th December 2023<br/>
                        By accessing and using www.satprerna.org, you agree to abide by the following Terms &amp;
                        Conditions. Please read these terms carefully before using the website.<br/><br/>
                        Use of Website:<br/>
                        a. Access: Users must be at least 18 years old to access and use this website. By using the
                        website, you affirm that you meet this age requirement.<br/>
                        b. Compliance: Users agree to comply with all applicable laws and regulations concerning
                        website use.<br/><br/>
                        Intellectual Property Rights:<br/>
                        a. Content: All content displayed on www.satprerna.org, including text, graphics, logos,
                        images, and multimedia, is the property of Satprerna Trust and is protected by intellectual
                        property laws.<br/>
                        b. Use Restrictions: Users may not reproduce, modify, distribute, or republish any content
                        from this website without prior written consent from Satprerna Trust.<br/><br/>
                        Limitation of Liability:<br/>
                        a. Use at Own Risk: Users agree that their use of www.satprerna.org is at their own risk.
                        Satprerna Trust is not liable for any direct, indirect, incidental, consequential, or punitive
                        damages arising from website use.<br/>
                        b. Indemnity: Users agree to indemnify and hold Satprerna Trust, its directors, employees,
                        and affiliates harmless from any claims, damages, or expenses resulting from violation of
                        these Terms &amp; Conditions or misuse of the website.<br/><br/>
                        Third-Party Links:<br/>
                        a. www.satprerna.org may contain links to third-party websites. Satprerna Trust is not
                        responsible for the content, accuracy, or practices of these external sites.<br/><br/>
                        Privacy Policy:<br/>
                        a. Use of personal information provided on www.satprerna.org is subject to the terms
                        outlined in the Privacy Policy.<br/>
                        b. By using the website, users consent to the collection and use of information as described
                        in the Privacy Policy.<br/><br/>
                        Modifications to Terms:<br/>
                        a. Satprerna Trust reserves the right to update, modify, or replace these Terms &amp; Conditions
                        at any time without prior notice. Users are encouraged to review this page periodically for
                        any changes.<br/><br/>
                        Governing Law:<br/>
                        a. These Terms &amp; Conditions shall be governed by and construed in accordance with the
                        laws of Gandhinagar, Gujarat, without regard to its conflict of law provisions.<br/><br/>
                        Contact Information:<br/>
                        a. For questions or concerns regarding these Terms &amp; Conditions or website use, please
                        contact us at info@satprerna.org<br/>
                        By using www.satprerna.org, users acknowledge and agree to the terms outlined in these
                        Terms &amp; Conditions.<br/><br/>
                    </p>
                </div>
            </div>
            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
		</div>
	)
}

export default TnC;