import React from 'react';
import '../fonts.css'

const e10 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Shri Yudhisthira Maharaja said, "Oh Lord, please be
	merciful to me and describe to me the Ekadasi that
	occurs during the light fortnight of the month of
	Shravana (July-August).
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Madhusudana, replied, Yes, Oh king,
	I shall happily narrate its glories to you, for just by
	hearing about this sacred Ekadasi one attains the merit
	of performing a horse sacrifice. The name of this Ekadasi is Pavitra.
	<hr style={{border: 'none'}} className="my-4"/>
	At the dawn of Dvarpara-yuga there lived a king by the name of
	Mahijita, who ruled the kingdom of Mahismati. Because he had no
	son, his entire kingdom seemed utterly cheerless to him. A married
	man who has no son gains no happiness in this life or the next.
	<hr style={{border: 'none'}} className="my-4"/>
	The Sanskrit word for 'son' is putra. Pu is the name of a particular
	hell, and tra means 'to deliver.' Thus the word putra means 'a person
	who delivers one from the hell named Pu.' Therefore every married
	man should produce at least one son and train him properly; then
	the father will be delivered from a hellish condition of life.
	<hr style={{border: 'none'}} className="my-4"/>
	For a long time this king tried very hard to obtain an heir, but to no
	avail. Seeing his years advancing ever onwards, King Mahijita
	became increasingly anxious. One day he told an assembly of his
	advisers: 'I have committed no sin in this life, and there is no ill-
	gotten wealth in my treasury. I have never usurped the offerings to
	the demigods or Brahmanas. When I waged war and conquered
	kingdoms, I followed the rules and regulations of the military art,
	and I have protected my subjects as if they were my own children. I
	punished even my own relatives if they broke the law, and if my
	enemy was gentle and religious I welcomed him. Oh twice-born
	souls, although I am a religious and faithful follower of the Vedic
	standards, still my home is without a son. Kindly tell me the reason
	for this.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this, the king's Brahman advisers discussed the subject
	among themselves, and with the aim of benefiting the king they
	visited the various ashrams of the great sages. At last they came
	upon a sage who was austere, pure, and self-satisfied, and who was
	strictly observing a vow of fasting. His senses were completely
	under control, he had conquered his anger, and he was expert at
	performing his occupational duty. Indeed, this great sage was expert
	in all the conclusions of the Vedas, and he had extended his life span
	to that of Lord Brahma himself. His name was Lomasa Rishi, and he
	knew past, present, and future. After each kalpa passed, one hair
	would fall out of his body. All the king's Brahman advisers very
	happily approached him one by one to offer their humble respects.
	<hr style={{border: 'none'}} className="my-4"/>
	Captivated by this great soul, King Mahijita's advisers offered
	obeisances to him and said very respectfully, ‘Only because of our
	great good fortune, Oh sage, have we been allowed to see you.’
	Lomasa Rishi saw them bowing down to him and replied, ‘Kindly let
	me know why you have come here. Why are you praising me? I must
	do all I can to solve your problems, for sages like me have only one
	interest: to help others. Do not doubt this.’
	<hr style={{border: 'none'}} className="my-4"/>
	Lomasa Rishi had all good qualities because he was a devotee of the
	Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	The king's representatives said, "We have come to you, Oh exalted
	sage, to ask for your help in solving a very serious problem. Oh sage,
	you are like Lord Brahma. Indeed, there is no better sage in the
	entire world. Our king, Mahijita, is without a son, though he has
	sustained and protected us as if we were his sons. Seeing him so
	unhappy on account of being sonless, we have become very sad, Oh
	sage, and therefore we have entered the forest to perform severe
	austerities. By our good fortune we happened upon you. Everyone's
	desires and activities become successful just by your darshan. Thus
	we humbly ask that you tell us how our kind king can obtain a son."
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing their sincere plea, Lomasa Rishi absorbed himself in deep
	meditation for a moment and at once understood the king's previous
	life. Then he said, 'Your ruler was a merchant in his past life, and
	feeling his wealth insufficient, he committed sinful deeds. He
	traveled to many villages to trade his goods. Once, at noon on the
	day after the Ekadasi that comes during the light fortnight of the
	month of Jyeshtha (May-June), he became thirsty while traveling
	from place to place. He came upon a beautiful pond on the outskirts
	of a village, but just as he was about to drink at the pond a cow
	arrived there with her newborn calf. These two creatures were also
	very thirsty because of the heat, but when the cow and calf started
	to drink, the merchant rudely shoved them aside and selfishly slaked
	his own thirst. This offense against a cow and her calf has resulted
	in your king's being without a son now. But the good deeds he
	performed in his previous life have given him rulership over an
	undisturbed kingdom.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this, the king's advisers replied, Oh renowned rishi, we have
	heard that the Vedas say one can nullify the effects of one's past
	sins by acquiring merit. Be so kind as to give us some instruction by
	which our king's sins can be destroyed; please give him your mercy
	so that a prince will take birth in his family.
	<hr style={{border: 'none'}} className="my-4"/>
	Lomasa Rishi said, There is an Ekadasi called Putrada, which comes
	during the light fortnight of the month of Shravana. On this day all of
	you, including your king, should fast and stay awake all night,
	strictly following the rules and regulations. Then you should give the
	king whatever merit you gain by this fast. If you follow these
	instructions of mine, he will surely be blessed with a fine son.
	<hr style={{border: 'none'}} className="my-4"/>
	All the king's advisers became very pleased to hear these words
	from Lomasa Rishi, and they all offered him their grateful
	obeisances. Then, their eyes bright with happiness, they returned
	home.
	<hr style={{border: 'none'}} className="my-4"/>
	When the month of Shravana arrived, the king's advisers
	remembered the advice of Lomasa Rishi, and under their direction all
	the citizens of Mahismati, as well as the king, fasted on Ekadasi. And
	on the next day, Dvadasi, the citizens dutifully offered their accrued
	merit to him. By the strength of all this merit, the queen became
	pregnant and eventually gave birth to a most beautiful son.
	<hr style={{border: 'none'}} className="my-4"/>
	O Yudhisthira, Lord Krishna concluded, the Ekadasi that comes
	during the light fortnight of the month of Shravana has thus
	rightfully become famous as Putrada ["bestower of a son"].
	Whoever desires happiness in this world and the next should
	certainly fast on this holy day. Indeed, whoever simply hears the
	glories of Putrada Ekadasi becomes completely free of all sins, is
	blessed with a good son, and surely ascends to heaven after death.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Shravana-sukla Ekadasi, or
	Putrada Ekadasi, from the Bhavishya Purana.
</p>

export default e10;
