import React from 'react';
import '../fonts.css'

const e14 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhishthira maharaj said, Oh Madhusudana, what
	is the name of the Ekadasi that comes during the
	light fortnight of the month of Ashvina (September -
	October)? Please be merciful and disclose this truth
	to me.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord Sri Krishna replied, Oh king,
	please listen as I explain the glories of this Ekadasi -
	Papankusha Ekadasi - which removes all sins. On
	this day one should worship the Deity of
	Padmanabha, the lotus navel Lord Vishnu, according to the rules of
	archana viddhi (regulations). By so doing, one achieves whatever
	heavenly pleasures one may want in this world, and at last attains
	liberation from this world thereafter. Simply by offering one's
	humbly obeisance unto Lord Vishnu, holder of the bow, one can
	achieve the same merit as is gained by performing great penance for
	a long time restraining and controlling the senses. Although a
	person might have committed unlimited and abominable sins, he can
	still escape hellish punishment just by paying his obeisance to Lord
	Sri Hari, the taker away of all sin.
	<hr style={{border: 'none'}} className="my-4"/>
	The merits gained by going on pilgrimage to the Holy Tirthas of this
	earthly planet can also be achieved simply by chanting the Holy
	names of Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	Both the Vaishnava who criticizes Lord Shiva and the Shaivite
	(Saivite) who criticizes Me certainly go to hell. The merit obtained by
	performing one hundred horse sacrifices and one hundred Rajasurya
	sacrifices is not even equal to one sixteenth of the merit a devotee is
	able to attain by fasting on Ekadasi. There is no higher merit one can
	achieve than that attained by fasting on Ekadasi. Indeed, nothing in
	all the three worlds is as pleasing or as able to purify one of
	accumulated sin as Ekadasi, the day of the lotus-navel Lord,
	Padmanabha.
	<hr style={{border: 'none'}} className="my-4"/>
	O king, until a person observes a fast on the day of Lord
	Padmanabha named Papankusha Ekadasi, he remains sinful, and the
	reactions of his past sinful activities never leave him like a chaste
	wife. There is no merit in all the three worlds that can match the
	merit that one gains by observing a fast on this Ekadasi. Whosoever
	observes it faithfully never has to see death personified, Lord
	Yamaraj. One who desires liberation, elevation to the heavens, good
	health, beautiful women, wealth, and food grains should simply fast
	on this Pashunkusha Ekadasi. O king, neither the Ganges, Gaya,
	Kashi, nor Pushkara, nor even the Holy site of Kurukshetra, can
	grant as much auspicious merit as this Papankusha Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	O Maharaj Yudhishthira, protector of the earth, after observing
	Ekadasi during the daytime, the devotee should remain awake
	through the night, absorbed in hearing, chanting and serving the
	lord - for by so doing he easily attains to the Supreme abode of Lord
	Vishnu. Not only that, but ten generations of ancestors on his
	mother's side, ten generations on his father's side, and ten
	generations on his wife's side are all liberated by a single
	observance of a fast on this Ekadasi. All these ancestors attain their
	original; four armed transcendental Vaikuntha forms. Wearing
	yellow garments and beautiful garlands, they ride to the spiritual
	realm on the back of Garuda. This is the benediction My devotee
	earns simply by observing one Papankusha Ekadasi properly.
	<hr style={{border: 'none'}} className="my-4"/>
	O best of kings, whether one is a child, a youth, or in old age, fasting
	on Papankusha Ekadasi frees him from all sins and makes him
	immune to suffering a hellish rebirth. Whosoever observes a fast on
	the Papankusha Ekadasi becomes free of all his sins and returns to
	the spiritual abode of Lord Sri Hari. Whosoever donates gold, sesame
	seeds, fertile land, cows, grain, drinking water, an umbrella, or a
	pair of shoes on this most auspicious of Holy days will never have to
	visit the abode of Yamaraj, who always punishes the sinners. But if a
	resident of earth fails to perform spiritual deeds, especially the
	observance of a fast on days such as Ekadasi, his breathing is said to
	be no better, or of as much use as the breathing / puffing of a
	blacksmith's bellows.
	<hr style={{border: 'none'}} className="my-4"/>
	O best of the kings, especially on this Papankusha Ekadasi, even the
	poor should first bathe and then give some charity according to their
	means, and perform other auspicious activities in accordance with
	their ability.
	<hr style={{border: 'none'}} className="my-4"/>
	Whosoever performs sacrifices and benefits the people, or builds
	public ponds, resting places, gardens, or houses does not suffer the
	punishments of Yamaraj. Indeed, one should understand that a
	person must have performed such pious activities as these in the
	past life if he is long lived, wealthy, of high birth, or free from all
	diseases. But a person who observes Papankusha Ekadasi goes to
	the abode of the Supreme Lord, Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna then concluded, Thus, Oh King, whatever you asked
	I have narrated to you. Now what further wishes you have tell me.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of the Papankusha Ekadasi, or
	Ashwina-shukla Ekadasi, from the Brahma-vaivarta Purana.
</p>

export default e14;
