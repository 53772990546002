import React from 'react';
import './carousel.css'
import './style.css'
import classNames from 'classnames';
import '../fonts.css'

class CarouselLeft extends React.Component {
	constructor() {
		super()
		this.state = {
			index: [1, 2, 3],
			classes: ["", "-above", "-below"],
			classes_anime: ["","Above","Below"],
			isReverse: false,
			carousel_1: null
		}
		this.onNextClick=this.onNextClick.bind(this)
		//this.tick=this.tick.bind(this)
	}

	componentDidMount() {
		this.interval = setInterval(() => this.onNextClick(), 5000);
	}

	onNextClick() {
		this.setState((prev) => {
			prev.classes.push(prev.classes.shift())
			prev.classes_anime.push(prev.classes_anime.shift())
			return {
				classes: prev.classes,
				classes_anime: prev.classes_anime
			}
		}) 
	}
	/*const [index, setIndex] = useState([1, 2, 3])
    const [classes, setClasses] = useState(["", "-above", "-below"])
    const [classes_anime, setClasses_anime] = useState(["","Above","Below"])
    const [isReverse, setIsReverse] = useState(false)

	useEffect(() => {
        const interval = setInterval(() => onNextClick(), 5000);
        return () => {
		    clearInterval(interval);
		};
    }, []);

	const onNextClick = () => {
		setClasses(classes.push(classes.shift()))
		setClasses_anime(classes_anime.push(classes_anime.shift()))
	}*/

	render() {
		return (
			<div className = "carousel-section">
			    <br/>
		    	<div className="carousel-wrapper-left">
					<div className="greybox-div greybox-div-left-carousel px-4 px-sm-5">
						<aside>
			    		<h2 className="Subheading-1bold-36ptleftdark-lato">{this.props.title}</h2>
					    <p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: this.props.text}}></p>
						</aside>
			    	</div>
			    	<div className="carousel-main carousel-main-left">
			    		<div className="top-left-all">
			    			<img className={
		    					classNames(
		    						"top-left"+this.state.classes[0],
		    						"next-button-action-topLeft"+this.state.classes_anime[0]
		    					)
		    				}
			    				src="https://satprerna.org/satprerna_backend/images/carousel/img3.jpg" // src={require("../images/"+"carousel"+"/img"+this.state.index[1]+".jpg")} //require(this.state.carousel_1)
			    				/*onClick={this.onNextClick}*/ alt=''
		    				/>
			    			<img className={
			    					classNames(
			    						"top-left"+this.state.classes[1],
			    						"next-button-action-topLeft"+this.state.classes_anime[1]
			    					)
			    				}
			    				src="https://satprerna.org/satprerna_backend/images/carousel/img2.jpg"
			    				/*onClick={this.onNextClick}*/ alt=''
			    			/>
			    			<img className={
			    					classNames(
			    						"top-left"+this.state.classes[2],
			    						"next-button-action-topLeft"+this.state.classes_anime[2]
			    					)
			    				}
			    				src="https://satprerna.org/satprerna_backend/images/carousel/img1.jpg"
			    				/*onClick={this.onNextClick}*/ alt=''
			    			/>
			    		</div>
			    	</div>
			    </div>
			</div>
		)
	}
}

export default CarouselLeft;