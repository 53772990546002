import React, { useContext } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { DataContext } from '../Context/DataContext'
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './testimonials.css'
import '../fonts.css'

function Testimonial(props) {
	return(
		<div className="testimonial-card">
			<div className="testimonial-pic">
				<img style={{display: 'inline-block', width: '60%'}} className="pic" src={require("../images/dp.png")} alt=""/>
			</div>
			<div className="testimonial-content">
				<div className="text-justify Bodytext-1regular-16ptleftdark-lato main-content">
					{props.content}
					<h3 className="text-left Subtitle-2regular-26ptleftwhite-kulimpark">{props.details}</h3>
				</div>
			</div>
		</div>
	)
}

function Testimonials(props) {
	const { state } = useContext(DataContext);
	
	return(
		<div className="testimonial-main">
		 	<div className="testimonial-header">
		 		<h2 className="text-center Subheading-1bold-36ptleftdark-lato">TESTIMONIALS</h2>
			</div>
			<OwlCarousel className='owl-theme' items={1} rewind touchDrag mouseDrag center margin={10}>
				{state.testimonials.map(testimonial => {
					return <Testimonial
						content={testimonial.text}
						details={testimonial.name}
					/>
				})}
			</OwlCarousel>
		</div>
	)
}

export default Testimonials;
