import React from 'react';
import '../fonts.css'

const e25 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhishthira Maharaja said, Oh Supreme Lord, what is the name
	and character of that Ekadasi that occurs during the dark fortnight
	(krishna paksha) of the extra, leap year month of Purushottama.
	Also, Oh master of all the universes, what is the process for
	observing it properly? Kindly narrate all of this to me?
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Lord Sri Krishna, replied, Oh
	Yudhishthira, this meritorious day is called Parama
	Ekadasi. It bestows the great benediction of an
	enjoyable life and ultimate liberation, release from
	birth and death. The process for observing it is
	similar to that for observing the Ekadasi that
	occurs during the light part of this month of
	Kartika. That is to say, on this Ekadasi one should
	worship Me – Lord Narotram, the best of all living
	beings, with full love and devotion. In this
	connection I will now tell you a wonderful history, just as I heard it
	from the great sage in the city of Kampilya.
	<hr style={{border: 'none'}} className="my-4"/>
	Once a very pious Brahmin named Sumedha resided in Kampilya
	with his wife, Pavitra, who was extremely chaste and devoted to her
	husband. On account of having committed some sin in his previous
	life, Sumedha was without any money or food grains, and even
	though he begged many people for food, he could not obtain any
	substantial amount. He hardly had adequate food, clothing, or
	shelter for himself and his beautiful young wife, who was of such
	excellent character that she continued to serve Sumedha faithfully
	despite their poverty. When guests would come to their home,
	Pavitra mataji would give them her own food, and although she
	often went hungry, her beautiful, lotuslike face never faded. This
	fasting made her weak, but her affection for Sumedha remained
	unbreakable.
	<hr style={{border: 'none'}} className="my-4"/>
	Seeing all this, and lamenting his bad luck, Sumedha one day said to
	Pavitra, My dear wife, Oh most beautiful one, I beg alms from the
	rich but receive scarcely a scrap. What am I to do? What possible
	relief is there for our plight? Where should I go for relief? Oh most
	obedient and loving wife, without enough wealth, household affairs
	is never successful. Therefore please permit me to go abroad and
	attain some wealth. If I make such an effort, I shall certainly obtain
	whatever fortune I am destined to enjoy. Without making some
	endeavor a person cannot satisfy his desires or meet his needs. Thus
	wise men have said that enthusiastic endeavor is always auspicious.
	One who endeavors enthusiastically will certainly attain success, but
	one who merely says ‘I accept my lot in life’ is a lazy man.
	<hr style={{border: 'none'}} className="my-4"/>
	Upon hearing this from her husband as he spoke these words,
	Pavitra joined her palms and, her eyes brimming with tears, spoke to
	him with great respect and affection: 'I think there is no one greater
	or more learned than you, my dear. One who, though in misery, is
	interested in the welfare of others speaks just as you have.
	However, the scriptures state that whatever wealth a person attains
	in his life is due to his having given charity in previous lives, and
	that if one has not given charity in previous lives, then even though
	he may sit atop a mound of gold as big as Mount Sumeru, he will still
	remain poor.
	<hr style={{border: 'none'}} className="my-4"/>
	Transcendental knowledge, spiritual education, satisfying wealth,
	and pleasing family members are acquired by a person who has
	given profuse charity. Whatever good a person does returns to him
	many folds.
	<hr style={{border: 'none'}} className="my-4"/>
	Whatever is predestined by Viddhata, the fortune-maker, will surely
	happen. One's so called good education, skill, and enthusiasm will
	not necessarily guarantee to bring one success." Whatever charity a
	person gives in the form of education, money, fertile land and the
	like is returned to him in a future lifetime. We gain what we have
	given, when given with a good heart. Indeed, whatever the Lord of
	destiny, the creator, has written as one's fortune will certainly come
	to pass. No one attains any wealth without having given in charity in
	his previous life. Oh best of the Brahmins, since we are now poor, in
	our previous lives neither you nor I must have given any/sufficient
	charity to worthy persons. Therefore, Oh gracious husband, you
	should remain here with me. Without you I cannot live even a
	moment.
	<hr style={{border: 'none'}} className="my-4"/>
	Bereft of her husband, a woman is not welcomed by her father,
	mother, brother, father-in-law, or any other family members.
	Everyone will say, ‘You have lost your husband; you are bad
	luck/fortune personified!’ In this way I shall be criticized severely!
	<hr style={{border: 'none'}} className="my-4"/>
	A chaste woman always thinks that pleasing her spiritually situated
	husband is her heavenly pleasure, utmost duty, and greatest
	austerity. Whatever is destined, we shall obtain in due course of
	time and enjoy here in perfect happiness.'
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing these plaintive words from his wife, Sumedha decided to
	remain in their native village. One day the great sage Kaundinya
	arrived at their place, and upon seeing him the Brahmin Sumedha
	and his wife stood up before him and then offered him their
	respectful obeisance. With his head bowed, Sumedha welcomed him:
	'We are very fortunate to behold you here today, Oh wisest of sages.
	My life has become successful, and I am much obliged to you.
	<hr style={{border: 'none'}} className="my-4"/>
	Sumedha offered Kaundinya Muni a comfortable seat and highly
	praised his austerities and learning. "Just by having
	your darshan today," said Sumedha, "I have become very
	fortunate." The poor Brahmin couple fed the sage as sumptuously as
	they could afford to according to their means, and afterwards
	Pavitra asked the mendicant, ‘Oh most learned one, what process
	can we follow to be relieved of our poverty? How can a person who
	has not given anything in charity in his previous life get a good
	family, much wealth, and a fine education in this life? My husband
	wants to leave me here and go abroad to beg alms, but I have
	earnestly prayed to him to stay here with me. I humbly told him that
	if one lacks wealth in the present life, it is because of not having
	given sufficient charity in previous lifetimes. And so he has
	consented to remain here. It is only due to my great fortune that you
	have so mercifully come here today. Now we are certain to see the
	end of our poverty very soon.’
	<hr style={{border: 'none'}} className="my-4"/>
	Oh best of the Brahmans, please tell us how we can be free of this
	perpetual misery brought on by poverty. Oh merciful one, kindlydescribes some means - a place of pilgrimage we may visit, a day of
	fasting we may observe, or an austerity we may perform - by which
	our ill fortune will end forever.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this sincere supplication from the patient lady, the great
	sage Kaundinya reflected silently for a moment and then said, There
	is a fast day very dear to the Supreme Lord, Lord Hari. Fasting on
	this day nullifies all kinds of sins and removes all miseries caused by
	poverty. This fast day, which occurs during the dark part (Krishna
	paksha) of the extra, leap-year month, is known as Parama Ekadasi.
	It is the topmost day of Lord Vishnu, hence the name Parama. This
	extra month (Purushottam Maas), dark fortnight Ekadasi bestows all
	the necessities of life, such as money and food grains, and at last
	gives liberation/release. When the evening of this day comes, one
	should begin singing the glories of the Lord and dancing in ecstasy,
	and one should continue through the entire night.
	<hr style={{border: 'none'}} className="my-4"/>
	This holy fast was once observed faithfully by Lord Kuvera (the
	treasurer of the devas). Also, king Harishchandra fasted on this
	Ekadasi after his dear wife and son had been sold, and the king was
	able to get them back. Thereafter he ruled his kingdom with no
	further impediments. Therefore, Oh broad-eyed lady, you also should
	observe the sacred fast of Parama Ekadasi, following all the
	appropriate rules and regulations and remaining awake all night.'"
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna continued, Oh Yudhishthira, son of Pandu, in this
	way Kaundinya Muni mercifully and affectionately instructed Pavitra
	about the Parama Ekadasi fast. Then he said to Sumedha, ‘On the
	Dvadasii, the day after Ekadasi, you should vow to observe
	Pancharatriki fasting according to all the rules and regulations. After
	taking a bath early in the morning, you and your good wife, along
	with both your parents and hers, should fast for five days according
	to your ability. Then you will all become eligible to return home, to
	the abode of Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	A person who simply makes use of only one seat during these five
	days goes to the heavenly planets. Whoever feeds qualified
	Brahmins nicely on these five days has in effect fed all the demigods,
	all human beings, and even all the demons. Whoever donates a pot
	of drinking water to a twice born Brahmin during this five-day period
	will gain merit equal to that gained by donating the entire planet in
	charity. Anyone who gives a learned person a pot filled with sesame
	seeds resides in heaven for as many years as there are seeds in the
	pot. One who donates a pot filled with golden ghee will surely go tothe abode of the sun god after fully enjoying the pleasures of this
	earthly planet. Whoever remains celibate during these five-days will
	attain celestial happiness and enjoy with the maidens of Indraloka.
	Therefore both of you - Sumedha and Pavitra- should fast during
	these five days of Pancharatrika in order to be rewarded with ample
	grains and wealth for the rest of your lives on this planet. The
	spiritual world will be your abode thereafter.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this sublime advice, the Brahmin couple, Sumedha and
	Pavitra, properly observed the Parama Ekadasi and the fast of
	Pancharatrika, and very soon thereafter they beheld a handsome
	prince approaching them from the royal palace. On the orders of
	Lord Brahma, the prince gave them a beautiful, exquisitely furnished
	house and invited them to live in it. Praising their austerity and
	patience, he also gave them an entire village for their livelihood.
	Then he returned to the palace. Thus Sumedha and his wife enjoyed
	all kinds of facilities in this world and at last went to the abode of
	Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	Anyone who observes a fast on Parama Ekadasi and also the fast of
	Pancharatrika is freed from all his sins, and after enjoying life he
	returns to Vishnuloka, as did the Brahmin Sumedha and his faithful
	wife, Pavitra. It is impossible, Oh Yudhishthira, to calculate the
	extent of the merit one obtains by fasting on Parama Ekadasi, for
	such an observance is equal to bathing in places of pilgrimage such
	as Pushkara Lake and the Ganges river, giving cows in charity, and
	performing all sorts of other religious activities. One who fasts on
	this day has also completed the offerings of oblations to his
	forefathers in Gaya. He has, in effect, fasted on all other auspicious
	days.
	<hr style={{border: 'none'}} className="my-4"/>
	As in the social order the Brahmin are considered the best, as
	amongst four-legged creatures the cow is best, and as among
	demigods Lord Indradev is the best, so among all months the extra
	month of leap-year is the best. The Pancharatrika fast - the fast of
	five days (panch = five, ratri = nights) in the extra, leap year month
	- is said to remove all kinds of abominable sins. But the
	Pancharatriki fast, together with the fasts of Parama and Paramai
	Ekadasi, destroys all a person's sins. If a person is unable to fast on
	these days, he should observe the fasts during the extra month
	according to his ability. A person who, having received a human
	birth, does not take a proper bath during this extra month and then
	observes these Ekadasis, which are very dear to Lord Hari, commits
	suicide and suffers. The rare human birth is meant for accumulatingmerit and at last achieving liberating release from this material
	world. Therefore one should by all means observe fasting on this
	auspicious Parama Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna concluded, Oh sinless Yudhishthira, as you have
	requested, I have described to you the wonderful merit one can
	attain by fasting on the Ekadasi named Parama, which occurs during
	the dark part of the extra, leap-year month. You should observe this
	fast if at all possible.
	<hr style={{border: 'none'}} className="my-4"/>
	Whoever, after taking a proper bath, observes a fast on these two
	extra-month Ekadasis will go to heaven and finally attain to the
	abode of Sri Vishnu, and as he travels there he will be praised and
	prayed to by all the demigods.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Parama Ekadasi, the
	Ekadasi that occurs during the dark fortnight of the extra, leap-year
	month, from the Skanda Purana.
</p>

export default e25;
