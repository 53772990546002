import React from 'react';
import '../fonts.css'

const e3 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhisthira Maharaj said, Oh Vasudeva, I offer
	my most humble obeisances unto You. Please now
	describe to me the Ekadasi of the dark fortnight
	(krishna paksha) of the month of Vaisakha (April-
	May), including its specific merits and influence.
	<hr style={{border: 'none'}} className="my-3"/>
	Lord Sri Krishna replied, "Oh King, in this world and
	the next, the most auspicious and magnanimous
	Ekadasi is Varuthinii Ekadasi, which occurs during
	the dark fortnight of the month of Vaisakha.
	<hr style={{border: 'none'}} className="my-3"/>
	Whosoever observes a complete fast on this sacred day has his sins
	completely removed, obtains continuous happiness, and achieves all
	good fortune.
	<hr style={{border: 'none'}} className="my-3"/>
	Fasting on Varuthinii Ekadasi makes even an unfortunate woman
	fortunate. Upon anyone who observes it, this Ekadasi bestows
	material enjoyment in this life and liberation after the death of this
	present body. It destroys the sins of all and saves people from the
	miseries of repeated rebirth.
	<hr style={{border: 'none'}} className="my-3"/>
	By observing this Ekadasi properly, King Mandhata was liberated.
	<hr style={{border: 'none'}} className="my-3"/>
	Whatever merit one obtains by performing austerities and penances
	for ten thousand years is achieved by a person who observes
	Varuthinii Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	The merit one achieves by donating a great amount of gold during a
	solar eclipse at Kurukshetra is gained by one who observes this one
	Ekadasi with love and devotion, and certainly attains his goals in this
	life and the next. In short, this Ekadasi is pure and very enlivening
	and the destroyer of all sins.
	<hr style={{border: 'none'}} className="my-3"/>
	Better than giving horses in charity is giving elephants and better
	than giving elephants is giving land. But better still than giving land
	is the giving of sesame seeds, and better than that is giving of gold.
	Still better than giving gold is giving food grains - for all the
	forefathers, demigods (devas), and human beings become satisfied
	by eating grains. Thus there is no better gift of charity than this in
	the past, present or future.
	<hr style={{border: 'none'}} className="my-3"/>
	Yet learned scholars have declared that giving away a young maiden
	in marriage to a worthy person is equal to giving away food grains in
	charity. Moreover, Lord Sri Krishna, the Supreme Lord, has said that
	giving cows in charity is equal to giving food grains. Still better than
	all these charities is teaching spiritual knowledge to the ignorant.
	Yet all the merits one can attain by performing all these acts of
	charity are attained by one who fasts on the Varuthinii Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	One who lives off the wealth of his daughters suffers a hellish
	condition until the inundation of the entire universe, Oh Bharata.
	Therefore one should be especially careful not to use the wealth of
	his daughter. Oh best of kings, any householder who takes his
	daughter’s wealth out of greed, who tries to sell his daughter, or
	who takes money from the man to whom he has given his daughter
	in marriage – such a householder becomes a lowly cat in his next
	life. Therefore it is said that whoever, as a sacred act of charity,
	gives away in marriage a maiden decorated with various ornaments,
	and who also gives a dowry with her, obtains merit that cannot be
	described even by Chitragupta, the chief secretary of Yamaraja in
	the heavenly planets. That very same merit, however, can be easily
	achieved by one who fasts on the Varuthinii Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	The following things should be given up on the Dashami, (the tenth
	phase of the Moon), the day before the Ekadasi: eating on bell-metal
	plates, eating any kind of urad-dal, eating red-lentils, eating chick-
	peas, eating kondo, eating spinach, eating honey, eating in another
	person’s house/home, eating more than once, and participating in
	sex of any kind.
	<hr style={{border: 'none'}} className="my-3"/>
	On the Ekadasi itself one should give up the following: gambling,
	sports, sleeping during the daytime, betal nuts and its leaf, brushing
	one’s teeth, spreading rumors, faultfinding, talking to the spiritually
	fallen, anger, and lying.
	<hr style={{border: 'none'}} className="my-3"/>
	Lord Sri Krishna continued whoever observes the Varuthinii Ekadasi
	in this way becomes free from all sinful reactions and returns to the
	eternal, spiritual abode. One who worships Lord Janardana (Krishna)
	on this Ekadasi by staying awake throughout the entire night, also
	becomes free from all his previous sins and attains to the spiritual
	abode. Therefore, Oh king, he who is frightened of his accumulated
	sins and their attendant reactions, and thus of death itself, must
	observe Varuthinii Ekadasi by fasting very strictly.
	<hr style={{border: 'none'}} className="my-3"/>
	Finally, Oh noble Yudhisthira, he who hears or reads this
	glorification of the sacred Varuthinii Ekadasi obtains the merit
	earned by donating one thousand cows in charity, and at last he
	returns home, to the Supreme abode of Lord Vishnu in the
	Vaikunthas.
</p>

export default e3;
