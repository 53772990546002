import React from 'react';
import '../fonts.css'

const e18 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Mokshada Ekadasi is a very special Ekadasi in
	two regards; today was the all-auspicious day
	on which Lord Sri Krishna spoke the Srimad
	Bhagavad Gita to Arjuna on the battlefield of
	Kurukshetra, at the place now known as
	Jyotisha tirtha.
	<hr style={{border: 'none'}} className="my-4"/>
	Anyone who gifts a Bhagavad Gita away to a deserving person on
	this day is bestowed profuse blessings by the Sri Krishna Bhagawan.
	<hr style={{border: 'none'}} className="my-4"/>
	The Ancient History of Mokshada Ekadasi from Brahmanda Purana:
	<hr style={{border: 'none'}} className="my-4"/>
	Yudhishthira Maharaj said, O Vishnu, master of all, O delight of the
	three worlds, O Lord of the entire Universe, O creator of the world, O
	oldest personality, O best of all beings, I offer my most respectful
	obeisances unto You.
	<hr style={{border: 'none'}} className="my-4"/>
	"O Lord of lords, for the benefit of all living entities, kindly answer
	some questions that I have. What is the name of the Ekadasi that
	occurs during the light fortnight of the month of Margashirsha
	(November-December) and removes all sins? How does one observe
	it properly, and which Deity is worshipped on that holiest of days? O
	my Lord please explain this to me in full."
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, O dear Yudhishthira, your enquiry is very
	auspicious in itself and will bring you fame. Just as I previously
	explained to you about the dearest Utpannaa Maha-Dwadasi - which
	occurs during the dark part of the month of Margashirsha, which is
	the day when Ekadasi-devi appeared from My body to kill the demon
	Mura, and which benefits everything animate and inanimate in the
	three worlds - so I shall now relate to you regarding this Ekadasi
	that occurs during the light part of the month of Margashirsha. This
	Ekadasi is famous as Mokshadaa because it purifies the faithful
	devotee of all sinful reactions and bestows liberation upon him. The
	worshippable Deity of this all auspicious day is Lord Damodara. With
	full attention one should worship Him with incense, a ghee lamp,
	fragrant flowers, and Tulasi manjaris (buds).
	<hr style={{border: 'none'}} className="my-4"/>
	O best of saintly kings, please listen as I narrate to you the ancient
	and auspicious history of this wonderful Ekadasi. Simply by hearing
	this history one can attain the merit earned by performing a horse
	sacrifice. By the influence of this merit, one's forefathers, mothers,
	sons, and other relatives who have gone to hell can turn around and
	go to the heavenly kingdom. For this reason alone, O king, you
	should listen with rapt attention to this narration. There once was a
	beautiful city named Champaka-nagar, which was decorated with
	devoted Vaishnavas. There the best of saintly kings Maharaj
	Vaikhaanasa, ruled over his subjects as if they were his very own
	dear sons and daughters. The Brahmins in that capital city were all
	experts in four kinds of Vedic knowledge. The king, while ruling
	properly, had a dream one night in which his father was seen to be
	suffering the pangs of hellish torture in one of the hellish planets
	ruled over by the Yamaraj. The king was overwhelmed withcompassion for his father and shed tears. The next morning, Maharaj
	Vaikhaanasa described what he had seen in his dream to his council
	of twice born learned Brahmins.
	<hr style={{border: 'none'}} className="my-4"/>
	O Brahmanas, the king addressed them, in a dream last night I saw
	my father suffering on a hellish planet. He was crying out in anguish,
	O son, please deliver me from this torment of this hellish condition!
	<hr style={{border: 'none'}} className="my-4"/>
	Now I have no peace in my mind, and even this beautiful kingdom
	has become unbearable to me. Not even my horses, elephants, and
	chariots and my vast wealth in my treasury that formerly brought so
	much pleasure, gives me no pleasure at all.
	<hr style={{border: 'none'}} className="my-4"/>
	Everything, O best of the Brahmins, even my own wife and sons,
	have become a source of unhappiness since I beheld my father
	suffering the tortures of that hellish condition so. Where can I go,
	and what can I do, O Brahmins, to alleviate this misery? My body is
	burning with fear and sorrow! Please tell me what kind of charity,
	what mode of fasting, what austerity, or what deep meditation, and
	in service upon which Deity I may have to perform to deliver my
	father from that agony and bestow upon liberation upon my
	forefathers. O best among the Brahmins, what is the use of one's
	being a powerful son if one's father must suffer on a hellish planet?
	Truly, such a son's life is utterly useless, to him and to his
	forefathers.
	<hr style={{border: 'none'}} className="my-4"/>
	The twice born Brahmins replied, O king, in the mountainous forest
	not far from here is the ashram where a great saint Parvata Muni
	resides. Please go to him, for he is tri-kala-jnan (he knows the past,
	the present, and the future of everything) and can surely help you in
	your gaining relief from your misery.
	<hr style={{border: 'none'}} className="my-4"/>
	Upon hearing this advice, the distressed king immediately set out on
	a journey to the ashram of the famous sage Parvata Muni. The
	ashram was indeed very big and housed many learned sages expert
	in chanting the sacred hymns of the four Vedas (Rig, Yajur, Sama,
	and Arthava). Approaching the holy ashram, the king beheld Parvata
	Muni seated among the assembly of sages adorned with hundreds oftilaks (from all the authorized sampradayas) like another Brahma or
	Vyaas.
	<hr style={{border: 'none'}} className="my-4"/>
	Maharaj Vaikhaanasa offered his humble obeisance to the muni,
	bowing his head and then prostrating his entire body before him.
	After the king had seated himself among the assembly Parvata Muni
	asked him about the welfare of the seven limbs of his extensive
	kingdom (his ministers, his treasury, his military forces, his allies,
	the Brahmins, the sacrificial offerings performed, and the needs of
	his subjects). The muni also asked him if his kingdom was free of
	troubles and whether everyone was peaceful, happy and satisfied.
	To these inquiries the king replied, 'By your mercy O glorious and
	great sage, all seven limbs of my kingdom are doing very well. Yet
	there is a problem that has recently arisen, and to solve it I have
	come to you, O Brahman for your expert help and guidance.
	<hr style={{border: 'none'}} className="my-4"/>
	Then Parvata Muni, the best of all sages, closed his eyes and
	meditated on the king's past, present and future. After a few
	moments he opened his eyes and said, 'Your father is suffering the
	results of committing a great sin, and I have discovered what it is.
	In his previous life he quarreled with his wife and forcibly enjoyed
	her sexually during her menstrual period. She tried to protest and
	resist his advances and even yelled out, Some one please save me!
	Please, O husband, do not interrupt my monthly period in this way!
	Still he did not stop or leave her alone. It is on account of this
	grievous sin that your father now has fallen into such a hellish
	condition of suffering.
	<hr style={{border: 'none'}} className="my-4"/>
	King Vaikhaanasa then said, O greatest among sages, by what
	process of fasting or charity may I liberate my dear father from such
	a condition? Please tell me how I can relieve and remove the burden
	of his sinful reactions, which are a great obstacle to his progress
	toward ultimate release (salvation - liberation - going back home).
	<hr style={{border: 'none'}} className="my-4"/>
	Parvata Muni replied, During the light fortnight of the month of
	Margashirsha there occurs an Ekadasi called Mokshadaa. If you
	observe this sacred Ekadasi strictly, with a full fast, and give directly
	to your suffering father the merit you thus attain/obtain, he will be
	freed from his pain and instantly liberated.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this, Maharaj Vaikhaanasa profusely thanked the great sage
	and then returned to his palace to perform his vrata (austere rite). O
	Yudhishthira, when the light part of the month of Margashirsha at
	last arrived, Maharaj Vaikhaanasa faithfully waited for the Ekadasi
	tithi to arrive. He then perfectly and with full faith observed the
	Ekadasi fast with his wife, children, and other relatives. He dutifully
	gave the merit from this fast to his father, and as he made the
	offering, beautiful flower petals showered down from the devas who
	peered out from behind the clouds in the sky. The king's father was
	then praised by the messengers of the demigods (devas) and
	escorted to the celestial region. As he passed his son, as he
	traversed the lower to middle to higher planets, the father said to
	the king, 'My dear son, all auspiciousness unto you!
	<hr style={{border: 'none'}} className="my-4"/>
	At last he reached the heavenly realm from where he can again with
	his newly acquired merit perform devotional service to Krishna or
	Vishnu and in due course return back to home back to Godhead.
	<hr style={{border: 'none'}} className="my-4"/>
	O son of Pandu, who so ever strictly observes the sacred Mokshadaa
	Ekadasi, following the established rules and regulations, achieves
	full and perfect liberation after death. There is no better fasting day
	than this Ekadasi of the light fortnight of the month of Margashirsha,
	O Yudhishthira, for it is a crystal-clear and sinless day. Whoever
	faithfully observes this Ekadasi fast, which is like chintaa-mani (a
	gem that yields all desires), obtains special merit that is very hard to
	calculate, for this day can elevate one from hellish life to the
	heavenly planets, and for one who observes Ekadasi for his own
	spiritual benefit, this elevates one to go back to Godhead, never to
	return to this material world.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus end the narration of the glories of Margashirsha-shukla Ekadasi
	or Mokshada Ekadasi, from the Brahmanda Purana.
</p>

export default e18;
