import React from 'react';
import '../fonts.css'

const e17 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Suta Goswami said, O learned Brahmanas, long ago Lord Sri Krishna,
	the Supreme Lord, explained the auspicious glories of Sri Ekadasi
	and the rules and regulations governing each observance of fasting
	on that holy day. O best of the Brahmins, whosoever hears about the
	origins and glories of these sacred fasts on the Ekadasi days goes
	directly to the abode of Lord Vishnu after enjoying many different
	kinds of happiness in this material world.
	<hr style={{border: 'none'}} className="my-4"/>
	Yudhishtra, asked the Lord, O Janardana, what are the pious
	benefits of complete fasting, eating only supper, or eating but once
	at midday on Ekadasi, and what are the regulations for observing the
	various Ekadasi days? Kindly narrate all this to me.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord Krishna replied, O son of Kunti, in the beginning
	of winter (northern hemisphere), on the Ekadasi that occurs during
	the dark fortnight of the month of Margasirsha (November-
	December), a novice should begin his practice of observing a fast on
	Ekadasi. On Dasami, the day before Ekadasi, he should clean his
	teeth nicely. Then during the eight portion of Dasami, just as the Sun
	is about to set, he should eat supper.
	<hr style={{border: 'none'}} className="my-4"/>
	The next morning the devotee should make a vow, according to the
	rules and regulations, to observe fasting. At midday he should bathe
	properly in a river, lake or small pond. A bath in a river is most
	purifying, bath taken in a lake is less so, and a bath in a small pond
	is the least purifying. If neither a river, lake nor pond is accessible,
	he may bathe with well water.
	<hr style={{border: 'none'}} className="my-4"/>
	The devotee should chant this prayer containing the names of
	Mother Earth: O Asvakrante! O Rathakrante! O Vishnukrante! O
	Vasundhare! O Mrttike! O Mother Earth! Kindly remove all the sins I
	have accumulated throughout my many past lives so that I may
	enter the sacred abode of the Supreme Lord." As the devotee chants,
	he should smear mud over his body.
	<hr style={{border: 'none'}} className="my-4"/>
	During the day of fasting the devotee should not speak to those who
	are fallen from their religious duties, to dog-eaters, to thieves, or to
	hypocrites. He should also avoid speaking with slanderers; with
	those who abuse the demigods, the Vedic literatures, or Brahmanas;
	or with any other wicked personalities, such as those who have sex
	with forbidden women, those who are known plunderers, or those
	who rob temples. If any such person is spoken to or even seen
	during Ekadasi, one must purify oneself by looking directly at the
	sun.
	<hr style={{border: 'none'}} className="my-4"/>
	Then the devotee should respectfully worship Lord Govinda with
	first-class food, flowers, and so forth. In his home he should offer
	the Lord a lamp in pure devotional consciousness. he should also
	avoid sleeping during the daytime and should completely abstain
	from sex. Fasting from all food and water, he should joyfully sing the
	Lord's glories and play musical instruments for His pleasure
	throughout the night. After remaining awake all night in pure
	consciousness, the worshipper should give charity to qualified
	Brahmanas and offer his humble obeisance unto them, begging their
	forgiveness for his offences.
	<hr style={{border: 'none'}} className="my-4"/>
	Those who are serious about devotional service should consider the
	Ekadasis that occur during dark fortnights to be as good as those
	that occur during bright fortnights. O king, one should never
	discriminate between these two kinds of Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Please listen as I now describe the results obtained by one who
	observes Ekadasi in this way. Neither the merit one receives by
	taking a bath in the sacred place of pilgrimage known as
	Sankhoddhara, where the Lord killed the Sankhasura demon, nor the
	merit one receives upon seeing Lord Gadadhara directly is equal to
	one sixteenth of the merit one obtains by fasting on Ekadasi. It is
	said that by giving charity on a Monday when the moon is full, one
	obtains a hundred thousand times the results of ordinary charity. O
	winner of wealth, one who gives charity on the day of the sankranti
	(equinox) attains four hundred thousand times the ordinary result.
	Yet simply by fasting on Ekadasi one obtains all these pious results,
	as well as whatever pious results one gets at Kurukshetra during an
	eclipse of the sun or moon. Furthermore, the faithful soul who
	observes complete fasting on Ekadasi achieves a hundred times
	more merit than one who performs an Asvamedha-yajna (horse
	sacrifice). One who observes Ekadasi just once earns ten times more
	merit than a person who gives a thousand cows in charity to a
	Brahman learned in the Vedas.
	<hr style={{border: 'none'}} className="my-4"/>
	A person who feeds just one Brahmacari earns ten times more merit
	than one who feeds ten good Brahmanas in his own house. But a
	thousand times more merit than is earned by feeding a Brahmachari
	is achieved by donating land to the needy and respectable Brahman,
	and a thousand time more than that is earned by giving away a
	virgin girl in marriage to a young, well-educated, responsible man.
	Ten times more beneficial than this is educating children properly on
	the spiritual path, without expecting any reward in return. Ten times
	better than this, however, is giving food grains to the hungry.
	Indeed, giving charity to those in need is the best of all, and there
	never has been or ever will be a better charity than this. O son of
	Kunti, all the forefathers and demigods in heaven become very
	satisfied when one gives food grains in charity. But the merit one
	obtains by observing a complete fast on Ekadasi cannot be
	measured. O Yudishtra, best of all Kurus, the powerful effect of this
	merit is inconceivable even to the demigods, and half this merit is
	attained by one who eats only supper on Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	One should therefore observe fasting on Lord Hari's day either by
	eating only once at midday, abstaining from grains and beans; or by
	fasting completely. The processes of staying in places of pilgrimage,
	giving charity, and performing fire sacrifices may boast only as long
	as Ekadasi has not arrived. Therefore anyone afraid of the miseries
	of material existence should observe Ekadasi. On Ekadasi one should
	not drink water from a conch-shell, kill living entities such as fish or
	pigs, or eat any grains or beans. Thus I have described to you, O
	Yudishtra , the best of all methods of fasting, as you have inquired
	from Me.
	<hr style={{border: 'none'}} className="my-4"/>
	Yudishtra then asked, O Lord, according to You, a thousand Vedic
	sacrifices do not equal even one Ekadasi fast. How can this be? How
	has Ekadasi become the most meritorious of all days?
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, 'I will tell you why Ekadasi is the most
	purifying of all days. In the Satya-Yuga there once lived an
	amazingly fearsome demon called Mura. Always very angry, he
	terrified all the demigods, defeating even Indra, the king of heaven;
	Vivasvan, the sun-god; the eight Vasus; Lord Brahma; Vayu. The
	wind-god; and Agni, the fire-god. With his terrible power he brought
	them all under his control.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Indra then approached Lord Shiva and said, "We have all fallen
	from our planets and are now wandering helplessly on the earth. O
	lord, how can we find relief from this affliction? What will be the fate
	of us demigods?
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Shiva replied, O best of the demigods, go to that place where
	Lord Vishnu, the rider of Garuda, resides. He is Jagannatha, the
	master of all the universes and their shelter as well. He is devoted to
	protecting all souls surrendered to Him.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Krishna continued, O Yudishtra, winner of wealth, after Lord
	Indra heard these words of Lord Shiva's, he proceeded with all the
	demigods to the place where Lord Jagannatha, the Lord of the
	universe, the protector of all souls, was resting. Seeing the Lord
	sleeping upon the water, the demigods joined their palms and, led
	by Indra, recite the following prayers:
	<hr style={{border: 'none'}} className="my-4"/>
	O Supreme Lord, all obeisances to You. O Lord of lords, O You who
	are praised by the foremost demigods, O enemy of all demons, O
	lotus-eyed Lord, O Madhusudana (killer of the Madhu demon), please
	protect us. Afraid of the demon Mura, we demigods have come to
	take shelter of You. O Jagannatha, You are the doer of everything
	and the creator of everything. You are the mother and the father of
	all universes. You are the creator, the maintainer, and the destroyer
	of all. You are the supreme helper of all the demigods, and You alone
	can bring peace to them. You alone are the earth, the sky, and the
	universal benefactor.
	<hr style={{border: 'none'}} className="my-4"/>
	You are Shiva, Brahma, and also Vishnu, the maintainer of the three
	worlds. You are the gods of the sun, moon, and fire. You are the
	clarified butter, the oblation, the sacred fire, the mantras, the
	rituals, the priests, and the silent chanting of japa. You are the
	sacrifice itself, its sponsor, and the enjoyer of its results, the
	Supreme Lord. Nothing within these three worlds, whether moveable
	or immovable, can exist independent of You. O Supreme Lord, Lord
	of lords, You are the protector of those who take shelter of You. O
	supreme mystic, O shelter of the fearful please rescues and protect
	us. We demigods have been defeated by the demons and have thus
	fallen from the heavenly realm. Deprived of our positions, O Lord of
	the universe, we are now wandering about this earthly planet.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Krishna continued, Having heard Indra and the other demigods
	speak these words, Sri Vishnu, the Supreme Lord, replied, "What
	demon possesses such great powers of delusion that he has been
	able to defeat all the demigods? What is his name, and where does
	he live? Where does he get his strength and shelter? Tell Me
	everything, O Indra, and do not fear.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Indra replied, O Supreme Godhead, O Lord of lords, O You who
	vanquish the fear in Your pure devotees' hearts, O You who are so
	kind to your faithful servants, there was once a powerful demon of
	the Brahma dynasty whose name was Nadijangha. He was
	extraordinarily fearsome and wholly dedicated to destroying the
	demigods, and he begot an infamous son named Mura.
	<hr style={{border: 'none'}} className="my-4"/>
	Mura's great capital city is Chandravati. From that base the terribly
	evil and powerful Mura demon has conquered the whole world and
	brought all the demigods under his control, driving them out of their
	heavenly kingdom. He has assumed the roles of Indra, the king of
	heaven; Agni, the fire-god; Yama, the lord of death; Vayu, the wind-
	god; Isha, or Lord Shiva; Soma, the moon-god; Nairrti, the lord of
	the directions; and Pasi, or Varuna, the water-god. He has also
	begun emanating light in the role of the sun god and has turned
	himself into the clouds as well. It is impossible for the demigods to
	defeat him. O Lord Vishnu, please kill this demon and make the
	demigods victorious.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing these words from Indra, Lord Janardana became very angry
	and said,
	<hr style={{border: 'none'}} className="my-4"/>
	O powerful demigods, all together you may now advance on Mura's
	capital city of Chandravati. Encouraged thus, the assembled
	demigods proceeded to Chandravati with Lord Hari leading the way.
	<hr style={{border: 'none'}} className="my-4"/>
	When Mura saw the demigods, that foremost of demons started
	roaring very loudly in the company of countless thousands of other
	demons, who were all holding brilliantly shining weapons. The
	mighty-armed demons struck the demigods, who began abandoning
	the battlefield and fleeing in the ten directions. Seeing the Supreme
	Lord Hrsikesha, the master of the senses, present on the battlefield,
	the furious demons rushed toward Him with various weapons in
	their hands. As they charged the Lord, who holds a sword, disk, and
	club, He immediately pierced all their limbs with His sharp,
	poisonous arrows. Thus many hundred of demons died by the Lord's
	hand.
	<hr style={{border: 'none'}} className="my-4"/>
	At last the chief demon, Mura, began fighting with the Lord. Mura
	used his mystic power to render useless whatever weapons the
	Supreme Lord Hrsikesa unleashed. Indeed, to the demon the
	weapons felt just like flowers striking him. When the Lord could not
	defeat the demon even with various kinds of weapons - whether
	those that are thrown or those that are held - He began fighting with
	His bare hands, which were as strong as iron-studded clubs. The
	Lord wrestled with Mura for one thousand celestial years and then,
	apparently fatigued, left for Badarikashrama. There Lord
	Yogeshvara, the greatest of all yogis, the Lord of the universe,
	entered a very beautiful cave named Himavati to rest. O
	DhananJaya, winner of wealth, that cave was ninety-six miles in
	diameter and had only on entrance. I went there out of fear, and
	also to sleep. There is no doubt about this, O son of Pandu, for the
	great fight made me very tired. The demon followed Me into that
	cave and, seeing Me asleep, started thinking within his heart, Today
	I will kill this slayer of all demons, Hari.
	<hr style={{border: 'none'}} className="my-4"/>
	While the wicked-minded Mura was making plans in this way, from
	My body there manifested a young girl who had a very bright
	complexion. O son of Pandu, Mura saw that she was equipped with
	various brilliant weapons and was ready to fight. Challenged by that
	female to do battle, Mura prepared himself and then fought with her,
	but he became very astonished when he saw that she fought him
	without cessation. The king of the demons then said, "Who has
	created this angry, fearsome girl who is fighting me so powerfully,
	just like a thunderbolt falling upon me?' After saying this, the demon
	continued to fight with the girl.
	<hr style={{border: 'none'}} className="my-4"/>
	Suddenly that effulgent goddess shattered all of Mura's weapons and
	in a moment deprived him of his chariot. He ran toward her to
	attacker with his bare hands, but when she saw him coming she
	angrily cut off his head. Thus the demon at once fell to the ground
	and went to the abode of Yamaraja. The rest of the Lord's enemies,
	out of fear and helplessness, entered the subterranean Patala
	region.
	<hr style={{border: 'none'}} className="my-4"/>
	Then the Supreme Lord woke up and saw the dead demo before Him,
	as well as the maiden bowing down to him with joined palms. His
	face expressing His astonishment, the Lord of the universe said,
	"Who has killed this vicious demon? He easily defeated all the
	demigods, Gandharvas, and even Indra himself, along with Indra's
	companions, the Maruts, and he also defeated the Nagas (snakes),
	the rulers of the lower planets. He even defeated Me, making Me
	hide in this cave out of fear. Who is it that has so mercifully
	protected Me after I ran from the battlefield and went to sleep in this
	cave?
	<hr style={{border: 'none'}} className="my-4"/>
	The maiden said, "It is I who have killed this demon after appearing
	from You transcendental body. Indeed, O Lord Hari, when he saw
	You sleeping he wanted to kill You. Understanding the intention of
	this thorn in the side of the three worlds, I killed the evil rascal and
	this freed all the demigods from fear. I am Your great maha-sakti,
	Your internal potency, who strikes fear into the hearts of all Your
	enemies. I have killed this universally terrifying demon to protect
	the three worlds. Please tell me why You are surprised to see that
	this demon has been killed, O Lord."
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord said, "O sinless one, I am very satisfied to see
	that it is you who have killed this king of the demons. In this way
	you have made the demigods happy, prosperous, and full of bliss.
	Because you have given pleasure to all the demigods in the three
	worlds, I am very pleased with you. Ask any boon you may desire, O
	auspicious one. I will give it to you without a doubt, though it be
	very rare among the demigods.
	<hr style={{border: 'none'}} className="my-4"/>
	"The maiden said, "O Lord, if You are pleased with me and wish to
	give me a boon, then give me the power to deliver from the greatest
	sins that person who fasts of this day. I wish that half the pious
	credit obtained by one who fasts will accrue to one who eats only in
	the evening (abstaining from grains and beans), and that half of this
	pious credit will be earned by one who eats only at midday. Also,
	may one who strictly observes a complete fast on my appearance
	day, with controlled senses, go to the abode of Lord Vishnu for one
	billion kalpas after he has enjoyed all kinds of pleasures in this
	world. This is the boon I desire to attain by Your mercy, my Lord, O
	Lord Janardana, whether a person observes complete fasting, eats
	only in the evening, or eats only at midday, please grant him a
	religious attitude, wealth, and at last liberation."
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord said, "O most auspicious lady, what you have
	requested is granted". All My devotees in this world will surely fast
	on your day, and thus they will become famous throughout the three
	worlds and finally come and stay with me in My abode. Because you,
	My transcendental potency, have appeared on the eleventh day of
	the waning moon, let your name by Ekadasi. If a person fasts on
	Ekadasi, I will burn up all his sins and bestow upon him My
	transcendental abode.
	<hr style={{border: 'none'}} className="my-4"/>
	These are the days of the waxing and waning moon that are most
	dear to Me: Tritiya (the third day), Asthami (the eighth day), Navami
	(the ninth day), Chaturdasi (the fourteenth day), and especially
	Ekadasi (the eleventh day).
	<hr style={{border: 'none'}} className="my-4"/>
	The merit one attains by fasting on Ekadasi is greater than that
	achieved by observing any other kind of fast or by going to a place of
	pilgrimage, and even greater than that achieved by giving charity to
	Brahmanas. I tell you most emphatically that this is true.
	<hr style={{border: 'none'}} className="my-4"/>
	Having thus given the maiden His benediction, the Supreme Lord
	suddenly disappeared. From that time onward the Ekadasi day
	became most meritorious and famous all over the universe. O
	Yudishtra, if a person strictly observes Ekadasi, I kill all his enemies
	and grant him the highest destination. Indeed, if a person observes
	this great Ekadasi fast in any of the prescribed way, I remove all
	obstacles to his spiritual progress and grant him the perfection of
	life.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus, O son of Partha, I have described to you the origin of Ekadasi.
	This one-day removes all sins eternally. Indeed, it is the most
	meritorious day for destroying all kinds of sins, and it has appeared
	in order to benefit everyone in the universe by bestowing all
	varieties of perfection.
	<hr style={{border: 'none'}} className="my-4"/>
	One should not discriminate between the Ekadasis of the waxing and
	waning moons; both must be observed, O Partha, and they should
	not be differentiated from Maha-Dvadasi. Everyone who fasts of
	Ekadasi should recognize that there is no difference between these
	two Ekadasis, for they comprise the same tithi.
	<hr style={{border: 'none'}} className="my-4"/>
	Whoever completely fasts on Ekadasi, following the rules and
	regulations, will achieve the supreme abode of Lord Vishnu, who
	rides upon Garuda. They are glorious who devote themselves to Lord
	Vishnu and spend all their rime studying the glories of Ekadasi. One
	who vows not to eat anything on Ekadasi but to eat only on the next
	day achieves the same merit as one who executes a horse sacrifice.
	Of this there is no doubt.
	<hr style={{border: 'none'}} className="my-4"/>
	On Dvadasi, the day after Ekadasi, one should pray, O
	Pundarikaksha, O lotus-eyed Lord, now I will eat. Please shelter me.
	After saying this, the wise devotee should offer some flowers and
	water at the Lord's lotus feet and invite the Lord to eat by chanting
	the eight-syllable mantra thrice. If the devotee wants to gain the
	fruit of his fast, he should then drink water taken form the sanctified
	vessel in which he offered water at the Lord's lotus feet.
	<hr style={{border: 'none'}} className="my-4"/>
	On Dvadasi one must avoid sleeping during the day, eating in
	another's home, eating more than once, having sex, eating honey,
	eating from a bell-metal plate, eating urad-dal, and rubbing oil on
	one's body. The devotee must give up these eight things on Dvadasi.
	If he wants to speak to an outcaste on that day, he must purify
	himself by eating a Tulasi leaf or an amalaki fruit. O best of kings,
	from noon on Ekadasi until dawn on Dvadasi, one should engage
	himself in taking baths, worshipping the Lord, and executing
	devotional activities, including the giving of charity and the
	performance of fire sacrifices. If one finds himself in difficult
	circumstances and cannot break the Ekadasi fast properly on
	Dvadasi, one can break it by drinking water, and then one is not at
	fault if he eats again after that.
	<hr style={{border: 'none'}} className="my-4"/>
	A devotee of Lord Vishnu who day and night hears these all-
	auspicious topics concerning the Lord from the mouth of another
	devotee will be elevated to the Lord's planet and reside there for ten
	million kalpas. And one who hears even one sentence about the
	glories of Ekadasi is freed from the reactions to such sins as killing a
	Brahman. There is no doubt of this. For all eternity there will be no
	better way of worshiping Lord Vishnu than observing a fast on
	Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Margasirsa-krishna Ekadasi,
	or Utpanna Ekadasi, from the Bhavisya-uttara Purana.
</p>

export default e17;
