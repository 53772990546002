import React, { createContext, useReducer, useEffect } from "react"
import axios from 'axios'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';
import Reducer from './Reducer'

const initialState = {
    home_text: [],
    home_images: [],
    testimonials: [],
    pmt_arch: [],
	pmt_artc: [],
	gallery: [],
    error: null
};

export const DataContext = createContext(initialState);

const DataContextProvider = (props) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [openloading, setopenloading] = React.useState(true);

    useEffect(() => {
		let textData;
    	setopenloading(true);
        axios.get('https://satprerna.org/satprerna_backend/home/get_all_text.php')
		.then(response => {
			textData = response.data;
			dispatch({type: 'SET_HOME_TEXT', payload: textData});
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		})
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
    }, []);
    
    useEffect(() => {
		let textData;
    	setopenloading(true);
        axios.get('https://satprerna.org/satprerna_backend/home/get_all_images.php')
		.then(response => {
			textData = response.data;
			dispatch({type: 'SET_HOME_IMAGES', payload: textData});
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		})
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
	}, []);

    useEffect(() => {
		let textData;
		setopenloading(true);
        axios.get('https://satprerna.org/satprerna_backend/home/get_testimonials.php')
		.then(response => {
			textData = response.data;
          	dispatch({type: 'SET_TESTIMONIALS', payload: textData});
			  setTimeout(function(){
				setopenloading(false)
			}, 2000)
        })
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
    }, []);

    useEffect(() => {
		let textData;
		setopenloading(true);
		axios.get('https://satprerna.org/satprerna_backend/home/get_pmt_old_arch.php')
		.then(response => {
			textData = response.data;
			dispatch({type: 'SET_PMT_ARCHIEVES', payload: textData});
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		})
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
      }, []);

    useEffect(() => {
      	let textData;
        setopenloading(true);
		axios.get('https://satprerna.org/satprerna_backend/home/get_pmt_articles.php')
		.then(response => {
			textData = response.data;
			dispatch({type: 'SET_PMT_ARTICLES', payload: textData});
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		})
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
	}, []);
	  
	useEffect(() => {
		let textData;
    	setopenloading(true);
		axios.get('https://satprerna.org/satprerna_backend/home/get_gallery.php')
		.then(response => {
			textData = response.data;
			dispatch({type: 'SET_GALLERY', payload: textData});
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		})
		.catch(error => {
			dispatch({type: 'SET_ERROR', payload: error});
		});
	}, []);

	const heading = (title) => {
		let heading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
			heading = item.heading
			}
		})
		return heading
	}
    
	const subheading = (title) => {
		let subheading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
			subheading = item.subheading
			}
		})
		return subheading
	}
    
	const paragraph = (title) => {
		let subheading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
			subheading = item.paragraph
			}
		})
		return subheading
	}
    
	const image = (name) => {
		let image = undefined
		// eslint-disable-next-line array-callback-return
		state.home_images.map(item => {
			if(item.name === name) {
			image = item.image
			}
		})
		return image
	}

    if(openloading) {
      	return <Backdrop style={{backgroundColor: 'white'}} open={openloading} onClick={()=>{setopenloading(false)}}>
			<CircularProgress style={{color: '#9a0808'}} />
		</Backdrop>
    }
    else return (
		<Grow>
			<DataContext.Provider value={{ state, dispatch, heading, subheading, paragraph, image }}>
				{props.children}
			</DataContext.Provider>
		</Grow>
    )
};

export default DataContextProvider;
