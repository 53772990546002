const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_HOME_TEXT':
            return {
                ...state,
                home_text: action.payload
            };
        case 'SET_HOME_IMAGES':
            return {
                ...state,
                home_images: action.payload
            };

        case 'SET_TESTIMONIALS':
            return {
                ...state,
                testimonials: action.payload
            };
        
        case 'SET_PMT_ARCHIEVES':
            return {
                ...state,
                pmt_arch: action.payload
            };

        case 'SET_PMT_ARTICLES':
            return {
                ...state,
                pmt_artc: action.payload
            }

        case 'SET_GALLERY':
            return {
                ...state,
                gallery: action.payload
            }
        // case 'UPDATE_HOME_TEXT_BY_NAME':

        //     return {
        //         ...state,
        //         home_text: undefined
        //     };
        // case 'ADD_HOME_TEXT':
        //     return {
        //         ...state,
        //         home_text: state.home_text.concat(action.payload)
        //     };
        // case 'REMOVE_HOME_TEXT':
        //     return {
        //         ...state,
        //         home_text: state.home_text.filter(post => post.id !== action.payload)
        //     };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;