import React from 'react';
import '../fonts.css'

const e13 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhishthira Maharaj said, Oh Lord, what is the
	name of the Ekadasi that occurs during the dark
	fortnight (Krishna paksha) of the month of
	Ashwin (September-October)? Please describe
	its glories to me.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Lord Sri Krishna then
	replied, "This Holy day is called Indira Ekadasi.
	If a person fasts on this day, all his sins are
	eradicated and his forefathers who have fallen
	into hell are liberated. Oh best of kings, one
	who simply hears about this sacred Ekadasi achieves the great merit
	earned by performing a horse sacrifice."
	<hr style={{border: 'none'}} className="my-4"/>
	In the Satya-yuga there lived a king named Indrasena, who was so
	powerful that he destroyed all his enemies. His kingdom was called
	Mahishmati. In this way King Indrasena systematically dedicated
	himself to pure spiritual life and spent much time meditating on the
	Absolute Truth.
	<hr style={{border: 'none'}} className="my-4"/>
	One day, as King Indrasena happily and peacefully presided over his
	assembly, the perfect orator, Sri Narada Muni, was seen descending.
	The king inviting him into the palace, offering him a comfortable
	seat, washing his feet, and spoke sweet words of welcome. Then
	Narada Muni said to Maharaj Indrasena, ‘Oh king, your kingdom is
	prospering?’
	<hr style={{border: 'none'}} className="my-4"/>
	The king replied, By your kind grace, Oh greatest of sages,
	everything is quite well.
	<hr style={{border: 'none'}} className="my-4"/>
	Sri Narada, the sage among the devas, then said, Oh king, I am
	astonished. When I descended from Brahmaloka to Yamaloka, I
	noticed your father in Yamaraja’s assembly. Though he had been
	very religious, because he broke an Ekadasi fast prematurely, he had
	to go to the Yamaloka. Your father gave me a message for you. He
	said, In Mahishmati lives a king named Indrasena. Please tell him
	about my situation here - that because of my past sinful deeds I
	have somehow been forced to reside in Yamaraja’s kingdom. Please
	give him this message from me: ‘Oh son, kindly observe the coming
	Indira Ekadasi and give much in charity so I can go upward to
	heaven.’
	<hr style={{border: 'none'}} className="my-4"/>
	When the king heard the words of Naradji he was greatly grieved
	and he said ‘Oh Maharishi. Please tell me specifically how to observe
	a fast on Indira Ekadasi, and also tell me during what month and on
	what day it occurs.
	<hr style={{border: 'none'}} className="my-4"/>
	Narada Muni replied, Oh king, please listen as I describe to you the
	full process of observing the Indira Ekadasi. This Ekadasi occurs
	during the dark fortnight of the month of Ashwin. On the Dashami
	tithi, the day before Ekadasi, rise early in the morning, take bath,
	and then do some service for God with full faith. At noon, bathe
	again in running water and then offer oblations to your forefathers
	with faith and devotion. Be sure not to eat more than once on this
	day and at night sleep on the floor.
	<hr style={{border: 'none'}} className="my-4"/>
	When you awaken on Ekadasi morning, cleanse your mouth and
	teeth thoroughly and then with deep devotion for the Lord take this
	sacred vow: Today I shall fast completely and give up all kinds of
	sense enjoyment. Oh lotus-eyed Supreme Lord, Oh infallible one,
	please give me shelter at Your lotus feet. At noon, stand before the
	sacred form of the Sri Shaligram Shila and worship Him faithfully,
	following all the rules and regulations; then offer oblations of ghee
	into the sacred fire, and tarpana directed to help your forefathers.
	Next, feed qualified Brahmins and offer them some charity according
	to your means. Now take the food pindas you offered to your
	forefathers, smell it, and then offer it to a cow. Next, worship Lord
	Hrishikesha with incense and flowers, and finally, remain awake all
	night near the Deity of Lord Sri Keshava.
	<hr style={{border: 'none'}} className="my-4"/>
	Early in the morning of the next day, Dvadasi tithi, worship Sri Hari
	with great devotion and invite Brahmin devotees to a sumptuous
	feast. Then feed you relatives, and finally take your meal in silence.
	Oh king, if you strictly observe a fast on Indira Ekadasi in this way,
	with controlled senses, your father will surely be elevated to the
	abode of lord Vishnu. After saying this, Devarishi Narada
	immediately disappeared from the scene to bless someone else with
	his presence.
	<hr style={{border: 'none'}} className="my-4"/>
	King Indrasena followed the great saint's instructions perfectly,
	observing the fast in the association of his relatives and servants. As
	he broke his fast on the Dvadasi tithi, flowers fell from the sky. The
	merit that Indrasena maharaj earned by observing this fast released
	his father from the kingdom of Yamaraja and caused him to attain a
	completely spiritual body. Indeed, Indrasena saw him rising to the
	abode of Lord Hari on the back of GarudaVahan. Indrasena himself
	was able to rule his kingdom without any obstacles, and in time
	when he handed over the kingdom to his son, he also went to the
	spiritual realm of Vaikuntha.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh Yudhishthira, these are the glories of the Indira Ekadasi, which
	occurs during the dark fortnight of the month of Ashwin. Whoever
	hears or reads this narration certainly enjoys life in this world, is
	freed of all reactions to his past sins, and at the time of death
	returns home, back to Godhead, where he lives eternally.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Ashwin-krishna Ekadasi, or
	Indira Ekadasi, taken from the Brahma-vaivarta Purana.
</p>

export default e13;
