/* eslint-disable jsx-a11y/anchor-has-content */
import React, {  } from 'react'
import  './contactus.css'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function Privacy(props) {

	return (
		<div>
			<Header 
				headerClass="allGalleryHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "2"
				buttonto=""
				linkto = "/"
			/>
			<div className="contactus-wrapper mx-auto">
				<div className="collaborate-header">Privacy Policy</div>
                <div className=" my-5">
                    <p style={{ columnCount: 1 }} className="text-justify Bodytext-1regular-16ptleftdark-lato">
                        Effective Date: 10th December 2023<br/>
                        At Satprerna Trust, we are committed to protecting the privacy and confidentiality of our
                        website visitors. This Privacy Policy outlines the types of personal information collected and
                        how it is used and safeguarded.<br/><br/>
                        Information Collection:<br/>
                        a. Personal Information: We may collect personal information, including but not limited to
                        names, email addresses, phone numbers, and mailing addresses, when voluntarily
                        submitted by visitors through contact forms or donation pages.<br/>
                        b. Usage Data: Our website may collect non-personally identifiable information such as
                        browser types, IP addresses, and pages visited to improve user experience.<br/><br/>
                        Use of Information:<br/>
                        a. Personal Information: The personal information collected may be used to respond to
                        inquiries, process donations, and communicate with visitors about our activities, events, and
                        services.<br/>
                        b. Usage Data: This information is utilized to analyze website trends, administer the site, and
                        gather demographic information for internal purposes.<br/><br/>
                        Data Security:<br/>
                        a. Satprerna Trust employs industry-standard security measures to safeguard personal
                        information and prevent unauthorized access or disclosure.<br/>
                        b. However, it&#39;s important to note that no data transmission over the internet or electronic
                        storage method is entirely secure, and while we strive to protect personal information,
                        absolute security cannot be guaranteed.<br/><br/>
                        Sharing of Information:<br/>
                        a. Satprerna Trust does not sell, trade, or rent personal information to third parties without
                        explicit consent, except as required by law or in collaboration with trusted service providers
                        involved in website operations or communication activities.<br/>
                        b. Visitors&#39; personal information may be shared with third-party service providers strictly for
                        the purpose of fulfilling requested services or improving website functionality.<br/>
                        Links to External Sites:<br/>
                        a. Our website may contain links to external sites not operated by Satprerna Trust. We are
                        not responsible for the privacy practices or content of these external sites.<br/><br/>
                        Consent:<br/>
                        a. By using www.satprerna.org, visitors consent to the collection and use of information as
                        outlined in this Privacy Policy.<br/>
                        b. Visitors may choose not to provide personal information, but this could limit their access
                        to certain features or services offered on the website.<br/><br/>
                        Policy Updates:<br/>
                        a. Satprerna Trust reserves the right to update or modify this Privacy Policy at any time.
                        Visitors are encouraged to review this page periodically for any changes.
                        Contact Information:<br/>
                        a. For questions or concerns regarding this Privacy Policy or the use of personal information,
                        please contact us.<br/>
                        By using www.satprerna.org, visitors acknowledge and agree to the terms outlined in this
                        Privacy Policy.<br/>
                    </p>
                </div>
            </div>
            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
		</div>
	)
}

export default Privacy;