import React from 'react';
import '../fonts.css'

const e26 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhishthira Maharaja said, Oh Janardana, what is
	the name of the Ekadasi that occurs during the light
	fortnight (shukla paksha) of the extra, leap year
	month? How does one observe it properly? Please
	narrate all this to me.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Lord Sri Krishna, replied the
	meritorious Ekadasi that occurs during the light
	fortnight of the extra month of leap year is called
	Padmini. It is very auspicious. The fortunate soul
	who observes it with great determination and faith will return
	toVishnu Loka. This extra-month Ekadasi is as powerful as I am in
	nullifying sins. Even four-headed Lord Brahma cannot glorify it
	sufficiently. Long ago Lord Brahma told Narada about this liberating,
	sin-removing Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Krishna said: Oh king, please listen carefully as I narrate to you
	the process of fasting on Padmini Ekadasi, which is rarely done even
	by great sages.
	<hr style={{border: 'none'}} className="my-4"/>
	One should begin his fast on the Dashami, the day before Ekadasi, by
	not eating any Uraddal, pink dal, chickpeas, spinach, honey, or sea
	salt, and also by not dining in homes of others or off bell-metal
	plates. These eight things should be avoided. One should eat only
	once on the Dashami, sleep on the ground, and remain celibate. On
	Ekadasi the devotee should rise early in the morning but should
	brush his teeth. Then he should thoroughly bathe - in a place of
	pilgrimage, if possible. While chanting sacred hymns from the Vedas,
	he should smear his body with cow dung mixed with clay, sesame-
	seed paste, kusha grass, and the powder of Amalaki fruits. Then the
	devotee should take another thorough bath, after which he should
	chant the following prayers: "Oh sacred clay, you have been created
	by Lord Brahma, purified by Kashyapa Muni, and lifted by Lord
	Krishna in His form as Varaha, the boar incarnation. Oh clay, please
	purify my head, eyes, and other limbs. Oh clay, I offer my obeisance
	unto you. Kindly purify me so I may worship the Supreme Lord, Sri
	Hari". "Oh cow-dung, you posses medicinal and antiseptic qualities
	because you have come directly from the stomach of our universal
	mother, the cow. You can purify the entire planet Earth. Please
	accept my humble obeisance’s and purify me". "Oh Amalakifruits,
	please accept my humble obeisance’s. You have taken your birth
	from the saliva of Lord Brahma, and thus by your very presence the
	entire planet is purified. Kindly cleanse and purify my bodily limbs".
	"Oh Supreme Lord Vishnu, Oh God of the gods, Oh master of the
	universe, Oh holder of the conch, disc, club, and lotus, please allow
	me to bathe in all the holy places of pilgrimage."
	<hr style={{border: 'none'}} className="my-4"/>
	Reciting these excellent prayers, chanting mantras to Lord Varuna,
	and meditating on all the places of pilgrimage located on the banks
	of the Ganges, one should bathe in whatever body of water is at
	hand. Then, Oh Yudhishthira, the devotee should rub his body, thus
	purifying his mouth, back, chest, arms, and waist as a prelude to
	worshipping the Supreme Lord, who wears brilliant yellow garments
	and gives pleasure to all creatures. By so doing, the devotee will
	destroy all his sins. Afterwards, he should chant the sacred Gayatri
	mantra, offer oblations to his forefathers, and then enter a Vishnu
	temple to worship Lord Narayana, the husband of the Goddess of
	Fortune, Laxmi-devi.
	<hr style={{border: 'none'}} className="my-4"/>
	If possible, the devotee should then fashion Deities of Sri Sri Radha
	and Krishna or Shiva and Parvati out of gold and offer them nice
	devotional worship. He should fill a copper or clay pot with pure
	water mixed with scents, and then he should cover the pot with a
	cloth lid and a gold or silver lid, in this way preparing an Asana upon
	which the Radha-Krishna or Shiva-Parvati murthis may sit for
	worship. According to capacity, the devotee should then worship
	these murtis with fragrant incense, a bright ghee lamp, and
	sandalwood paste along with camphor, musk, kumkum, and other
	scents, as well as selected aromatic flowers like white lotuses and
	other seasonal blooms, and also very nicely prepared foods. On this
	special Ekadasi the devotees should dance and sing ecstatically
	before the Deity. He should avoid prajalpa (talking unnecessarily of
	ordinary, mundane conversational topics) at all costs and should not
	talk to or touch lowborn persons (untrained persons habituated to
	low acts) or a woman in her menstrual period, or others so
	absorbed. On this day he should be especially careful to speak the
	truth and certainly not criticize anyone before the Deity of Lord
	Vishnu, the Brahmins, or the spiritual master. Rather, with other
	devotees he should be absorbed in listening to Vaishnavas read the
	glories of Lord Vishnu from the Puranas. One should not drink or
	even touch water to his lips on this Ekadasi, and one who is unable
	to perform this austerity should drink only water or milk. Otherwise,
	the fast is considered broken. One should remain awake that Ekadasi
	night, singing and playing musical instruments for the
	transcendental pleasure of the Supreme Person.
	<hr style={{border: 'none'}} className="my-4"/>
	During the first quarter of the Ekadasi night the devotee should offer
	some coconut meat to his worshippable Deity (Isthadeva), during
	the second part he should offer soothing belfruit, during the third
	part an orange, and as the night draws to a close some betel nut.
	Remaining awake during the first part of the Ekadasi night bestows
	on the devotee /sadhaka the same merit as that gained by
	performing the Agnistoma-yajna. Staying awake during the second
	part of the night bestows the same merit as that gained by
	performing aVajapeya-yajna. Stay awake during the third part gives
	one the same merit as that attained by performing an Ashvamedha-
	yajna. And one who remains awake throughout the night receives all
	of the above mentioned merit, as well as the grand merit of having
	performed a RajasUrya-yajna. Thus there is no better fasting day in
	the year than Padmini Ekadasi. Nothing can compare to it as a giver
	of merit, whether it be a fire sacrifice, knowledge, education, or
	austerity. Indeed, whoever observes this sacred Ekadasi fast
	receives all the merit attained by bathing in all the places of
	pilgrimage in the world.
	<hr style={{border: 'none'}} className="my-4"/>
	After remaining awake throughout the night, the devotee should
	bathe at sunrise and then worship Me nicely. He should then feed a
	qualified Brahmin and respectfully give him the Deity of Lord
	Keshava and the pot filled with pure scented water. This gift will
	guarantee the devotee success in this life and liberation in the
	hereafter.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh sinless Yudhishthira, as you have requested, I have described the
	rules and regulations, as well as the benefits, regarding the Ekadasi
	that occurs during the light fortnight of the extra, leap-year month.
	Fasting on this Padmini day bestows merit equal to that gained by
	fasting on all other Ekadasis. The Ekadasi that occurs during the
	dark part of the extra month, which is known as Parama Ekadasi, is
	as powerful at removing sin as this one, Padmini. Now please listen
	to Me carefully as I narrate to you a fascinating account connected
	with this sacred day. Pulastya Muni once recited this history to
	Naradji.
	<hr style={{border: 'none'}} className="my-4"/>
	Pulastya Muni once had occasion to rescue the ten headed demon
	Ravana from the prison of Kartavirya, and upon hearing of this event
	Narada Muni asked his friend, Oh greatest of sages, since this
	Ravana defeated all the demigods, including Lord Indradev, how
	could Kartavirya defeat Ravana, who was so skilled in battle?
	<hr style={{border: 'none'}} className="my-4"/>
	Pulastya Muni replied, Oh great Narada, during the Tretayuga
	Kartavirya (Kartavirya’s father) took birth in the Haihaya dynasty.
	His capital city was Mahishmati, and he had one thousand Queens,
	whom he loved very dearly. None of them, however, was able to give
	him the son he wanted so badly. He performed sacrifices and
	worshipped the devas(demigods) and forefathers (pitris), but due to
	the curse of some sage he was unable to beget a son - and without a
	son, a king cannot enjoy his kingdom.
	<hr style={{border: 'none'}} className="my-4"/>
	King Kartavirya carefully considered his plight and then decided to
	perform severe austerities to achieve his goal. Thus he donned a
	loincloth made of bark, grew matted locks, and turned over the reins
	of his kingdom to his ministers. One of his queens, Padmini - who
	was born in the Ikshvaku dynasty, who was the best of all women,
	and who was the daughter of King Harishchandra - saw the king
	leaving. She felt that, since she was a chaste wife, her duty was to
	follow in the footsteps of her beloved husband. Removing all the
	regal ornaments from her beautiful body and donning but one piece
	of cloth, she thus followed her husband into the forest.
	<hr style={{border: 'none'}} className="my-4"/>
	At last Kartavirya reached the summit of Mount Gandhamadana,
	where he performed severe austerities and penance’s for ten
	thousand years, meditating and praying to Lord Gadadhara, who
	wields a club. But still he did not get a son. Seeing her dear husband
	waste away to mere skin and bone, Padmini thought of a solution to
	the problem. She went to the chaste Anasuya. With great reverence,
	Padmini said, ‘Oh great lady, my dear husband, Kartavirya, has been
	performing austerities for the last ten thousand years, but Lord
	Krishna (Keshava), who alone can remove one's past sins and
	present difficulties, has not yet become pleased with him. Oh most
	fortunate one, please tell me a fast day we can observe and thus
	please the Supreme Lord with our devotion, so much so that He will
	bless me with a nice son who will later rule the world as emperor.’
	<hr style={{border: 'none'}} className="my-4"/>
	Upon hearing the appealing words of Padmini, who was very chaste
	and deeply devoted to her husband, the great Anasuya replied to her
	in a very cheerful mood: 'Oh beautiful, lotus-eyed lady, usually there
	are twelve months in a year, but after every thirty-two months an
	extra month is added, and the two Ekadasis that occur during this
	month are called Padmini Ekadasi and Parama Ekadasi. They fall on
	the Dvadasiis of the light and dark part of the month, respectively.
	You should fast on these days and remain awake throughout the
	night. If you do so, the Supreme Lord, Sri Hari, will bless you with a
	son.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh Narada, in this way Anasuya, the daughter of the sage Kardama
	Muni, explained the potency of these special Ekadasis. Hearing this,
	Padmini faithfully followed the instructions to fulfill her desire for a
	son. Padmini fasted completely, even from water, and remained
	awake all night, chanting the glories of God and dancing in ecstasy.
	Lord Keshava thus became most pleased with her devotion and
	appeared before her, riding on the back of the great Garuda. The
	Lord said, Oh beautiful one, you have greatly pleased Me by fasting
	on the special Ekadasi of the extra month of Purushottama. Please
	ask Me for a benediction.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing these sublime words from the overseer of the entire
	universe, Padmini offered the Supreme Lord devotional prayers and
	asked Him for the boon her husband desired. Lord Keshava (Krishna)
	was moved to reply, Oh gentle lady, I am very happy with you, for
	there is no month dearer to Me than this, and the Ekadasis that
	occur during this month are the most dear to Me of all Ekadasis. You
	have followed My devotee Anasuya's instructions perfectly, and
	therefore I shall do what will please you. You and your husband will
	have the desired son that you wish.'
	<hr style={{border: 'none'}} className="my-4"/>
	The Lord, who removes the distress of the world, then spoke to King
	Kartavirya: Oh King, please ask of Me any boon that will fulfill your
	heart's desire, for your dear wife has pleased Me greatly with her
	devotional fasting.
	<hr style={{border: 'none'}} className="my-4"/>
	The king was very happy to hear this. Naturally he asked for the son
	he had desired for so long: Oh master of the universe, Oh killer of
	the Madhu-demon, kindly grant me a son who will never be
	conquered by the demigods, human beings, snakes, demons, or
	hobgoblins, but whom only You can defeat. The Supreme Lord
	immediately replied, ‘So be it!’ and disappeared.
	<hr style={{border: 'none'}} className="my-4"/>
	The king became so very pleased with his wife and returned to his
	palace in her company. Padmini soon became pregnant, and the
	mighty-armed Kartavirya appeared as her son. He was the mightiest
	person in all the three worlds, and thus even the ten-headed Ravana
	could not defeat him in battle. Except for Lord Narayana, who holds
	a club, a disc, and other symbols in His hands no one could overcome
	him. By the merit that resulted from his mother's strict and faithful
	observance of Padmini Ekadasi, he could defeat even the dreaded
	Ravana. This is not at all surprising, Oh Naradji, for Kartavirya was
	the fulfillment of the benediction of the Supreme Lord. With these
	words, Pulastya Muni departed.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Sri Krishna, concluded, 'Oh sinless Yudhishthira,
	as you have enquired from Me, I have explained to you the power of
	this special Ekadasi. Oh best of kings, whoever observe this fast will
	surely attain to Vishnu Loka.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Padmini Ekadasi, the
	Ekadasi that occurs during the light fortnight of the extra, leap-year
	month of Purushottama, from the Skanda Purana.
</p>

export default e26;
