import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import EkadashiPage from '../../Pages/Ekadashi.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Ekadashi() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Do 1 Get 11" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <EkadashiPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="bsd_header" imageKeys={["/images/ekadashi/ekadashi-bg.jpg", "/images/ekadashi/ekadashi-bg.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="bsd_c1_p1" imageKeys={["/images/ekadashi/img5.jpeg", "/images/ekadashi/img6.jpeg", "/images/ekadashi/img7.jpeg"]} heading subheading/>
                <EditCard title="CAROUSEL 2" textKey="bsd_c2_p1" imageKeys={["/images/ekadashi/img6.jpeg", "/images/ekadashi/img7.jpeg", "/images/ekadashi/img5.jpeg"]} heading subheading/>    
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="DO 1 GET 11" textKey="bsd_do1_get11_1" imageKeys={["/images/ekadashi/eleven-benefits_#9a0808.jpg"]} heading subheading/>
                <EditCard title="BENEFIT 1" textKey="bsd_do1_get11_1" paragraph titleP="Benefit 1"/>
                <EditCard title="BENEFIT 2" textKey="bsd_do1_get11_2" paragraph titleP="Benefit 2"/>
                <EditCard title="BENEFIT 3" textKey="bsd_do1_get11_3" paragraph titleP="Benefit 3"/>
                <EditCard title="BENEFIT 4" textKey="bsd_do1_get11_4" paragraph titleP="Benefit 4"/>
                <EditCard title="BENEFIT 5" textKey="bsd_do1_get11_5" paragraph titleP="Benefit 5"/>
                <EditCard title="BENEFIT 6" textKey="bsd_do1_get11_6" paragraph titleP="Benefit 6"/>
                <EditCard title="BENEFIT 7" textKey="bsd_do1_get11_7" paragraph titleP="Benefit 7"/>
                <EditCard title="BENEFIT 8" textKey="bsd_do1_get11_8" paragraph titleP="Benefit 8"/>
                <EditCard title="BENEFIT 9" textKey="bsd_do1_get11_9" paragraph titleP="Benefit 9"/>
                <EditCard title="BENEFIT 10" textKey="bsd_do1_get11_10" paragraph titleP="Benefit 10"/>
                <EditCard title="BENEFIT 11" textKey="bsd_do1_get11_11" paragraph titleP="Benefit 11"/>
            </TabPanel>
        </div>
    );
}
