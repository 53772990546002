import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import GangaPage from '../../Pages/Ganga.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Ganga() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Main Video" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <GangaPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="ganga_header" imageKeys={["/images/3G/ganga-bg1.jpg", "/images/3G/ganga-bg-m.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="ganga_c1_p1" imageKeys={["/images/3G/canva3.jpg"]} heading subheading titleS="Paragraph 1"/>
                <EditCard title="" textKey="ganga_c1_p2" imageKeys={["/images/3G/canva8.jpg"]} subheading titleS="Paragraph 2"/>
                <EditCard title="" textKey="ganga_c1_p3" imageKeys={["/images/3G/canva9.jpg"]} subheading titleS="Paragraph 3"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="YOUTUBE INSPIRING VIDEO" textKey="ganga_main_vdo" heading subheading paragraph titleP="Youtube Video Link"/>
            </TabPanel>
        </div>
    );
}
