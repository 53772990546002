import React from 'react';
import '../fonts.css'

const e21 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Daalbhya Rishi said to Palastya Muni, When the spirit soul comes
	in contact with the material energy,
	he immediately begins to perform sinful activities,
	such as stealing, killing, and illicit sex. He may even
	perform many other terrible deeds, such as killing a
	Brahmin. Oh purest of personalities, please tell me
	how these unfortunate souls may escape the
	punishment of being sent to hellish regions of
	creation. Kindly inform me how, by giving even a
	little in charity, one may be easily released from the
	reactions of his sins.
	<hr style={{border: 'none'}} className="my-4"/>
	Pulastya Muni replied, Oh fortunate one, you have asked me an
	important and confidential question, which not even Brahma,
	Vishnu, Shiva or Indra has ever asked. Please listen very carefully to
	my answer.
	<hr style={{border: 'none'}} className="my-4"/>
	With the arrival of the month of Magh (January - February), one
	should bathe, carefully control his senses by giving up lust, anger,
	pride, jealousy, faultfinding, and greed, and meditate on the
	Supreme Lord Sri Krishna. One should then gather up some cow
	dung before it touches the ground and, after mixing it with sesame
	seeds and cotton, form 108 balls. This should be done on the day
	when the constellation of Purva-ashadha nakshatra arrives. Then
	one should follow the rules and regulations of Sri Ekadasi, which I
	shall now explain to you.
	<hr style={{border: 'none'}} className="my-4"/>
	After bathing, the person who intends to observe Ekadasi should
	worship the Supreme Lord. While praying to Lord Sri Krishna by
	chanting His holy name, he should promise to observe the Ekadasi
	fast. He should remain awake overnight and perform a homa (fire
	sacrifice). Then the devotee should perform an Arati ceremony for
	the pleasure of the Lord - Who holds a conch, disk, club, and so on in
	His hands - offering Him sandalwood paste to his feet, incense,
	camphor, a bright ghee lamp, and delicious food preparations. Next
	the devotee should offer the 108 balls of cow dung, sesame seeds,
	and cotton wool into the sacred fire whilst chanting such holy names
	of the Lord as Purusha sukta, and other names. Throughout the
	whole day and night he should also observe the standard Ekadasi
	vrata (fast, which in this case is a fast from all kinds of grains and
	beans). On this occasion one should offer the Lord - pumpkin,
	coconut, and guava. If these items are unavailable, betel nut may be
	substituted.
	<hr style={{border: 'none'}} className="my-4"/>
	The devotee should pray to Lord Sri Janardana, the benefactor of all
	living beings, in this way; Oh Lord Sri Krishna, You are the most
	merciful Lord and the giver of liberation to all fallen souls. Oh Lord,
	we have fallen into the ocean of material existence. Please be kind
	to us. Oh lotus-eyed divinity, please accept our most humble,
	affectionate and respectful obeisances. Oh protector of the entire
	world, we offer You our humble respects again and again. Oh
	Supreme Spirit, Oh Supreme One, Oh source of all our forefathers,
	may You and Your eternal consort, Srimati Laxmi-devi, please accept
	these humble offerings.
	<hr style={{border: 'none'}} className="my-4"/>
	The devotee should then try to please a qualified Brahmin with a
	warm welcome, a pot full of water (prune cumber), an umbrella, a
	pair of shoes, and clothes (cloth - Doha, and Ana vesture),
	requesting him at the same time to bestow his blessings, by which
	one may develop unalloyed love for Lord Sri Krishna. According to
	one's ability, one may also donate a black cow to such a Brahmin,
	particularly to one who is very well versed in all the injunctions of
	the Vedic scriptures. One should also offer him a pot full of sesame
	seeds.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh exalted Daalbhya Muni, black sesame seeds are especially
	suitable for formal worship and fire sacrifices while white or brown
	ones are meant to be eaten by a qualified Brahmin. One who can
	arrange to give both kinds of sesame seeds (black and white or
	brown) especially on this Sat-tilaa Ekadasi day will be promoted to
	at least the heavenly planets after leaving this present body, for as
	many thousands of years as the number of seeds that would be
	produced if the seeds he donated were sown in the ground and grew
	into mature, seed bearing plants.
	<hr style={{border: 'none'}} className="my-4"/>
	On this Ekadasi a faithful person should:
	<hr style={{border: 'none'}} className="my-4"/>
	1. bathe in the water mixed with sesame seeds,
	<hr style={{border: 'none'}} className="my-2"/>
	2. rub sesame seed paste on his body,
	<hr style={{border: 'none'}} className="my-2"/>
	3. offer sesame seeds into the fire in sacrifice,4. water with sesame seeds,
	<hr style={{border: 'none'}} className="my-2"/>
	5. eat sesame seeds,
	<hr style={{border: 'none'}} className="my-2"/>
	6. give sesame seeds away in charity.
	<hr style={{border: 'none'}} className="my-4"/>
	These are the six (sat) ways in which sesame seeds (tilaa) are
	utilized for spiritual purification on this Ekadasi. Therefore it is
	called Sat-tilaa Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	The great Devarishi Naarad Muni once asked the Supreme Lord, Sri
	Krishna, Oh mighty armed Lord, Oh You who are so affectionate to
	Your loving devotees, please accept my most humble obeisances. Oh
	Yaadava, kindly tell me the result one obtains by observing Sat-tilaa
	Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, Oh best of the twice born Brahmins, I shall
	narrate to you an account of an incident I personally witnessed.
	Long ago on earth there lived an old female brahmini who
	worshipped Me every day with controlled senses. She very faithfully
	observed many a fast, especially on special days honoring Me and
	served Me with full devotion, devoid of any personal motive. Her
	rigorous fasting made her quite weak and thin. She gave charity to
	Brahmans and to young maidens (kanyas), and even planned to give
	away her house in charity. Oh best of the Brahmans although this
	spiritually minded woman gave charitable donations to worthy
	people, the odd feature of her austerity was that she never gave
	food to Brahmans or the devas (demigods).
	<hr style={{border: 'none'}} className="my-4"/>
	I began to reflect on this curious omission: This fine woman has
	purified herself by fasting on all the auspicious occasions and by
	offering Me strict devotional worship. Therefore she certainly has
	become eligible to enter My personal abode, which is unattainable by
	ordinary persons.
	<hr style={{border: 'none'}} className="my-4"/>
	So I came down to this planet to examine her, disguising Myself as a
	beggar.
	<hr style={{border: 'none'}} className="my-4"/>
	As I approached her, she said to Me, "Oh respectful one, tell me
	truthfully why You have come before Me".
	<hr style={{border: 'none'}} className="my-4"/>
	I replied, Oh beautiful one, I have come to get some sacred alms
	from you - whereupon she angrily threw a dense lump of mud into
	My begging pot! Oh Naarada Muni I simply turned around and went
	back to My personal abode astonished at this fine Brahmani's
	peculiar mixture of great magnanimity and stinginess.
	<hr style={{border: 'none'}} className="my-4"/>
	At last this austere lady reached the spiritual world in her self-same
	body, so great were her efforts at fasting and charity. And because
	she had indeed offered Me a lump of mud, I transformed that mud
	into a beautiful home. However, Oh Naradaji, this particular house
	was just like that mud completely devoid of any edible grains, as
	well as any furniture or ornamentation, and when she entered it she
	found only an empty structure. She therefore approached Me and
	said with great anger.
	<hr style={{border: 'none'}} className="my-4"/>
	I have fasted repeatedly n so many auspicious occasions, making my
	body weak and thin. I have worshipped You and prayed to You in so
	many different ways for You are truly the master and protector of all
	the universes. Yet despite all this there is no food or wealth to be
	seen in my new home, Oh Janardana, please tell me. Why is this?
	<hr style={{border: 'none'}} className="my-4"/>
	I replied, Please return to your house. Sometime later the wives of
	the devas (demigods) will pay you a visit out of curiosity to see the
	new arrival, but do not open your door until they have described to
	you the glories and importance of Sat-tilaa Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Hearing this, she returned to her house. Eventually the devas wives
	arrived there and in unison said, Oh beautiful one, we have come to
	have your darshan. Oh auspicious one, please open the door to your
	house and let us see you. The lady replied, Oh most dear ones, if you
	want me to open this door, you will have to describe to me the merit
	one obtains by observing the sacred fast of Sat-tilaa Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	But to this request, not even one of the wives responded.
	<hr style={{border: 'none'}} className="my-4"/>
	Later, however, they returned to the house, and one of the wives
	nicely explained the sublime nature of this sacred Ekadasi. And when
	the lady at last opened her door, they saw that she was neither a
	demigoddess, a Gandharvi, a she-demon, nor even a Naga-patni. She
	was simply an ordinary human lady.
	<hr style={{border: 'none'}} className="my-4"/>
	From then on the lady observed Sat-tilaa Ekadasi, which awards all
	material enjoyment and liberation at the same time, as it has been
	described to her. And she finally received the beautiful furnishings
	and grains she had expected for her home. Moreover, her once
	ordinary material body was transformed into a beautiful spiritual
	form made of sat-chit-ananda (eternality, knowledge, and bliss),
	with a fine complexion. So, by the mercy and grace of Sat-tilaa
	Ekadasi, both the lady and her new home in the spiritual world were
	at last radiantly splendid and lustrous with gold, silver, jewels, and
	diamonds.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh Naradaji, a person should not ostentatiously observe Ekadasi out
	of greed, with the hope of attaining wealth dishonestly. Selflessly,
	he should simply donate sesame seed, clothes, and food according to
	his capacity; for by doing so he will achieve good health and exalted
	spiritual consciousness, birth after birth. Ultimately, he will be given
	release from the bonds of this world (liberation) and admittance into
	the Lord's supreme abode will be his to enjoy. That is my opinion, Oh
	best of the demigod’s deva-rishis).
	<hr style={{border: 'none'}} className="my-4"/>
	Oh Daalbhya Muni, Pulastya Rishi concluded, One who properly
	observes this wonderful Sat-tilaa Ekadasi with great faith become
	free from all kinds of poverty - spiritual, mental, physical, social, and
	intellectual - as well as all kinds of ill luck and evil omens (sakuna).
	Indeed, following this Ekadasi fast by donating, sacrificing, or eating
	sesame seeds frees one of all past sin, without a doubt. One need
	not wonder how this happens. The rare soul who properly performs
	these acts of charity in the right devotional mood, following the
	Vedic injunctions, will become utterly free of all sinful reactions and
	go back to Godhead, back home to the spiritual world.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Magh-krishna Ekadasi, or
	Sat-tilaa Ekadasi, from the sacred Bhavishya-uttara Purana of Srila
	Krishna Dwaipayana Vyaasa.
</p>

export default e21;
