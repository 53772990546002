import React, {useState} from 'react'
import './cards.css'
import './style.css'
import '../fonts.css'
import Blog from './EkadashiBlog.js'


function CardB(props) {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
	    setOpen(true)
	};

	const handleClose = () => {
	    setOpen(false)
	};


	return (
		<div style={{width: "350px", minWidth: "200px"}} className="card-a-types-card my-5 mx-4 mx-lg-4 mx-xl-4">
			<div className="card__container">
				<div className="card p-4">
					<div className="card__image-container">
						<img className="card-image" src={props.url} alt="" />
					</div>
					<svg className="card__svg" viewBox="0 0 1000 400">
						<path style={{opacity: "0.15"}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z" fill="#fff"></path>
						<path style={{opacity: "0.3"}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z" fill="#fff"></path>
						<path style={{opacity: "1"}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z" fill="#fff"></path>					
					</svg>					
					<div className="card__content">
						<h2 className="card__title mb-4">{props.name}</h2>
						<h3 className="card__sub__title my-4">{props.subname}</h3>
						<p style={{fontSize: '1em'}} className="card__text text-justify Bodytext-1regular-16ptcenterdark-lato">
                            {props.content}
                            {props.readMore === true ? <Blog banner={props.banner} file={props.file} video={props.video} title={props.title} subtitle={props.subtitle}/> : undefined}
                        </p>
					</div>
				</div>
			</div>
		</div>
	)
}

CardB.defaultProps = {
	readMore: true
};

export default CardB;