import React from 'react'
import './button.css'
import '../fonts.css'
import {
	Link
} from "react-router-dom";


export function ButtonA(props) {

	return(
		<div>
			<input className="styled-buttona Button-15ptcenterwhite-lato" type="button" value={props.buttonto} onClick={props.onClick} disabled={props.disabled}/>
		</div>
	)

}

export function ButtonB(props) {

	return(
		<div>
			<input className="styled-buttonb Button-15ptcenterwhite-lato" type="button" value={props.buttonto} onClick={props.onClick}/>
		</div>
	)

}
