import React, { useState, useRef, useEffect, useContext } from 'react'
import axios from 'axios'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../Context/DataContext'
import './editcard.css'

export function EditText(props) {
    const [data, setData] = useState({
		title: (props.title).toUpperCase(),
		text: props.text
	});
	
	const [preview, setPreview] = useState(true);
	let _text = useRef();

    const _handlePreviewClick = (e) => {
		e.preventDefault();
		setPreview(true);
    }
    
    const _handleEditClick = (e) => {
		e.preventDefault();
		setPreview(false);
    }

	const _handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("name", props.textKey);
		formData.append("heading", props.type === '1' ? data.text : '');
		formData.append("subheading", props.type === '2' ? data.text : '');
		formData.append("paragraph", props.type === '3' ? data.text : '');
		axios.post('https://satprerna.org/satprerna_backend/home/update_by_name_api.php', formData).then(
			window.location.reload()
		).then((error) => {
			console.log(error);
		});
	}
    
    const _renderPreview = () => {
		if (data.text) {
			let htmlText = () => ({ __html: data.text });
			return (
				<div>
					<div className="edit-element-preview-box">
						<span className="edit-element-preview-title">Preview</span>
						<div dangerouslySetInnerHTML={htmlText()}></div>
					</div>
					<span>
						<button onClick={_handleEditClick} className="edit-element-button">
							Edit
						</button>	
						<button onClick={_handleSubmit} className="edit-element-button">
							Submit
						</button>
					</span>
				</div>
			);
		}
		return null;
	}

    const editStyle = () => {
		if (!preview)
			return { display: 'block' };
		else 
			return { display: 'none' };
	};

	const previewStyle = () => {
		if (preview)
			return { display: 'block' };
		else 
			return { display: 'none' };
	};

    return (
		<div className="edit-element-wrapper">
			<h1 className="edit-element-title">{data.title}</h1>
			<form className="edit-element-form">
				<div style={editStyle()}>
					<textarea 
						ref={_text} 
						value={data.text}
						className="edit-element-textarea"
						onChange={e=>{setData({...data, text: e.target.value})}}
					/>
					<button onClick={_handlePreviewClick} className="edit-element-button">
						Preview
					</button>
				</div>
				<div style={previewStyle()}>
					{_renderPreview()}
				</div>
			</form>
		</div>
    );
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export function UploadImage(props) {
	const classes = useStyles();
	const [fileObj, setfileObj] = useState([]);
	const [fileArray, setfileArray] = useState([]);
	const [fileBase64, setFileBase64] = useState([]);
	const [openloading, setopenloading] = React.useState(false);

	useEffect(() => {
		let a = []
		if(fileObj.length !== 0) {
			for (let i = 0; i < fileObj[0].length; i++) {
				a.push([URL.createObjectURL(fileObj[0][i])])
			}
		}
		setfileArray(a)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileObj])

	const baseUrl = "https://satprerna.org";

	const uploadMultipleFiles = (e) => {
        e.preventDefault();
        setfileObj([e.target.files]);
		var fileReader = new FileReader();
		fileReader.onloadend = function() {
			var base64data = fileReader.result;
			setFileBase64([base64data]);
		}
		fileReader.readAsDataURL(e.target.files[0]);
    }

    const uploadFiles = (e) => {
        e.preventDefault();
		setopenloading(true);
		const formData = new FormData();
		formData.append("dir", "../.." + props.imagepath);
		formData.append("image", fileBase64[[0]]);
		axios.post('https://satprerna.org/satprerna_backend/home/store_image_to_folder.php', formData).then(
			setTimeout(function(){
				setopenloading(false)
			}, 2000)
		).then((error) => {
			console.log(error);
		});
    }

	const fetchImages = () => {
		if(fileArray.length === 0) {
			return (
				<div>
					<img src={baseUrl + props.imagepath} alt="" className="upload-images"/>
				</div>
			)
		}
		else {
			return fileArray.map(url => (
				<img src={url} alt="" className="upload-images"/>
			))
		}
	}

	return (
		<div className="edit-element-wrapper">
			<h1 className="edit-element-title">{props.title}</h1>
			<div className="edit-image-wrapper">
				<form>
					<div className="form-group multi-preview">
						{fetchImages()}
					</div>
					<span className="form-group">
						<label className="edit-element-button">
							<input type="file" name="userfile" className="form-control" onChange={uploadMultipleFiles} style={{display: 'none'}}/>
							Upload
						</label>
						<button onClick={uploadFiles} className="edit-element-button">
							Submit
						</button>
						<button onClick={(e) => { e.preventDefault(); setfileArray([])}} className="edit-element-button">
							Reset
						</button>
					</span>
				</form>
			</div>
			<Backdrop className={classes.backdrop} open={openloading} onClick={()=>{setopenloading(false)}}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	)
}

export default function EditCard(props) {
	const { heading, subheading, paragraph } = useContext(DataContext);

	return (
		<div className="editable-header">
			<h3 className="editable-title">{props.title}</h3>
			{props.heading ? <EditText title={props.titleH} text={heading(props.textKey)} type="1" textKey={props.textKey}/> : undefined}
			{props.subheading ? <EditText title={props.titleS} text={subheading(props.textKey)} type="2" textKey={props.textKey}/> : undefined}
			{props.paragraph ? <EditText title={props.titleP} text={paragraph(props.textKey)} type="3" textKey={props.textKey}/> : undefined}
			{props.imageKeys.map(item => <UploadImage title={props.titleImg} imagepath={item}/>)}
		</div>
	)
}

EditCard.defaultProps = {
	titleH: "Title",
	titleS: "Subtitle",
	titleP: "Content",
	titleImg: "UPLOAD IMAGE",
	imageKeys: []
}
