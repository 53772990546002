import React from 'react';
import '../fonts.css'

const e11 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhisthira Maharaja ,son of Kunti said, Oh
	Janardana, protector of all living entities, please
	tell me the name of the Ekadasi that occurs
	during the dark fortnight of the month of
	Bhadrapada (August-September).
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Sri Krishna, then replied, Oh
	King, hear Me attentively. The name of this sin-
	removing, sacred Ekadasi is Aja. Any person who
	fasts completely on this day and worships
	Hrishikesha, the master of the senses, becomes free of all reactions
	to his sins. Even one who simply hears about this Ekadasi is freed
	from his past sins. Oh King, there is no better day than this in all the
	earthly and heavenly worlds. This is true without a doubt.
	<hr style={{border: 'none'}} className="my-4"/>
	There once lived a famous king named Harishchandra, who was the
	emperor of the world and a person of great truth and integrity. By
	the force of destiny, however, Harishchandra lost his great kingdom
	and sold his wife and son. The pious king himself became a menial
	servant of a dog-eater (chandal), who made him guard a
	crematorium. Yet even while doing such menial service, he did not
	forsake his truthfulness and good character, just assoma-rasa, even
	when mixed with some other liquid, does not lose its ability to
	bestow immortality.
	<hr style={{border: 'none'}} className="my-4"/>
	The king passed many years in this condition. Then one day he sadly
	thought, ‘What shall I do? Where shall I go? How can I be delivered
	from this plight?' In this way he was drowned in an ocean of anxiety
	and sorrow.
	<hr style={{border: 'none'}} className="my-4"/>
	One day a great sage happened to come by, and when the king saw
	him he thought, ‘Ah, Lord Brahma has created Brahmins just to help
	others.’ Harishchandra paid his respectful obeisances to the sage,
	whose name was Gautama Muni. With joined palms the king stood
	before Gautama Muni and narrated his pitiful story. Gautama Muni
	was astonished to hear the king's tale of woe. He thought, ‘How has
	this mighty king been reduced to collecting clothes from the dead?’
	Gautama Muni became very much compassionate toward
	Harishchandra and instructed him on the process of fasting for
	purification.
	<hr style={{border: 'none'}} className="my-4"/>
	Gautama Muni said, Oh king, during the dark fortnight of the month
	of Bhadrapada there occurs an especially meritorious Ekadasi named
	Aja (Annada), which removes all sins. Indeed, this Ekadasi is so
	auspicious that if you simply fast on that day and perform no other
	austerity, all your sins will be nullified. By your good fortune it is
	coming in just seven days. So I urge you to fast on this day and
	remain awake through the night. If you do so, all the reactions of
	your past sins will come to an end. Oh Harishchandra, I have come
	here because of your past pious deeds. Now, all good fortune to you
	in the future!' So saying, the great sage Sri Gautama Muni
	immediately disappeared from his vision.
	<hr style={{border: 'none'}} className="my-4"/>
	King Harishchandra followed Gautama Muni's instructions
	concerning fasting on the sacred day of Aja Ekadasi. Oh Maharaja
	Yudhisthira, because the king fasted on that day, the reactions to his
	previous sins were completely destroyed at once. Oh lion among
	kings, just see the influence of this Ekadasi fast! It immediately
	vanquishes whatever miseries one may be suffering as a result of
	past karmic sinful activities.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus all Harishchandra's miseries were relieved. Just by the power
	of this wonderful Ekadasi, he was reunited with his wife and son,
	who had died but were now revived. In the heavenly regions
	the devas (demigods) began beating on their celestial kettledrums
	and showering down flowers upon Harishchandra, his queen, and
	their son. By the blessings of the Ekadasi fast, he regained his
	kingdom without difficulty. Moreover, when King Harishchandra left
	the planet, his relatives and all his subjects too, went with him to
	the spiritual world.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh king, whoever fasts on Aja Ekadasi is surely freed from all his
	sins and ascends to the spiritual world. And whosoever hears and
	studies the glories of this Ekadasi achieves the merit gained by
	performing a horse sacrifice.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Bhadrapada-krishna
	Ekadasi, or Aja Ekadasi, from the Brahma-vaivarta Purana.
</p>

export default e11;
