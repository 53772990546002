import React from 'react';
import '../fonts.css'

const e4 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhisthira Maharaja said, Oh Janardana, what is
	the name of the Ekadasi that occurs during the light
	fortnight (shukla paksha) of the month of Vaisakha
	(April-May) what is the process for observing it
	properly? Kindly narrate all of these details to me.
	<hr style={{border: 'none'}} className="my-3"/>
	The Supreme Lord, Lord Sri Krishna replied, Oh
	blessed son of Dharma, what Vasishtha Muni once
	told to Lord Ramachandra I shall now describe to you. Please hear
	Me attentively.
	<hr style={{border: 'none'}} className="my-3"/>
	Lord Ramachandra asked Vasishtha Muni, "Oh great sage, I would
	like to hear about the best of all fasting days that day which
	destroys all kinds of sins and sorrows. I have suffered long enough
	in separation from My dear Sita, and so I wish to hear from you
	about how My suffering can be ended."
	<hr style={{border: 'none'}} className="my-3"/>
	The sage Vasishtha replied, Oh Lord Rama, Oh You, whose
	intelligence is so keen, simply by remembering Your name one can
	cross the ocean of the material world. You have questioned me in
	order to benefit all of humanity and fulfill everyone's desires. I shall
	now describe that day of fasting which purifies the whole world.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh Rama, that day is known as Vaisakha-sukla Ekadasi, which falls
	on Dvadasii. It removes all sins and is famous as Mohinii Ekadasi.
	Truly, Oh dear Rama, the merit of this Ekadasi frees the fortunate
	soul who observes it from the network of illusion. Therefore, if You
	want to relieve Your suffering(s), observe this auspicious Ekadasi
	perfectly, for it removes all obstacles from ones path and relieves
	the greatest miseries. Kindly listen as I describe its glories, because
	for one who even just hears about this auspicious Ekadasi, the
	greatest sins are nullified.
	<hr style={{border: 'none'}} className="my-3"/>
	On the banks of the Sarasvati River there was once a beautiful city
	named Bhadravati, which was ruled by King Dyutiman. Oh Rama,
	that steadfast, truthful, and highly intelligent king was born in the
	dynasty of the Moon (Chandra-vamsa). In his kingdom was a
	merchant named Dhanapala, who possessed a great deal of wealth
	of food grains and money. He was also very pious. Dhanapala
	arranged for lakes to be dug, sacrificial arenas to be erected, and
	beautiful gardens to be cultivated for the benefit of all the citizens of
	Bhadravati. He was an excellent devotee of Lord Vishnu and had five
	sons: Sumana, Sadbuddhi, Medhavii, Sukriti, and Dhrishthabuddhi.
	<hr style={{border: 'none'}} className="my-3"/>
	Unfortunately, his son Dhrishthabuddhi always engaged in greatly
	sinful activities, such as sleeping with prostitutes and associating
	with similar degraded persons. He enjoyed illicit sex, gambling, and
	many other varieties of acts aimed at gratifying the senses. He
	disrespected the demigods (devas), the Brahmins, the forefathers
	and other elders of the community, as well as his family's guests.
	The evil-hearted Dhrishthabuddhi spent up his father's wealth
	indiscriminately, always feasting on untouchable foods and drinking
	alcohol to excess.
	<hr style={{border: 'none'}} className="my-3"/>
	One day Dhanapala kicked Dhrishthabuddhi out of the house after he
	saw him walking along the road arm-in-arm with a known prostitute.
	From then on all Dhrishthabuddhi's relatives were highly critical of
	him and distanced themselves from him also. After he had sold all of
	his inherited ornaments and become destitute, the prostitute also
	abandoned him and insulted him because of his poverty.
	<hr style={{border: 'none'}} className="my-3"/>
	Dhrishthabuddhi was now full of anxiety, and also hungry. He
	thought, What should I do? Where should I go? How can I maintain
	myself? He then began to steal. The king's constables arrested him,
	but when they learned who he was, and that his father was the
	famous Dhanapala, they released him. He was caught and released
	in this way many times. But at last, sick of his arrogance and total
	disrespect for others, and their property, the ill-mannered
	Dhrishthabuddhi was apprehended, handcuffed, and then beaten.
	After whipping him, the king's marshals warned him, Oh evil minded
	one, there is no place for you in this kingdom.
	<hr style={{border: 'none'}} className="my-3"/>
	However, Dhrishthabuddhi was freed from his tribulation by his
	father and immediately thereafter entered the dense forest. He
	wandered here and there, hungry and thirsty and suffering greatly.
	Eventually he began killing the jungle animals, and for food.
	<hr style={{border: 'none'}} className="my-3"/>
	Dhrishthabuddhi was always miserable and anxious, but one day,
	during the month of Vaisakha, by the force of some of his past merit
	he chanced upon the sacred Ashrama of Kaundinya Muni. The great
	sage had just finished bathing in the Ganges River, and water was
	dripping from him still. Dhrishthabuddhi had the great good fortune
	to touch some of those droplets of water that were falling from the
	great sage's wet clothing. Instantly Dhrishthabuddhi was freed of
	his ignorance, and his sinful reactions were reduced. Offering his
	humble obeisances to Kaundinya Muni, Dhrishthabuddhi prayed to
	him with joined palms; Oh great Brahman, please describe to me
	some of the atonement I may perform without too much endeavor. I
	have committed so many sins in my life, and these have now made
	me very poor.
	<hr style={{border: 'none'}} className="my-3"/>
	The great rishi replied Oh son, listen with great attention, for by
	hearing me your life will change, and you will become free of all your
	remaining sins. In the light fortnight of this very month, Vaisakha
	(April-May) there occurs the sacred Mohinii Ekadasi. If you follow
	my advice and faithfully observe a fast on this Ekadasi, which is so
	dear to Lord Hari, you will be freed from all the sinful reactions of
	many, many births.
	<hr style={{border: 'none'}} className="my-3"/>
	Hearing these words with great joy, Dhrishthabuddhi promised to
	observe a fast on Mohinii Ekadasi according to the sage's
	instructions and direction. Vasishtaji says, Oh best of kings, Oh
	Ramachandra Bhagawan, by fasting completely on Mohinii Ekadasi,
	the once sinful Dhrishthabuddhi, the prodigal son of the merchant
	Dhanapala, became sinless. Afterwards he achieved a beautiful
	transcendental form and, free at last of all obstacles, rode upon the
	carrier of Lord Vishnu, Garuda, to the Supreme abode of the Lord.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh Ramachandra, the fast day of Mohinii Ekadasi removes the
	darkest illusory attachments to material existence. There is thus no
	better fast day in all the three worlds than this.
	<hr style={{border: 'none'}} className="my-3"/>
	And He who hears and studies the glories of Mohinii Ekadasi
	achieves the merit of giving away one thousand cows in charity.
	<hr style={{border: 'none'}} className="my-3"/>
	Thus ends the narration of the glories of Vaisakha-shukla Ekadasi, or
	Mohinii Ekadasi, from the Kurma Purana.
</p>

export default e4;
