import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import ImageGallery from 'react-image-gallery'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import  './gallery.css';

function Gallery() {
	const { heading, subheading, state } = useContext(DataContext);

	const getRequiredSet = (project) => {
        let arr = state.gallery.filter(row => row.project === project)
            .sort((a, b) => Number(b.id) - Number(b.id))
		return arr.map(row => {
            return {
                original: row.image,
                thumbnail: row.image,
            }
        })
	}

    return (
        <div>
            <Header 
                headerClass="allGalleryHeader"
                pageTitle="Our Memories" 
                pageSubTitle=""
                svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
                svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
                svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
                curr = "4"
                buttonto=""
                linkto = "/"
            />

            <div className="gallery-main-header">Gallery, a glimpse of our projects</div>

            <div className="gallery-wrapper">
                {/* <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents">
                        <div className="gallery-text mr-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_3')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_3')}}></p>
                        </div>
						<div className="gallery-slider">
							<ImageGallery items={getRequiredSet('anand_utsav')} thumbnailPosition="right"/>
						</div>
                    </div>
                </div> */}
                <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents-rev">
						<div className="gallery-slider">
							<ImageGallery items={getRequiredSet('ascent_of_life')} thumbnailPosition="right"/>
						</div>
                        <div className="gallery-text ml-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_1')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_1')}}></p>
                        </div>
                    </div>
                </div>
                {/* <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents">
                        <div className="gallery-text mr-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_4')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_4')}}></p>
                        </div>
                        <div className="gallery-slider">
							<ImageGallery items={getRequiredSet('ayushman_bhav')} thumbnailPosition="right"/>
						</div>
                    </div>
                </div>
                <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents-rev">
						<div className="gallery-slider">
							<ImageGallery items={getRequiredSet('divya_shishu_ratna')} thumbnailPosition="right"/>
						</div>
                        <div className="gallery-text ml-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_7')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_7')}}></p>
                        </div>
                    </div>
                </div>
                <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents">
                        <div className="gallery-text mr-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_2')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_2')}}></p>
                        </div>
                        <div className="gallery-slider">
							<ImageGallery items={getRequiredSet('gau_ganga_gita')} thumbnailPosition="right"/>
						</div>
                    </div>
                </div>
                <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents-rev">
						<div className="gallery-slider">
							<ImageGallery items={getRequiredSet('welfare_activities')} thumbnailPosition="right"/>
						</div>
                        <div className="gallery-text ml-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_5')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_5')}}></p>
                        </div>
                    </div>
                </div> */}
                <div className="p-3 p-md-5 gallery-inner">
                    <div className="my-5 gallery-contents">
                        <div className="gallery-text mr-lg-5">
                            <h2 className="mb-4 text-center gallery-header">{heading('projects_6')}</h2>
                            <p className="text-center Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('projects_6')}}></p>
                        </div>
						<div className="gallery-slider">
							<ImageGallery items={getRequiredSet('women_empowerment')} thumbnailPosition="right"/>
						</div>
                    </div>
                </div>
            </div>

            <Footer
                svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
                svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
                svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
            />
        </div>
    )
}

export default Gallery;
