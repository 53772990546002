import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import '../fonts.css'
import './navmobile.css'
import {
	Link
} from "react-router-dom";


function NavModal(props) {

	const [isOpen, setIsOpen] = useState(props.show)

	return (
			<div className="mob-nav-slide-wrapper">
		        <div className={classNames("mob-nav-slide", props.show === true?"mob-nav-slide-expanded":"mob-nav-slide-collapsed")}>
			        <ul className="mob-menu__navigation clearfix py-3">
		                <li className="mob-menu__item mob-menu__item--full-width">
		                	<Link to="/" className="mob-menu__link mob-menu__link__single" href="/technology">Home</Link>
		                </li>

		                <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Projects
							<ul className="mob-menu__submenu clearfix">
								<li className="mob-menu__submenu-item"><Link to="/3G-Project/ShriGitaPrerna" className="mob-menu__link" href="/biomarker-assays">Shri Gita Prerna</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/AnandUtsav" className="mob-menu__link" href="/cell-based-assays">Utsavam</Link></li>
								{/* <li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/biochemical-assays">Ascent of Life</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/biomarker-assays">Ayushman Bhav</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/cell-based-assays">Body Servicing Day</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/biochemical-assays">Divya Shishu Ratna</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/cell-based-assays">'Devi' Being Women</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/" className="mob-menu__link" href="/biochemical-assays">Welfare Activities</Link></li> */}
							</ul>
		                </li>

		                {/* <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Gau, Ganga and Gita (3G)
							<ul className="mob-menu__submenu clearfix">
								<li className="mob-menu__submenu-item"><Link to="/3G-Project/Gaay" className="mob-menu__link" href="/biomarker-assays">Gau Madhuram</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/3G-Project/ShriGitaPrerna" className="mob-menu__link" href="/cell-based-assays">Shri Gita Prerna</Link></li>
								<li className="mob-menu__submenu-item"><Link to="/3G-Project/Ganga" className="mob-menu__link" href="/biochemical-assays">Ganga Protection</Link></li>
							</ul>
		                </li> */}

		                <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Magazine
							<ul className="mob-menu__submenu clearfix">
								<li className="mob-menu__submenu-item"><Link to="/PrabhuMeinTera" className="mob-menu__link" href="/videos">Prabhu Mein Tera</Link></li>
							</ul>
		                </li>

		                <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Get Involved
							<ul className="mob-menu__submenu clearfix">
								<li className="mob-menu__submenu-item"><Link to="/AboutUs" className="mob-menu__link" href="/who-we-are">Vision and Mission</Link></li>
								{/* <li className="mob-menu__submenu-item"><Link to="/Collaborate" className="mob-menu__link" href="/in-the-news/">Collaborate</Link></li> */}
								<li className="mob-menu__submenu-item"><Link to="/ContactUs" className="mob-menu__link" href="/in-the-news/">Contact Us</Link></li>
							</ul>
		                </li>

		                <li className="mob-menu__item mob-menu__item--full-width">
		                	<Link to="/Gallery" className="mob-menu__link" href="/careers">Gallery</Link>
		                </li>

		                {/* <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Related
							<ul className="mob-menu__submenu clearfix">
								<li className="mob-menu__submenu-item"><a rel="noopener noreferrer" className={classNames("mob-menu__link")} href="http://www.prernamurti.com" target="_blank">Shriji</a></li>
							</ul>
		                </li> */}

		                <li className="mob-menu__item mob-menu__item--full-width mob-menu__submenu-holder">Social Media
		                	<div className="my-3">
			    				<a href="https://www.facebook.com/satprerna"><img 
			    					className="mr-1"
				    				src={require("../images/fb.svg")}
				    				alt='' width="35px" height="35px"
				    			/></a>
			    				<a href="https://www.instagram.com/satprerna"><img 
			    					className="mx-1"
			    					src={require("../images/insta.svg")}
			    					alt='' width="35px" height="35px"
			    				/></a>
			    				<a href="https://twitter.com/Satprerna"><img 
			    					className="mx-1"
			    					src={require("../images/twitter.svg")}
			    					alt='' width="35px" height="35px"
			    				/></a>
			    				<a href="https://www.youtube.com/@satprerna"><img 
			    					className="ml-1"
			    					src={require("../images/youtube.svg")}
			    					alt='' width="35px" height="35px"
			    				/></a>
		    				</div>
		                </li>
		            </ul>
		        </div>
		    </div>
	)
}

function NavMobile(props) {

	const [isOpen, setIsOpen] = useState(false)

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<nav role="navigation" className="mobile-nav-wrapper">
		  <div id="menuToggle">
		    <input type="checkbox" checked={isOpen} onClick={handleClick} onChange={()=>{}}/>
		    <NavModal show={isOpen}/>
		    <span></span>
		    <span></span>
		    <span></span>
		    
		    <ul id="menu">
		    </ul>
		  </div>
		</nav>
	)
}

export default NavMobile;