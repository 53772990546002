import React from 'react';
import '../fonts.css'

const e1 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhisthira Maharaja said, "Oh Supreme Lord, I
	have heard from You the explanation of Amalakii
	Ekadasii which occurs during the light fortnight of the
	month of Phalguna (February-March), and now I wish
	to hear about the Ekadasi that occurs during the dark
	fortnight of the month of Chaitra (March-April). What
	is its name, Oh Lord, and what results can one attain
	by observing it?"
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Lord Sri Krishna, replied, O best of
	kings, for the benefit of everyone I shall gladly describe to you the
	glories of this Ekadasi, which is known as Papamochanii. The history
	of this Ekadasi was once narrated to the Emperor Mandhata by
	Lomasa Rishi. The reply he gave is what I am going to tell you.
	<hr style={{border: 'none'}} className="my-4"/>
	Lomasa Rishi replied, O King, The Ekadasi that occurs during the
	dark part of the month of Chaitra is named Papamochanii Ekadasi.
	For the faithful devotee it removes the influences of all sins. Purifies
	one's life of all sinful reactions, and makes a person perfectly
	virtuous.
	<hr style={{border: 'none'}} className="my-4"/>
	Now please listen to the historical account concerning this Ekadasi.
	In old times Kuber had a garden named Chitraratha. There the
	daughters of the Gandharvas (heavenly musicians) and Kinnaras
	lived. There were different types of flowers blooming in this garden
	and many sages were also present, performing their austerities and
	penances. The demigods particularly enjoyed visiting this celestial
	garden during the months of Chaitra and Vaisakha (April-May). Lord
	Indra himself, the king of heaven, was enjoying a visit there.
	<hr style={{border: 'none'}} className="my-4"/>
	A great sage named Medhavi resided in that forest. He was a
	devotee of Lord Shiva. One day one Apsara in particular,
	Manjughosha, contrived many ways to allure the exalted Muni, but
	out of great respect for the sage and feat of his power, which he had
	attained after years and years of ascetics, she would not come very
	close to him. At a spot far from the sage, she sat and began singing
	very sweetly as she played a (Veena) tamboura. Cupid himself
	became excited when he saw and heard her perform so nicely and
	smelled the fragrance of her sandal-paste . He remembered his own
	unfortunate experience with Lord Shiva and decided to take revenge
	by seducing Medhavi.
	<hr style={{border: 'none'}} className="my-4"/>
	Using the eyebrows of Manjughosha as a bow, her glances as a
	bowstring, her eyes as arrows, and her breasts as a target, Cupid
	approached Medhavi in order to tempt him to break his trance and
	his vows. In other words, Cupid engaged Manjughosha as his
	assistant, and when she looked at that powerful and attractive
	young sage, she also became agitated by lust. Seeing that he was
	highly intelligent and learned, wearing a clean white Brahmana's
	thread draped across his shoulder, holding a sannyasi's staff, and
	sitting handsomely in the ashram.
	<hr style={{border: 'none'}} className="my-4"/>
	She began to sing seductively, and the small bells of her belt and
	around her ankles, together with the bangles on her wrists,
	produced a delightful musical symphony. The sage Medhavi was
	enchanted. He understood that this beautiful young woman desired
	union with him, and at that instant Cupid increased his attraction for
	Manjughosha by releasing his powerful weapons of taste, touch,
	sight, smell, and sound.
	<hr style={{border: 'none'}} className="my-4"/>
	Slowly Manjughosha approached Medhavi, her bodily movements
	and sweet glances attracting him. She gracefully put her tamboura
	down and embraced the sage with her two arms, just as a creeper
	winds itself around a strong tree. Captivated, Medhavi gave up his
	meditation and decided to sport with her - and instantly his purity of
	heart and mind abandoned him. Forgetting even the difference
	between night and day, he went away with her to sport for a long,
	long time.
	<hr style={{border: 'none'}} className="my-4"/>
	Seeing that the young yogi's sanctity had become seriously eroded,
	Manjughosha decided to abandon him and return home. She said. "O
	great one, please permit me to return home."Medhavi replied, "But you have only just arrived, O beautiful one.
	Please stay with me at least until tomorrow."
	<hr style={{border: 'none'}} className="my-4"/>
	Fearful of the sage's yogic power, Manjughosha stayed with Medhavi
	for precisely fifty-seven years, nine months, and three days, but to
	Medhavi all this time seemed like a moment. Again she asked him,
	"Please permit me to leave."
	<hr style={{border: 'none'}} className="my-4"/>
	Medhavi replied, "O dear one, listen to me. Stay with me for one
	more night, and then you may leave tomorrow morning."
	<hr style={{border: 'none'}} className="my-4"/>
	Manjughosha was still fearful of the sage's great yogic power, but
	she forced a smile and said, "How long will it take you to finish your
	morning hymns and rituals? Please be merciful and think of all the
	time you have already spent with me.
	<hr style={{border: 'none'}} className="my-4"/>
	The sage reflected on the years he had been with Manjughosha and
	then said with great astonishment.
	<hr style={{border: 'none'}} className="my-4"/>
	Why, I have spent more than fifty-seven years with you!
	<hr style={{border: 'none'}} className="my-4"/>
	His eyes turned red and began to emanate sparks. He now regarded
	Manjughosha as death personified and the destroyer of his spiritual
	life.
	<hr style={{border: 'none'}} className="my-4"/>
	You bad woman! You have turned all the hard-earned results of my
	austerities to ashes!
	<hr style={{border: 'none'}} className="my-4"/>
	Trembling with anger, he cursed Manjughosha,
	Oh sinful one, Oh hard-hearted, degraded one! You know only sin!
	May all terrible fortune by yours! Oh rascal woman, I curse you to
	become an evil hobgoblin – pishacha (hobgoblin)!
	<hr style={{border: 'none'}} className="my-4"/>
	Cursed by the sage Medhavi, the beautiful Manjughosha humbly
	beseeched him, "Oh best of the Brahmanas, please be merciful to me
	and revoke your curse! I have been with you for fifty-seven years,
	Oh master, so please be kind to me!
	<hr style={{border: 'none'}} className="my-4"/>
	Medhavi Muni replied, Oh gentle lady what can I possibly do? You
	have destroyed all my austerities. But even though you have done
	this sinful deed, I shall tell you a way you can be released from my
	wrath. In the dark fortnight of the month of Chaitra there is an all-
	auspicious Ekadasi that removes all one's sins. Its name is
	Papamochanii, Oh beautiful one, and whoever fasts on this sacred
	day becomes completely freed from having to take birth in any kind
	of devilish form.
	<hr style={{border: 'none'}} className="my-4"/>
	With these words, the sage left at once for his father's Ashram.
	Seeing him enter the hermitage, Chyavana Muni said, "Oh son, by
	acting unlawfully you have squandered the wealth of your penance
	and austerities."
	<hr style={{border: 'none'}} className="my-4"/>
	Medhavi replied, Oh Father, kindly reveal what atonement I must
	perform to remove the obnoxious sin I have incurred by privately
	associating with the dancing girl Manjughosha.
	<hr style={{border: 'none'}} className="my-4"/>
	Chyavana Muni answered, Dear son, you must fast on Papamochanii
	Ekadasi, which occurs during the dark fortnight of the month of
	Chaitra. It eradicates all sins, no matter how grievous they may be.
	<hr style={{border: 'none'}} className="my-4"/>
	Medhavi followed his father's advice and fasted on Papamochanii
	Ekadasi. Thus all his sins were destroyed and he again became filled
	with excellent merit. Similarly. Manjughosha observed the same fast
	and became free of the hobgoblin curse. Ascending once again to the
	heavenly spheres, she too returned to her former position.
	<hr style={{border: 'none'}} className="my-4"/>
	Lomasha Rishi continued, Thus, Oh king, the great benefit of fasting
	on Papamochanii Ekadasi is that whoever does so with faith and
	devotion will have all his sins completely destroyed.
	<hr style={{border: 'none'}} className="my-4"/>
	Whoever reads or hears about Papamochanii Ekadasi obtains the
	very same merit he would get if he donated a thousand cows in
	charity, and he also nullifies the sinful reactions he may have
	incurred by killing a Brahman, killing an embryo through abortion,
	drinking liquor, or having sex with his guru's wife, etc. all such sins
	are destroyed and heaven is attained.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Chaitra-Krishna Ekadasi, or
	Papamochaniii Ekadasi, from the Bhavishya-uttara Purana.
</p>

export default e1;
