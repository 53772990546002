import React from 'react';
import '../fonts.css'

const e16 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Lord Brahma said to Narada Muni, Dear son, O best of
	the sages, I shall narrate to you the glories of
	Haribodhini Ekadasi, which eradicates all kinds of sins
	and bestows great merit, and ultimately liberation,
	upon the wise persons who surrender unto the
	Supreme Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	O best of the Brahmanas, the merits acquired by
	bathing in the Ganges remain significant only as long
	as Haribodhini Ekadasi does not come. This Ekadasi, which occurs
	during the light fortnight of the month of Kartika, is much more
	purifying than a bath in the ocean, at a place of pilgrimage, or in a
	lake. This sacred Ekadasi is more powerful in nullifying sin than one
	thousand Asvamedha sacrifices and one hundred Rajasuya
	sacrifices.
	<hr style={{border: 'none'}} className="my-4"/>
	Narada Muni inquired O father, please describe the relative merits of
	fasting completely on Ekadasi, eating supper (without grains or
	beans), or eating once at midday (without grains or beans).
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Brahma replied, If a person eats once at midday on Ekadasi, the
	sins of his previous birth are erased, if he eats supper, the sins
	acquired during his previous two births are removed, and if he fasts
	completely, the sins accumulated during his pervious seven births
	are eradicated.
	<hr style={{border: 'none'}} className="my-4"/>
	O son, whatever is only rarely achieved within the three worlds is
	obtained by him who strictly observes Haribodhini Ekadasi. A person
	whose sins equal Mount Sumeru in volume sees them all reduced to
	nothing if he simply fasts on Papaharini Ekadasi (another name for
	Haribodhini Ekadasi). The sins a person has accumulated over a
	thousand previous births are burned to ashes if he not only fasts but
	also remains awake throughout Ekadasi night, just as a mountain of
	cotton can be burned to ashes if one lights a small fire in it.
	<hr style={{border: 'none'}} className="my-4"/>
	O Narada, a person who strictly observes this fast achieves the
	results I have mentioned. Even if one does a small amount of pious
	activity on this day, following the rules and regulations, one will
	earn merit to Mount Sumeru in volume; however a person who does
	not follow the rules and regulations given in the Scriptures may
	perform pious activity equal to Mount Sumeru in volume, but he will
	not earn even a small amount of merit. One who does not chant the
	Gayatri mantra three times a day, who disregards fast days, who
	does not believe in God, who criticizes the Vedic Scriptures, who
	thinks the Vedas bring only ruination to one who follows their
	injunctions, who enjoys another's wife, who is utterly foolish and
	wicked, who does not appreciate any service that has been rendered
	to him, or who cheats others - such a sinful person can never
	perform any religious activity effectively. Be he a Brahman or a
	shudra, whoever tries to enjoy another man's wife, particularly the
	wife of a twice-born person, is said to be no better than a dog-eater.
	<hr style={{border: 'none'}} className="my-4"/>
	O best of the sages, any Brahman who enjoys sex with a widow or a
	Brahman lady married to another man brings ruin to himself and his
	family. Any Brahman who enjoys illicit sex will have no children in
	his next life, and any past merit he may have earned is ruined.
	Indeed, if such a person displays any arrogance toward a twice-born
	Brahman or a spiritual master, he loses all his spiritual advancement
	immediately, as well as his wealth and children.
	<hr style={{border: 'none'}} className="my-4"/>
	These three kinds of men ruin their acquired merits: he whose
	character is immoral, he who has sex with the wife of a dog-eater,
	and he who appreciates the association of rogues. Whoever
	associates with sinful people and visits their homes without a
	spiritual purpose will go directly to the abode of Lord Yamaraj, the
	superintendent of death. And if someone eats in such a home, his
	acquired merit is destroyed, along with his fame, duration of life,
	children, and happiness.
	<hr style={{border: 'none'}} className="my-4"/>
	Any sinful person who insults a saintly person soon loses his
	religiosity, economic development, and sense gratification, and he at
	last burns in the fire of hell. Anyone who likes to offend saintly
	persons, or who does not interrupt someone who is insulting saintly
	persons, is considered no better than an ass. Such a wicked man
	sees his dynasty destroyed before his very eyes.
	<hr style={{border: 'none'}} className="my-4"/>
	A person whose character is unclean, who is a rogue or a swindler,
	or who always finds fault with others does not achieve a higher
	destination after death, even if he gives charity generously or
	performs other pious deed. Therefore one should refrain from
	performing inauspicious acts and perform only pious ones, by which
	one will acquire merit and avoid suffering. However, the sins of one
	who, after due consideration, decides to fast of Haribodhini Ekadasi
	are erased from one hundred previous lives, and whoever fasts and
	remains awake overnight on this Ekadasi achieves unlimited merit
	and after death goes to the supreme abode of Lord Vishnu, and then
	thousand of his ancestors, relatives, and descendants also reach that
	abode. Even if one's forefathers were implicated in many sins and
	are suffering in hell, they still attain beautifully ornamented spiritual
	bodies and happily go to Vishnu's abode.
	<hr style={{border: 'none'}} className="my-4"/>
	O Narada, even one who has committed the heinous sin of killing a
	Brahman is freed of all stains on his character by faster on
	Haribodhini Ekadasi and remaining awake that night. The merit that
	cannot by won by bathing in all the places of pilgrimage, performing
	a horse sacrifice, or giving cows, gold, or fertile land in charity can
	easily be achieved by fasting on this holy day and remaining awake
	throughout the night.
	<hr style={{border: 'none'}} className="my-4"/>
	Anyone who observes Haribodhini Ekadasi is celebrated as highly
	qualified and makes his dynasty famous. As Death is certain, so
	losing one's wealth is also certain. Knowing this, O best of sages,
	one should observe a fast on this day so dear to Hari - Sri
	Haribodhini Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	All places of pilgrimage in the three worlds at once come to reside in
	the house of a person who fasts on this Ekadasi. Therefore, to please
	the Lord, who holds a disc in His hand, one should give up all
	engagements, surrender, and observe this Ekadasi fast. One who
	fasts on this Haribodhini day is acknowledged as a wise man, a true
	yogi, an ascetic, and one whose senses are truly under control. He
	alone enjoys this world properly, and he will certainly achieve
	liberation. This Ekadasi is very dear to Lord Vishnu, and thus it is the
	very essence of religiosity. Even one observance of it bestows the
	topmost reward in all the three worlds.
	<hr style={{border: 'none'}} className="my-4"/>
	O Naradaji, whoever fasts on this Ekadasi will definitely not enter a
	womb again, and thus faithful devotees of the Supreme Godhead
	give up all varieties of religion and simply surrender to fasting on
	this Ekadasi. For that great soul who honors this Ekadasi by fasting
	and remaining awake throughout the night, the Supreme Lord, Sri
	Govinda, personally terminates the sinful reactions that soul has
	acquired by the actions of his mind, body, and words.
	<hr style={{border: 'none'}} className="my-4"/>
	O son, for anyone who bathes in a place of pilgrimage, gives charity,
	chants the holy names of the Supreme Lord, undergoes austerities,
	and performs sacrifices for God on Haribodhini Ekadasi, the merit
	thus earned all becomes imperishable. A devotee who worships Lord
	Madhava on this day with first-class paraphernalia becomes free
	from the great sins of a hundred lifetimes. A person who observes
	this fast and worships Lord Vishnu properly is freed from great
	danger.
	<hr style={{border: 'none'}} className="my-4"/>
	This Ekadasi fast pleases Lord Janardana so much that He takes the
	person who observes it back to His abode, and while going there the
	devotee illuminates then ten universal directions. Whoever desires
	beauty and happiness should try to honor Haribodhini Ekadasi,
	especially if it falls on Dvadasi. The sins of one's past hundred births
	- the sins committed during childhood, youth and old age in al those
	lifetimes, whether those sins are dry or wet - are nullified by the
	Supreme Lord Govinda if one fasts on Haribodhini Ekadasi with
	devotion. Haribodhini Ekadasi is the best Ekadasi. Nothing is
	unobtainable or rare in this world for one who fasts on this day, for
	it gives food grains, great wealth, and high merit, as well as
	eradication of all sin, the terrible obstacle to liberation. fasting on
	this Ekadasi is a thousand times better than giving charity on the
	day of the solar or lunar eclipse. Again I say to you, O Naradaji,
	whatever merit is earned by one who bathes in a place of pilgrimage,
	performs sacrifices, and studies the Vedas is only one then-millionth
	the merit earned by the person who fasts but one on Haribodhini
	Ekadasi. Whatever merit one has acquired in his life by some pious
	activities becomes completely fruitless if one does not observe the
	Ekadasi fast and worship Lord Vishnu in the month of Kartika.
	Therefore, you should always worship the Supreme Lord, Janardana,
	and render service to Him. Thus you will attain the desired goal, the
	highest perfection.
	<hr style={{border: 'none'}} className="my-4"/>
	On Haribodhini Ekadasi, a devotee of the Lord should not eat in
	another's house or eat food cooked by a non devotee. If he does so,
	he achieves only the merit of fasting on a full moon day.
	Philosophical discussion of Scriptures in the month of Kartika
	pleases Sri Vishnu more than if one gives elephants and horses in
	charity or performs a costly sacrifice. Whoever chants or hears
	descriptions of Lord Vishnu's qualities and pastimes, even if but a
	half or even a fourth of a verse, attains the wonderful merit derived
	from giving away a hundred cows to a Brahman. O Narada, during
	the month of Kartika one should give up all kinds or ordinary duties
	and devote one's full time and energy especially while fasting, to
	discussing the transcendental pastimes of the Supreme Lord. Such
	glorification of Sri Hari on the day so dear to the Lord, Ekadasi,
	liberates a hundred previous generations. One who spends his time
	enjoying such discussions, especially in the month of Kartika,
	achieves the results of performing ten thousand fire sacrifices and
	burns all his sins to ashes.
	<hr style={{border: 'none'}} className="my-4"/>
	He who hears the wonderful narrations concerning Lord Vishnu,
	particularly during the month of Kartika, automatically earns the
	same merit as that bestowed upon someone who donates a hundred
	cows in charity. O great sage, a person who chants the glories of
	Lord Hari on Ekadasi achieves the merit earned by donating seven
	islands.
	<hr style={{border: 'none'}} className="my-4"/>
	Narada Muni asked his glorious father, O universal sire, best of all
	demigods, please tell me how to observe this most sacred Ekadasi.
	What kind of merit does it bestow upon the faithful.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Brahma replied, O son, a person who wants to observe this
	Ekadasi should rise early on Ekadasi morning, during the Brahma-
	muhurta hour (an hour and a half before sunrise until fifty minutes
	before sunrise). He should then clean his teeth and bathe in a lake,
	river, pond, or well, or in his own home, as the situation warrants.
	After worshipping Lord Sri Keshava, he should listen carefully to the
	sacred descriptions of the Lord. He should pray to the Lord thus: O
	Lord Keshava, I shall fast on this day, which is so dear to You, and
	tomorrow I shall honor Your sacred prasadam. O lotus-eyed Lord, O
	infallible one; You are my only shelter. Kindly protect me.’
	<hr style={{border: 'none'}} className="my-4"/>
	Having spoken this solemn prayer before the Lord with great love
	and devotion, one should fast cheerfully. O Narada, whoever remains
	awake all night on this Ekadasi, singing beautiful songs glorifying
	the Lord, dancing in ecstasy, playing delightful instrumental music
	for His transcendental pleasure, and reciting the pastimes of Lord
	Krishna as records in bona-fide Vedic literature - such a person will
	most assuredly reside far beyond the three worlds, in the eternal,
	spiritual realm of God.
	<hr style={{border: 'none'}} className="my-4"/>
	On Haribodhini Ekadasi one should worship Sri Krishna with
	camphor, fruits, and aromatic flowers, especially the yellow agaru
	flower. One should not absorb oneself in making money on this
	important day. In other words, greed should be exchanged for
	charity. This is the process for turning loss into unlimited merit. One
	should offer many kinds of fruits to the Lord and bathe Him with
	water from a conchshell. Each of these devotional practices, when
	performed on Haribodhini Ekadasi, is ten million times more
	beneficial than bathing in all the places of pilgrimage and giving all
	forms of charity.
	<hr style={{border: 'none'}} className="my-4"/>
	Even Lord Indra joins his palm and offers his obeisances to a
	devotee who worships Lord Janardana with first-class agastya
	flowers of this day. The supreme Lord Hari is very pleased when he
	is decorated with nice agastya flowers. O Narada, I give liberation to
	one who devotedly worships Lord Krishna on this Ekadasi in the
	month of Kartika with leaves of the bel tree. And for one who
	worships Lord Janardana with fresh tulasi leaves and fragrant
	flowers during this month, O son, I personally burn to ashes all the
	sins he has committed for then thousand births.
	<hr style={{border: 'none'}} className="my-4"/>
	One who merely sees Tulasi Maharani, touches her, mediates on her,
	narrates her history, offers obeisances to her, prays to her for her
	grace, plants her, worships her, or waters her lives in the abode of
	Lord Hari eternally. O Narada, one who serves Tulasi-devi in these
	nine ways achieves happiness in the higher world for as many
	thousands of yugas as there are roots and subroots growing from a
	mature tulasi plant. When a full grown tulasi plant produces seeds,
	many plants grow from those seeds and spread their branches,
	twigs, and flowers, and these flowers also produce numerous seeds.
	For as many thousands of kalpas as there are seeds produced in this
	way, the forefathers of one who serves tulasi in these nine ways will
	live in the abode of Lord Hari.
	<hr style={{border: 'none'}} className="my-4"/>
	Those who worship Lord Keshava with kadamba flowers, which are
	very pleasing to Him, get his mercy and do not see the abode of
	Yamaraj, death personified. What is the use of worshipping someone
	else if all desires can be fulfilled by pleasing Lord Hari? For example,
	a devotee who offers Him bakula, ashoka, and patali flowers is freed
	from misery and distress for as long as the sun and moon exist in
	this universe, and at last he achieves liberation. O best of the
	Brahmanas, an offering of kannera flowers to Lord Jagannatha
	brings as much mercy upon the devotee as that earned by
	worshipping Lord Keshava for four yugas. One who offers tulasi
	flowers (manjaris) to Sri Krishna during the month of Kartika
	receives more merit than can be obtained by donating ten million
	cows. Even a devotional offering of newly grown sprouts of grass
	brings with it a hundred times the benefit obtained by ordinary
	ritualistic worship of the Supreme Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	One who worships Lord Vishnu with the leaves of the samika tree is
	freed from the clutches of Yamaraja, the lord of death. One who
	worships Vishnu during the rainy season with champaka or jasmine
	flowers never returns to the planet earth again. One who worships
	the Lord with but a single kumbhi flower achieves the boon of
	donating a pala of gold (two hundred grams). If a devotee offers a
	single yellow flower of the ketaki, or wood-apple, tree to Lord
	Vishnu, who rides on Garuda, he is freed from the sins of ten million
	births. Furthermore, one who offers Lord Jagannatha flowers and
	also a hundred leaves anointed with red and yellow sandalwood
	paste will certainly come to reside in Svetadvipa, far beyond the
	coverings of this material creation.
	<hr style={{border: 'none'}} className="my-4"/>
	O greatest of Brahmanas, Sri Narada, after thus worshipping Lord
	Keshava, the bestower of all material and spiritual happiness, on
	Haribodhini Ekadasi, one should rise early the next day, bathe in a
	river, chant japa of Krishna's holy names, and render loving
	devotional service to the Lord at home to the best of one's ability. To
	break the fast, the devotee should first offer some prasadam to
	Brahmanas and only then, with their permission, eat some grains.
	Thereafter, to please the Supreme Lord, the devotee should worship
	his spiritual master, the purest of the Lord's devotees, and offer him
	sumptuous food, nice cloth, gold, and cows, according to the
	devotee's means. This will certainly please the Supreme Lord, the
	holder of the disc.
	<hr style={{border: 'none'}} className="my-4"/>
	Next the devotee should donate a cow to a Brahman, and if the
	devotee has neglected some rules and regulation of spiritual life, he
	should confess them before Brahman devotees of the Lord. Then the
	devotee should offer them some dakshina (money). O king, those
	who have eaten supper on Ekadasi should feed a Brahman the next
	day. That is very pleasing to the Supreme Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	O son, if a man has fasted without asking the permission of his
	priest, or if a woman has fasted without asking her husband's
	permission, he or she should donate a bull to a Brahman. Honey and
	yogurt are also proper gifts for a Brahman. Someone who has fasted
	from ghee should donate milk, one who has fasted from grains
	should donate rice, one who has slept on the floor should donate a
	bedstead with a quilt, one who has eaten on a leaf plate should
	donate a pot of ghee, one who has remained silent should donate a
	bell, and one who has fasted from sesame should give gold in charity
	and feed a Brahman couple with sumptuous food. A man who wants
	to prevent baldness should donate a mirror to a Brahman, one who
	has second-hand shoes should donate shoes, and one who has
	fasted from salt should donate some sugar to a Brahman. During this
	month everyone should regularly offer a ghee lamp to Lord Vishnu or
	to Srimati Tulasidevi in a temple.
	<hr style={{border: 'none'}} className="my-4"/>
	An Ekadasi fast is complete when one offers a qualified Brahman a
	gold or copper pot filled with ghee and ghee wicks, along with eight
	waterpots containing some gold and covered by cloths. One who
	cannot afford these gifts should at least offer a Brahman some sweet
	words. One who does so will surely attain the full benefit of fasting
	on Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	After offering his obeisance and begging permission, the devotee
	should eat his meal. On this Ekadasi, Chaturmasya ends, so
	whatever one gave up during Chaturmasya should now be donated
	to Brahmanas. One who follows this process of Chaturmasya
	receives unlimited merit, O king of kings, and goes to the abode of
	Lord Vasudeva after death. O king, anyone who observes the
	complete Chaturmasya without a break attains eternal happiness
	and does not receive another birth. But if someone breaks the fast,
	he becomes either a blind man or a leper.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus I have narrated to you the complete process for observing
	Haribodhini Ekadasi. Someone who reads or hears about this
	achieves the merit earned by donating cows to a qualified Brahman.
	Thus ends the narration of the glories of Karttika-sukla Ekadasi
	- also known as Haribodhini Ekadasi or Devotthaani Ekadasi - from the
	Skanda Purana.
</p>

export default e16;
