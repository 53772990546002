import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import WomenEmpPage from '../../Pages/WomenEmp.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function WomenEmp() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Power of Women" {...a11yProps(3)} />
                <Tab label="Article" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <WomenEmpPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="wemp_header" imageKeys={["/images/empowerment/top-ban1.jpg", "/images/empowerment/top-ban1-mob.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="wemp_c1_p1" imageKeys={["/images/empowerment/ban1.jpg", "/images/empowerment/ban2.jpg", "/images/empowerment/ban3.jpg"]} heading subheading/>
                <EditCard title="CAROUSEL 2" textKey="wemp_c2_p1" imageKeys={["/images/empowerment/ban3.jpg", "/images/empowerment/ban1.jpg", "/images/empowerment/ban2.jpg"]} heading subheading/>    
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="POWER OF WOMEN" textKey="wemp_pow" imageKeys={["/images/empowerment/back1.jpg"]} heading subheading />
                <EditCard title="PART 1" textKey="wemp_pow_1" heading subheading paragraph titleP="Youtube link 1"/>
                <EditCard title="PART 2" textKey="wemp_pow_2" heading subheading paragraph titleP="Youtube link 2"/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="ARTICLE" textKey="wemp_art" imageKeys={["/images/empowerment/wemp-graphic.png", "/images/empowerment/wemp-pic2.jpg"]} heading subheading paragraph titleS="Paragraph 1" titleP="Paragraph 2"/>
            </TabPanel>
        </div>
    );
}
