import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import PmtPage from '../../Pages/Pmt.js'
import PmtTable from '../Editables/PmtTable.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Pmt() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Main Video" {...a11yProps(2)} />
                <Tab label="Price" {...a11yProps(3)} />
                <Tab label="Latest Issue" {...a11yProps(4)} />
                <Tab label="Old Archives" {...a11yProps(5)} />
                <Tab label="Articles" {...a11yProps(6)} />
                <Tab label="PMT Form" {...a11yProps(7)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <PmtPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="pmt_header" imageKeys={["/images/pmt/headermob.jpg", "/images/pmt/header5.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="PROMOTIONAL VIDEO" textKey="pmt_price_4" paragraph titleP="Youtube Video Link"/>
                <EditCard title="WHAT WILL YOU GET" textKey="pmt_vdo" heading subheading paragraph/>
                <EditCard title="" textKey="pmt_vdo_1" paragraph titleP="Point 1"/>
                <EditCard title="" textKey="pmt_vdo_2" paragraph titleP="Point 2"/>
                <EditCard title="" textKey="pmt_vdo_3" paragraph titleP="Point 3"/>
                <EditCard title="" textKey="pmt_vdo_4" paragraph titleP="Point 4"/>
                <EditCard title="" textKey="pmt_vdo_5" paragraph titleP="Point 5"/>
                <EditCard title="" textKey="pmt_vdo_6" paragraph titleP="Point 6"/>
                <EditCard title="" textKey="pmt_vdo_7" paragraph titleP="Point 7"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="1 YEAR" textKey="pmt_price_1" heading subheading paragraph titleH="Years" titleS="Print Magazine" titleP="E-Magazine"/>
                <EditCard title="2 YEAR" textKey="pmt_price_2" heading subheading paragraph titleH="Years" titleS="Print Magazine" titleP="E-Magazine"/>
                <EditCard title="5 YEAR" textKey="pmt_price_3" heading subheading paragraph titleH="Years" titleS="Print Magazine" titleP="E-Magazine"/>
                <EditCard title="1 YEAR (OUT OF INDIA)" textKey="pmt_price_4" heading subheading titleH="Years" titleS="Magazine"/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="LATEST ISSUE" textKey="pmt_latest" imageKeys={["/images/pmt/covers_sm/recent.jpg"]} heading subheading paragraph titleH="Month" titleS="Languages" titleP="Other Detail"/>
                <EditCard title="FREE HIGHTLIGHTS LINKS" textKey="free_highlights" heading subheading paragraph titleH="Hindi" titleS="Gujarati" titleP="Marathi"/>
                <EditCard title="SHRI SHARNAM" textKey="pmt_latest_1" subheading/>
                <EditCard title="KATHAMRITUM" textKey="pmt_latest_2" subheading/>
                <EditCard title="SHRI VACHANAMRITUM" textKey="pmt_latest_3" subheading/>
                <EditCard title="SATPRERNA UTSAVAM" textKey="pmt_latest_4" subheading/>
                <EditCard title="AROGYAM" textKey="pmt_latest_5" subheading/>
                <EditCard title="KAVYAM" textKey="pmt_latest_6" subheading/>
            </TabPanel>
            <TabPanel value={value} index={5} preview={false}>
                <EditCard title="ARCHIVES" textKey="pmt_arch" heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={6} preview={false}>
                <EditCard title="ARTICLES" textKey="pmt_artc" heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={7} preview={false}>
                <div style={{padding: '30px'}} ><PmtTable /></div>
            </TabPanel>
        </div>
    );
}
