import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import classNames from 'classnames';
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import SliderLeft from '../Components2/SliderLeft.js'
import SliderRight from '../Components2/SliderRight.js'
import CardB from '../Components/CardB.js'
import '../fonts.css'

import e1 from '../images/ekadashi/26Ekadashis/small/e1.jpg'
import e2 from '../images/ekadashi/26Ekadashis/small/e2.jpg'
import e3 from '../images/ekadashi/26Ekadashis/small/e3.jpg'
import e4 from '../images/ekadashi/26Ekadashis/small/e4.jpg'
import e5 from '../images/ekadashi/26Ekadashis/small/e5.jpg'
import e6 from '../images/ekadashi/26Ekadashis/small/e6.jpg'
import e7 from '../images/ekadashi/26Ekadashis/small/e7.jpg'
import e8 from '../images/ekadashi/26Ekadashis/small/e8.jpg'
import e9 from '../images/ekadashi/26Ekadashis/small/e9.jpg'
import e10 from '../images/ekadashi/26Ekadashis/small/e10.jpg'
import e11 from '../images/ekadashi/26Ekadashis/small/e11.jpg'
import e12 from '../images/ekadashi/26Ekadashis/small/e12.jpg'
import e13 from '../images/ekadashi/26Ekadashis/small/e13.jpg'
import e14 from '../images/ekadashi/26Ekadashis/small/e14.jpg'
import e15 from '../images/ekadashi/26Ekadashis/small/e15.jpg'
import e16 from '../images/ekadashi/26Ekadashis/small/e16.jpg'
import e17 from '../images/ekadashi/26Ekadashis/small/e17.jpg'
import e18 from '../images/ekadashi/26Ekadashis/small/e18.jpg'
import e19 from '../images/ekadashi/26Ekadashis/small/e19.jpg'
import e20 from '../images/ekadashi/26Ekadashis/small/e20.jpg'
import e21 from '../images/ekadashi/26Ekadashis/small/e21.jpg'
import e22 from '../images/ekadashi/26Ekadashis/small/e22.jpg'
import e23 from '../images/ekadashi/26Ekadashis/small/e23.jpg'
import e24 from '../images/ekadashi/26Ekadashis/small/e24.jpg'
import e25 from '../images/ekadashi/26Ekadashis/small/e25.jpg'
import e26 from '../images/ekadashi/26Ekadashis/small/e26.jpg'

import t1 from '../Blogs/e1.js'
import t2 from '../Blogs/e2.js'
import t3 from '../Blogs/e3.js'
import t4 from '../Blogs/e4.js'
import t5 from '../Blogs/e5.js'
import t6 from '../Blogs/e6.js'
import t7 from '../Blogs/e7.js'
import t8 from '../Blogs/e8.js'
import t9 from '../Blogs/e9.js'
import t10 from '../Blogs/e10.js'
import t11 from '../Blogs/e11.js'
import t12 from '../Blogs/e12.js'
import t13 from '../Blogs/e13.js'
import t14 from '../Blogs/e14.js'
import t15 from '../Blogs/e15.js'
import t16 from '../Blogs/e16.js'
import t17 from '../Blogs/e17.js'
import t18 from '../Blogs/e18.js'
import t19 from '../Blogs/e19.js'
import t20 from '../Blogs/e20.js'
import t21 from '../Blogs/e21.js'
import t22 from '../Blogs/e22.js'
import t23 from '../Blogs/e23.js'
import t24 from '../Blogs/e24.js'
import t25 from '../Blogs/e25.js'
import t26 from '../Blogs/e26.js'

function Ekadashi(props) {
	const { heading, subheading, state } = useContext(DataContext);

	var hovered = false;
	const toggleHover = () => hovered=!hovered;

	return(
		<div>
			<Header 
				headerClass="allEkadashiHeader"
				pageTitle={heading('bsd_header')}
				pageSubTitle={subheading('bsd_header')}
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>
			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeft 
					img1="https://satprerna.org/satprerna_backend/images/ekadashi/img5.jpeg"
					img2="https://satprerna.org/satprerna_backend/images/ekadashi/img6.jpeg"
					img3="https://satprerna.org/satprerna_backend/images/ekadashi/img7.jpeg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
			    		<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('bsd_c1_p1')}</h2>
					    <p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('bsd_c1_p1')}}></p>
					</aside>
		    	</div>
		    </div>

		    <div className="content-ekadashi-left-slider-wrapper content-ekadashi-right-slider-wrapper-display py-5">
		    	<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pr-xl-5">
					<aside>	
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('bsd_c2_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('bsd_c2_p1')}}></p>
					</aside>
				</div>
				<SliderRight
					img1="https://satprerna.org/satprerna_backend/images/ekadashi/img6.jpeg"
					img2="https://satprerna.org/satprerna_backend/images/ekadashi/img7.jpeg"
					img3="https://satprerna.org/satprerna_backend/images/ekadashi/img5.jpeg"
				/>
			</div>

			<div className="eleven-benefits">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: 'white'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: 'white'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: 'white'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className={classNames("benefits-container py-5", hovered ? "":"benefits-container-unhovered")} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('bsd_do1_get11_1')}</h2>
					<p className="Subtitle-2regular-26ptleftdark-kulimpark py-4 text-justify">{subheading('bsd_do1_get11_1')}</p>
					<ul className="px-2 px-sm-3">
						{state.home_text.map(row => {
							return (row.name.startsWith('bsd_do1_get11')) ? <li className="Bodytext-1regular-16ptleftdark-lato">{row.paragraph}</li> : undefined;
						})}
					</ul>
				</div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>


			<div className="ekadashi-types-main">
				<div className="ekadashi-types-header">
					<h2 className="text-center Subheading-1bold-36ptleftdark-lato">{heading('bsd_26')}</h2>
				</div>
				<div className="ekadashi-types-subheader mx-auto">
					<h3 className="Bodytext-1regular-16ptcenterdark-lato pt-4 mx-4 text-justify">{subheading('bsd_26')}</h3> 
				</div>
				<div className="ekadashi-types-card-wrapper-outer my-5">
					<div className={classNames("ekadashi-types-card-wrapper")}>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e1.jpg")}
							name="Papmochani Ekadashi"
							subname="Chaitra-Krishna Ekadashi"
							title="Papmochani Ekadashi"
							subtitle="Chaitra-Krishna Ekadashi (March-April)"
							banner={e1}
							file={t1}
							video={"https://www.youtube.com/embed/ekdHjeuo0ak"}
							content={<p style={{display: 'inline'}}>Sri Yudhisthira Maharaja said, "Oh Supreme Lord, I
							have heard from You the explanation of Amalakii
							Ekadasii which occurs during the light fortnight of the
							month of Phalguna (February-March), and now I wish
							to hear about the Ekadasi that occurs during the <b>dark
							fortnight</b> of the month of <b>Chaitra (March-April).</b> What
							is its name, Oh Lord, and what results can one attain
							by observing it?"
							<br/>
							The Supreme Lord, Lord Sri Krishna, replied</p>/*1*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e2.jpg")}
							name="Kamada Ekadashi"
							subname="Chaitra-Shukla Ekadashi"
							title="Kamada Ekadashi"
							subtitle="Chaitra-Shukla Ekadashi (March-April)"
							banner={e2}
							file={t2}
							video={"https://www.youtube.com/embed/zwtIRU1UUNk"}
							content={<p style={{display: 'inline'}}>Yudhishthira Maharaja said, Oh Lord Krishna, Oh Vasudeva, please
							accept my humble obeisance. Please describe to me the Ekadasi that
							occurs during the <b>lightpart</b> of the month of <b>Chaitra [March-April].</b> What is its
							name, and what are its glories?'
							<br/>
							Lord Sri Krishna replied, Oh Yudhishthira, please listen
							to Me attentively as I relate the ancient history of this
							sacred Ekadasi, a history Vasishtha Muni once related
							to King Dilipa.</p>/*2*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e3.jpg")}
							name="Varuthini Ekadashi"
							subname="Vaisakha-Krishna Ekadashi"
							title="Varuthini Ekadashi"
							subtitle="Vaisakha-Krishna Ekadashi (April-May)"
							banner={e3}
							file={t3}
							video={"https://www.youtube.com/embed/4loZ1tbph1o"}
							content={<p style={{display: 'inline'}}>Sri Yudhisthira Maharaj said, Oh Vasudeva, I offer
							my most humble obeisances unto You. Please now
							describe to me the Ekadasi of the <b>dark fortnight</b> (krishna paksha)
							of the month of <b>Vaisakha (April-
							May),</b> including its specific merits and influence.
							<br/>
							Lord Sri Krishna replied, "Oh King, in this world and
							the next, the most auspicious and magnanimous
							Ekadasi is Varuthini Ekadasi, which occurs during
							the dark fortnight of the month of Vaisakha.</p>/*3*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e4.jpg")}
							name="Mohini Ekadashi"
							subname="Vaisakha-Shukla Ekadashi"
							title="Mohini Ekadashi"
							subtitle="Vaisakha-Shukla Ekadashi (April-May)"
							banner={e4}
							file={t4}
							video={"https://www.youtube.com/embed/U2Y0t_Zyc8E"}
							content={<p style={{display: 'inline'}}>Sri Yudhisthira Maharaja said, Oh Janardana, what is
							the name of the Ekadasi that occurs during the <b>light
							fortnight</b> (shukla paksha) of the month of <b>Vaisakha
							(April-May)</b> what is the process for observing it
							properly? Kindly narrate all of these details to me.
							<br/>
							The Supreme Lord, Lord Sri Krishna replied, Oh
							blessed son of Dharma, what Vasishtha Muni oncetold to Lord Ramachandra I shall now describe to you. Please hear
							Me attentively.</p>/*4*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e5.jpg")}
							name="Apara Ekadashi"
							subname="Jyeshtha-Krishna Ekadashi"
							title="Apara Ekadashi"
							subtitle="Jyeshtha-Krishna Ekadashi (May-June)"
							banner={e5}
							file={t5}
							video={"https://www.youtube.com/embed/RCaMnS4ALII"}
							content={<p style={{display: 'inline'}}>Sri Yudhishthira Maharaj said, Oh Janardana, what is
							the name of the Ekadasi that occurs during the <b>dark
							fortnight</b> (Krishna paksha) of the month
							of <b>Jyeshtha (May-June)?</b> I wish to hear from You the
							glories of this sacred day of Hari. Please narrate
							everything to me.
							<br/>
							Oh, the name of this unlimitedly meritorious Ekadasi is
							Apara Ekadasi. Whoever fasts on this holy day
							becomes famous all over the universe.</p>/*5*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e6.jpg")}
							name="Nirjala Ekadashi"
							subname="Jyeshtha-Shukla Ekadashi"
							title="Nirjala Ekadashi"
							subtitle="Jyeshtha-Shukla Ekadashi (May-June)"
							banner={e6}
							file={t6}
							video={"https://www.youtube.com/embed/_4kAPdyX6YM"}
							content={<p style={{display: 'inline'}}>The Story of Pandava Nirjala Ekadasi: Pandava Nirjala Ekadasi -
							Jyeshtha-shukla Ekadasi, which occurs during the <b>light
							fortnight</b> of the month
							of <b>Jyeshtha (May-June).</b> - from Brahma-vaivarta Purana.
							<br/>
							Once Bhimasena, the younger brother of Maharaja
							Yudhisthira, asked the great sage Shrila Vyasadeva,
							the grandfather of the Pandavas, if it is possible to
							return to the spiritual world without having
							observed all the rules and regulations of the Ekadasi
							fasts.</p>/*6*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e7.jpg")}
							name="Yogini Ekadashi"
							subname="Ashadha-Krishna Ekadashi"
							title="Yogini Ekadashi"
							subtitle="Ashadha-Krishna Ekadashi (June-July)"
							banner={e7}
							file={t7}
							video={"https://www.youtube.com/embed/xzMZ3lEhPfw"}
							content={<p style={{display: 'inline'}}>Yudhisthira Maharaj said, I wish to hear from You
							about the shuddha Ekadasi that occurs during
							the <b>dark fortnight</b> of the month of <b>Ashadha (June -
							July).</b> Kindly describe to me all about it in detail.
							<br/>
							The Supreme Lord, Sri Krishna, then replied, "Oh
							king, I shall indeed tell you about the best of all
							fasting days, the Ekadasi that comes during the
							dark part of the month of Ashadha. Famous as Yogini Ekadasi...</p>/*7*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e8.jpg")}
							name="Devshayani (Padma) Ekadashi"
							subname="Ashadha-Shukla Ekadashi"
							title="Devshayani (Padma) Ekadashi"
							subtitle="Ashadha-Shukla Ekadashi (June-July)"
							banner={e8}
							file={t8}
							video={"https://www.youtube.com/embed/8SrydXFWH7k"}
							content={<p style={{display: 'inline'}}>The saintly king Yudhishthira Maharaj said, Oh
							Keshava, what is the name of that Ekadasi that occurs
							during the <b>light fortnight</b> of the month of <b>Ashadha
							(June - July)?</b> Who is the worshipable Deity for the
							auspicious day, and what is the process for observing
							this event?
							<br/>
							Lord Sri Krishna replied, Oh caretaker of this Earthly
							planet, I shall gladly tell you a wonderful historical
							event that...</p>/*8*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e9.jpg")}
							name="Kamika Ekadashi"
							subname="Shravana-Krishna Ekadashi"
							title="Kamika Ekadashi"
							subtitle="Shravana-Krishna Ekadashi (July-August)"
							banner={e9}
							file={t9}
							video={"https://www.youtube.com/embed/LqXjoAqhPzU"}
							content={<p style={{display: 'inline'}}>The son of Kunti, king Yudhisthira maharaj said, Oh
							Supreme Lord, I would like to hear from You the
							glories of the Ekadasi that occurs during the <b>dark
							fortnight</b> (Krishna paksha) of the month of <b>Shravana
							(July - August).</b> Please be merciful to me and explain
							its glories and this fast is devoted to which god.
							<br/>
							The Supreme Lord, Shri Krishna, replied, Oh King,
							please listen attentively as I describe the auspicious</p>/*9*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e10.jpg")}
							name="Putrada Ekadashi"
							subname="Shravana-Shukla Ekadashi"
							title="Putrada Ekadashi"
							subtitle="Shravana-Shukla Ekadashi (July-August)"
							banner={e10}
							file={t10}
							video={"https://www.youtube.com/embed/3Hwxk0CDyQ0"}
							content={<p style={{display: 'inline'}}>Shri Yudhisthira Maharaja said, "Oh Lord, please be
							merciful to me and describe to me the Ekadasi that
							occurs during the <b>light fortnight</b> of the month
							of <b>Shravana (July-August).</b>
							<br/>
							The Supreme Lord, Madhusudana, replied, Yes, Oh king,
							I shall happily narrate its glories to you, for just by
							hearing about this sacred Ekadasi one attains the merit
							of performing a horse sacrifice.</p>/*10*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e11.jpg")}
							name="Aja Ekadashi"
							subname="Bhadrapada-Krishna Ekadashi"
							title="Aja Ekadashi"
							subtitle="Bhadrapada-Krishna Ekadashi (August-September)"
							banner={e11}
							file={t11}
							video={"https://www.youtube.com/embed/EDeFfHb1yzA"}
							content={<p style={{display: 'inline'}}>Shri Yudhisthira Maharaja ,son of Kunti said, Oh
							Janardana, protector of all living entities, please
							tell me the name of the Ekadasi that occurs
							during the <b>dark fortnight</b> of the month
							of <b>Bhadrapada (August-September).</b>
							<br/>
							The Supreme Lord, Sri Krishna, then replied, Oh
							King, hear Me attentively. The name of this sin-
							removing, sacred Ekadasi is Aja. Any person</p>/*11*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e12.jpg")}
							name="Parivartini (Padma) Ekadashi"
							subname="Bhadrapada-Shukla Ekadashi"
							title="Parivartini (Padma) Ekadashi"
							subtitle="Bhadrapada-Shukla Ekadashi (August-September)"
							banner={e12}
							file={t12}
							video={"https://www.youtube.com/embed/nc_244mIoKY"}
							content={<p style={{display: 'inline'}}>Sri Yudhishthira Maharaja asked of Lord Sri Krishna, What is the
							name of that Ekadasi that occurs duringthe <b>light fortnight</b> (shukla paksha) of the month
							of <b>Bhadrapada (August-September)?</b> Who is the
							worshipable Deity for this Ekadasi, and what merit
							does one attain in observing it? Kindly reveal all of
							this to me my Lord.</p>/*12*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e13.jpg")}
							name="Indira Ekadashi"
							subname="Ashwin-Krishna Ekadashi"
							title="Indira Ekadashi"
							subtitle="Ashwin-Krishna Ekadashi (September-October)"
							banner={e13}
							file={t13}
							video={"https://www.youtube.com/embed/0dIFBYnsKjY"}
							content={<p style={{display: 'inline'}}>Yudhishthira Maharaj said, Oh Lord, what is the
							name of the Ekadasi that occurs during the <b>dark fortnight</b> (Krishna paksha) of the month
							of <b>Ashwin (September-October)?</b> Please describe
							its glories to me.
							<br/>
							The Supreme Lord, Lord Sri Krishna then
							replied, "This Holy day is called Indira Ekadasi.
							If a person fasts on this day, all his sins are
							eradicated and his forefathers who have fallen
							into hell are liberated.</p>/*13*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e14.jpg")}
							name="Papankusha Ekadashi"
							subname="Ashwin-Shukla Ekadashi"
							title="Papankusha Ekadashi"
							subtitle="Ashwin-Shukla Ekadashi (September-October)"
							banner={e14}
							file={t14}
							video={"https://www.youtube.com/embed/4eJz6czZ1u4"}
							content={<p style={{display: 'inline'}}>Yudhishthira maharaj said, Oh Madhusudana, what
							is the name of the Ekadasi that comes during
							the <b>light fortnight</b> of the month of <b>Ashvin (September -
							October)?</b> Please be merciful and disclose this truth
							to me.
							<br/>
							The Supreme Lord Sri Krishna replied, Oh king,
							please listen as I explain the glories of this Ekadasi -
							Papankusha Ekadasi - which removes all sins.</p>/*14*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e15.jpg")}
							name="Rama Ekadashi"
							subname="Kartik-Krishna Ekadashi"
							title="Rama Ekadashi"
							subtitle="Kartik-Krishna Ekadashi (October-November)"
							banner={e15}
							file={t15}
							video={"https://www.youtube.com/embed/RYaT1K5Vf08"}
							content={<p style={{display: 'inline'}}>Yudhisthira Maharaj said, O Janardana, O protector
							of all beings, what is the name of the Ekadasi that
							comes during the <b>dark fortnight</b> (Krishna paksha)
							of the month of <b>Kartika (October - November)?</b> Please
							impart this sacred knowledge to me.
							<br/>
							The Supreme Lord, Sri Krishna then spoke as
							follows, "O king, please listen as I narrate to you.
							The Ekadasi that occurs during the dark part of the
							month of Karttika</p>/*15*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e16.jpg")}
							name="Haribodhini (Devothaani) Ekadashi"
							subname="Kartik-Shukla Ekadashi"
							title="Haribodhini (Devothaani) Ekadashi"
							subtitle="Kartik-Shukla Ekadashi (October-November)"
							banner={e16}
							file={t16}
							video={"https://www.youtube.com/embed/H7uNVXhZO3U"}
							content={<p style={{display: 'inline'}}>Lord Brahma said to Narada Muni, Dear son, O best of
							the sages, I shall narrate to you the glories of
							Haribodhini Ekadasi, that comes during
							the <b>light fortnight</b> of the month of <b>Kartika (October - November)</b>
							and which eradicates all kinds of sins
							and bestows great merit, and ultimately liberation,
							upon the wise persons who surrender unto the
							Supreme Lord.</p>/*16*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e17.jpg")}
							name="Utpanna Ekadashi"
							subname="Margasirsha-Krishna Ekadashi"
							title="Utpanna Ekadashi"
							subtitle="Margasirsha-Krishna Ekadashi (November-December)"
							banner={e17}
							file={t17}
							video={"https://www.youtube.com/embed/n9VOePdf_34"}
							content={<p style={{display: 'inline'}}>Yudhishtra, asked the Lord, O Janardana, what are the pious
							benefits of complete fasting, eating only supper, or eating but once
							at midday on Ekadasi, and what are the regulations for observing the
							various Ekadasi days? Kindly narrate all this to me.
							<br/>
							The Supreme Lord Krishna replied, O son of Kunti, in the beginning
							of winter (northern hemisphere), on the Ekadasi that occurs during
							the <b>dark fortnight</b> of the month of <b>Margasirsha (November-
							December)</b></p>/*17*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e18.jpg")}
							name="Mokshada Ekadashi"
							subname="Margasirsha-Shukla Ekadashi"
							title="Mokshada Ekadashi"
							subtitle="Margasirsha-Shukla Ekadashi (November-December)"
							banner={e18}
							file={t18}
							video={"https://www.youtube.com/embed/PnQIuO8bFJ4"}
							content={<p style={{display: 'inline'}}>Yudhishthira Maharaj said, O Vishnu, master of all, O delight of the
							three worlds, O Lord of the entire Universe, O creator of the world, O oldest personality, O best of all beings, I offer my most respectful
							obeisances unto You.
							"O Lord of lords, for the benefit of all living entities, kindly answer
							some questions that I have. What is the name of the Ekadasi that
							occurs during the <b>light fortnight</b> of the month of <b>Margasirsha (November-
							December)</b> and removes all sins?</p>/*18*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e19.jpg")}
							name="Saphala Ekadashi"
							subname="Paush-Krishna Ekadashi"
							title="Saphala Ekadashi"
							subtitle="Pausha-Krishna Ekadashi (December-January)"
							banner={e19}
							file={t19}
							video={"https://www.youtube.com/embed/KFaPLnIkFY8"}
							content={<p style={{display: 'inline'}}>Saphala Ekadashi is an auspicious fasting day observed on the ‘ekadashi’ 
							(11th day) of the <b>dark fortnight</b> (the waning phase of moon) during the month of ‘Paush’ 
							in the traditional Hindu calendar. This ekadashi is also referred as ‘Pausa Krishna Ekadashi’. 
							It is celebrated between the months of <b>Paush (December to January).</b> 
							<br/>
							The importance of Saphala Ekadashi is mentioned as a conversation between 
							Dharamraja Yudhisthira and Lord Krishna in the ‘Brahmanda Purana’. 
							According to the Hindu scriptures</p>/*19(check)*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e20.jpg")}
							name="Vaikuntha (Putrada) Ekadashi"
							subname="Paush-Shukla Ekadashi"
							title="Vaikuntha (Putrada) Ekadashi"
							subtitle="Paush-Shukla Ekadashi (December-January)"
							banner={e20}
							file={t20}
							video={"https://www.youtube.com/embed/56A1m3R8p1k"}
							content={<p style={{display: 'inline'}}>The pious and saintly Yudhisthira Maharaj said, "Oh Lord,
							You have so nicely explained to us the wonderful glories
							of the Saphalaa Ekaadasi, which occurs during the dark
							fortnight (Krishna paksha) of the month of Pausha.
							Now please be merciful to me and
							explain to me the details of the Ekaadasi that occurs in
							the <b>light fortnight</b> (Shukla or Gaura paksha) of this month <b>Paush
							(December - January).</b> What is its name, and what Deity is to be worshipped on that sacred
							day?</p>/*20*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e21.jpg")}
							name="Shattila Ekadashi"
							subname="Magha-Krishna Ekadashi"
							title="Shattila Ekadashi"
							subtitle="Magha-Krishna Ekadashi (January-February)"
							banner={e21}
							file={t21}
							video={"https://www.youtube.com/embed/b7auS8GOHP0"}
							content={<p style={{display: 'inline'}}>The great Devarishi Naarad Muni once asked the Supreme Lord, Sri
							Krishna, Oh mighty armed Lord, Oh You who are so affectionate to
							Your loving devotees, please accept my most humble obeisances. Oh
							Yaadava, kindly tell me the result one obtains by observing Sat-tilaa
							Ekadasi, that occurs in	the <b>dark fortnight</b> of the month <b>Magh (January - February).</b>
							<br/>
							Lord Sri Krishna replied, Oh best of the twice born Brahmins, I shall
							narrate to you an account of an incident I personally witnessed.
							Long ago on earth there lived an old female brahmini</p>/*21*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e22.jpg")}
							name="Jaya Ekadashi"
							subname="Magha-Shukla Ekadashi"
							title="Jaya Ekadashi"
							subtitle="Magha-Shukla Ekadashi (January-February)"
							banner={e22}
							file={t22}
							video={"https://www.youtube.com/embed/jGu37avVMKk"}
							content={<p style={{display: 'inline'}}>Yudhishthira Maharaja said, My Lord, You have so kindly explained to me the
							auspicious day known as Sattila Ekadasi, which occurs
							during the dark fortnight (krishna paksha) of the month
							of Magha (January - February). Now please explain to
							me the Ekadasi that occurs in the <b>light fortnight</b> (shukla
							or Gaura paksha) of this month <b>Magh (January - February).</b> By what name is it
							known, and what is the process for observing it? Who is
							the presiding Deity that is to be worshipped on this
							sublime day, which is so very dear to You?</p>/*22*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e23.jpg")}
							name="Vijaya Ekadashi"
							subname="Phalguna-Krishna Ekadashi"
							title="Vijaya Ekadashi"
							subtitle="Phalguna-Krishna Ekadashi (February-March)"
							banner={e23}
							file={t23}
							video={"https://www.youtube.com/embed/KG3rrUMhwqQ"}
							content={<p style={{display: 'inline'}}>Yudhisthira Maharaj said, Oh Lord Sri Krishna, O
							glorious son of Vasudeva, please be merciful to
							me and describe the Ekadasi that occurs during
							the <b>dark fortnight</b> of the month of <b>Phalguna
							(February-March).</b>
							<br/>
							Lord Sri Krishna replied, Oh Yudhisthira, Oh king
							of kings, gladly I shall tell you about this great
							fast, known as Vijaya Ekadasi. Whoever observes
							it certainly achieves success in this life and the next. All the sins of
							one who fasts on this Ekadasi and hears its sublime glories are
							eradicated.</p>/*23*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e24.jpg")}
							name="Amalaki Ekadashi"
							subname="Phalguna-Shukla Ekadashi"
							title="Amalaki Ekadashi"
							subtitle="Phalguna-Shukla Ekadashi (February-March)"
							banner={e24}
							file={t24}
							video={"https://www.youtube.com/embed/LtXV1a767aQ"}
							content={<p style={{display: 'inline'}}>King Mandhata once said to Vasishtha Muni, "O great
							sage, kindly be merciful to me and tell me of a holy
							fast that will benefit me eternally."
							<br/>
							Vasishtha Muni replied. O king, kindly listen as I
							describe the best of all fast days, Amalakii Ekadasi,
							that occurs during the <b>light fortnight</b> of the month of <b>Phalguna
							(February-March).</b>. He who faithfully observes a fast on this Ekadasi obtains
							enormous wealth, gets free of the effects of all kinds
							of sins, and attains liberation. Fasting on this Ekadasi
							is more purifying than donating one thousand cows in
							charity to a pure Brahman.</p>/*24*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e25.jpg")}
							name="Parama Ekadashi"
							subname="Adik Maas-Krishna Ekadashi"
							title="Parama Ekadashi"
							subtitle="Adik Maas-Krishna Ekadashi"
							banner={e25}
							file={t25}
							video={"https://www.youtube.com/embed/bMSj9cjNR1I"}
							content={<p style={{display: 'inline'}}>Sri Yudhishthira Maharaja said, Oh Supreme Lord, what is the name
							and character of that Ekadasi that occurs during the <b>dark fortnight</b> (krishna
							paksha) of the extra, <b>leap year month of Purushottama.</b> Also,
							Oh master of all the universes, what is the process for
							observing it properly? Kindly narrate all of this to me?
							<br/>
							The Supreme Lord, Lord Sri Krishna, replied, Oh
							Yudhishthira, this meritorious day is called Parama
							Ekadasi. It bestows the great benediction of an
							enjoyable life and ultimate liberation</p>/*25*/}
						/>
						<CardB
							url={require("../images/ekadashi/26Ekadashis/small/e26.jpg")}
							name="Padmini Ekadashi"
							subname="Adik Maas-Shukla Ekadashi"
							title="Padmini Ekadashi"
							subtitle="Adik Maas-Shukla Ekadashi"
							banner={e26}
							file={t26}
							video={"https://www.youtube.com/embed/mLa6M_fDJpM"}
							content={<p style={{display: 'inline'}}>Yudhishthira Maharaja said, Oh Janardana, what is
							the name of the Ekadasi that occurs during the <b>light fortnight</b> (shukla
							paksha) of the extra, <b>leap year month of Purushottama?</b> How
							does one observe it properly? Please
							narrate all this to me.
							<br/>
							The Supreme Lord, Lord Sri Krishna, replied the
							meritorious Ekadasi that occurs during the light
							fortnight of the extra month of leap year is called
							Padmini. It is very auspicious. The fortunate soul
							who observes it with great determination and faith</p>/*26*/}
						/>
					</div>
				</div>
			</div>

			<Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>
		</div>
	)
}


export default Ekadashi;