/* eslint-disable jsx-a11y/anchor-has-content */
import React, {  } from 'react'
import  './contactus.css'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function Refund(props) {

	return (
		<div>
			<Header 
				headerClass="allGalleryHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "2"
				buttonto=""
				linkto = "/"
			/>
			<div className="contactus-wrapper mx-auto">
				<div className="collaborate-header">Refund Policy</div>
                <div className=" my-5">
                    <p style={{ columnCount: 1 }} className="text-justify Bodytext-1regular-16ptleftdark-lato">
                        No Refund Policy for Satprerna Trust (www.satprerna.org)<br/><br/>
                        Effective Date: 10 th December 2023<br/>
                        As a charitable organization, Satprerna Trust aims to utilize all donations effectively and
                        efficiently towards our causes. Hence, we operate with a strict no refund policy for
                        donations made through www.satprerna.org.<br/><br/>
                        Donation Policy:<br/>
                        a. All donations made through www.satprerna.org are considered final and non-refundable.<br/>
                        b. Satprerna Trust appreciates the generosity of donors and ensures that donated funds are
                        used for charitable purposes aligned with our mission.<br/><br/>
                        Acknowledgement:<br/>
                        a. Donors acknowledge that their contributions are voluntary and made without any
                        coercion or expectation of goods/services in return.<br/>
                        b. Satprerna Trust does not provide refunds for donations received through
                        www.satprerna.org.<br/><br/>
                        Contact Information:<br/>
                        a. For any queries or concerns regarding donations or our no refund policy, please contact
                        us at info@satprerna.org<br/><br/>
                        Modification of Policy:<br/>
                        a. Satprerna Trust reserves the right to modify or update this No Refund Policy without prior
                        notice. Any changes will be effective immediately upon posting on www.satprerna.org.
                        By making a donation through www.satprerna.org, donors acknowledge and agree to the
                        terms outlined in this No Refund Policy.<br/>
                    </p>
                </div>
            </div>
            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
		</div>
	)
}

export default Refund;