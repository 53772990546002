import React from 'react';
import '../fonts.css'

const e5 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhishthira Maharaj said, Oh Janardana, what is
	the name of the Ekadasi that occurs during the dark
	fortnight (Krishna paksha) of the month
	of Jyeshtha (May-June)? I wish to hear from You the
	glories of this sacred day of Hari. Please narrate
	everything to me.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh, the name of this unlimitedly meritorious Ekadasi is
	Apara Ekadasi. Whoever fasts on this holy day
	becomes famous all over the universe.
	<hr style={{border: 'none'}} className="my-3"/>
	Even such sins as killing a Brahman, a cow, or an embryo;
	blasphemy; or having sex with another man’s wife are completely
	eradicated by observing Apara Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh king people who bear false witness are most sinful. A person who
	falsely or sarcastically glorifies another; one who cheats while
	weighing something on a scale; one who fails to execute the duties
	of his varna or Ashrama (an unqualified man’s posing as a Brahmin,
	for example, or a person’s reciting the Vedas wrongly); one who
	invents his own scriptures; one who cheats others; one who is a
	charlatan astrologer, a cheating accountant, or a false Ayurvedic
	doctor. All these are surely as bad as persons who bears false
	witness, and they are all destined for hellish punishments. But
	simply by observing Apara Ekadasi, all such sinners become
	completely free of their sinful reactions.
	<hr style={{border: 'none'}} className="my-3"/>
	Warriors who fall from their kshatriya-dharma and flee the
	battlefield go to a ferocious hell. But, even such a fallen Kshatriya, if
	he observes fasting on the Apara Ekadasi, is freed of that great
	sinful reaction and goes to heaven.
	<hr style={{border: 'none'}} className="my-3"/>
	That disciple is the greatest sinner who, after receiving a proper
	spiritual education from his spiritual master, turns around and
	blasphemes him. Such a so-called disciple suffers unlimitedly. But
	even he, rascal though he be, if he simply observes Apara Ekadasi,
	can attain to the spiritual world. Listen, Oh king, as I describe to you
	further glories of this amazing Ekadasi. The merit attained by one
	who performs all of the following acts of piety is equal to the merit
	achieved by one who observes Apara Ekadasi: bathing three times
	daily in Pushkara-kshetra during Kartika (October-November);
	bathing at Ganga and offering oblations to one’s forefather at Prayag
	in the month of Magh (January-February) when the sun is in the
	zodiac of Capricorn; rendering service to Lord Shiva at Varanasi
	(Benares) during Shiva-ratri; offering oblations to one’s forefathers
	at Gaya; bathing in the sacred Gautami River when Jupiter transits
	Leo (Simha); having darshan of Lord Shiva at Kedarnatha; seeing
	Lord Badrinath when the Sun transits the sign of Aquarius
	(Kumbha); and bathing at the time of a solar eclipse at Kurukshetra
	and giving cows, elephants, and gold there in charity. All the merit
	one gets from performing these pious acts is gained by a person who
	observes the Apara Ekadasi fast. Also, the merit attained by one who
	donates a pregnant cow, along with gold and fertile land, is attained
	by one who fasts on this day.
	<hr style={{border: 'none'}} className="my-3"/>
	In other words, Apara Ekadasi is the sun blazing before one’s dark
	misdeeds, and it is a lion stalking the meek deer of impiety.
	<hr style={{border: 'none'}} className="my-3"/>
	Therefore, whoever truly fears his past and present sins must
	observe Apara Ekadasi very strictly.
	<hr style={{border: 'none'}} className="my-3"/>
	Therefore one must faithfully observe the sacred Apara Ekadasi and
	worship the Supreme Lord, Sri Vishnu. One who does so is freed of
	all his sins and promoted to the abode of Lord Vishnu. Oh King, for
	the benefit of all humanity I have thus described to you this the
	importance of the holy Apara Ekadasi. Anyone who hears or reads
	this description is certainly freed from all kinds of sins.
	<hr style={{border: 'none'}} className="my-3"/>
	Thus ends the narration of the glories of Jyeshtha-krishna Ekadasi,
	or Apara Ekadasi, from the Brahmanda Purana.
</p>

export default e5;
