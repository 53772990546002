import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import GitaPage from '../../Pages/Gita.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Gita() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Main Video" {...a11yProps(3)} />
                <Tab label="Activities" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <GitaPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="gita_header" imageKeys={["/images/3G/gita-bg-m.jpg", "/images/3G/gita-bg5.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="gita_c1_p1" imageKeys={["/images/3G/canva2.jpg"]} heading subheading titleS="Paragraph 1"/>
                <EditCard title="" textKey="gita_c1_p2" imageKeys={["/images/3G/canva6.jpg"]} subheading titleS="Paragraph 2"/>
                <EditCard title="" textKey="gita_c1_p3" imageKeys={["/images/3G/canva7.jpg"]} subheading titleS="Paragraph 3"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="YOUTUBE INSPIRING VIDEO" textKey="gita_main_vdo" heading subheading paragraph titleP="Youtube Video Link"/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="ACTIVITIES" textKey="gita_act" heading subheading/>
                <EditCard title="ACTIVITY 1" textKey="gita_act_1" imageKeys={["/images/3G/s1.jpg", "/images/3G/s2.jpg", "/images/3G/s3.jpg"]} heading subheading paragraph titleP="Youtube Link"/>
                <EditCard title="ACTIVITY 2" textKey="gita_act_2" imageKeys={["/images/3G/s7.jpg", "/images/3G/s8.jpg", "/images/3G/s9.jpg"]} heading subheading paragraph titleP="Youtube Link"/>
                <EditCard title="ACTIVITY 3" textKey="gita_act_3" imageKeys={["/images/3G/s4.jpg", "/images/3G/s5.jpg", "/images/3G/s6.jpg"]} heading subheading paragraph titleP="Youtube Link"/>
            </TabPanel>
        </div>
    );
}
