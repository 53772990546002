import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import AscentOfLifePage from '../../Pages/AscentOfLife.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AscentOfLife() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="The 3 I of Life" {...a11yProps(3)} />
                <Tab label="Aspects" {...a11yProps(4)} />
                <Tab label="FAQ" {...a11yProps(5)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <AscentOfLifePage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="aol_header" imageKeys={["/images/ascentoflife/ascentoflife-bg.jpg", "/images/ascentoflife/ascentoflife-bg.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="aol_c1_p1" imageKeys={["/images/ascentoflife/ban1.jpg", "/images/ascentoflife/ban2.jpg", "/images/ascentoflife/ban3.jpg"]} heading subheading/>
                <EditCard title="CAROUSEL 2" textKey="aol_c2_p1" imageKeys={["/images/ascentoflife/ban2.jpg", "/images/ascentoflife/ban3.jpg", "/images/ascentoflife/ban1.jpg"]} heading subheading/>    
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="THE 3 I'S OF LIFE" textKey="aol_3i" heading subheading/>
                <Divider variant="middle" />
                <EditCard title="INTELLIGENCE" textKey="aol_3i_p1_1" heading subheading titleS="Intelligence Point 1"/>
                <EditCard title="" textKey="aol_3i_p1_2" subheading titleS="Intelligence Point 2"/>
                <EditCard title="" textKey="aol_3i_p1_3" subheading titleS="Intelligence Point 3"/>
                <EditCard title="" textKey="aol_3i_p1_4" subheading titleS="Intelligence Point 4"/>
                <Divider variant="middle" />
                <EditCard title="INVALUABLE VALUES" textKey="aol_3i_p2_1" heading subheading titleS="Invaluable Values Point 1"/>
                <EditCard title="" textKey="aol_3i_p2_2" subheading titleS="Invaluable Values Point 2"/>
                <Divider variant="middle" />
                <EditCard title="IMPOSSIBLE TO POSSIBLE" textKey="aol_3i_p3_1" heading subheading titleS="Impossible to Possible Point 1"/>
                <EditCard title="" textKey="aol_3i_p3_2" subheading titleS="Impossible to Possible Point 1"/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="ASPECT 1" textKey="aol_aspects_1" imageKeys={["/images/ascentoflife/aol_aspects_1.jpg"]} heading subheading/>
                <EditCard title="ASPECT 2" textKey="aol_aspects_2" imageKeys={["/images/ascentoflife/aol_aspects_2.jpg"]} heading subheading/>
                <EditCard title="ASPECT 3" textKey="aol_aspects_3" imageKeys={["/images/ascentoflife/aol_aspects_3.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={5} preview={false}>
                <EditCard title="FAQ 1" textKey="aol_faq_p1_1" heading subheading titleS="FAQ 1 Point 1"/>
                <EditCard title="" textKey="aol_faq_p1_1" subheading titleS="FAQ 1 Point 2"/>
                <EditCard title="" textKey="aol_faq_p1_1" subheading titleS="FAQ 1 Point 3"/>
                <Divider variant="middle" />
                <EditCard title="FAQ 2" textKey="aol_faq_p1_2" heading subheading titleS="FAQ 2 Point 1"/>
                <EditCard title="" textKey="aol_faq_p1_2" subheading titleS="FAQ 2 Point 2"/>
                <EditCard title="" textKey="aol_faq_p1_2" subheading titleS="FAQ 2 Point 3"/>
                <EditCard title="" textKey="aol_faq_p1_2" subheading titleS="FAQ 2 Point 4"/>
                <EditCard title="" textKey="aol_faq_p1_2" subheading titleS="FAQ 2 Point 5"/>
                <EditCard title="" textKey="aol_faq_p1_2" subheading titleS="FAQ 2 Point 6"/>
                <Divider variant="middle" />
                <EditCard title="FAQ 3" textKey="aol_faq_p1_3" heading subheading titleS="FAQ 3 Point 1"/>
                <EditCard title="" textKey="aol_faq_p1_3" subheading titleS="FAQ 3 Point 2"/>
                <EditCard title="" textKey="aol_faq_p1_3" subheading titleS="FAQ 3 Point 3"/>
                <EditCard title="" textKey="aol_faq_p1_3" subheading titleS="FAQ 3 Point 4"/>
            </TabPanel>
        </div>
    );
}
