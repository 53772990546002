import React from 'react';
import '../fonts.css'

const e2 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhishthira Maharaja said, Oh Lord Krishna, Oh Vasudeva, please
	accept my humble obeisance. Please describe to me the Ekadasi that
	occurs during the light part of the month of Chaitra [March-April]. What is its
	name, and what are its glories?'
	<hr style={{border: 'none'}} className="my-3"/>
	Lord Sri Krishna replied, Oh Yudhishthira, please listen
	to Me attentively as I relate the ancient history of this
	sacred Ekadasi, a history Vasishtha Muni once related
	to King Dilipa.
	<hr style={{border: 'none'}} className="my-3"/>
	It is very purifying, and it bestows the highest merit
	upon one who faithfully observes it. Oh king, now hear an ancient
	history, which is so meritorious that it removes all one’s sins, simply
	by being heard.
	<hr style={{border: 'none'}} className="my-3"/>
	Once, long ago, there existed a city-state named Bhogipura. King
	Pundarika was the ruler of this most beautiful kingdom, which
	numbered many Gandharvas, Kinnaras, and Apsaras among its
	citizens.
	<hr style={{border: 'none'}} className="my-3"/>
	Among the Gandharvas were Lalit and his wife Lalita, who was an
	especially lovely dancer. These two were intensely attracted to each
	other, and their home was full of great wealth and fine food. Lalita
	loved her husband dearly, and likewise he constantly thought of her
	within his heart.
	<hr style={{border: 'none'}} className="my-3"/>
	Once, at the court of King Pundarika, many Gandharvas were
	dancing and Lalit was singing alone, without his wife. He could not
	help thinking about her as he sang, and because of this distraction
	he lost track of the song's meter and melody. Indeed, Lalit sang the
	ending of his song improperly, and one of the envious snakes Kakot
	who was in attendance at the king's court complained to the king
	that Lalit was absorbed in thinking of his wife instead of his
	sovereign. The king became furious upon hearing this, and his eyes
	turned crimson with rage. Suddenly he shouted, "Oh foolish knave,
	because you were lustfully thinking of a woman instead of
	reverently thinking of your king as you performed your court duties,
	I curse you to at once become a cannibal!" One, who eats raw meat,
	man and drinks.
	<hr style={{border: 'none'}} className="my-3"/>
	With the curse of the king, Lalit immediately became a fearful
	cannibal, a great man-eating demon whose appearance terrified
	everyone. His arms were long, his mouth was as big and fire
	emanated, his eyes were as awesome as the sun and moon, his
	nostrils resembled enormous pits in the earth, his neck was a
	veritable mountain, his hips were four miles wide, and his gigantic
	body stood a full sixty-four miles high. Thus poor Lalit, the loving
	Gandharva singer, had to suffer the reaction of his offense against
	King Pundarika.
	<hr style={{border: 'none'}} className="my-3"/>
	Seeing her husband suffering as a horrible cannibal, Lalita became
	overwhelmed with grief. She thought, "Now that my dear husband is
	suffering the effects of the kings' curse, what is to be my lot? What
	should I do? Where should I go?" In this way Lalita grieved day and
	night. Instead of enjoying life as a Gandharvas wife, she had to
	wander everywhere in the thick jungle with her monstrous husband,
	who had fallen completely under the spell of the king's curse and
	was wholly engaged in terrible sinful activities. He wandered fitfully
	across forbidding region; a once-beautiful Gandharva now reduced
	to the ghastly behavior of a man-eater. Utterly distraught to see her
	dear husband suffer so much in his dreadful condition, Lalita began
	to cry as she followed his mad journey.
	<hr style={{border: 'none'}} className="my-3"/>
	By good fortune, however, Lalita came upon the sage Shringi one
	day. He was sitting on the peak of the famous VindhyAchala Hill.
	Approaching him, she immediately offered the ascetic her respectful
	obeisance. The sage noticed her bowing down before him and said,
	"Oh most beautiful one, who are you? Whose daughter are you, and
	why have you come here? Please tell me everything in truth."
	<hr style={{border: 'none'}} className="my-3"/>
	Lalita replied, "Oh great sage, my name is Lalita. I roam the forests
	and plains with my dear husband, whom King Pundarika has cursed
	to become a man-eating demon. Oh Brahman, I am greatly
	aggrieved to see his ferocious form and terribly sinful activities. Oh
	master; please tell me how I can perform some act of atonement on
	behalf of my husband. What pious act can I perform to free him from
	this demonic form, Oh best of Brahmanas?
	<hr style={{border: 'none'}} className="my-3"/>
	The sage replied, "Oh heavenly maiden, there is an Ekadasi named
	Kamada that occurs in the light fortnight of the month of Chaitra. It
	is coming up soon. Whoever fasts on this day has all his desires
	fulfilled. If you observe this Ekadasi fast according to its rules and
	regulations and give the merit you thus earn to your husband, he
	will be freed from the curse at once." Lalita was overjoyed to hear
	these words from the sage.
	<hr style={{border: 'none'}} className="my-3"/>
	Lalita faithfully observed the fast of Kamada Ekadasi according to
	the instructions of the sage Shringi, and on Dvadasi she appeared
	before him and the Deity of Lord Vasudeva and said, "I have
	faithfully observed the fast of Kamada Ekadasi. By the merit earned
	through my observance of this fast, let my husband be free from the
	curse that has turned him into a demoniac cannibal. May the merit I
	have gained thus free him from misery."
	<hr style={{border: 'none'}} className="my-3"/>
	As soon as Lalita finished speaking, her husband, who stood nearby,
	was at once freed from the king's curse. He immediately regained
	his original form as the Gandharva Lalit, a handsome heavenly
	singer adorned with many beautiful ornaments. Now, with his wife
	Lalita, he could enjoy even more opulence than before. All this was
	accomplished by the power and glory of Kamada Ekadasi. At last the
	Gandharva couple boarded a celestial airplane and ascended to
	heaven.
	<hr style={{border: 'none'}} className="my-3"/>
	Vasistha Muni continued, Oh King, best of kings, anyone who hears
	this wonderful narration should certainly observe holy Kamada
	Ekadasi to the best of his ability, such great merit does it bestow
	upon the faithful devotee. I have therefore described its glories to
	you for the benefit of all humanity.
	<hr style={{border: 'none'}} className="my-3"/>
	There is no better Ekadasi than Kamada Ekadasi. It can eradicate
	even the sin of killing a Brahman, and it also nullifies demoniac
	curses and cleanses the consciousness. In all the three worlds,
	among movable and immovable living entities, there is no better
	day.
</p>

export default e2;
