import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { Button } from 'bootstrap';

const useDailogStyles = makeStyles((theme) => ({
    text: {
        color: '#222'
    },
    dailogbutton: {
        color: '#fff'
    },
    uploadedImg: {
        height: '100px',
        paddingLeft: '20px'
    }
}));

const headCells = [
    { id: 'id', label: 'Id' },
    { id: 'schoolname', label: 'School' },
    { id: 'city', label: 'City' },
    { id: 'paymentsc', label: 'Screenshot' }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    table: {
        minWidth: 500,
        marginTop: '50px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    checkboxcolor: {
        color: '#222',
        '&$checked': {
            color: '#222',
        },
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: '90px',
        zIndex: 1000
    },
    fab2: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: '20px',
        zIndex: 1000
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [loading, setloading] = useState(true);

    useEffect(() => {
        let textData;
        axios.get('https://satprerna.org/satprerna_backend/home/get_sgp_form.php').then(response => {
            textData = response.data;
            setRows(textData);
            setloading(false);
        })
            .catch(error => {
                console.log(error);
            });
    }, []);

    if (loading)
        return <CircularProgress style={{ color: '#9a0808' }} />
    else return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography align="center" className={classes.title} variant="h3" id="tableTitle" component="div">
                    Shri Gita Prerna 2023
                </Typography>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align='left'
                                        padding='default'
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .map((row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell align="left" component="th" scope="row" padding="none">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">{row.schoolname}</TableCell>
                                            <TableCell align="left">{row.city}</TableCell>
                                            <TableCell align="left"><a href={"https://satprerna.org/satprerna_backend/home/get_sgp_form_image.php?id=" + row.id} target='_blank'>View Image</a></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
