import React from 'react';
import '../fonts.css'

const e7 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Yudhisthira Maharaj said, I wish to hear from You
	about the shuddha Ekadasi that occurs during the
	dark fortnight of the month of Ashadha (June -
	July). Kindly describe to me all about it in detail.
	<hr style={{border: 'none'}} className="my-3"/>
	The Supreme Lord, Sri Krishna, then replied, "Oh
	king, I shall indeed tell you about the best of all
	fasting days, the Ekadasi that comes during the
	dark part of the month of Ashadha. Famous as
	Yogini Ekadasi, it removes all kinds of sinful reactions and awards
	supreme liberation.
	<hr style={{border: 'none'}} className="my-3"/>
	Oh best of kings, this Ekadasi delivers people who are drowning in
	the vast ocean of material existence and transports them to the
	shore of the spiritual world. In all the three worlds, it is the chief of
	all sacred fasting days. I shall now reveal this truth to you by
	narrating a history recounted in the Puranas.
	<hr style={{border: 'none'}} className="my-3"/>
	"The king of Alakapuri - Kuvera, was ruling the kingdom. He
	employed a servant named Hemamali as his personal gardener.
	Hemamali, was very lustfully attracted to his gorgeous wife, Vishal
	lakshi.
	<hr style={{border: 'none'}} className="my-3"/>
	Hemamali’s daily duty was to visit Manasarovara Lake and bring
	back flowers for his master, Kuvera, with which he would use them
	in the puja offerings to lord Shiva. One day, after picking the
	flowers, Hemamali went to his wife instead of returning directly to
	his master and fulfilling his duty by bringing the flowers for the puja.
	Absorbed in loving affairs of a bodily nature with his wife, he forgot
	to return to the abode of Kuvera.
	<hr style={{border: 'none'}} className="my-3"/>
	When at midday their gardener had not brought the flowers for
	worship. The lack of such an important item (upachara) angered the
	great Koshad-yaksha (treasurer of the devas) even more, and the
	king asked a messenger, ‘why the gardener had not come with the
	flowers to find out the exact reason and report back to him in
	person.’ The Sevak returned and told Kuvera, Oh dear lord,
	Hemamali has become lost in freely enjoying coitus with his wife.
	<hr style={{border: 'none'}} className="my-3"/>
	Kuvera became extremely angry when he heard this and at once
	summoned lowly Hemamali before him, approached his master in
	great fear. The gardener first paid his obeisance and then stood
	before his lord, whose eyes had become red with anger and whose
	lips trembled in rage. So enraged, Kuvera cried out to Hemamali, Oh
	you sinful rascal! Oh destroyer of religious principles! You are a
	walking offense to Shiva! I therefore curse you to suffer from
	leprosy and to become separated from your beloved wife! Only great
	suffering is deservedly yours! Oh lowborn fool, leave this place
	immediately and betake yourself to the lower planets to suffer!’
	<hr style={{border: 'none'}} className="my-3"/>
	And so Hemamali fell at once from grace in Alakapuri and became ill
	with the terrible affliction of leprosy. He awoke in a dense and
	fearful forest, where there was nothing to eat or drink. Thus he
	passed his days in misery, unable to sleep at night due to pain. He
	suffered in both winter and summer season, but because he
	continued to worship Lord Shiva himself with faith, his
	consciousness remained purely fixed and steady. Although
	implicated by great sin and its attendant reactions, he remembered
	his past life because of his piety.
	<hr style={{border: 'none'}} className="my-3"/>
	After wandering for some time here and there, over mountains and
	across plains, Hemamali eventually came upon the vast expanse of
	the Himalayan mountain ranges. There he had the wonderful good
	fortune to come in contact with the great saintly soul Markann Deya
	Rishi, the best of ascetics, whose ashram looked like the hall of
	Brahma.
	<hr style={{border: 'none'}} className="my-3"/>
	Hemamali went there and fell at his feet. Markan Deya Rishi was
	seated peacefully at his Ashrama, looking as effulgent as a second
	Brahma. Markan Deya Rishi saw the leper and called him near, "Oh
	you, what sort of sinful deeds have you done to earn this dreadful
	affliction?"
	<hr style={{border: 'none'}} className="my-3"/>
	Hearing this, Hemamali painfully and ashamed replied, ‘Dear sir, I
	am a servant of lord Kuvera, and my name is Hemamali. It was my
	daily service to pick the flowers from the Manasarovara lake for my
	master’s worship of lord Shiva, but one day I was negligent and was
	late in returning with the offering because I had become
	overwhelmed with lusty passion for enjoying bodily pleasures with
	my wife. When my master discovered why I was late, he cursed me
	in great anger to be as I am before you. Thus I am now bereft of my
	home, my wife, and my service. But fortunately I have come upon
	you, and now I hope to receive from you an auspicious benediction.’
	<hr style={{border: 'none'}} className="my-3"/>
	Softhearted Markan Deya Rishi replied, ‘Because you have told me
	the truth, I shall tell you about a fast day that will benefit you
	greatly. If you fast on the Ekadasi that comes during the dark
	fortnight of the month of Ashadha, you will surely be freed of this
	terrible curse.’
	<hr style={{border: 'none'}} className="my-3"/>
	Hemamali fell to the ground in complete gratitude and offered him
	his humble obeisances again and again.
	<hr style={{border: 'none'}} className="my-3"/>
	Thus, as the sage had instructed him, Hemamali dutifully observed
	the Ekadasi fast, and by its influence he again became a handsome
	Yaksha. Then he returned home, where he lived very happily with
	his wife.
	<hr style={{border: 'none'}} className="my-3"/>
	Lord Sri Krishna concluded, so, you can readily see, Oh king fasting
	on Yogini Ekadasi is very powerful and auspicious. Whatever merit
	one obtains by feeding eighty-eight thousand Brahmins is also
	obtained simply by observing a strict fast on Yogini Ekadasi. For one
	who fasts on this sacred Ekadasi, she (Ekadasi Devi), destroys heaps
	of past sinful reactions and makes him most pious. Oh King, thus I
	have explained to you the purity of Yogini Ekadasi.
	<hr style={{border: 'none'}} className="my-3"/>
	Thus ends the narration of the glories of Ashadha-krishna Ekadasi,
	or Yogini Ekadasi, from the Brahma-vaivarta Purana.
</p>

export default e7;
