import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './userdetails.css'
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dailogContent: {
        alignItems: 'center',
	}
}));

export default function FormDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [name, setname] = React.useState('');
    const [email, setemail] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name==='name')
            setname(value);
        if(name==='email')
            setemail(value);
    };

    const config = {
		cors: 'https://infinite-bastion-79180.herokuapp.com/', // <optional> doesn't display the cors error
		formUrl: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2UVc2q5TjKQ2GPziAH-CkNP7cHcmKP380j91Zzwt1A50hDw/formResponse',
		name_id: '1583869429',
        email_id: '2028199797'
    };

    const handleClose = async () => {
        setOpen(false);
        const formData = new FormData();
        formData.append(`entry.${config.name_id}`, name);
		formData.append(`entry.${config.email_id}`, email);
        await axios({
            url: `${config.cors}${config.formUrl}`,
            method: 'post',
            data: formData,
            responseType: 'json'
        })
    };

    return (
        <div>
            <button onClick={handleClickOpen} className="pmt-cover-img-links">{props.title}</button>
            <Dialog className={classes.dailogContentOuter} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Welcome</DialogTitle>
                <DialogContent className={classes.dailogContent}>
                    <DialogContentText>
                        To read online, please provide us your following details.
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                value={name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <a className="" href={(name !== '' && email !== '') ? props.link : undefined} target="_blank" rel="noopener noreferrer">
                    <Button onClick={handleClose} color="secondary" disabled={(name === '' || email === '')}>
                        Read Now
                    </Button>
                </a>
                </DialogActions>
            </Dialog>
        </div>
    );
}
