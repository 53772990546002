import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import WelfarePage from '../../Pages/Welfare.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Welfare() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Video & Motto" {...a11yProps(3)} />
                <Tab label="Activities" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <WelfarePage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="wa_header" imageKeys={["/images/welfare/header1.jpg", "/images/welfare/header1-mob.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="wa_c1_p1" imageKeys={["/images/welfare/ban1.jpg", "/images/welfare/ban2.jpg", "/images/welfare/ban3.jpg"]} heading subheading/>
                <EditCard title="CAROUSEL 2" textKey="wa_c2_p1" imageKeys={["/images/welfare/ban3.jpg", "/images/welfare/ban1.jpg", "/images/welfare/ban2.jpg"]} heading subheading/>    
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="MOTTO" textKey="wa_pts" imageKeys={["/images/dsr/back1.jpg"]} heading subheading paragraph titleP="Youtube Video Link"/>
                <EditCard title="MOTTO 1" textKey="wa_pts_1" paragraph titleP="Point 1"/>
                <EditCard title="MOTTO 2" textKey="wa_pts_2" paragraph titleP="Point 2"/>
                <EditCard title="MOTTO 3" textKey="wa_pts_3" paragraph titleP="Point 3"/>
                <EditCard title="MOTTO 4" textKey="wa_pts_4" paragraph titleP="Point 4"/>
                <EditCard title="MOTTO 5" textKey="wa_pts_5" paragraph titleP="Point 5"/>
                <EditCard title="MOTTO 6" textKey="wa_pts_6" paragraph titleP="Point 6"/>
                <EditCard title="MOTTO 7" textKey="wa_pts_7" paragraph titleP="Point 7"/>
                <EditCard title="MOTTO 8" textKey="wa_pts_8" paragraph titleP="Point 8"/>
                <EditCard title="MOTTO 9" textKey="wa_pts_9" paragraph titleP="Point 9"/>
                <EditCard title="MOTTO 10" textKey="wa_pts_10" paragraph titleP="Point 10"/>
                <EditCard title="MOTTO 11" textKey="wa_pts_11" paragraph titleP="Point 11"/>
            </TabPanel>
            <TabPanel value={value} index={4} preview={false}>
                <EditCard title="TITLE & SUBTITLE" textKey="wa_act" heading subheading/>
                <EditCard title="ACTIVITY 1" textKey="wa_act_1" heading subheading paragraph titleP="Youtube Video Link 1"/>
                <EditCard title="ACTIVITY 2" textKey="wa_act_2" heading subheading paragraph titleP="Youtube Video Link 2"/>
                <EditCard title="ACTIVITY 3" textKey="wa_act_3" heading subheading paragraph titleP="Youtube Video Link 3"/>
            </TabPanel>
        </div>
    );
}
