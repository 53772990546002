import React from 'react';
import '../fonts.css'

const e9 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	The son of Kunti, king Yudhisthira maharaj said, Oh
	Supreme Lord, I would like to hear from You the
	glories of the Ekadasi that occurs during the dark
	fortnight (Krishna paksha) of the month of Shravana
	(July -August). Please be merciful to me and explain
	its glories and this fast is devoted to which god.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Shri Krishna, replied, Oh King,
	please listen attentively as I describe the auspicious influence of this
	Holy fast (vrata) day, which removes all sins. Narada Muni once
	asked Lord Brahma about this same topic. Oh Father, said Naradji,
	please tell me the name of the Ekadasi that occurs during the dark
	fortnight of the sacred month of Shravana. Please also tell me which
	Deity is to be worshipped on that Holy day, the process one must
	follow to observe it, and the merit it awards.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Brahma replied, My dear son Narada, for the benefit of all
	humanity I shall happily tell you everything you wish to know, for
	just hearing the glories of the Kamika Ekadasi bestows merit equal
	to that obtained by one who performs a horse sacrifice. Certainly,
	great merit is attained by one who worships, and who also meditates
	on the lotus feet of the four-armed Lord Gadadhara, who holds
	conchshell, disc, club and lotus in His hands and who is also known
	as Shridhara, Hari, Vishnu, Madhava, and Madhusdana. And the
	blessings achieved by such a person / devotee, who worships Lord
	Vishnu exclusively are far greater than those achieved by one who
	takes a sacred bath in the Ganges at Kashi (Varanasii), in the forest
	of Naimisharanya, or at Pushkara, which is the only place on the
	planet where I am formally worshipped. But one who observes this
	Kamika Ekadasi and also worships Lord Shri Krishna achieves
	greater merit than one who has darshan of Lord Kedaranatha in the
	Himalayas, or one who bathes at Kurukshetra during a solar eclipse,
	or one who donates the whole Earth in charity, including its forests
	and oceans, or one who bathes in the Gandaki River (where the
	sacred Shaligrams are found) or the Godavari River on a full moon
	(purnima) day that falls on a Monday when Leo (Simha) and Jupiter
	(Guru) are conjoined (conjunct).
	<hr style={{border: 'none'}} className="my-4"/>
	Observing Kamika Ekadasi bestows the same merit as donating a
	milk-cow and her auspicious calf, along with their feed. On this all
	auspicious day, whosoever worships Lord Sri Shridhara-deva,
	Vishnu, is glorified by all the devas, Gandharvas, Sun, and others.
	<hr style={{border: 'none'}} className="my-4"/>
	Those who are afraid of their past sins and completely immersed in
	sinful materialistic life should at least observe this best of Ekadasis
	according to their ability and thus attain liberation. This Ekadasi is
	the purest of all days and the most powerful for removing sins of the
	native. Oh Naradji, Lord Shri Hari Himself once said about this
	Ekadasi, One who fasts on Kamika Ekadasi attains much more merit
	than one who studies all the spiritual literatures.
	<hr style={{border: 'none'}} className="my-4"/>
	Anyone who fasts on this particular day remains awake throughout
	the night will never experience the anger of Yamaraja, the king of
	death personified. It has been seen that whoever observes Kamika
	Ekadasi will not have to suffer future births, and in the past too,
	many Yogis of devotion who fasted on this day went to the spiritual
	world. One should therefore follow in their auspicious footsteps and
	strictly observe a fast on this most auspicious of Ekadasis.
	<hr style={{border: 'none'}} className="my-4"/>
	Whosoever worships Lord Shri Hari with Tulasi leaves is freed from
	all implication of sin. Indeed, he lives untouched by sin, as the lotus
	leaf, although in the water, is untouched by it. Whosoever offers
	Lord Shri Hari but a single leaf from the sacred Tulasi tree attains as
	much merit as one who gives away in charity four times silver or
	gold . The Supreme Lord is more pleased by one who offers Him a
	single Tulasi leaf than by one who worships Him with pearls, rubies,
	topaz, diamonds, and gem stones. Oh Narad I always pay obeisance
	to Tulasi as it is very dear to the Lord. One who offers Lord Keshava
	newly grown manjari buds from the Tulasi plant gets rid of all the
	sins he has committed during this or any other lifetime. Indeed,
	mere darshana of Tulasi on Kamika Ekadasi removes all sins, and
	merely touching her and praying to her removes all kinds of disease.
	One who waters Tulasi Devi need never fear the Lord of death,
	Yamaraja. One who plants or transplants Tulasi on these days will
	eventually reside with Lord Shri Krishna in His own abode. To
	Srimati Tulasi Devi, who awards liberation in devotional service, one
	should therefore daily offer one's full obeisance.
	<hr style={{border: 'none'}} className="my-4"/>
	Even Chitragupta, Yamaraja's secretary, cannot calculate the merit
	obtained by one who offers Shrimati Tulasi-devi a perpetually
	burning ghee lamp. So dear is this sacred Ekadasi to the Supreme
	Lord that all the forefathers of one who offers a bright ghee lamp to
	Lord Shri Krishna on this day ascend to the heavenly planets and
	drink the celestial nectar there. Whoever offers a ghee or sesame oil
	lamp to Shri Krishna on this day is freed from all his sins and enters
	the abode of Surya, the Sun god, with a body as bright as ten million
	lamps. This Ekadasi is so powerful that if one who is unable to fast
	simply follow the practices as mentioned herein, s/he is elevated to
	the heavenly planets, along with all their forefathers.
	<hr style={{border: 'none'}} className="my-4"/>
	Brahmaji said to his son Narad Muni , One who kills the innocent,
	i.e., a Brahmin (Brahman), a child in the womb, a pious and spotless
	woman, etc., and then later hears about the glories of Kamika
	Ekadasi will be relieved of the reaction to one's sins.
	<hr style={{border: 'none'}} className="my-4"/>
	Whosoever hears these glories of the Kamika Ekadasi with faith
	becomes free of all sins and returns home, back to Godhead -
	Vishnu-loka, Vaikuntha.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of the Shravana-krshna
	Ekadasi, or Kamika Ekadasi, from the Brahma-Vaivarta Purana.
</p>

export default e9;
