import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import {FormLabel, FormGroup, FormControlLabel} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { ButtonA } from '../Components/Button.js'
import { TextField, Checkbox, Divider, Grid, Snackbar } from '@material-ui/core';
import emailjs from 'emailjs-com';
import './pmtform.css'
import  '../Pages/collaborate.css';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		boxShadow: 'none',
		backgroundColor: 'transparent'
	},
	title: {
		flex: 1,
		fontWeight: 'normal',
		color: 'grey',
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(3)
	},
	text: {
		flex: 1,
		fontWeight: 'normal',
		color: '#333',
		marginBottom: theme.spacing(2)
	},
	close: {
	    color: 'white',
	    backgroundColor: '#9a0808',
	    position: 'fixed',
	    top: '20px',
	    right: '20px',
	    '&:hover': {
	      color: 'white',
	      backgroundColor: '#9a0808',
	  	}
    },
	formControl: {
    },
	formControlCheckox: {
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
	const [message, setmessage] = useState(false);
	const [formstatus, setformstatus] = useState('error');
	const [logtext , setlogtext] = useState('');

	const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [mob, setmob] = useState('');
    const [eaddr, seteaddr] = useState('');

    const [etype, setetype] = React.useState({
        ggg: false,
        au: false,
        aol: false,
        ab: false,
        bsd: false,
        dsr: false,
        pmt: false,
        we: false,
        wa: false
    });

	const [emailerr, setemailerr] = useState('');
	const [openloading, setopenloading] = React.useState(false);

	const handleetypeCheckboxChange = (event) => {
        setetype({ ...etype, [event.target.name]: event.target.checked });
    };

	const handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if(name==='name')
			setname(value);
		else if(name==='eaddr')
			seteaddr(value);
		else if(name==='mob')
			setmob(value);
		
		else if(name==='email') {
		  	setemail(value);
			// eslint-disable-next-line no-useless-escape
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(value) === false) {
				setemailerr('Email is Not Correct');
				return;
			}
			else {
				setemailerr('');
			}
		}
    };
    
    const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendEmail = (mail, name) => {
        emailjs.send("service_6tpaae9","template_8oq7nfl",{
            form_name: name,
            to_email: mail,
        }, "user_WO7pjjJ2kpBHGbUJekjMm");

		emailjs.send("service_6tpaae9","template_ehavusr",{
			form_link: "https://satprerna.org/850274/Admin/Collaborate",
			form_name: name,
			to_email: "harshitvidhyarthi@gmail.com",
		}, "user_WO7pjjJ2kpBHGbUJekjMm");
	}
	
	const handleFormSubmit = async () => {
		setopenloading(true);
		const formData = new FormData()
		if (name && eaddr && email && mob) {
            let prog_txt = ''
            if(etype.ggg === true)   prog_txt += '3G Project; '
            if(etype.au === true)   prog_txt += 'Anand Utsav; '
            if(etype.aol === true)   prog_txt += 'Ascent of Life; '
            if(etype.ab === true)   prog_txt += 'Ayushman Bhava; '
            if(etype.bsd === true)   prog_txt += 'Body Servicing Day; '
            if(etype.dsr === true)   prog_txt += 'Divya Shishu Ratna; '
            if(etype.pmt === true)   prog_txt += 'Prabhu Mein Tera; '
            if(etype.we === true)   prog_txt += 'Women Empowerment; '
            if(etype.wa === true)   prog_txt += 'Welfare Activities; '

			formData.append("name", name)
			formData.append("eaddr", eaddr);
			formData.append("mob", mob);
			formData.append("email", email);
            formData.append("prog_txt", prog_txt);

			setmessage(true);
			setformstatus('info');
			setlogtext('Submitting');
			await axios({
		      url: 'https://satprerna.org/satprerna_backend/home/insert_into_event_form.php',
		      method: 'post',
		      data: formData,
		      responseType: 'json'
		    })
		    .then(() => {
				sendEmail(email, 'LPDF Event Registration');
				setmessage(true);
				setformstatus('success');
				setlogtext('Details Submitted Successfully');
				setname('');
				seteaddr('');
				setmob('');
				setemail('');
                setetype({
                    ggg: false,
                    au: false,
                    aol: false,
                    ab: false,
                    bsd: false,
                    dsr: false,
                    pmt: false,
                    we: false,
                    wa: false
                })
		    })
		    .catch(err => {
				console.log('err', err)
				setmessage(true)
				setformstatus('error')
				setlogtext('Something went wrong')
			})
		}
		else {
			setmessage(true);
			setformstatus('error');
			setlogtext('Please fill all details');
		}
		setopenloading(false);
	};

	return (
		<div>
            <input className="styled-button-white-bg" style={{marginTop: "0em", marginBottom: "0em", borderRadius: "10em/1em"}} type='button' value='Host an Event' onClick={handleClickOpen}/>
			<Dialog className={classes.dailog} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<div className="form-header-volunteer">
					<AppBar className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
							</Typography>
							<IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</AppBar>
					<svg  className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                    
						<path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
						<path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
						<path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
					</svg>
				</div>
                <div className="form-wrapper">
                    <div className="dsr-form-header">
                        <div>
                            <h1 className="t1" >Host an Event</h1>
                            <h6 className="t2 my-3" >Event Registration</h6>
                        </div>
                        <div className="my-2 my-xs-0">
                            <img src={require("../images/decoration/eve.svg")} alt="" width="auto" height="150px"/>
                        </div>
                    </div>
                    <Grid container className="mb-5" alignItems="center">
                        <Typography variant="h6" className={classes.title}>
                            Personal Details
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Full Name"
                                    value={name}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    multiline
								    rows={6}
                                    required
                                    fullWidth
                                    id="eaddr"
                                    label="Event Address"
                                    name="eaddr"
                                    autoComplete="eaddr"
                                    value={eaddr}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="mob"
                                    label="Mobile Number"
                                    name="mob"
                                    autoComplete="mob"
                                    value={mob}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="email"
                                    name="email"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    value={email}
                                    onChange={handleInputChange}
                                />
                                <p style={{color: 'orangered', fontSize: '0.8rem', marginLeft: '8px'}}>{emailerr}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <div className="my-5">
                        <Typography variant="h6" className={classes.title}>
                            Type of Event being hosted
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" className={classes.formControl} fullWidth>
                                    <FormLabel component="legend">Select Applicable</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.ggg} onChange={handleetypeCheckboxChange} name="ggg" />}
                                            label="3G Project"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.au} onChange={handleetypeCheckboxChange} name="au" />}
                                            label="Anand Utsav"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.aol} onChange={handleetypeCheckboxChange} name="aol" />}
                                            label="Ascent of Life"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.ab} onChange={handleetypeCheckboxChange} name="ab" />}
                                            label="Ayushman Bhava"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.bsd} onChange={handleetypeCheckboxChange} name="bsd" />}
                                            label="Body Servicing Day"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.dsr} onChange={handleetypeCheckboxChange} name="dsr" />}
                                            label="Divya Shishu Ratna"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.pmt} onChange={handleetypeCheckboxChange} name="pmt" />}
                                            label="Prabhu Mein Tera"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.we} onChange={handleetypeCheckboxChange} name="we" />}
                                            label="Women Empowerment"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={etype.wa} onChange={handleetypeCheckboxChange} name="wa" />}
                                            label="Welfare Activities"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="mt-4 text-right">
                                <ButtonA buttonto="Submit Details" onClick={handleFormSubmit}/>
                            </Grid>
                            <Snackbar open={message} autoHideDuration={6000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={() => setmessage(false)} severity={formstatus}>
                                    {logtext}
                                </Alert>
                            </Snackbar>
                            <Backdrop className={classes.backdrop} open={openloading} onClick={()=>{}}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Grid>
                    </div>
                </div>
            </Dialog>
		</div>
	);
}
