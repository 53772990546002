import React from 'react';
import '../fonts.css'

const e19 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Saphala Ekadashi is an auspicious fasting day observed on the ‘ekadashi’ (11th day) of the Krishna Paksha (the waning phase of moon) during the month of ‘Paush’ in the traditional Hindu calendar. This ekadashi is also referred as ‘Pausa Krishna Ekadashi’. If you follow the Gregorian calendar, it is celebrated between the months of December to January. The day of Saphala Ekadashi is sacred to Hindus as it is believed that by fasting sincerely on this day, the devotees can wash away their sins and also enjoy a blissful life. Ekadashi is a revered day that occurs twice in every lunar Hindu month and is a day dedicated to worshipping the preserver of this Universe, who is none other than Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	The word ‘saphala’ in Hindi means ‘to prosper’ and therefore this ekadashi should be observed by anyone who desires success and happiness in all the fields of life. Saphala Ekadashi is therefore a means to open the doors of abundant, success, prosperity and fortune. It is celebrated with great zeal and enthusiasm in all the corners of the country. Huge events are organized on this day in Lord Krishna temples as; He is an avatar of Lord Vishnu.
	<hr style={{border: 'none'}} className="my-4"/>
	The importance of Saphala Ekadashi is mentioned as a conversation between Dharamraja Yudhisthira and Lord Krishna in the ‘Brahmanda Purana’. According to the Hindu scriptures, it is said that even 100 Rajasuya Yagas and 1000 Ashvamedha Yagas are not as beneficial as observing a sacred fast on the day of Saphala Ekadashi. The day of Saphala Ekadashi is rightfully described as a day that transforms bad luck into rewarding fortune by ending all the miseries of life. Saphala Ekadashi has powers to help a person accomplish his/her desires and dreams in reality. Besides it also give satisfaction and inner peace to the observer of the Saphala Ekadashi fast.
</p>

export default e19;
