import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'

function AboutUs(props) {
	const { heading, subheading, paragraph, state } = useContext(DataContext);

	return (
		<div>
			<Header 
				headerClass="allAboutUsHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "2"
				buttonto=""
				linkto = "/"
				textDark
			/>
			<div className="collaborate-content-wrapper">
				<div className="collaborate-header">{heading('abs_header')}</div>
				<div className="about-us-vision-wrapper">
					<div>
						<img src="https://satprerna.org/satprerna_backend/images/aboutus/vision.webp" alt="" className="about-us-vision-img mb-5 mb-xl-0"/>
					</div>
					<div className="about-us-vision-text-wrapper ml-xl-5">
						<h2 className="Subheading-1bold-36ptleftdark-lato mb-4">{paragraph('abs_header')}</h2>
						{/* <p className="Bodytext-1regular-16ptleftdark-lato">{paragraph('abs_header')}</p> */}
					</div>
				</div>
				<div className="text-center">
					<img src={require("../images/aboutus/deco.png")} alt="" className="mt-4" height="50px"/>
				</div>
				<div className="collaborate-header">{heading('abs_mission_1')}</div>
				{/* <h2 className="Subheading-1bold-36ptleftdark-lato my-5">{paragraph('abs_mission_1')}</h2> */}
				<p className="my-5 text-center Bodytext-1regular-16ptleftdark-lato">{paragraph('abs_mission_1')}</p>
				<div className="about-us-mission-wrapper">
					{/* <ul className="mission-list Bodytext-1regular-16ptleftdark-lato">
						{state.home_text.map(row => {
							return (row.name.startsWith('abs_mission')) ? <li dangerouslySetInnerHTML={{__html: row.paragraph}}></li> : undefined;
						})}
					</ul> */}
					{/* <div>
						<img src="https://satprerna.org/satprerna_backend/images/aboutus/mission.webp" alt="" className="about-us-vision-img"/>
					</div> */}
				</div>
				<div className="text-center">
					<img src={require("../images/aboutus/deco.png")} alt="" className="mb-4" height="50px"/>
				</div>
                <div className="collaborate-header">{heading('abs_art')}</div>
                <div className="about-us-text-wrapper my-5">
                    <p className="text-justify Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('abs_art')}}></p>
                    <div>
                        <img src="https://satprerna.org/satprerna_backend/images/aboutus/about-us-bg.webp" alt="" className="my-5" height='100%' width='100%'/>
                    </div>
                    <p className="mt-4 text-justify Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: paragraph('abs_art')}}></p>
                </div>
            </div>
            <Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>
		</div>
	)
}

export default AboutUs;