import React from 'react';
import '../fonts.css'

const e12 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	Sri Yudhishthira Maharaja asked of Lord Sri Krishna, What is the
	name of that Ekadasi that occurs during
	the light fortnight (shukla paksha) of the month of
	Bhadrapada (August-September)? Who is the
	worshipable Deity for this Ekadasi, and what merit
	does one attain in observing it? Kindly reveal all of
	this to me my Lord.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord Lord Sri Krishna addressed His
	devoted Yudhishthira as follows, This Ekadasi, Oh
	Yudhishthira, is called Vamana Ekadasi, and it
	bestows upon those who observe it both great merit and ultimate
	liberation from material bondage. Therefore, because it removes all
	one’s sinful reactions, it is also called Jayanti Ekadasi. Just hearing
	of its glories frees one from all his past misdeeds. So auspicious is
	this fast that observing it bestows the same merit as that earned by
	performing a horse sacrifice. There is no better Ekadasi than this,
	because it awards liberation so easily. Thus if one truly desires
	freedom from the punishing material world, one should fast on
	Vamana Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	While observing this holy fast, a Vaishnava should lovingly worship
	the Supreme Lord in His form as Vamandev, the dwarf incarnation,
	whose eyes are like lotus petals. By doing so, he worships all other
	Deities as well, including Brahma, Vishnu, and Shiva, and at death he
	undoubtedly goes to that abode of Sri Hari. In all the three worlds
	there is no fast that is more important to observe. The reason this
	Ekadasi is so auspicious is that it celebrates the day when the
	sleeping Lord Vishnu turns over on His other side; thus it is also
	known as Parivartinii Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Maharaja Yudhishthira then asked of the Lord, Oh lord, please clear
	up a question I have. How is it that the Supreme Lord sleeps and
	then turns over on His side? Oh Lord, when You are asleep what
	happens to all the other living entities? Please also tell me how You
	bound the king of the demons, Bali Daityaraj (Bali Maharaja), as well
	as how one may please the Brahmans. How does one observe
	Chaturmasya, which You mention in the Chaturmasya-mahatmya of
	the Bhavishya Purana? Kindly be merciful to me and answer these
	questions.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord Sri Krishna replied, "Oh Yudhishthira, I will gladly
	narrate to you an historical event that, simply by hearing, eradicates
	all one’s sinful reactions.
	<hr style={{border: 'none'}} className="my-4"/>
	In the Treta-yuga there once was a king of the name Bali. Though
	born in a family dynasty of demons (daityas), he was very devoted
	to Me. He sang many Vedic hymns to Me and performed
	the homa rituals (fire sacrifices) just to satisfy Me. He respected the
	twice-born Brahmins and engaged them in performing sacrifices
	daily. This great soul had a quarrel with Indra, however, and
	eventually defeated him in battle. Bali took over his entire celestial
	kingdom, which I Myself had given to Indra. Therefore Indra and all
	the other devas (demigods), along with many great sages,
	approached Me and complained about Bali Maharaja. Bowing their
	heads to the ground and offering many sacred prayers from the
	Vedas, they worshipped Me along with their spiritual master,
	Brihaspati. Thus I agreed to appear on their behalf as a dwarf
	Vamanadev, My fifth incarnation.
	<hr style={{border: 'none'}} className="my-4"/>
	King Yudhishthira further enquired, Oh Lord, how was it possible for
	You to conquer such a powerful demon, and only by coming in the
	form of a dwarf Brahmin? Please explain this clearly, for I am Your
	faithful devotee.
	<hr style={{border: 'none'}} className="my-4"/>
	The Supreme Lord, Sri Krishna replied, Though a dwarf, I was a
	Brahman, and I approached that pious king Bali to ask him for alms
	in the form of land. I said, ‘Oh Bali, please give Me just three steps
	of land in charity. Such a small piece of land will be good as all the
	three worlds for Me.’ Bali agreed to grant My request without
	lengthy consideration. But as soon as he vowed to give Me the land,
	My body began to expand into a gigantic transcendental form. I
	covered the entire Earth with My feet, all the Bhuvarloka with My
	thighs, the Swarga heavens with My waist, Maharloka with My
	stomach, Janaloka with My chest, Tapoloka with My neck, and
	Satyaloka with My head and face. I covered the entire material
	creation. Indeed, all the planets of the universe, including the Sun
	and the Moon, were encompassed by My gigantic form.
	<hr style={{border: 'none'}} className="my-4"/>
	Seeing this astonishing pastime of Mine, all the demigods, including
	Indra and Shesha, the king of the snakes, began to sing Vedic hymns
	and offer prayers to Me. Then I took Bali by the hand and said to
	him, Oh sinless one, I have covered the whole Earth with one step
	and all the heavenly planets with the second. Now where shall I
	place My foot to measure the third step of land you promised Me?
	<hr style={{border: 'none'}} className="my-4"/>
	Upon hearing this, Bali Maharaja bowed down in humility and
	offered Me his head to place My third step. Oh Yudhishthira, I placed
	My foot on his head and sent him all the way to Patalaloka. Seeinghim thus humbled, I became very pleased and told Bali that
	henceforth I would permanently reside in his palace. Thereafter, on
	Parivartinii Ekadasi, which occurs during the light part of the month
	of Bhadra (August-September), Bali, the son of Virochana, installed
	a Deity form of Me in his residence.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh king, until Haribodhinii Ekadasi, which occurs during the light
	part of the month of Karttika, I continue to sleep in the ocean of
	milk. The merit one accumulates during this period is particularly
	powerful. One should therefore observe Parivartinii Ekadasi
	carefully. Indeed, it is especially purifying and thus cleanses one of
	all sinful reactions. On this day the faithful devotee should worship
	Lord, Vamanadeva, who is the supreme father, because on this day I
	turn over to sleep on My other side. If possible, on this day one
	should give a qualified person some yogurt mixed with rice, as well
	as some silver, and then remain awake throughout the night. This
	simple observance will free one of all material conditioning. One who
	observes this sacred Parivartinii Ekadasi in the way I have described
	will surely attain all kinds of happiness in this world and the
	kingdom of God in the hereafter. One who simply hears this
	narration with devotion will go to the abode of the demigods and
	shine there like the Moon itself, so powerful is observance of this
	Ekadasi. Indeed, this observance is as powerful as the performance
	of a thousand horse sacrifices.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of Parivartinii Ekadasi, or
	Vamana Ekadasi, which occurs during the light part of the month of
	Bhadrapada, from the Brahma-vaivarta Purana.
</p>

export default e12;
