import React from 'react'
import './header.css'
import './style.css'
import classNames from 'classnames';
import '../fonts.css'
import NavMobile from './NavMobile.js'
import { ButtonA, ButtonB } from './Button.js'
import {
	Link
} from "react-router-dom";


function Header(props) {

	const scrollDown = () => {
		window.scroll({
			top: props.amountToScroll,
			left: 0,
			behavior: 'smooth'
		  })
	}

	return(
		<div className={props.headerClass}>
			<div className={classNames("lpdf-header-wrapper")}>

				<nav className="navbar navbar-expand-lg navbar-custom mt-2 mt-lg-3 px-0">
					<Link to="/" className="link"><img className="lpdf-header-logo" src={require("../logo.png")} title="Home" alt="logo"/></Link>
					{/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
						<span className="navbar-toggler-icon"></span>
					</button>*/}
					<NavMobile/>
					<div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
						<ul className={classNames("navbar-nav", "my-5", "my-lg-0", props.textDark ? "" : "nav-dark")}>
							<li className="nav-item active mr-xl-3 mr-lg-1">
								<Link to="/" className={classNames("nav-link", "lpdf-tab-over", props.curr === "1"?"lpdf-tab-active":"")} href="#">Home</Link>
							</li>
							<li className="nav-item-seperator">|</li>
							<li className="nav-item mx-xl-3 mx-lg-1 position-relative nav-bar__submenu-holder">
								<Link to={window.location.pathname} className={classNames("nav-link", "lpdf-tab-over", props.curr === "2"?"lpdf-tab-active":"")} href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									About Us
								</Link>
								<ul className="nav-bar__submenu clear-fix nav-bar__submenu__first">
									<li>
										<ul className="">
											<li className="nav-bar__sub-item"><Link to="/AboutUs" className="nav-bar__sub-link" href="/biomarker-assays">Vision and Mission</Link></li>
											<li className="nav-bar__sub-item"><Link to="/ContactUs" className="nav-bar__sub-link" href="/biomarker-assays">Contact Us</Link></li>
											{/* <li className="nav-bar__sub-item"><Link to="/Collaborate" className="nav-bar__sub-link" href="/cell-based-assays">Collaborate</Link></li> */}
										</ul>
									</li>
								</ul>
							</li>
							<li className="nav-item-seperator">|</li>
							<li className="nav-item mx-xl-2 mx-lg-1 position-relative nav-bar__submenu-holder">
								<Link to={window.location.pathname} className={classNames("nav-link", "lpdf-tab-over", props.curr === "3"?"lpdf-tab-active":"")} href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Projects
								</Link>
								<ul className="nav-bar__submenu clear-fix nav-bar__submenu__second">
									<li>
										<ul className="">
											{/* <li className="nav-bar__sub-item"><Link to="/3G-Project" className="nav-bar__sub-link" href="/biomarker-assays">3G Project</Link><i style={{color: '#9a0808'}} className="fa fa-caret-right ml-2" aria-hidden="true"></i></li> */}
											{/* <li className="nav-bar__sub-item"><Link to="/3G-Project/Gaay" className="nav-bar__sub-link__sub" href="/cell-based-assays">- Gau Madhuram</Link></li> */}
											<li className="nav-bar__sub-item"><Link to="/3G-Project/ShriGitaPrerna" className="nav-bar__sub-link">Shri Gita Prerna</Link></li>
											<li className="nav-bar__sub-item"><Link to="/AnandUtsav" className="nav-bar__sub-link">Utsavam</Link></li>
											{/* <li className="nav-bar__sub-item"><Link to="/3G-Project/Ganga" className="nav-bar__sub-link__sub" href="/cell-based-assays">- Ganga Protection</Link></li>
											<li className="nav-bar__sub-item"><Link to="/AyushmanBhav" className="nav-bar__sub-link" href="/biomarker-assays">Ayushman Bhav</Link></li> */}
										</ul>
									</li>
									{/* <div style={{width: '20px'}}>
									</div>
									<li>
										<ul className="">
											<li className="nav-bar__sub-item"><Link to="/AnandUtsav" className="nav-bar__sub-link" href="/cell-based-assays">Utsavam</Link></li>
											<li className="nav-bar__sub-item"><Link to="/AscentOfLife" className="nav-bar__sub-link" href="/biochemical-assays">Ascent of Life</Link></li>
											<li className="nav-bar__sub-item"><Link to="/BodyServicingDay" className="nav-bar__sub-link" href="/cell-based-assays">Body Servicing Day</Link></li>
											<li className="nav-bar__sub-item"><Link to="/DivyaShishuRatna" className="nav-bar__sub-link" href="/biochemical-assays">Divya Shishu Ratna</Link></li>
											<li className="nav-bar__sub-item"><Link to="/WomenEmpowerment" className="nav-bar__sub-link" href="/cell-based-assays">'Devi' Being Women</Link></li>
											<li className="nav-bar__sub-item"><Link to="/WelfareActivities" className="nav-bar__sub-link" href="/biochemical-assays">Welfare Activities</Link></li>
										</ul>
									</li> */}
								</ul>
							</li>
							<li className="nav-item-seperator">|</li>
							<li className="nav-item mx-xl-3 mx-lg-1">
								<Link to="/Gallery" className={classNames("nav-link", "lpdf-tab-over", props.curr === "4"?"lpdf-tab-active":"")} href="#">Gallery</Link>
							</li>
							<li className="nav-item-seperator">|</li>
							<li className="nav-item mx-xl-3 mx-lg-1">
								<Link to="/PrabhuMeinTera" className={classNames("nav-link", "lpdf-tab-over", props.curr === "5"?"lpdf-tab-active":"")} href="#">Magazine</Link>
							</li>
							{/* <li className="nav-item-seperator">|</li>
							<li className="nav-item ml-xl-3 ml-lg-1">
								<a rel="noopener noreferrer" className={classNames("nav-link", "lpdf-tab-over")} href="http://www.prernamurti.com" target="_blank">Shriji</a>
							</li> */}
						</ul>
					</div>

					<div className="collapse navbar-collapse row justify-content-end align-content-end lpdf-smc-wrapper mt-n2" id="navbarNavDropdown">
			    		<div className="lpdf-smc col align-self-end">
		    				<a href="https://www.facebook.com/satprerna"><img 
		    					className="mr-1"
			    				src={require("../images/fb.svg")}
			    				alt='' width="35px" height="35px"
			    			/></a>
		    				<a href="https://www.instagram.com/satprerna"><img 
		    					className="ml-1"
		    					src={require("../images/insta.svg")}
		    					alt='' width="35px" height="35px"
		    				/></a>
		    			</div>
		    			<div className="lpdf-smc col">
		    				<a href="https://twitter.com/Satprerna"><img 
		    					className="mr-1"
		    					src={require("../images/twitter.svg")}
		    					alt='' width="35px" height="35px"
		    				/></a>
		    				<a href="https://www.youtube.com/@satprerna"><img 
		    					className="ml-1"
		    					src={require("../images/youtube.svg")}
		    					alt='' width="35px" height="35px"
		    				/></a>
			    		</div>
			    	</div>
			    </nav>

		    	<div className={classNames("introduction", props.textDark ? "light-text" : "")}>
		    		<h1 className={props.textDark ? "H3-48ptleftdark-kulimpark" : "H3-48ptleftwhite-kulimpark"}>{props.pageTitle}</h1>
		    		<h3 className={props.textDark ? "Subtitle-2regular-26ptleftdark-lato" : "Subtitle-2regular-26ptleftwhite-lato"}>
		    			{props.pageSubTitle}
		    		</h3>
		    		<Link to={props.linkto} className="link">{props.textDark ? <ButtonA buttonto={props.buttonto} onClick={scrollDown}/> : <ButtonB buttonto={props.buttonto} onClick={scrollDown}/>}</Link>
		    	</div>
			</div>

		    <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">
				<path d={props.svgPath1}></path>
				<path d={props.svgPath2}></path>
				<path d={props.svgPath3}></path>
			</svg>
			<div style={{backgroundColor: 'white', height: '10px'}}></div>
		</div>
	)
}


export default Header;
