import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import AyushmanBhavPage from '../../Pages/AyushmanBhav.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AyushmanBhav() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header" {...a11yProps(1)} />
                <Tab label="Carousel" {...a11yProps(2)} />
                <Tab label="Inspiring Video" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <AyushmanBhavPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER" textKey="ab_header" imageKeys={["/images/ayushman/ayushmanBhav.jpg", "/images/ayushman/ayushmanBhav_mobile.jpg"]} heading subheading/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="CAROUSEL 1" textKey="ab_c1_p1" imageKeys={["/images/ayushman/canva1.jpg"]} heading subheading titleS="Paragraph 1"/>
                <EditCard title="" textKey="ab_c1_p2" imageKeys={["/images/ayushman/canva2.jpg"]} subheading titleS="Paragraph 2"/>
                <EditCard title="" textKey="ab_c1_p3" imageKeys={["/images/ayushman/canva3.jpg"]} subheading titleS="Paragraph 3"/>
                <EditCard title="CAROUSEL 2" textKey="ab_c2_p1" imageKeys={["/images/ayushman/canva4.jpg"]} heading subheading titleS="Paragraph 1"/>
                <EditCard title="" textKey="ab_c2_p2" imageKeys={["/images/ayushman/canva5.jpg"]} subheading titleS="Paragraph 2"/>
                <EditCard title="" textKey="ab_c2_p3" imageKeys={["/images/ayushman/canva6.jpg"]} subheading titleS="Paragraph 3"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="Youtube Inspiring Video" textKey="ab_main_vdo" imageKeys={["/images/ayushman/wall1.jpg"]} heading subheading paragraph titleP="Youtube Video Link"/>
            </TabPanel>
        </div>
    );
}
