import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import Header from '../Components/Header.js'
import CarouselLeft from '../Components/CarouselLeft.js'
import CarouselRight from '../Components/CarouselRight.js'
import Projects from '../Components/Projects.js'
import Magazine from '../Components/Magazine.js'
import Suvakya from '../Components/Suvakya.js'
import Testimonials from '../Components/Testimonials.js'
import Footer from '../Components/Footer.js'

function Home(props) {
	const { state } = useContext(DataContext);

	const heading = (title) => {
		let heading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
				heading = item.heading
			}
		})
		return heading
	}

	const subheading = (title) => {
		let subheading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
				subheading = item.subheading
			}
		})
		return subheading
	}

	const paragraph = (title) => {
		let subheading = 'not found'
		// eslint-disable-next-line array-callback-return
		state.home_text.map(item => {
			if(item.name === title) {
				subheading = item.paragraph
			}
		})
		return subheading
	}

	const slideData = () => {
		//.sort((a, b) => Number(b.id) - Number(b.id))
		let arr = [...state.gallery.filter(row => row.project === 'suvakya').reverse().map(row => {
			return {
			  index: row.id,
			  src: row.image,
			}
		})];
		for(var i=0; i<arr.length; i=i+1) {
			arr[i].index = i;
		}
		return arr;
	  }

	let home_text;

    // if (!state.error && state.home_text) {
		
	// }
	home_text = <div>
			<Header
				headerClass="homePageHeader"
				pageTitle={heading('top_header')}
				pageSubTitle={subheading('top_header')}
				svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
				svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
				svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
				curr = "1"
				buttonto="About Us"
				linkto = "/AboutUs"
				textDark
			/>
			<CarouselLeft
				title={heading('carousel_1')}
				text={subheading('carousel_1')}
			/>
			<CarouselRight
				title={heading('carousel_2')}
				text={subheading('carousel_2')}
			/>
			<Projects
				title={(title) => heading(title)}
				text={(title) => subheading(title)}
			/>
			<Magazine
				title={(title) => heading(title)}
				text={(title) => subheading(title)}
				para={(title) => paragraph(title)}
			/>
			<Suvakya
				title={(title) => heading(title)}
				text={(title) => subheading(title)}
				slideData={() => slideData()}
			/>
			<Testimonials/>
			<Footer
				svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
				svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
				svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
	        />
		</div>;
	
	return (
		home_text
	);
}

export default Home;