import React from 'react';
import '../fonts.css'

const e8 = <p style={{fontSize: '1em'}} className="blog__text text-justify Bodytext-1regular-16ptcenterdark-lato px-3 px-sm-5">
	The saintly king Yudhishthira Maharaj said, Oh
	Keshava, what is the name of that Ekadasi that occurs
	during the light fortnight of the month of Ashadha
	(June - July)? Who is the worshipable Deity for the
	auspicious day, and what is the process for observing
	this event?
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna replied, Oh caretaker of this Earthly
	planet, I shall gladly tell you a wonderful historical
	event that the deva lord Brahma once narrated to his
	son Narad Muni. One day Narad Muni asked his father, ‘What is the
	name of the Ekadasi that comes during the light part of the month of
	Ashadha’, much as you did? ‘Kindly tell me how I should observe this
	Ekadasi and thus please the Supreme Lord, Sri Vishnu.’
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Brahma replied, ‘Oh great saintly orator, O best of all sages, Oh
	purest devotee of Lord Vishnu, your question is as usual excellent
	for all mankind. There is nothing better than Ekadasi, the day of Lord
	Sri Hari, in this or any other world. It nullifies even the worst sins if
	observed properly. For this reason I shall tell you about this
	Ashadha-shukla Ekadasi."
	<hr style={{border: 'none'}} className="my-4"/>
	Fasting on this Ekadasi purifies one of all sins and fulfills all ones
	desires. Therefore, whoever neglects to observe this sacred fast day
	is a good candidate for entering into hell. Ashadha-shukla Ekadasi is
	also famous as Padma Ekadasi. Just to please the Supreme Lord
	Vishnu, one should fast on this day. Listen carefully, Oh Narad, as I
	relate to you a wonderful historical event that was recorded in the
	scriptures regarding this Ekadasi. Just hearing this account destroys
	all kinds of sins, along with all obstacles on the path to spiritual
	perfection.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh son, there was once a saintly king in the Surya Vamsha (Sun
	dynasty) whose name was Mandhata. Because he always stood up
	for the truth, he was appointed emperor. He took care of his subjects
	as though they were his very own family members and children. On
	account of his piety and great religiosity, there was no pestilence,
	drought, or disease of any kind in his entire kingdom. All his subjects
	were very wealthy.
	<hr style={{border: 'none'}} className="my-4"/>
	Once, however, because of some sin in his kingdom, there was a
	drought for three years. The subjects found themselves beset by
	famine also. The lack of food grains made it impossible for them to
	perform the prescribed ritualistic worship. Finally, they all came
	before their beloved king in a great assembly and addressed him
	thus, ‘Oh king, you always see to our welfare, so we humbly beg
	your assistance now. Everyone and everything in this world needs
	water. Without water, almost everything is rendered useless or
	dead’.
	<hr style={{border: 'none'}} className="my-4"/>
	In His form as the clouds, the Supreme Lord is present throughout
	the sky and pours forth rains, from which grow the grains that
	maintain every living entity.
	<hr style={{border: 'none'}} className="my-4"/>
	Oh best ruler on Earth, please find some solutions to this problem
	and bring us to peace and prosperity once again.
	<hr style={{border: 'none'}} className="my-4"/>
	From rain grows the grain that maintains us. You are sad as there is
	no rain, I understand your sorrow, but after searching my past and
	present character I can honestly say that I find no sin. Still, for the
	good of all you subjects, I shall try to remedy the situation. Thinking
	in this way, King Mandhata assembled his army and entourage. He
	wandered here and there, seeking great sages in their Ashrams and
	inquiring about how to resolve the crisis in his kingdom. At last he
	came upon the ashram of Angira Muni, whose effulgence lit up all
	directions. Seated in his hermitage, Angira looked like a second
	Brahma. King Mandhata was very pleased to see that exalted sages,
	whose senses were completely under control. The king immediately
	dismounted his horse and offered his respectful obeisance’s at the
	lotus feet of Angira Muni. Then the king joined his palms and prayed
	for the Muni’s blessings. That saintly person reciprocated by blessing
	the king with sacred mantras; then he asked him about the welfare
	of his kingdom.
	<hr style={{border: 'none'}} className="my-4"/>
	Then Angira Muni asked the king why he had undertaken such a
	difficult journey into the forest, and the king told him of the
	affliction his kingdom was suffering. The king said, Oh great sage, I
	am ruling and maintaining my kingdom while following the Vedic
	injunctions, and thus I do not know the reason for the drought. To
	solve this mystery, I have approached you for help. Please help me
	relieve the suffering of my subjects.
	<hr style={{border: 'none'}} className="my-4"/>
	Angira Rishi said to the king, ‘The present age, Satya yuga, is the
	best of all ages, for in this age Dharma stands on all four legs
	(Truthfulness, Austerity, Mercy, and Cleanliness). In this age
	everyone respects Brahmins as the topmost members of society.
	Also, everyone fulfills his occupational duties, and only twice-born
	Brahmins are allowed to perform Vedic austerities and penance.
	Although this is a standard, Oh lion among kings, there is one shudra
	who is unlawfully performing the rites of austerity and penance in
	your kingdom. This is why there is no rain in your land. You should
	therefore punish this laborer with death, for by so doing you will
	remove the contamination inflicted by his actions and restore peace
	to your subjects.’
	<hr style={{border: 'none'}} className="my-4"/>
	The king then replied, How can I kill an offense-less performer of
	austerity and sacrifice? Please give me some spiritual solution.
	<hr style={{border: 'none'}} className="my-4"/>
	The great sage Angira Muni then said, Oh king, you should observe a
	fast on the Ekadasi that occurs during the light fortnight of the
	month of Ashadha. This auspicious day is named Padma Ekadasi, and
	by its influence plentiful rains and thus grains and other foodstuffs
	will surely return to your kingdom. This Ekadasi bestows perfection
	upon its faithful observers, removes all kinds of bad elements, and
	destroys all obstacles on the path to perfection. Oh king, you, your
	relatives, and your subjects should all observe this sacred Ekadasi
	fast. Then everything in your kingdom will undoubtedly return to
	normal. Upon hearing these words, the king offered his obeisances
	and then returned to his palace. When the Padma Ekadasi arrived,
	King Mandhata assembled all the Brahmins, Kshatriyas, Vaishyas
	and shudras in his kingdom and instructed them to strictly observe
	this important fast day. After they had observed it, the rains fell, just
	as the sage had predicted, and in due course of time there were
	abundant crops and a rich harvest of grain. By the mercy of the
	Supreme Lord Hrishikesha, the master of the senses, all the subjects
	of King Mandhata became extremely happy and prosperous.
	<hr style={{border: 'none'}} className="my-4"/>
	Therefore, Oh Narada, everyone should observe this Ekadasi fast
	very strictly, for it bestows all kinds of happiness, as well as ultimate
	liberation, upon the faithful devotee.
	<hr style={{border: 'none'}} className="my-4"/>
	Lord Sri Krishna concluded, My dear Yudhishthira, Padma Ekadasi is
	so powerful that one who simply reads or hears its glories becomes
	completely sinless. Oh Pandava, one who wishes to please Me should
	strictly observe this Ekadasi, which is also known as Deva-sayani
	Ekadasi.
	<hr style={{border: 'none'}} className="my-4"/>
	Deva-sayani, or Vishnu-sayani, indicates the day when Lord Vishnu
	goes to sleep with all the devas (demigods). It is said that after this
	day one should not perform any new auspicious ceremonies unti
	Devotthani Ekadasi (Haribodhini (Probodhini) Devotthaani (Utthana)
	Ekadasi), which occurs during the month of Kartika (October -
	November), because the devas (demigods), being asleep, cannot be
	invited to the sacrificial arena and because the Sun is traveling along
	its Southern course (Dakshinayanam). Lord Sri Krishna continued,
	Oh lion among kings, Yudhishthira Maharaj, whoever wants
	liberation should regularly observe a fast on this Ekadasi which is
	also the day when the Chaturmasya fast begins.
	<hr style={{border: 'none'}} className="my-4"/>
	Thus ends the narration of the glories of the Ashadha-shukla Ekadasi
	- also known as Padma Ekadasi or Deva-sayani Ekadasi - from the
	Bhavishya-uttara Purana.
</p>

export default e8;
