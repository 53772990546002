import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import './sgpform.css'
import { ButtonA } from './Button.js'
import { TextField, Select, MenuItem, Divider, Grid, Snackbar } from '@material-ui/core';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
    title: {
        flex: 1,
        fontWeight: 'normal',
        color: 'grey',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3)
    },
    text: {
        flex: 1,
        fontWeight: 'normal',
        color: '#333',
        marginBottom: theme.spacing(2)
    },
    close: {
        color: 'white',
        backgroundColor: '#9a0808',
        position: 'fixed',
        top: '20px',
        right: '20px',
        '&:hover': {
            color: 'white',
            backgroundColor: '#9a0808',
        }
    },
    uploadedImg: {
        height: '100px',
        paddingLeft: '20px'
    },
    qrImg: {
        height: '500px',
    },
    formControl: {
    },
    formControlCheckox: {
    }
}));

export default function NarmadaParikrama2024(props) {
    const classes = useStyles();
    // const Razorpay = require('razorpay');
    const [open, setOpen] = useState(props.openform);
    const [message, setmessage] = useState(false);
    const [formstatus, setformstatus] = useState('error');
    const [logtext, setlogtext] = useState('');
    const [issubmitting, setissubmitting] = useState(false);

    const [name, setname] = useState('');
    const [city, setcity] = useState('');
    const [age, setage] = useState('');
    const [gender, setgender] = useState('');
    const [email, setemail] = useState('');
    const [emailerr, setemailerr] = useState('');
    const [mobile, setmobile] = useState('');
    const [travel, settravel] = useState('');
    const [pan, setpan] = useState('');
    const [anyMedicalIssue, setanyMedicalIssue] = useState('');
    const [identity, setidentity] = useState('');

    const [price, setprice] = useState(0);
    const [userid, setuserid] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const countInPayment = (count) => {
        if (count > 0) return 1;
        else return 0;
    }

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'name')
            setname(value);
        if (name === 'city')
            setcity(value);
        if (name === 'gender')
            setgender(value);
        if (name === 'age') {
            let reg = /^\d+$/;
            if (value === '' || reg.test(value) === true)
                setage(value);
        }
        if (name === 'email') {
            setemail(value);
            // eslint-disable-next-line no-useless-escape
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (reg.test(value) === false) {
                setemailerr('Email is Not Correct');
                return;
            }
            else {
                setemailerr('');
            }
        }

        if (name === 'mobile') {
            let reg = /^\d+$/;
            if (value === '' || reg.test(value) === true)
                setmobile(value);
        }
        if (name === 'pan')
            setpan(value);
        if (name === 'travel') {
            settravel(value);
            if (value === 'Private Vehicle')
                setprice(0);
            // else if (value === 'Bus to and from + Parkirama on foot')
            //     setprice(351);
            else
                setprice(700);
        }
        if (name === 'anyMedicalIssue')
            setanyMedicalIssue(value);
        if (name === 'identity')
            setidentity(value);
    };

    const validateForm = () => {
        if (name && city && gender && age && email && mobile && travel && pan && anyMedicalIssue && identity) {
            return true;
        }
        else {
            return false;
        }
    }

    const sendEmail = (mail) => {
        emailjs.send("service_8m18sys", "template_uh4himl", {
            to_email: mail,
        }, "user_sY1m8Ki5Oafoc32NNK9TG");
    }

    const handleFormSubmit = (e) => {
        setmessage(!message)
        const formData = new FormData()
        if (validateForm()) {
            setmessage(true)
            setformstatus('info');
            setlogtext('Submitting. Please wait for atleast a minute.');

            formData.append("name", name);
            formData.append("city", city);
            formData.append("gender", gender);
            formData.append("age", age);
            formData.append("email", email);
            formData.append("mobile", mobile);
            formData.append("travel", travel);
            formData.append("pan", pan);
            formData.append("anyMedicalIssue", anyMedicalIssue);
            formData.append("identity", identity);
            if(price == 0)
                formData.append("payment_status", "₹0 (Not Applicable)");
            else
                formData.append("payment_status", "Not Paid");

            initiateSubmission(formData, e)
        }
        else {
            setmessage(true)
            setformstatus('error');
            setlogtext('Please fill all details');
        }
    };

    const initiateSubmission = async (formData, e) => {
        document.body.style.overflow = "scroll";
        setOpen(true);
        setformstatus('info');
        setlogtext('Submitting... Please wait !');
        setissubmitting(true);
        await axios({
            url: 'https://satprerna.org/satprerna_backend/home/insert_into_parikrama_2024.php',
            method: 'post',
            data: formData,
            responseType: 'json'
        })
            .then((response) => {
                setmessage(true);
                console.log(response);
                setuserid(response.data.id);
                if (price > 0) {
                    setformstatus('info');
                    setlogtext('Please complete payment...');
                    paymentHandler(response.data.id, e)
                }
                else {
                    setmessage(true);
                    setformstatus('success');
                    setlogtext('Submitted Successfully !');
                    setissubmitting(false);
                    sendEmail(email);

                    setemail('');
                    setname('');
                    setcity('');
                    setgender('');
                    setage('');
                    setemail('');
                    setmobile('');
                    settravel('');
                    setpan('');
                    setanyMedicalIssue('');
                    setidentity('');
                }
            })
            .catch(err => {
                console.log('err', err)
                setmessage(true)
                setformstatus('error')
                setlogtext('Something went wrong !')
                setissubmitting(false);
            })
    }

    const paymentHandler = (id, e) => {
        e.preventDefault();
        const options = {
            key: 'rzp_live_bH8zSPYWzsshms',
            amount: price * 100,
            name: 'Satprerna Trust',

            "handler": async function (response) {
                const paymentId = response.razorpay_payment_id;
                const formData = new FormData();
                formData.append("razorpay_payment_id", paymentId);
                formData.append("payment_status", "Paid ₹"+price);
                formData.append("id", id);
                document.body.style.overflow = "scroll";
                setOpen(true);
                setformstatus('info');
                setlogtext('Submitting...');
                await axios({
                    url: 'https://satprerna.org/satprerna_backend/home/update_parikrama_2024.php',
                    method: 'post',
                    data: formData,
                    responseType: 'json'
                })
                    .then(() => {
                        setmessage(true);
                        setformstatus('success');
                        setlogtext('Payment Completed Successfully !');
                        setissubmitting(false);
                        sendEmail(email);
                    })
                    .then(() => {
                        setemail('');
                        setname('');
                        setcity('');
                        setgender('');
                        setage('');
                        setemail('');
                        setmobile('');
                        settravel('');
                        setpan('');
                        setanyMedicalIssue('');
                        setidentity('');
                        setprice(0);
                    })
                    .catch(err => {
                        console.log('err', err)
                        setmessage(true)
                        setformstatus('error')
                        setlogtext('Something went wrong')
                        setissubmitting(false);
                    })
            },
            modal: {
                escape: false,
                ondismiss() {
                    document.body.style.overflow = "scroll";
                    setOpen(true);
                    setformstatus('error')
                    setlogtext('Payment Failed. Registration Cancelled !')
                    setissubmitting(false);
                }
            },
            prefill: {
                name: name,
                email: email
            },
            notes: {
                address: city
            },
            theme: {
                color: '#fa6400',
            },
        };
        const rzp1 = new window.Razorpay(options);
        setOpen(false);
        rzp1.open();
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
    }

    const handlePaymentScUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        let scBase64 = '';
        getBase64(file, (result) => {
            scBase64 = result;
            setidentity(scBase64);
            // uploadPaymentScFile(schoolname + "_payment_screenshot_" + file.name);
        });
    }

    const uploadPaymentScFile = (name) => {
        const formData = new FormData();
        setmessage(true);
        setformstatus('info');
        setlogtext('Please wait...');
        formData.append("dir", "../images/sgp_form/payment_screenshot/" + name);
        formData.append("image", identity);
        axios.post('https://satprerna.org/satprerna_backend/home/store_image_to_folder.php', formData)
            .then(() => {
                setmessage(true);
                setformstatus('success');
                setlogtext('Uploaded');
            });
    }

    return (
        <h1>Form closed</h1>
    );
    
    // return (
    //     <div>
    //         <div className="form-header">
    //             <AppBar className={classes.appBar}>
    //                 <Toolbar>
    //                     <Typography variant="h6" className={classes.title}>
    //                     </Typography>
    //                     <Link to="/"><IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
    //                         <CloseIcon />
    //                     </IconButton></Link>
    //                 </Toolbar>
    //             </AppBar>
    //             <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">
    //                 <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
    //                 <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
    //                 <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
    //             </svg>
    //         </div>
    //         <div className="form-wrapper">
    //             <h1 className="t1" >Narmada Parikrama 2024</h1>
    //             <h6 className="t2 my-3" >Online registration form</h6>
    //             <p>
    //                 <b>Terms & Conditions / नियम एवं शर्तें :</b><br /><br />
    //                 1- One Registration is valid for one person only. / एक पंजीकरण केवल एक व्यक्ति के लिए मान्य है ।<br /><br />

    //                 2- The Narmada Parikrama will start in the afternoon of May 4th, 2024. We kindly request all participants to arrive at Shri Leela Gaudham on time. / 4 May 2024 दोपहर को नर्मदा परिक्रमा का शुभारंभ होगा इसलिए हमारा अनुरोध है कि आप श्री लीला गौधाम समय पर पहुंचें ।<br /><br />

    //                 3- Please note that arriving late may result in the cancellation of your registration. / देर से आने पर आपका रजिस्ट्रेशन कैंसिल हो जाएगा ।<br /><br />

    //                 4- Only individuals who are registered will be permitted to participate in the parikrama. / केवल पंजीकृत व्यक्तियों को ही परिक्रमा करने की अनुमति होगी ।<br /><br />

    //                 5- There is no fee for those who will come to the Parikrama Spot by their own vehicle. The fare for doing the entire Parikrama by bus is ₹700. जो परिक्रमा स्थल तक अपने वाहन से आयेंगे उनके लिये यात्रा निःशुल्क है। पूरी परिक्रमा बस द्वारा करने हेतु किराया 700₹ है ।<br /><br />

    //                 6- For further details and queries, kindly contact: / अधिक जानकारी प्राप्त करने हेतु संपर्क करें: 9924466383 <br /><br />

    //                 7- Yellow-White would be the dress code. / पीला-सफेद ड्रेस कोड होगा । <br /><br />
    //             </p>
    //             {/* <Grid container className="my-5" alignItems="center">
    //                 <Grid item xs={12} md={12}>
    //                     <div className="mb-5 mb-lg-0">
    //                         <img className="aa" src={require("../images/3G/6.jpg")} alt="" width="100%" height="auto" />
    //                     </div>
    //                 </Grid>
    //             </Grid> */}
    //             <Divider />
    //             <div className="my-5">
    //                 <Typography variant="h6" className={classes.title}>
    //                     Personal Details
    //                 </Typography>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             autoComplete="name"
    //                             name="name"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="name"
    //                             label="Full Name"
    //                             value={name}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="age"
    //                             label="Age"
    //                             name="age"
    //                             autoComplete="age"
    //                             value={age}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <FormControl variant="outlined" fullWidth>
    //                             <InputLabel id="demo-simple-select-outlined-label-2">Gender</InputLabel>
    //                             <Select
    //                                 required
    //                                 id="gender"
    //                                 label="Gender"
    //                                 name="gender"
    //                                 autoComplete="gender"
    //                                 value={gender}
    //                                 onChange={handleInputChange}
    //                             >
    //                                 <MenuItem value={'Male'}>Male</MenuItem>
    //                                 <MenuItem value={'Female'}>Female</MenuItem>
    //                             </Select>
    //                         </FormControl>
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="city"
    //                             label="City"
    //                             name="city"
    //                             autoComplete="city"
    //                             value={city}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             autoComplete="email"
    //                             name="email"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="email"
    //                             label="Email Id"
    //                             value={email}
    //                             onChange={handleInputChange}
    //                         /><p style={{ color: 'orangered', fontSize: '0.8rem', marginLeft: '8px' }}>{emailerr}</p>
    //                     </Grid>
    //                     <Grid item xs={12} sm={6}>
    //                         <TextField
    //                             autoComplete="mobile"
    //                             name="mobile"
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="mobile"
    //                             label="Whatsapp Number"
    //                             value={mobile}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                 </Grid>

    //                 <Typography variant="h6" className={classes.title}>
    //                     Other Details
    //                 </Typography>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             fullWidth
    //                             id="pan"
    //                             label="PAN Number"
    //                             name="pan"
    //                             autoComplete="pan"
    //                             value={pan}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <FormControl variant="outlined" fullWidth>
    //                             <InputLabel id="demo-simple-select-outlined-label">Travel Preference</InputLabel>
    //                             <Select
    //                                 required
    //                                 id="travel"
    //                                 label="Travel Preference"
    //                                 name="travel"
    //                                 autoComplete="travel"
    //                                 value={travel}
    //                                 onChange={handleInputChange}
    //                             >
    //                                 <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'Private Vehicle'}>निजी वाहन द्वारा परिक्रमा स्थल तक ( निःशुल्क )</MenuItem>
    //                                 {/* <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'Bus to and from + Parkirama on foot'}>आश्रम से परिक्रमा स्थल तक आने-जाने का बस किराया ( ₹351 प्रति व्यक्ति )</MenuItem> */}
    //                                 <MenuItem style={{ whiteSpace: 'normal', wordWrap: 'break-all' }} value={'Bus to and from + Parkirama on Bus'}>पूरी परिक्रमा बस द्वारा करने हेतु किराया ( ₹700 प्रति व्यक्ति )</MenuItem>
    //                             </Select>
    //                         </FormControl>
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <TextField
    //                             variant="outlined"
    //                             required
    //                             multiline
    //                             fullWidth
    //                             id="anyMedicalIssue"
    //                             label="Do you have any Medical Issue ?"
    //                             name="anyMedicalIssue"
    //                             autoComplete="anyMedicalIssue"
    //                             value={anyMedicalIssue}
    //                             onChange={handleInputChange}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <input type="file" id="contained-button-file-2" accept="image/*" onChange={(e) => handlePaymentScUpload(e)} style={{ display: 'none' }} />
    //                         <label htmlFor="contained-button-file-2">
    //                             <Button variant="contained" color="primary" component="span" size="large">
    //                                 Upload Government Issued Aadhar Card
    //                             </Button>
    //                         </label>
    //                         <img src={identity} alt="" className={classes.uploadedImg} />
    //                     </Grid>
    //                 </Grid>
    //             </div>
    //             {price>0 && <Grid container spacing={1}>
    //             <p style={{ color: 'red' }}>
    //                 <b>Registration will be marked completed only after payment of ₹{price} is done in next step / अगले चरण में ₹{price} का भुगतान होने के बाद ही पंजीकरण पूर्ण माना जाएगा :</b><br /><br />
    //             </p>
    //             </Grid>}
    //             <Grid container spacing={1}>
    //                 <Grid container spacing={3}>
    //                     <Grid item xs={12} className="mt-4 text-right pmt-form-buttons">
    //                         <div className="ml-3 pmt-submit-btn"><ButtonA buttonto={!issubmitting ? (price == 0 ? "Submit Details" : "Proceed to Payment") : "Please Wait ..."} onClick={handleFormSubmit} disabled={issubmitting} /></div>
    //                     </Grid>
    //                     <Snackbar open={message} autoHideDuration={formstatus !== 'success' ? null : 20000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
    //                         <Alert onClose={() => setmessage(false)} severity={formstatus}>
    //                             {logtext}
    //                         </Alert>
    //                     </Snackbar>
    //                 </Grid>
    //             </Grid>
    //         </div>
    //     </div>
    // );
}
