import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from './TabPanel.js'
import EditCard from '../EditCard.js'
import AboutUsPage from '../../Pages/AboutUs.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '90vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '200px'
    }
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AboutUs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Preview" {...a11yProps(0)} />
                <Tab label="Header/Vision" {...a11yProps(1)} />
                <Tab label="Mission" {...a11yProps(2)} />
                <Tab label="Article" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0} preview>
                <AboutUsPage />
            </TabPanel>
            <TabPanel value={value} index={1} preview={false}>
                <EditCard title="HEADER & VISION" textKey="abs_header" imageKeys={["/images/decoration/gallery-bg.jpg", "/images/aboutus/vision.png"]} heading subheading paragraph titleS="Vision Subtitle" titleP="Vision Sentence"/>
            </TabPanel>
            <TabPanel value={value} index={2} preview={false}>
                <EditCard title="MISSION SUBTITLE" textKey="abs_mission_1" imageKeys={["/images/aboutus/mission.png"]} subheading/>
                <EditCard title="MISSION 1" textKey="abs_mission_1" paragraph titleP="Point 1"/>
                <EditCard title="MISSION 2" textKey="abs_mission_2" paragraph titleP="Point 2"/>
                <EditCard title="MISSION 3" textKey="abs_mission_3" paragraph titleP="Point 3"/>
                <EditCard title="MISSION 4" textKey="abs_mission_4" paragraph titleP="Point 4"/>
            </TabPanel>
            <TabPanel value={value} index={3} preview={false}>
                <EditCard title="ARTICLE" textKey="abs_art" imageKeys={["/images/aboutus/about-us-bg.jpg"]} heading subheading paragraph titleS="Paragraph 1" titleP="Paragraph 2"/>
            </TabPanel>
        </div>
    );
}
